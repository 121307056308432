import { Box, InputLabel, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import "./uploadFile.css";
import PropTypes from "prop-types";
import "react-image-crop/dist/ReactCrop.css";

const UploadFile = (props) => {
  const { value, label, className, name, inputClassName } = props;

  const [, setSelectedImage] = useState(null);
  const [blobURL, setBlobURL] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const blobURL = URL.createObjectURL(file);
      setSelectedImage(file);
      setBlobURL(blobURL);
    }
  };

  return (
    <Box className="main-input">
      <Stack spacing={1}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Box className="image-child">
          <label className={className}>
            <input
              type="file"
              name={name}
              id={name}
              value={value}
              className={inputClassName}
              accept="image/*"
              onChange={handleImageUpload}
            />
            <Typography className="profile-upload">+</Typography>
            <Typography>Upload</Typography>
          </label>
          {blobURL && (
            <div>
              <img
                src={blobURL}
                alt="Selected"
                width="200"
                className="cropped-image"
              />
            </div>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

UploadFile.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  name: PropTypes.string,
  setShow: PropTypes.any,
  show: PropTypes.any,
};

export default UploadFile;
