// import toast from "react-hot-toast";
import { toast } from "react-hot-toast";
import {
  BUYER_ADD,
  BUYER_ADD_FAIL,
  BUYER_ADD_SUCCESS,
  BUYER_DELETE,
  BUYER_DELETE_FAIL,
  BUYER_DELETE_SUCCESS,
  BUYER_LIST,
  BUYER_LIST_FAIL,
  BUYER_LIST_SUCCESS,
  BUYER_SINGLE,
  BUYER_SINGLE_FAIL,
  BUYER_SINGLE_SUCCESS,
  BUYER_SINGLE_UPDATE,
  BUYER_SINGLE_UPDATE_FAIL,
  BUYER_SINGLE_UPDATE_SUCCESS,
  BUYER_UPDATE,
  BUYER_UPDATE_FAIL,
  BUYER_UPDATE_SUCCESS,
  BUYER_WITH_WAREHOUSE,
  BUYER_WITH_WAREHOUSE_FAIL,
  BUYER_WITH_WAREHOUSE_SUCCESS,
} from "../actions/buyerActions";

const initialState = {
  buyerList: {},
  buyerAdd: {},
  buyerWareHouseList: {},
  buyerWareHouseAdd: {},
  buyerSingle: {},
  isLodding: false,
  error: "",
  isSuccess: false,
  buyerWithWareHouseData: false,
};

const buyerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case BUYER_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case BUYER_LIST_SUCCESS: {
      return {
        ...state,
        buyerList: payload.data,
        isLoading: false,
      };
    }

    case BUYER_LIST_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BUYER_ADD: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case BUYER_ADD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        buyerAdd: payload.data,
        isLoading: false,
        isSuccess: true,
      };
    }

    case BUYER_ADD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }
    case BUYER_DELETE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case BUYER_DELETE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    }

    case BUYER_DELETE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }
    case BUYER_SINGLE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case BUYER_SINGLE_SUCCESS: {
      return {
        ...state,
        buyerSingle: payload.data,
        isLoading: false,
      };
    }

    case BUYER_SINGLE_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BUYER_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case BUYER_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        // buyerUpdate: payload.data,
        isLoading: false,
        isSuccess: true,
      };
    }

    case BUYER_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }
    case BUYER_SINGLE_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case BUYER_SINGLE_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    }

    case BUYER_SINGLE_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case BUYER_WITH_WAREHOUSE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case BUYER_WITH_WAREHOUSE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        buyerWithWareHouseData: payload?.data,
      };
    }

    case BUYER_WITH_WAREHOUSE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
      };
    }

    default: {
      return { ...state, isSuccess: false };
    }
  }
};
export default buyerReducer;
