import { useCallback, useState } from "react";
import "./../warehouseform/wareHouseForm.css";
import PropTypes from "prop-types";

import {
  Box,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../../../@extended/button/ButtonComponent";
import UploadFile from "../../../../@extended/uploadfile/UploadFile";
import { useDispatch } from "react-redux";
import { BRANCH, UPDATE_BRANCH } from "../../../../../actions/settingActions";

const WareHouseForm = (props) => {
  const { toggleDrawer, id, listData } = props;
  const [show, setShow] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const isId = listData?.find((data) => data?.id === id);

  const dispatch = useDispatch();
  //add branch
  const branchAdd = useCallback((values) => {
    dispatch({ type: BRANCH, payload: values });
  });
  //updateBranch
  const updateBranch = useCallback((data) => {
    dispatch({ type: UPDATE_BRANCH, payload: data });
  }, []);

  return (
    <Box className={isMobile ? "main-div-1" : "mobile-main-div-1"}>
      <Formik
        initialValues={{
          name: isId ? isId?.name : "",
          slug: isId ? isId?.slug : "",
          email: isId ? isId?.email : "",
          mobile: isId ? isId?.mobile : "",
          darkLogo: isId ? isId?.darkLogo : "",
          logo: isId ? isId?.logo : "",
          billingAddress: isId ? isId?.billingAddress : "",
          bankDetail: isId ? isId?.bankDetail : "",
          signature: isId ? isId?.signature : "",
          termsAndConditions: isId
            ? isId?.termsAndConditions
            : "1. Goods once sold will not be taken back or exchanged 2. All disputes are subject to [ENTER_YOUR_CITY_NAME] jurisdiction only",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("Name is required"),
          slug: Yup.string().max(255).required("Slug is required"),
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          mobile: Yup.number().required("Phone Number is required"),
          logo: Yup.string(),
          darkLogo: Yup.string(),
          signature: Yup.string(),
          billingAddress: Yup.string().required("Address is required"),
          bankDetail: Yup.string().required("Bank Detail is required"),
          termsAndConditions: Yup.string().required(
            "Terms & Conditions is required",
          ),
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <div className="ware-houseform-div">
            <Form noValidate onSubmit={handleSubmit}>
              <Grid container className="warehouse-form-container">
                <Grid
                  className={
                    isMobile
                      ? "ware-houseform-main-grid"
                      : "mobile-form-main-grid"
                  }
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="ware-houseform-main-input"
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="name">Name*</InputLabel>
                      <OutlinedInput
                        id="name"
                        value={values.name}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Please Enter Name"
                        fullWidth
                        error={Boolean(touched.name && errors.name)}
                      />
                      {touched.name && errors.name && (
                        <FormHelperText error id="helper-text-name">
                          {errors.name}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="ware-houseform-main-input"
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="slug">Slug*</InputLabel>
                      <OutlinedInput
                        id="slug"
                        value={values.slug}
                        name="slug"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Please Enter Slug"
                        fullWidth
                        error={Boolean(touched.slug && errors.slug)}
                      />
                      {touched.slug && errors.slug && (
                        <FormHelperText error id="helper-text-slug">
                          {errors.slug}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8} className="ware-houseform-main-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email">Email*</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                      id="email"
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="demo@company.com"
                      // inputProps={{}}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText error id="helper-text-email">
                        {errors.email}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid className="ware-houseform-main-invoice">
                  <InputLabel
                    htmlFor="invoice"
                    className="ware-house-form-invoice"
                  >
                    {" "}
                    Show email on invoice
                  </InputLabel>
                  <FormGroup>
                    <FormControlLabel control={<Switch defaultChecked />} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={8} className="ware-houseform-main-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="mobile">Phone Number*</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.mobile && errors.mobile)}
                      type="number"
                      id="mobile"
                      value={values.mobile}
                      name="mobile"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Phone Number"
                      inputProps={{}}
                    />
                    {touched.mobile && errors.mobile && (
                      <FormHelperText error id="helper-text-mobile">
                        {errors.mobile}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid className="ware-houseform-main-invoice">
                  <InputLabel
                    htmlFor="invoice"
                    className="ware-house-form-invoice"
                  >
                    {" "}
                    Show phone on invoice
                  </InputLabel>
                  <FormGroup>
                    <FormControlLabel control={<Switch defaultChecked />} />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="ware-houseform-main-input"
                  onClick={() => setShow(true)}
                >
                  <Stack spacing={1}>
                    <UploadFile
                      name="logo"
                      // value={values?.logo}
                      className="custom-file-upload"
                      label="Logo"
                      inputClassName="input-file-hide"
                      show={show}
                    />
                    {touched.logo && errors.logo && (
                      <FormHelperText error id="helper-text-logo">
                        {errors.logo}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="ware-houseform-main-input">
                  <Stack spacing={1}>
                    <UploadFile
                      name="darkLogo"
                      // value={values?.darkLogo}
                      className="custom-file-upload"
                      label="Dark Logo"
                      inputClassName="input-file-hide"
                      show={show}
                    />
                    {touched.darkLogo && errors.darkLogo && (
                      <FormHelperText error id="helper-text-darklogo">
                        {errors.darkLogo}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className="ware-houseform-main-input"
                >
                  <Stack spacing={1}>
                    <Typography
                      variant="body2"
                      className="ware-houseform-address"
                    >
                      Billing Address
                    </Typography>
                    <TextField
                      multiline
                      name="billingAddress"
                      id="billingAddress"
                      value={values.billingAddress}
                      onBlur={handleBlur}
                      error={errors.billingAddress}
                      onChange={handleChange}
                      rows={2}
                      placeholder="Please Enter Billing Address"
                    />
                    {touched.billingAddress && errors.billingAddress && (
                      <FormHelperText error id="helper-text-address">
                        {errors.billingAddress}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={8} className="ware-houseform-note">
                  <Typography className="ware-houseform-color">
                    Note: Details added below will be shown on your invoices
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className="ware-houseform-main-input"
                >
                  <Stack spacing={1}>
                    <Typography
                      variant="body2"
                      className="ware-houseform-address"
                    >
                      Bank Details
                    </Typography>
                    <TextField
                      multiline
                      name="bankDetail"
                      id="bankDetail"
                      value={values.bankDetail}
                      onBlur={handleBlur}
                      error={errors.bankDetail}
                      onChange={handleChange}
                      rows={2}
                      placeholder="Please Enter Bank Details"
                    />
                    {touched.bankDetail && errors.bankDetail && (
                      <FormHelperText error id="helper-text-bankDetail">
                        {errors.bankDetail}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  xl={12}
                  className="ware-houseform-main-input"
                >
                  <Stack spacing={1}>
                    <InputLabel htmlFor="terms">Terms & Conditions*</InputLabel>
                    <TextField
                      multiline
                      maxRows={4}
                      id="termsAndConditions"
                      value={values.termsAndConditions}
                      name="termsAndConditions"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(
                        touched.termsAndConditions && errors.termsAndConditions,
                      )}
                    />
                    {touched.termsAndConditions &&
                      errors.termsAndConditions && (
                        <FormHelperText error id="helper-text-terms">
                          {errors.termsAndConditions}
                        </FormHelperText>
                      )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="ware-houseform-main-input">
                  <Stack spacing={1}>
                    <UploadFile
                      name="signature"
                      // value={values?.signature}
                      className="custom-file-upload"
                      label="Signature"
                      inputClassName="input-file-hide"
                      show={show}
                    />
                    {touched.signature && errors.signature && (
                      <FormHelperText error id="helper-text-logo">
                        {errors.signature}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Box className="common-form-drawer-btn-wrapper">
                <ButtonComponent
                  name={isId ? "Update" : "Create"}
                  isName={true}
                  Icon={SaveAsIcon}
                  type="submit"
                  onClick={(e) => {
                    if (id) {
                      updateBranch({ ...values, id: id });
                    } else {
                      branchAdd({ ...values });
                    }
                    toggleDrawer("right", false)(e);
                  }}
                />
                <Box className="common-form-drawer-btn-2">
                  <ButtonComponent
                    name="Cancel"
                    isName={true}
                    className="background-white"
                    onClick={toggleDrawer("right", false)}
                  />
                </Box>
              </Box>
            </Form>
          </div>
        )}
      </Formik>
    </Box>
  );
};
WareHouseForm.propTypes = {
  toggleDrawer: PropTypes.any,
  id: PropTypes.any,
  listData: PropTypes.any,
};

export default WareHouseForm;
