import React, { useCallback, useState } from "react";
import { Box, TextField } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import "../seller/Seller.css";

import AddIcon from "@mui/icons-material/Add";
import CustomizedModal from "../../@extended/customizedmodal/CustomizedModal";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../@extended/deletemodal/DeleteModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import BreadCrumb from "../../@extended/breadcrumbs/Breadcrumbs";
import { PRODUCT_DELETE } from "../../../actions/productActions";
import ProductForm from "./ProductForm";
import ProductTable from "./ProductTable";

const ProductModule = () => {
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [id, setId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );

  const gradeDelete = useCallback((data) => {
    dispatch({ type: PRODUCT_DELETE, payload: data });
  }, []);

  const columns = [
    {
      field: "no",
      headerName: "No",
      headerClassName: "email-query-header",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "email-query-header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="email-query-table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              onClick={() => {
                setOpen(true);
                setId(params?.row?.rowId);
                setRowData(params?.row);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "product" && data?.edit) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => {
                setDeleteModal(true);
                setId(params?.row?.rowId);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "product" && data?.delete) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box className="email-query-box">
      <BreadCrumb title="Product" perentTabName="Product" />
      <Box className="email-query-box-main">
        <Box>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={(e) => setSearch(e.target.value)}
            sx={{ width: "400px" }}
            inputProps={{ placeholder: "Search here...." }}
          />
        </Box>
        <ButtonComponent
          name="Add Product"
          isName={true}
          Icon={AddIcon}
          onClick={() => setOpen(true)}
          disabledbtn={userRoleAccess?.userPermissions?.find(
            (data) =>
              (data?.userModule == "product" && data?.add) ||
              userRoleAccess?.userRoleType == "admin",
          )}
        />
      </Box>
      <ProductTable columns={columns} search={search} />
      {open && (
        <CustomizedModal
          isHeadingName={true}
          heading="Product Form"
          open={open}
          onClick={() => {
            setOpen(false);
            setId("");
          }}
        >
          <ProductForm
            rowData={rowData}
            onClick={() => {
              setOpen(false);
              setId("");
            }}
            id={id}
          />
        </CustomizedModal>
      )}
      {deleteModal && (
        <DeleteModal
          isIcon={true}
          Icon={ErrorOutlineOutlinedIcon}
          open={deleteModal}
          onClick={() => {
            setDeleteModal(false);
            setId("");
          }}
          id={id}
          deleteData={gradeDelete}
          userModule="product"
        />
      )}
    </Box>
  );
};

export default ProductModule;
