//Branch Actions
export const BRANCH = "BRANCH";
export const BRANCH_SUCCESS = "BRANCH_SUCCESS";
export const BRANCH_FAIL = "BRANCH_FAIL";

export const LIST_BRANCH = "LIST_BRANCH";
export const LIST_BRANCH_SUCCESS = "LIST_BRANCH_SUCCESS";
export const LIST_BRANCH_FAIL = "LIST_BRANCH_FAIL";

export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS";
export const UPDATE_BRANCH_FAIL = "UPDATE_BRANCH_FAIL";

export const DELETE_BRANCH = "DELETE_BRANCH";
export const DELETE_BRANCH_SUCCESS = "DELETE_BRANCH_SUCCESS";
export const DELETE_BRANCH_FAIL = "DELETE_BRANCH_FAIL";

//Unit Action

export const UNIT = "UNIT";
export const UNIT_SUCCESS = "UNIT_SUCCESS";
export const UNIT_FAIL = "UNIT_FAIL";

export const LIST_UNIT = "LIST_UNIT";
export const LIST_UNIT_SUCCESS = "LIST_UNIT_SUCCESS";
export const LIST_UNIT_FAIL = "LIST_UNIT_FAIL";

export const LIST_SINGLE_UNIT = "LIST_SINGLE_UNIT";
export const LIST_SINGLE_UNIT_SUCCESS = "LIST_SINGLE_UNIT_SUCCESS";
export const LIST_SINGLE_UNIT_FAIL = "LIST_SINGLE_UNIT_FAIL";

export const UPDATE_UNIT = "UPDATE_UNIT";
export const UPDATE_UNIT_SUCCESS = "UPDATE_UNIT_SUCCESS";
export const UPDATE_UNIT_FAIL = "UPDATE_UNIT_FAIL";

export const DELETE_UNIT = "DELETE_UNIT";
export const DELETE_UNIT_SUCCESS = "DELETE_UNIT_SUCCESS";
export const DELETE_UNIT_FAIL = "DELETE_UNIT_FAIL";

//Tax Action

export const TAX = "TAX";
export const TAX_SUCCESS = "TAX_SUCCESS";
export const TAX_FAIL = "TAX_FAIL";

export const LIST_TAX = "LIST_TAX";
export const LIST_TAX_SUCCESS = "LIST_TAX_SUCCESS";
export const LIST_TAX_FAIL = "LIST_TAX_FAIL";

export const LIST_SINGLE_TAX = "LIST_SINGLE_TAX";
export const LIST_SINGLE_TAX_SUCCESS = "LIST_SINGLE_TAX_SUCCESS";
export const LIST_SINGLE_TAX_FAIL = "LIST_SINGLE_TAX_FAIL";

export const UPDATE_TAX = "UPDATE_TAX";
export const UPDATE_TAX_SUCCESS = "UPDATE_TAX_SUCCESS";
export const UPDATE_TAX_FAIL = "UPDATE_TAX_FAIL";

export const DELETE_TAX = "DELETE_TAX";
export const DELETE_TAX_SUCCESS = "DELETE_TAX_SUCCESS";
export const DELETE_TAX_FAIL = "DELETE_TAX_FAIL";

//Payment mode Actions

export const PAYMENT_MODE = "PAYMENT_MODE";
export const PAYMENT_MODE_SUCCESS = "PAYMENT_MODE_SUCCESS";
export const PAYMENT_MODE_FAIL = "PAYMENT_MODE_FAIL";

export const LIST_PAYMENT_MODE = "LIST_PAYMENT_MODE";
export const LIST_PAYMENT_MODE_SUCCESS = "LIST_PAYMENT_MODE_SUCCESS";
export const LIST_PAYMENT_MODE_FAIL = "LIST_PAYMENT_MODE_FAIL";

export const LIST_SINGLE_PAYMENT_MODE = "LIST_SINGLE_PAYMENT_MODE";
export const LIST_SINGLE_PAYMENT_MODE_SUCCESS =
  "LIST_SINGLE_PAYMENT_MODE_SUCCESS";
export const LIST_SINGLE_PAYMENT_MODE_FAIL = "LIST_SINGLE_PAYMENT_MODE_FAIL";

export const UPDATE_PAYMENT_MODE = "UPDATE_PAYMENT_MODE";
export const UPDATE_PAYMENT_MODE_SUCCESS = "UPDATE_PAYMENT_MODE_SUCCESS";
export const UPDATE_PAYMENT_MODE_FAIL = "UPDATE_PAYMENT_MODE_FAIL";

export const DELETE_PAYMENT_MODE = "DELETE_PAYMENT_MODE";
export const DELETE_PAYMENT_MODE_SUCCESS = "DELETE_PAYMENT_MODE_SUCCESS";
export const DELETE_PAYMENT_MODE_FAIL = "DELETE_PAYMENT_MODE_FAIL";

//Currencies

export const CURRENCIES = "CURRENCIES";
export const CURRENCIES_SUCCESS = "CURRENCIES_SUCCESS";
export const CURRENCIES_FAIL = "CURRENCIES_FAIL";

export const LIST_CURRENCIES = "LIST_CURRENCIES";
export const LIST_CURRENCIES_SUCCESS = "LIST_CURRENCIES_SUCCESS";
export const LIST_CURRENCIES_FAIL = "LIST_CURRENCIES_FAIL";

export const LIST_SINGLE_CURRENCIES = "LIST_SINGLE_CURRENCIES";
export const LIST_SINGLE_CURRENCIES_SUCCESS = "LIST_SINGLE_CURRENCIES_SUCCESS";
export const LIST_SINGLE_CURRENCIES_FAIL = "LIST_SINGLE_CURRENCIES_FAIL";

export const UPDATE_CURRENCIES = "UPDATE_CURRENCIES";
export const UPDATE_CURRENCIES_SUCCESS = "UPDATE_CURRENCIES_SUCCESS";
export const UPDATE_CURRENCIES_FAIL = "UPDATE_CURRENCIES_FAIL";

export const DELETE_CURRENCIES = "DELETE_CURRENCIES";
export const DELETE_CURRENCIES_SUCCESS = "DELETE_CURRENCIES_SUCCESS";
export const DELETE_CURRENCIES_FAIL = "DELETE_CURRENCIES_FAIL";

//CustomField Action

export const CUSTOM_FIELD = "CUSTOM_FIELD";
export const CUSTOM_FIELD_SUCCESS = "CUSTOM_FIELD_SUCCESS";
export const CUSTOM_FIELD_FAIL = "CUSTOM_FIELD_FAIL";

export const LIST_CUSTOM_FIELD = "LIST_CUSTOM_FIELD";
export const LIST_CUSTOM_FIELD_SUCCESS = "LIST_CUSTOM_FIELD_SUCCESS";
export const LIST_CUSTOM_FIELD_FAIL = "LIST_CUSTOM_FIELD_FAIL";

export const LIST_SINGLE_CUSTOM_FIELD = "LIST_SINGLE_CUSTOM_FIELD";
export const LIST_SINGLE_CUSTOM_FIELD_SUCCESS =
  "LIST_SINGLE_CUSTOM_FIELD_SUCCESS";
export const LIST_SINGLE_CUSTOM_FIELD_FAIL = "LIST_SINGLE_CUSTOM_FIELD_FAIL";

export const UPDATE_CUSTOM_FIELD = "UPDATE_CUSTOM_FIELD";
export const UPDATE_CUSTOM_FIELD_SUCCESS = "UPDATE_CUSTOM_FIELD_SUCCESS";
export const UPDATE_CUSTOM_FIELD_FAIL = "UPDATE_CUSTOM_FIELD_FAIL";

export const DELETE_CUSTOM_FIELD = "DELETE_CUSTOM_FIELD";
export const DELETE_CUSTOM_FIELD_SUCCESS = "DELETE_CUSTOM_FIELD_SUCCESS";
export const DELETE_CUSTOM_FIELD_FAIL = "DELETE_CUSTOM_FIELD_FAIL";
