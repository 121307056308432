import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PropTypes from "prop-types";
import "./../datepicker/datePicker.css";

const BasicDatePicker = (props) => {
  const { value, handleDateChange, disabled = false } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          size="small"
          // label="Date Picker"
          value={value}
          onChange={(e) => {
            handleDateChange(e?.toDate());
          }}
          disabled={disabled && disabled}
          className="fieldSet-date-picker"
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};
BasicDatePicker.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.any,
  handleDateChange: PropTypes.any,
  disabled: PropTypes.any,
};
export default BasicDatePicker;
