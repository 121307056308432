import React from "react";
import UserManage from "../../components/dashboard/usermanagement/UserManage";

const UserManagementPage = () => {
  return (
    <div>
      <UserManage />
    </div>
  );
};

export default UserManagementPage;
