import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./../profile/tabLayout.css";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Tablayout = ({ data, onClose, active }) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(active);

  const handleChange = (e, newValue) => {
    navigate(data[newValue].link);
    setValue(newValue);
  };

  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <>
      <Box
        className={!isLaptop ? "tab-layout-main-laptop" : "tab-layout-main-box"}
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          variant={"scrollable"}
          className={!isLaptop ? "tab-main-laptop" : "main-tabs"}
        >
          {data?.map((data, ind) => {
            return (
              <Tab key={ind} {...data} onClick={onClose} className="tab-list" />
            );
          })}
        </Tabs>
      </Box>
    </>
  );
};
Tablayout.propTypes = {
  onClose: PropTypes.any,
  data: PropTypes.any,
  active: PropTypes.any,
};
export default Tablayout;
