// import toast from "react-hot-toast";
import {
  LIST_EMAIL_QUERY,
  LIST_EMAIL_QUERY_FAIL,
  LIST_EMAIL_QUERY_SUCCESS,
} from "../actions/emailQueryActions";

const initialState = {
  listEmailQuery: {},
  error: "",
};
const emailQueryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    //EMAIL QUERY reducer
    case LIST_EMAIL_QUERY: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_EMAIL_QUERY_SUCCESS: {
      return {
        ...state,
        listEmailQuery: payload.data,
        isLoading: false,
      };
    }

    case LIST_EMAIL_QUERY_FAIL: {
      return {
        ...state,
      };
    }

    default: {
      return { ...state };
    }
  }
};
export default emailQueryReducer;
