import PropTypes from "prop-types";
import "./expenseCategoriesForm.css";

import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../../components/@extended/button/ButtonComponent";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import {
  EXPENSE_CATEGORY,
  UPDATE_EXPENSE_CATEGORY,
} from "../../../actions/expenseCategoryActions";

const ExpenseCategoryForm = (props) => {
  const { onClick, id, singleData } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const addExpenseCategory = useCallback((data) => {
    dispatch({ type: EXPENSE_CATEGORY, payload: data });
  }, []);
  const updateExpenseCategory = useCallback((data) => {
    dispatch({ type: UPDATE_EXPENSE_CATEGORY, payload: data });
  }, []);

  return (
    <Box
      className={
        isMobile ? "expense-category-main-div" : "expense-category-form-box"
      }
    >
      <Formik
        initialValues={{
          name: id ? singleData?.name : "",
          description: id ? singleData?.description : "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255)
            .required(" expense category Name is required"),
          description: Yup.string()
            .max(255)
            .required("Description is required"),
        })}
        onSubmit={(values) => {
          if (id) {
            updateExpenseCategory({ ...values, id: id });
          } else {
            addExpenseCategory({ ...values });
          }

          onClick();
        }}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">
                      Expense Category Name*
                    </InputLabel>
                    <OutlinedInput
                      id="name"
                      fullWidth
                      value={values?.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Expense Category Name"
                      error={Boolean(touched.name && errors.name)}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error id="helper-text-name">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="description">Description*</InputLabel>
                    <TextField
                      multiline
                      rows={8}
                      id="description"
                      fullWidth
                      value={values?.description}
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Description"
                      error={Boolean(touched.description && errors.description)}
                    />
                    {touched.description && errors.description && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors.description}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box className="expense-category-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={id ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                onClick={onClick}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
ExpenseCategoryForm.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
};

export default ExpenseCategoryForm;
