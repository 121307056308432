import toast from "react-hot-toast";
import {
  DELETE_SUBSCRIPTIONS,
  DELETE_SUBSCRIPTIONS_FAIL,
  DELETE_SUBSCRIPTIONS_SUCCESS,
  LIST_SINGLE_SUBSCRIPTIONS,
  LIST_SINGLE_SUBSCRIPTIONS_FAIL,
  LIST_SINGLE_SUBSCRIPTIONS_SUCCESS,
  LIST_SUBSCRIPTIONS,
  LIST_SUBSCRIPTIONS_FAIL,
  LIST_SUBSCRIPTIONS_SUCCESS,
  SUBSCRIPTIONS,
  SUBSCRIPTIONS_FAIL,
  SUBSCRIPTIONS_SUCCESS,
  UPDATE_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTIONS_FAIL,
  UPDATE_SUBSCRIPTIONS_SUCCESS,
} from "../actions/subscriptionsActions";
const initialState = {
  addSubscriptions: {},
  listSubscriptions: {},
  listSingleSubscriptions: {},
  updateSubscriptions: {},
  isAddSubscriptionsSuccess: false,
  isUpdataSubscriptionsSuccess: false,
  isDeleteSubscriptionsSuccess: false,
  error: "",
};

const subscriptionsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SUBSCRIPTIONS: {
      return {
        ...state,
        isLoading: true,
        isAddSubscriptionsSuccess: false,
      };
    }
    case SUBSCRIPTIONS_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        addSubscriptions: payload.user,
        isLoading: false,
        isAddSubscriptionsSuccess: true,
      };
    }

    case SUBSCRIPTIONS_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isAddSubscriptionsSuccess: false,
      };
    }

    case LIST_SUBSCRIPTIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        listSubscriptions: payload?.data,
        isLoading: false,
      };
    }

    case LIST_SUBSCRIPTIONS_FAIL: {
      return {
        ...state,
      };
    }

    case LIST_SINGLE_SUBSCRIPTIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_SINGLE_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        listSingleSubscriptions: payload?.data,
      };
    }

    case LIST_SINGLE_SUBSCRIPTIONS_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case UPDATE_SUBSCRIPTIONS: {
      return {
        ...state,
        isLoading: true,
        isUpdateSubscriptionsSuccess: false,
      };
    }

    case UPDATE_SUBSCRIPTIONS_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        updateSubscriptions: payload,
        isLoading: false,
        isUpdateSubscriptionsSuccess: true,
      };
    }

    case UPDATE_SUBSCRIPTIONS_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isUpdateSubscriptionsSuccess: false,
      };
    }
    case DELETE_SUBSCRIPTIONS: {
      return {
        ...state,
        isLoading: true,
        isDeleteSubscriptionsSuccess: false,
      };
    }

    case DELETE_SUBSCRIPTIONS_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteSubscriptionsSuccess: true,
      };
    }

    case DELETE_SUBSCRIPTIONS_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteSubscriptionsSuccess: false,
      };
    }

    default: {
      return { ...state };
    }
  }
};
export default subscriptionsReducer;
