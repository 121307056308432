import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CommonTable from "../../@extended/table/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import { BUYER_LIST } from "../../../actions/buyerActions";

const BuyerTablePage = (props) => {
  const { columns, type, search } = props;
  const dispatch = useDispatch();

  const buyerData = useSelector((data) => data?.buyer?.buyerList);
  const { totalResults = 0, limit, page } = buyerData;
  const [limitState, setLimit] = useState(10);
  const [pageState, setPage] = useState(1);
  const isSuccess = useSelector((data) => data?.buyer?.isSuccess);

  const buyerList = useCallback((values) => {
    dispatch({ type: BUYER_LIST, payload: values });
  }, []);

  const rows = buyerData?.results?.map((item, ind) => {
    return {
      id: ind + 1,
      no: ind + 1,
      firstName: item?.firstName,
      lastName: item?.lastName,
      mobile: item?.mobile,
      isVerify: item?.isVerified,
      email: item?.email,
      companyDocument: item?.companyDocument,
      gstCertificate: item?.gstCertificate,
      createBy: item?.createBy?.firstName,
      actions: "",
      rowId: item?.id,
    };
  });

  useEffect(() => {
    if (buyerData?.limit) {
      setLimit(buyerData?.limit);
    }
    if (buyerData?.page) {
      setPage(buyerData?.page);
    }
  }, [buyerData?.limit, buyerData?.page]);

  useEffect(() => {
    buyerList({ limitState, pageState, type, search });
  }, [isSuccess, limitState, pageState, type, search]);

  return (
    <Box className="email-query-box">
      <Box className="email-query-common-table-box-main">
        <Box className="email-query-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
            pageData={{ limit, page, totalResults, setLimit, setPage }}
          />
        </Box>
      </Box>
    </Box>
  );
};
BuyerTablePage.propTypes = {
  columns: PropTypes.any,
  type: PropTypes.any,
  search: PropTypes.text,
  setDeleteModal: PropTypes.any,
};
export default BuyerTablePage;
