import PropTypes from "prop-types";
import "../seller/SellerForm.css";

import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  BRAND_LIST,
  CATEGORY_LIST,
  GRADE_LIST,
  MFI_LIST,
  PRODUCT_ADD,
  // PRODUCT_SINGLE,
  PRODUCT_UPDATE,
  SUB_CATEGORY_LIST,
} from "../../../actions/productActions";
import AWS from "aws-sdk";

const ProductForm = (props) => {
  const { onClick, id, rowData } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const brandListData = useSelector((state) => state?.product?.brandList);
  const categoryListData = useSelector((state) => state?.product?.categoryList);
  const gradeListData = useSelector((state) => state?.product?.gradeList);
  const mfiListData = useSelector((state) => state?.product?.mfiList);
  const [recentValues, setRecentValues] = useState();
  const [recentLabels, setRecentLabels] = useState();
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );
  const subCategoryListData = useSelector(
    (state) => state?.product?.subCategoryList,
  );

  // const productList = useCallback((values) => {
  //   dispatch({ type: GRADE_LIST, payload: values });
  // }, []);

  const BrandList = useCallback((values) => {
    dispatch({ type: BRAND_LIST, payload: values });
  }, []);
  const CategoryList = useCallback((values) => {
    dispatch({ type: CATEGORY_LIST, payload: values });
  }, []);
  const SubCategoryList = useCallback((values) => {
    dispatch({ type: SUB_CATEGORY_LIST, payload: values });
  }, []);
  // const singleListProduct = useCallback((data) => {
  //   dispatch({ type: PRODUCT_SINGLE, payload: data });
  // }, []);
  const gradeList = useCallback((values) => {
    dispatch({ type: GRADE_LIST, payload: values });
  }, []);
  const mfiList = useCallback((values) => {
    dispatch({ type: MFI_LIST, payload: values });
  }, []);

  const productUpdate = useCallback((values) => {
    dispatch({ type: PRODUCT_UPDATE, payload: values });
  }, []);

  // const rowData = rowData;
  console.log("rowData ==>", rowData);
  console.log("rowData ==>", rowData);
  const [tdsLink, setTDSLink] = useState(id ? rowData?.tdsSheetLink : "");
  console.log("tdsLink ==>", tdsLink);

  // useEffect(() => {
  //   if (id) {
  //     singleListProduct(id);
  //   }
  // }, [id]);
  useEffect(() => {
    // productList({ type: "active", search: "" });
    BrandList({ limitState: 1000, pageState: 1 });
    CategoryList({ limitState: 1000, pageState: 1 });
    SubCategoryList({ limitState: 1000, pageState: 1 });
    gradeList({ limitState: 1000, pageState: 1 });
    mfiList({ limitState: 1000, pageState: 1 });
  }, []);

  const getProductName = (values) => {
    const key1 = categoryListData?.results?.find(
      (data) => data?.id === values?.category,
    );
    const key2 = subCategoryListData?.results?.find(
      (data) => data?.id === values?.subCategory,
    );
    const key3 = brandListData?.results?.find(
      (data) => data?.id === values?.brand,
    );
    const key5 = mfiListData?.results?.find((data) => data?.id === values?.mfi);
    const key4 = gradeListData?.results?.find(
      (data) => data?.id === values?.grade,
    );

    const lastData = `${
      (key1?.categoryName ? key1?.categoryName + "-" : "") || ""
    }${(key2?.subCategoryName ? key2?.subCategoryName + "-" : "") || ""}${
      (key3?.name ? key3?.name + "-" : "") || ""
    }${(key5?.name ? key5?.name + "-" : "") || ""}${key4?.name || ""}`;
    console.log("lastData ==>", lastData);
    return lastData;
  };

  const handleUpload = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    try {
      // Initialize S3 client
      const s3 = new AWS.S3({
        accessKeyId: "AKIA47CRXW4MNFSCJ3VM",
        secretAccessKey: "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",
        region: "ap-south-1",
      });

      const params = {
        Bucket: "crystal-polymer-dev",
        Key: `tds/${selectedFile.name}`,
        Body: selectedFile,
      };

      // Upload file to S3 bucket
      const response = await s3.upload(params).promise();
      console.log("response ==>", response);
      setTDSLink(response.Location);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <Box
      className={
        isMobile ? "expense-category-main-div" : "expense-category-form-box"
      }
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: id ? rowData?.name : "",
          brand: id ? rowData?.brand?.id || "" : "",
          category: id ? rowData?.category?.id : "",
          tdsSheetLink: id ? rowData?.tdsSheetLink : "",
          subCategory: id ? rowData?.subCategory?.id : "",
          grade: id ? rowData?.grade?.id : "",
          mfi: id ? rowData?.mfi?.id : "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name is required"),
          brand: Yup.string().required("Brand is required"),
          category: Yup.string().required("Category is required"),
          subCategory: Yup.string().required("Sub Category is required"),
          grade: Yup.string().required("Grade is required"),
          mfi: Yup.string().required("MFI is required"),
        })}
        onSubmit={(values) => {
          if (id) {
            productUpdate({
              ...values,
              tdsSheetLink: tdsLink,
              id: id,
              callback: onClick,
            });
          } else {
            dispatch({
              type: PRODUCT_ADD,
              payload: { ...values, tdsSheetLink: tdsLink, callback: onClick },
            });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className="expense-category-form-input"
                >
                  <InputLabel htmlFor="currency" sx={{ marginBottom: "8px" }}>
                    Material*
                  </InputLabel>
                  <Autocomplete
                    defaultValue={id ? rowData?.category?.categoryName : ""}
                    disablePortal
                    size="small"
                    options={
                      categoryListData?.results?.length > 0
                        ? categoryListData?.results?.map((recent) => ({
                            label: recent?.categoryName,
                            value: recent?.id,
                          }))
                        : []
                    }
                    // value={recentLabels}
                    name="category"
                    onChange={(event, value) => {
                      setRecentValues(value?.value);
                      setRecentLabels(value?.label);
                      setFieldValue("category", value?.value);
                      setFieldValue(
                        "name",
                        getProductName({
                          ...values,
                          category: value?.value,
                        }),
                      );
                    }}
                    error={touched.category && errors.category}
                    renderInput={(params) => {
                      const findVal = categoryListData?.results?.find(
                        (data) => data?.id === recentValues,
                      );
                      return (
                        <TextField
                          {...params}
                          value={findVal?.categoryName}
                          placeholder="Material"
                        />
                      );
                    }}
                  />
                  {touched.category && errors.category && (
                    <FormHelperText error id="helper-text-name">
                      {errors.category}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className="expense-category-form-input"
                >
                  <InputLabel htmlFor="currency" sx={{ marginBottom: "8px" }}>
                    Material Type*
                  </InputLabel>
                  <Autocomplete
                    defaultValue={
                      id ? rowData?.subCategory?.subCategoryName : ""
                    }
                    disablePortal
                    size="small"
                    options={
                      subCategoryListData?.results?.length > 0
                        ? subCategoryListData?.results?.map((recent) => ({
                            label: recent?.subCategoryName,
                            value: recent?.id,
                          }))
                        : []
                    }
                    // value={recentLabels}
                    name="subCategory"
                    onChange={(event, value) => {
                      setRecentValues(value?.value);
                      setRecentLabels(value?.label);
                      setFieldValue("subCategory", value?.value);
                      setFieldValue(
                        "name",
                        getProductName({
                          ...values,
                          subCategory: value?.value,
                        }),
                      );
                    }}
                    error={touched.subCategory && errors.subCategory}
                    renderInput={(params) => {
                      const findVal = subCategoryListData?.results?.find(
                        (data) => data?.id === recentValues,
                      );
                      return (
                        <TextField
                          {...params}
                          value={findVal?.subCategoryName}
                          placeholder="Material Type"
                        />
                      );
                    }}
                  />
                  {touched.subCategory && errors.subCategory && (
                    <FormHelperText error id="helper-text-name">
                      {errors.subCategory}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className="expense-category-form-input"
                >
                  <InputLabel htmlFor="currency" sx={{ marginBottom: "8px" }}>
                    Brand*
                  </InputLabel>
                  <Autocomplete
                    defaultValue={id ? rowData?.brand?.name : ""}
                    disablePortal
                    size="small"
                    options={
                      brandListData?.results?.length > 0
                        ? brandListData?.results?.map((recent) => ({
                            label: recent?.name,
                            value: recent?.id,
                          }))
                        : []
                    }
                    value={recentLabels}
                    name="brand"
                    onChange={(event, value) => {
                      setRecentValues(value?.value);
                      setRecentLabels(value?.label);
                      setFieldValue("brand", value?.value);
                      setFieldValue(
                        "name",
                        getProductName({
                          ...values,
                          brand: value?.value,
                        }),
                      );
                    }}
                    error={touched.brand && errors.brand}
                    renderInput={(params) => {
                      const findVal = brandListData?.results?.find(
                        (data) => data?.id === recentValues,
                      );
                      return (
                        <TextField
                          {...params}
                          value={findVal?.name}
                          placeholder="Brand"
                        />
                      );
                    }}
                  />
                  {touched.brand && errors.brand && (
                    <FormHelperText error id="helper-text-name">
                      {errors.brand}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className="expense-category-form-input"
                >
                  <InputLabel htmlFor="currency" sx={{ marginBottom: "8px" }}>
                    MFI*
                  </InputLabel>
                  <Autocomplete
                    defaultValue={id ? rowData?.mfi?.name : ""}
                    disablePortal
                    size="small"
                    options={
                      mfiListData?.results?.length > 0
                        ? mfiListData?.results?.map((recent) => ({
                            label: recent?.name,
                            value: recent?.id,
                          }))
                        : []
                    }
                    value={recentLabels}
                    name="mfi"
                    onChange={(event, value) => {
                      setRecentValues(value?.value);
                      setRecentLabels(value?.label);
                      setFieldValue("mfi", value?.value);
                      setFieldValue(
                        "name",
                        getProductName({
                          ...values,
                          mfi: value?.value,
                        }),
                      );
                    }}
                    error={touched.mfi && errors.mfi}
                    renderInput={(params) => {
                      const findVal = mfiListData?.results?.find(
                        (data) => data?.id === recentValues,
                      );
                      return (
                        <TextField
                          {...params}
                          value={findVal?.name}
                          placeholder="MFI"
                        />
                      );
                    }}
                  />
                  {touched.mfi && errors.mfi && (
                    <FormHelperText error id="helper-text-name">
                      {errors.mfi}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className="expense-category-form-input"
                >
                  <InputLabel htmlFor="currency" sx={{ marginBottom: "8px" }}>
                    GRADE*
                  </InputLabel>
                  <Autocomplete
                    defaultValue={id ? rowData?.grade?.name : ""}
                    disablePortal
                    size="small"
                    options={
                      gradeListData?.results?.length > 0
                        ? gradeListData?.results?.map((recent) => ({
                            label: recent?.name,
                            value: recent?.id,
                          }))
                        : []
                    }
                    value={recentLabels}
                    name="grade"
                    onChange={(event, value) => {
                      setRecentValues(value?.value);
                      setRecentLabels(value?.label);
                      setFieldValue("grade", value?.value);
                      setFieldValue(
                        "name",
                        getProductName({
                          ...values,
                          grade: value?.value,
                        }),
                      );
                    }}
                    error={touched.grade && errors.grade}
                    renderInput={(params) => {
                      const findVal = gradeListData?.results?.find(
                        (data) => data?.id === recentValues,
                      );
                      return (
                        <TextField
                          {...params}
                          value={findVal?.name}
                          placeholder="MFI"
                        />
                      );
                    }}
                  />
                  {touched.grade && errors.grade && (
                    <FormHelperText error id="helper-text-name">
                      {errors.grade}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">Product Name*</InputLabel>
                    <OutlinedInput
                      id="categoryName"
                      size="large"
                      fullWidth
                      value={values?.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Name"
                      error={Boolean(touched.name && errors.name)}
                      readOnly
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error id="helper-text-name">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="tdsSheetLink">TDS Sheet</InputLabel>
                    {tdsLink && (
                      <a href={tdsLink} target="_blank" rel="noreferrer">
                        Download TDS Sheet
                      </a>
                    )}
                    <TextField
                      id="tdsSheetLink"
                      fullWidth
                      type="file"
                      accept="application/pdf"
                      name="tdsSheetLink"
                      onBlur={handleBlur}
                      onChange={(e) => handleUpload(e)}
                      placeholder="Please Enter address"
                      error={Boolean(
                        touched.tdsSheetLink && errors.tdsSheetLink,
                      )}
                    />
                    {touched.tdsSheetLink && errors.tdsSheetLink && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors.tdsSheetLink}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box className="expense-category-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={id ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "product" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                onClick={onClick}
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "product" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

ProductForm.propTypes = {
  onClick: PropTypes.any,
  rowData: PropTypes.any,
  id: PropTypes.any,
};

export default ProductForm;
