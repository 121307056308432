import { Box } from "@mui/material";
import React from "react";
import NotifyMessage from "../../components/@extended/notify/NotifyMessage";
import BreadCrumb from "../../components/@extended/breadcrumbs/Breadcrumbs";
import Expense from "../../components/dashboard/expense/Expense";

const ExpensePage = () => {
  return (
    <Box>
      <NotifyMessage
        heading="Trial Plan"
        description="You are on a trial version! Your trial ends on 24-02-2024"
        backgroundColor="var(--notify-bg-color)"
        borderColor="var(--notify-border-color)"
        className="btn-change-plan"
      />
      <BreadCrumb
        title="Expense"
        perentTabName="Expenses"
        childTabName="Expense"
        // openMenuClick={toggleDrawer("bottom", true)}
      />
      <Expense />
    </Box>
  );
};
export default ExpensePage;
