import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonComponent from "../button/ButtonComponent";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import MailIcon from "@mui/icons-material/Mail";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import "./../../@extended/notify/notifyMessage.css";
import PropTypes from "prop-types";

const NotifyMessage = (props) => {
  const {
    description,
    heading,
    backgroundColor,
    borderColor,
    btnColor,
    variant,
    className,
    name,
    Icon,
    cardIcon,
    EndIcon,
  } = props;
  return (
    <Box
      position="static"
      className="notify-main-box"
      style={{
        backgroundColor: `${backgroundColor}`,
        border: `1px solid ${borderColor}`,
      }}
    >
      <Box
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        className="notify-message-box"
      >
        {cardIcon === "MailIcon" ? (
          <MailIcon className="notify-message-mail-icon" />
        ) : (
          <CardMembershipIcon className="notify-message-icon" />
        )}
        <Box>
          <Typography component="div" className="notify-message-trial-plan">
            {heading}
          </Typography>
          <Typography component="div" className="notify-message-trial">
            {description}
          </Typography>
        </Box>
      </Box>

      <Box className="btn-change-plan-box">
        <ButtonComponent
          className={className} // default className btn-change-plan
          isName={true}
          Icon={Icon ? Icon : ""}
          name={name ? name : "Change Plan"}
          EndIcon={EndIcon ? "" : KeyboardDoubleArrowRightIcon}
          color={btnColor}
          variant={variant}
        />
      </Box>
    </Box>
  );
};
NotifyMessage.propTypes = {
  description: PropTypes.any,
  heading: PropTypes.any,
  backgroundColor: PropTypes.any,
  borderColor: PropTypes.any,
  btnColor: PropTypes.any,
  variant: PropTypes.any,
  className: PropTypes.any,
  name: PropTypes.any,
  Icon: PropTypes.any,
  cardIcon: PropTypes.any,
  EndIcon: PropTypes.any,
};
export default NotifyMessage;
