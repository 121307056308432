import { deleteRequest, get, patch, post } from "../utils/api";

export const addUserRole = (data) => {
  return post(`/admin/user-role/`, data);
};

export const listRole = () => {
  return get(`/admin/user-role/`);
};

export const singleRole = (id) => {
  return get(`/admin/user-role/${id}`);
};

export const updateRole = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/user-role/${id}`, data);
};

export const deleteRole = (id) => {
  return deleteRequest(`/admin/user-role/${id}`);
};
export const roleApi = {
  addUserRole,
  listRole,
  singleRole,
  updateRole,
  deleteRole,
};
