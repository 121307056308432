import { toast } from "react-hot-toast";
import {
  ADD_COMPANY,
  ADD_COMPANY_FAIL,
  ADD_COMPANY_SUCCESS,
  ADD_LIST_PLAN,
  ADD_LIST_PLAN_FAIL,
  ADD_LIST_PLAN_SUCCESS,
  DELETE_COMPANY,
  DELETE_COMPANY_FAIL,
  DELETE_COMPANY_SUCCESS,
  LIST_COMPANY,
  LIST_COMPANY_FAIL,
  LIST_COMPANY_SUCCESS,
  LIST_PLAN,
  LIST_PLAN_FAIL,
  LIST_PLAN_SUCCESS,
  PAYMENT_MODE_LIST,
  PAYMENT_MODE_LIST_FAIL,
  PAYMENT_MODE_LIST_SUCCESS,
  SINGLE_COMPANY_LIST,
  SINGLE_COMPANY_LIST_FAIL,
  SINGLE_COMPANY_LIST_SUCCESS,
  SUBSCRIPTION_LIST_PLAN,
  SUBSCRIPTION_LIST_PLAN_FAIL,
  SUBSCRIPTION_LIST_PLAN_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_FAIL,
  UPDATE_COMPANY_SUCCESS,
} from "../actions/companyActions";

const initialState = {
  listCompany: {},
  isLoading: false,
  isSuccess: false,
  addCompany: {},
  singleCompany: {},
  isSingleCompanyLoading: false,
  deleteCompany: {},
  listPlan: [],
  subscriptionPlanList: {},
  paymentModeList: {},
  addListPlan: {},
  error: "",
};
const companyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LIST_COMPANY: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_COMPANY_SUCCESS: {
      return {
        ...state,
        listCompany: payload.data,
        isLoading: false,
      };
    }

    case LIST_COMPANY_FAIL: {
      return {
        ...state,
      };
    }

    case SINGLE_COMPANY_LIST: {
      return {
        ...state,
        isSingleCompanyLoading: true,
      };
    }

    case SINGLE_COMPANY_LIST_SUCCESS: {
      return {
        ...state,
        singleCompany: payload.data,
        isSingleCompanyLoading: false,
      };
    }

    case SINGLE_COMPANY_LIST_FAIL: {
      return {
        ...state,
        isSingleCompanyLoading: false,
      };
    }

    case ADD_COMPANY: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case ADD_COMPANY_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        addCompany: payload.data,
        isLoading: false,
        isSuccess: true,
      };
    }

    case ADD_COMPANY_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case UPDATE_COMPANY: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case UPDATE_COMPANY_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        updateCompany: payload.data,
        isLoading: false,
        isSuccess: true,
      };
    }

    case UPDATE_COMPANY_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case DELETE_COMPANY: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case DELETE_COMPANY_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        deleteCompany: payload.data,
        isLoading: false,
        isSuccess: true,
      };
    }

    case DELETE_COMPANY_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
      };
    }

    case LIST_PLAN: {
      return {
        ...state,
      };
    }

    case LIST_PLAN_SUCCESS: {
      return {
        ...state,
        listPlan: payload.data,
      };
    }

    case LIST_PLAN_FAIL: {
      return {
        ...state,
      };
    }

    case SUBSCRIPTION_LIST_PLAN: {
      return {
        ...state,
      };
    }

    case SUBSCRIPTION_LIST_PLAN_SUCCESS: {
      return {
        ...state,
        subscriptionPlanList: payload.data,
      };
    }

    case SUBSCRIPTION_LIST_PLAN_FAIL: {
      return {
        ...state,
      };
    }

    case PAYMENT_MODE_LIST: {
      return {
        ...state,
      };
    }

    case PAYMENT_MODE_LIST_SUCCESS: {
      return {
        ...state,
        paymentModeList: payload.data,
      };
    }

    case PAYMENT_MODE_LIST_FAIL: {
      return {
        ...state,
      };
    }

    case ADD_LIST_PLAN: {
      return {
        ...state,
        // isLoading: true,
      };
    }

    case ADD_LIST_PLAN_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        addListPlan: payload.data,
        // isLoading: false,
      };
    }

    case ADD_LIST_PLAN_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
      };
    }

    default: {
      return { ...state };
    }
  }
};
export default companyReducer;
