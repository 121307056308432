export const SUBSCRIPTIONS = "SUBSCRIPTIONS";
export const SUBSCRIPTIONS_SUCCESS = "SUBSCRIPTIONS_SUCCESS";
export const SUBSCRIPTIONS_FAIL = "SUBSCRIPTIONS_FAIL";

export const LIST_SUBSCRIPTIONS = "LIST_SUBSCRIPTIONS";
export const LIST_SUBSCRIPTIONS_SUCCESS = "LIST_SUBSCRIPTIONS_SUCCESS";
export const LIST_SUBSCRIPTIONS_FAIL = "LIST_SUBSCRIPTIONS_FAIL";

export const LIST_SINGLE_SUBSCRIPTIONS = "LIST_SINGLE_SUBSCRIPTIONS";
export const LIST_SINGLE_SUBSCRIPTIONS_SUCCESS =
  "LIST_SINGLE_SUBSCRIPTIONS_SUCCESS";
export const LIST_SINGLE_SUBSCRIPTIONS_FAIL = "LIST_SINGLE_SUBSCRIPTIONS_FAIL";

export const UPDATE_SUBSCRIPTIONS = "UPDATE_SUBSCRIPTIONS";
export const UPDATE_SUBSCRIPTIONS_SUCCESS = "UPDATE_SUBSCRIPTIONS_SUCCESS";
export const UPDATE_SUBSCRIPTIONS_FAIL = "UPDATE_SUBSCRIPTIONS_FAIL";

export const DELETE_SUBSCRIPTIONS = "DELETE_SUBSCRIPTIONS";
export const DELETE_SUBSCRIPTIONS_SUCCESS = "DELETE_SUBSCRIPTIONS_SUCCESS";
export const DELETE_SUBSCRIPTIONS_FAIL = "DELETE_SUBSCRIPTIONS_FAIL";
