import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import "../seller/Seller.css";
import BreadCrumb from "../../@extended/breadcrumbs/Breadcrumbs";
import ProductTable from "./SellingProductTable";

const SellingProductModule = () => {
  const [search, setSearch] = useState("");

  const columns = [
    {
      field: "no",
      headerName: "No",
      headerClassName: "email-query-header",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "seller",
      headerName: "Seller",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "warehouse",
      headerName: "Location",
      flex: 1,
      headerClassName: "email-query-header",
    },
  ];

  return (
    <Box className="email-query-box">
      <BreadCrumb title="Seller Product" perentTabName="Seller Product" />
      <Box className="email-query-box-main">
        <Box>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={(e) => setSearch(e.target.value)}
            sx={{ width: "400px" }}
            inputProps={{ placeholder: "Search here...." }}
          />
        </Box>
      </Box>
      <ProductTable columns={columns} search={search} />
    </Box>
  );
};

export default SellingProductModule;
