import React, { Suspense, lazy } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loader from "./components/Loader";
import ThemeCustomization from "./themes";
import Setting from "./pages/dashboard/Setting";
import ExpenseCategoriesPage from "./pages/dashboard/ExpenseCategory";
import MainLayout from "./components/dashboard/layout/MainLayout";
import Dashboard from "./../src/pages/dashboard/Dashboard";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./variables.css"; // variables
import PosPage from "./pages/dashboard/PosPage";
import { Toaster } from "react-hot-toast";
import CompaniesPage from "./pages/dashboard/Companies";
import ExpensePage from "./pages/dashboard/Expense";
import Subscription from "./components/dashboard/ subscription/ Subscription";
import EmailQueryPage from "./pages/dashboard/EmailQuery";
import Transactions from "./components/superadmindashboard/transactions/Transactions";
import SellerModule from "./components/dashboard/seller/SellerModule";
import SellerWareHouseModule from "./components/dashboard/sellerWareHouse/SellerWareHouseModule";
import BuyerModule from "./components/dashboard/buyer/BuyerModule";
import BuyerWareHouseModule from "./components/dashboard/buyerWareHouse/BuyerWareHouseModule";
import CategoryModule from "./components/dashboard/category/CategoryModule";
import SubCategoryModule from "./components/dashboard/subCategory/SubCategoryModule";
import BrandModule from "./components/dashboard/brand/BrandModule";
import GradeModule from "./components/dashboard/grade/GradeModule";
import MFIModule from "./components/dashboard/mfi/MFIModule";
import TransportChargeModule from "./components/dashboard/transportcharge/TransportChargeModule";
import ProductModule from "./components/dashboard/product/ProductModule";
import SellingProductModule from "./components/dashboard/product/SellingProductModule";
import BuyerEnquiryModule from "./components/dashboard/product/BuyerEnquiryModule";
import UserManagementPage from "./pages/dashboard/UserManagement";
import OrderModule from "./components/dashboard/order/OrderModule";
import LocationModule from "./components/dashboard/location/LocationModule";
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const Forgot = lazy(() => import("./pages/Forgot"));

function App() {
  return (
    <div className="App">
      <ThemeCustomization>
        <Suspense fallback={<Loader />}>
          <Router>
            <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/forgot" element={<Forgot />} />

              {/* ----- dashboard ----- */}

              <Route exact path="/" element={<MainLayout />}>
                <Route exact path="/admin/dashboard" element={<Dashboard />} />
                <Route exact path="/admin/customer" element={<Dashboard />} />
                <Route exact path="/admin/brands" element={<Dashboard />} />
                {/* //! new product route */}
                <Route
                  exact
                  path="/admin/category"
                  element={<CategoryModule />}
                />
                <Route
                  exact
                  path="/admin/subCategory"
                  element={<SubCategoryModule />}
                />{" "}
                <Route exact path="/admin/brand" element={<BrandModule />} />
                <Route
                  exact
                  path="/admin/transport-charge"
                  element={<TransportChargeModule />}
                />
                <Route exact path="/admin/mfi" element={<MFIModule />} />
                <Route exact path="/admin/grade" element={<GradeModule />} />
                <Route
                  exact
                  path="/admin/product"
                  element={<ProductModule />}
                />
                <Route
                  exact
                  path="/admin/selling-product"
                  element={<SellingProductModule />}
                />
                <Route
                  exact
                  path="/admin/buyer-enquiry"
                  element={<BuyerEnquiryModule />}
                />
                <Route
                  exact
                  path="/admin/companies"
                  element={<CompaniesPage />}
                />
                <Route
                  exact
                  path="/admin/email-queries"
                  element={<EmailQueryPage />}
                />
                <Route
                  exact
                  path="/admin/payment-transactions"
                  element={<Transactions />}
                />
                <Route exact path="/admin/seller" element={<SellerModule />} />
                <Route exact path="/admin/order" element={<OrderModule />} />
                <Route exact path="/admin/buyer" element={<BuyerModule />} />
                <Route
                  exact
                  path="/admin/seller/warehouse"
                  element={<SellerWareHouseModule />}
                />
                <Route
                  exact
                  path="/admin/location"
                  element={<LocationModule />}
                />
                <Route
                  exact
                  path="/admin/buyer/warehouse"
                  element={<BuyerWareHouseModule />}
                />
                <Route exact path="/admin/setting/:id" element={<Setting />} />
                <Route
                  exact
                  path="/admin/expense-categories"
                  element={<ExpenseCategoriesPage />}
                />
                <Route exact path="/admin/expense" element={<ExpensePage />} />
                <Route
                  exact
                  path="/admin/user-management"
                  element={<UserManagementPage />}
                />
                <Route
                  exact
                  path="/subscriptions/:id"
                  element={<Subscription />}
                />
              </Route>
              <Route exact path="/admin/stock/pos" element={<PosPage />} />
              {/* ----- end dashboard ----- */}
            </Routes>
            <Toaster position="top-center" reverseOrder={false} />
          </Router>
        </Suspense>
      </ThemeCustomization>
    </div>
  );
}

export default App;
