import { deleteRequest, get, patch, post } from "../utils/api";

export const addRoleUser = (data) => {
  return post(`/admin/user/`, data);
};

export const listRoleUser = () => {
  return get(`/admin/user/`);
};

export const singleRoleUser = (id) => {
  return get(`/admin/user/${id}`);
};

export const updateRoleUser = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/user/${id}`, data);
};

export const deleteRoleUser = (id) => {
  return deleteRequest(`/admin/user/${id}`);
};
export const roleUserApi = {
  addRoleUser,
  listRoleUser,
  singleRoleUser,
  updateRoleUser,
  deleteRoleUser,
};
