import { deleteRequest, get, patch, post, put } from "../utils/api";

//Branch Api
export const addBranch = (data) => {
  return post(`/branch/`, data);
};

export const listBranch = () => {
  return get(`/branch/`);
};

export const updateBranch = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/branch/${id}`, data);
};

export const deleteBranch = (id) => {
  return deleteRequest(`/branch/${id}`);
};

//units Api
export const addUnit = (data) => {
  return post(`/unit/`, data);
};

export const listUnit = (data) => {
  return get(`/unit/`, data);
};

export const singleUnit = (id) => {
  return get(`/unit/${id}`);
};

export const updateUnit = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/unit/${id}`, data);
};

export const deleteUnit = (id) => {
  return deleteRequest(`/unit/${id}`);
};

//Tax Api
export const addTax = (data) => {
  return post(`/tax/`, data);
};

export const listTax = (data) => {
  return get(`/tax/`, data);
};

export const singleTax = (id) => {
  return get(`/tax/${id}`);
};

export const updateTax = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/tax/${id}`, data);
};

export const deleteTax = (id) => {
  return deleteRequest(`/tax/${id}`);
};

//Payment mode Api
export const addPaymentMode = (data) => {
  return post(`/payment-mode/`, data);
};

export const listPaymentMode = (data) => {
  return get(`/payment-mode/`, data);
};

export const singlePaymentMode = (id) => {
  return get(`/payment-mode/${id}`);
};

export const updatePaymentMode = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/payment-mode/${id}`, data);
};

export const deletePaymentMode = (id) => {
  return deleteRequest(`/payment-mode/${id}`);
};

//currencies
export const addCurrencies = (data) => {
  return post(`/currency/`, data);
};
export const listCurrencies = (data) => {
  return get(`/currency/`, data);
};

export const singleCurrencies = (id) => {
  return get(`/currency/${id}`);
};

export const updateCurrencies = (data) => {
  const id = data?.id;
  delete data["id"];
  return put(`/currency/${id}`, data);
};

export const deleteCurrencies = (id) => {
  return deleteRequest(`/currency/${id}`);
};

//CustomField Api

export const addCustomField = (data) => {
  return post(`/custom-field/`, data);
};

export const listCustomField = (data) => {
  return get(`/custom-field/`, data);
};

export const singleCustomField = (id) => {
  return get(`/custom-field/${id}`);
};

export const updateCustomField = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/custom-field/${id}`, data);
};

export const deleteCustomField = (id) => {
  return deleteRequest(`/custom-field/${id}`);
};

export const settingApi = {
  addBranch,
  listBranch,
  updateBranch,
  deleteBranch,
  // addRole,
  // listRole,
  // singleRole,
  // updateRole,
  // deleteRole,
  addUnit,
  listUnit,
  singleUnit,
  updateUnit,
  deleteUnit,
  addTax,
  listTax,
  singleTax,
  updateTax,
  deleteTax,
  addPaymentMode,
  listPaymentMode,
  singlePaymentMode,
  updatePaymentMode,
  deletePaymentMode,
  addCurrencies,
  listCurrencies,
  singleCurrencies,
  updateCurrencies,
  deleteCurrencies,
  addCustomField,
  listCustomField,
  singleCustomField,
  updateCustomField,
  deleteCustomField,
};
