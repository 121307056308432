import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CommonTable from "../../@extended/table/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_LIST } from "../../../actions/orderActions";

const OrderTablePage = (props) => {
  const { columns, status, search, setFetchData, fetchData } = props;
  const dispatch = useDispatch();

  const orderData = useSelector((data) => data?.order?.orderList);
  const { totalResults = 0, limit, page } = orderData;
  const [limitState, setLimit] = useState(10);
  const [pageState, setPage] = useState(1);

  const isSuccess = useSelector((data) => data?.order?.isSuccess);
  const orderList = useCallback((values) => {
    dispatch({ type: ORDER_LIST, payload: values });
  }, []);

  const rows = orderData?.results?.map((item, ind) => {
    return {
      id: ind + 1,
      no: ind + 1,
      seller: `${item?.seller?.firstName} ( ${item?.seller?.mobile} ) : ${item?.warehouse?.location}`,
      buyer: `${item?.buyer?.firstName} ( ${item?.buyer?.mobile} ) : ${item?.shippingAddress?.city}`,
      orderNumber: item?.orderNumber,
      product: item?.orderItems?.[0]?.sellingProduct?.product?.name,
      qty: item?.orderItems?.[0]?.qty,
      orderDate: item?.createdAt,
      driver: `${item?.vehicleNumber} ( ${item?.driverContact} )`,
      price: item?.orderItems?.[0]?.price,
      sellerPrice: item?.orderItems?.[0]?.sellerPrice,
      grandTotal: item?.grandTotal,
      invoiceLink: item?.invoiceLink,
      sellerInvoiceLink: item?.sellerInvoiceLink,
      sellerGrandTotal: item?.sellerGrandTotal,
      orderStatus: item?.orderStatus,
      isConfirm: item?.isConfirm,
      createBy: item?.createBy?.firstName,
      paymentStatus: item?.paymentStatus,
      invoiceNumber: item?.invoiceNumber || "-",
      actions: "",
      rowId: item?.id,
      status: status,
    };
  });

  useEffect(() => {
    if (orderData?.limit) {
      setLimit(orderData?.limit);
    }
    if (orderData?.page) {
      setPage(orderData?.page);
    }
  }, [orderData?.limit, orderData?.page]);

  useEffect(() => {
    orderList({ limitState, pageState, status, search });
    if (typeof setFetchData == "function") {
      setFetchData(false);
    }
  }, [isSuccess, limitState, pageState, status, search, fetchData]);

  return (
    <Box className="email-query-box">
      <Box className="email-query-common-table-box-main">
        <Box className="email-query-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
            pageData={{ limit, page, totalResults, setLimit, setPage }}
          />
        </Box>
      </Box>
    </Box>
  );
};
OrderTablePage.propTypes = {
  columns: PropTypes.any,
  status: PropTypes.any,
  search: PropTypes.text,
  setDeleteModal: PropTypes.any,
  fetchData: PropTypes.any,
  setFetchData: PropTypes.any,
};
export default OrderTablePage;
