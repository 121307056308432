/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Drawer, useMediaQuery, useTheme } from "@mui/material";
import ProfileForm from "../../components/dashboard/settings/profile/ProfileForm";
import Tablayout from "../../components/dashboard/settings/profile/TabLayout";
import BreadCrumb from "../../components/@extended/breadcrumbs/Breadcrumbs";
import { useParams } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
// ! more fetures for admin
// import GTranslateIcon from "@mui/icons-material/GTranslate";
// import CompanyForm from "../../components/dashboard/settings/company/CompanyForm";
// import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
// import ViewModuleOutlinedIcon from "@mui/icons-material/ViewModuleOutlined";
// import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
// import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// import StorageSharpIcon from "@mui/icons-material/StorageSharp";
// import UpdateSharpIcon from "@mui/icons-material/UpdateSharp";
// import WarehouseIcon from "@mui/icons-material/Warehouse";
// import Warehouse from "../../components/dashboard/settings/warehouse/Warehouse";
// import NotifyMessage from "./../../components/@extended/notify/NotifyMessage";
// import PaymentIcon from "@mui/icons-material/Payment";
// import ApartmentIcon from "@mui/icons-material/Apartment";
// import RolePermissions from "../../components/dashboard/settings/rolepermissions/RolePermissions";
// import Taxes from "../../components/dashboard/settings/taxes/Taxes";
// import Currencies from "../../components/dashboard/settings/currencies/Currencies";
// import PaymentModes from "../../components/dashboard/settings/paymentmodes/PaymentModes";
// import Units from "../../components/dashboard/settings/units/Units";
// import CustomFields from "../../components/dashboard/settings/customfields/CustomFields";
// import FolderSharedIcon from "@mui/icons-material/FolderShared";
// import TaskIcon from "@mui/icons-material/Task";
// import EditNoteIcon from "@mui/icons-material/EditNote";
// import EmailSetting from "../../components/dashboard/settings/emailsetting/EmailSetting";
import AuthChangePassword from "../../components/auth/AuthChangePassword";
import ProductSetting from "../../components/auth/ProductSetting";

const Setting = () => {
  const { id } = useParams();
  console.log("Setting--", id);
  const [state, setState] = React.useState({
    bottom: false,
  });

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  const data = [
    // ! more fetures for admin
    // {
    //   icon: <ComputerIcon />,
    //   iconPosition: "start",
    //   label: "Company Settings",
    //   ...a11yProps(0),
    //   link: "/admin/setting/company-settings",
    // },
    {
      icon: <PersonOutlineIcon />,
      iconPosition: "start",
      label: "Profile",
      ...a11yProps(1),
      link: "/admin/setting/profile",
    },
    {
      icon: <LockOpenIcon />,
      iconPosition: "start",
      label: "Change Password",
      ...a11yProps(2),
      link: "/admin/setting/change-password",
    },
    {
      icon: <PermDataSettingIcon />,
      iconPosition: "start",
      label: "Product Setting",
      ...a11yProps(2),
      link: "/admin/setting/product-setting",
    },
    //--------- Todo: for superadmin -----------//
    // ! more fetures for admin
    // {
    //   icon: <GTranslateIcon />,
    //   iconPosition: "start",
    //   label: "Translations",
    //   ...a11yProps(2),
    //   link: "/admin/setting/translations",
    // },

    // {
    //   icon: <PaidOutlinedIcon />,
    //   iconPosition: "start",
    //   label: "Currencies",
    //   ...a11yProps(3),
    //   link: "/admin/setting/currencies",
    // },

    // {
    //   icon: <ViewModuleOutlinedIcon />,
    //   iconPosition: "start",
    //   label: "Modules",
    //   ...a11yProps(4),
    //   link: "/admin/setting/modules",
    // },

    // {
    //   icon: <FolderCopyOutlinedIcon />,
    //   iconPosition: "start",
    //   label: "Storage Settings",
    //   ...a11yProps(5),
    //   link: "/admin/setting/storage-settings",
    // },

    // {
    //   icon: <EmailOutlinedIcon />,
    //   iconPosition: "start",
    //   label: "Email Settings",
    //   ...a11yProps(6),
    //   link: "/admin/setting/email-settings",
    // },

    // {
    //   icon: <PersonOutlineIcon />,
    //   iconPosition: "start",
    //   label: "White Label Settings",
    //   ...a11yProps(7),
    //   link: "/admin/setting/white-label-settings",
    // },

    // {
    //   icon: <StorageSharpIcon />,
    //   iconPosition: "start",
    //   label: "Database Backup",
    //   ...a11yProps(8),
    //   link: "/admin/setting/database-backup",
    // },

    // {
    //   icon: <UpdateSharpIcon />,
    //   iconPosition: "start",
    //   label: "Update App",
    //   ...a11yProps(9),
    //   link: "/admin/setting/update-app",
    // },
    // {
    //   icon: <WarehouseIcon />,
    //   iconPosition: "start",
    //   label: "Warehouse",
    //   ...a11yProps(10),
    //   link: "/admin/setting/warehouse",
    // },
    // {
    //   icon: <FolderSharedIcon />,
    //   iconPosition: "start",
    //   label: "Role & Permissions",
    //   ...a11yProps(11),
    //   link: "/admin/setting/role",
    // },
    // {
    //   icon: <TaskIcon />,
    //   iconPosition: "start",
    //   label: "Taxes",
    //   ...a11yProps(12),
    //   link: "/admin/setting/taxes",
    // },
    // {
    //   icon: <PaymentIcon />,
    //   iconPosition: "start",
    //   label: "Payment Modes",
    //   ...a11yProps(13),
    //   link: "/admin/setting/payment-modes",
    // },
    // {
    //   icon: <ApartmentIcon />,
    //   iconPosition: "start",
    //   label: "Units",
    //   ...a11yProps(14),
    //   link: "/admin/setting/units",
    // },
    // {
    //   icon: <EditNoteIcon />,
    //   iconPosition: "start",
    //   label: "Custom Fields",
    //   ...a11yProps(15),
    //   link: "/admin/setting/custom-fields",
    // },
  ];

  const toggleDrawer = (bottom, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [bottom]: open });
  };
  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.up("lg"));

  const breadCrumbLabel = {
    profile: "Profile",
    currencies: "Currencies",
    "change-password": "Change - password",
    "company-settings": "Company Settings",
    "email-settings": "Email Settings",
    warehouse: "Warehouse",
    role: "Role & Permissions",
    taxes: "Taxes",
    "payment-modes": "Payment Modes",
    units: "Units",
    "custom-fields": "Custom Fields",
  };

  let BreadCrumbId;
  function GetComponent() {
    switch (id) {
      case "profile":
        return <ProfileForm />;
      case "change-password":
        return <AuthChangePassword />;
      case "product-setting":
        return <ProductSetting />;
      // ! more fetures for admin
      // case "currencies":
      //   return <Currencies />;
      // case "company-settings":
      //   return <CompanyForm />;
      // case "email-settings":
      //   return <EmailSetting />;
      // case "warehouse":
      //   return <Warehouse />;
      // case "role":
      //   return <RolePermissions />;
      // case "taxes":
      //   return <Taxes />;
      // case "payment-modes":
      //   return <PaymentModes />;
      // case "units":
      //   return <Units />;
      // case "custom-fields":
      //   return <CustomFields />;

      default:
        return <></>;
    }
  }
  return (
    <>
      <Box>
        <BreadCrumb
          title={breadCrumbLabel?.[id]}
          perentTabName="Setting"
          childTabName={breadCrumbLabel?.[id]}
          openMenuClick={toggleDrawer("bottom", true)}
        />
        <Box
          style={{
            display: isLaptop ? "flex" : "block",
            width: isLaptop ? "" : "100%",
          }}
        >
          {isLaptop ? (
            <Tablayout
              data={data}
              active={data.findIndex(
                (data) => data.label == breadCrumbLabel?.[id],
              )}
            />
          ) : (
            <React.Fragment key={"bottom"}>
              <Drawer
                anchor={"bottom"}
                open={state["bottom"]}
                onClose={toggleDrawer("bottom", false)}
              >
                <Tablayout
                  data={data}
                  active={data.findIndex(
                    (data) => data.label == breadCrumbLabel?.[id],
                  )}
                  onClose={toggleDrawer("bottom", false)}
                />
              </Drawer>
            </React.Fragment>
          )}
          <GetComponent />
        </Box>
      </Box>
    </>
  );
};

export default Setting;
