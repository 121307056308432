import React from "react";
import RolePermissions from "../settings/rolepermissions/RolePermissions";
import CommonTab from "../../@extended/tabs/CommonTab";
import { Box } from "@mui/material";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import AddUser from "./addUser/AddUser";

const UserManage = () => {
  const commonTabData = [
    {
      label: "User",
      value: "1",
      icon: <PersonAddAltOutlinedIcon fontSize="small" />,
      component: <AddUser />,
    },
    // ---------todo:- pending ---------- //
    {
      label: "Role",
      value: "2",
      icon: <FolderSharedIcon fontSize="small" />,
      component: <RolePermissions />,
    },
  ];
  return (
    <Box className="email-query-box">
      <CommonTab data={commonTabData} />
    </Box>
  );
};

export default UserManage;
