import { Box, InputLabel, Stack, Typography } from "@mui/material";
import React, { useState, useRef } from "react";
import "./uploadFile.css";
import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const UploadFile = (props) => {
  const { value, label, className, name, inputClassName, show } = props;
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 75,
    height: 75,
  });
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const imageRef = useRef(null);
  const fileUrl = useRef(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoaded = (image) => {
    imageRef.current = image;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        "newFile.jpeg",
      );
      setCroppedImageUrl(croppedImageUrl);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        if (fileUrl.current) {
          window.URL.revokeObjectURL(fileUrl.current);
        }
        fileUrl.current = window.URL.createObjectURL(blob);
        resolve(fileUrl.current);
      }, "image/jpeg");
    });
  };
  return (
    <Box className="main-input">
      <Stack spacing={1}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Box className="image-child">
          <label className={className}>
            <input
              type="file"
              name={name}
              id={name}
              value={value}
              className={inputClassName}
              accept="image/*"
              onChange={onSelectFile}
            />
            <Typography className="profile-upload">+</Typography>
            <Typography>Upload</Typography>
          </label>
          {src && (
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
            />
          )}
          {croppedImageUrl && show && (
            <img alt="Crop" src={croppedImageUrl} className="cropped-image" />
          )}
        </Box>
      </Stack>
    </Box>
  );
};

UploadFile.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  name: PropTypes.string,
  setShow: PropTypes.any,
  show: PropTypes.any,
};

export default UploadFile;
