import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import "./../../@extended/selectwithsearch/selectWithSearch.css";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";

const SelectWithSearch = (props) => {
  const { heading, handleChange, optionData } = props;
  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <Box className="select-with">
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={heading}
          onChange={handleChange}
          style={{
            height: "100%",
            width: "70px",
          }}
          size="small"
        >
          {optionData?.map((data, ind) => {
            return (
              <MenuItem key={ind} value={data?.id}>
                {data?.headerName}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <Box className="select-with-textfield">
        <TextField
          id="outlined-basic"
          variant="outlined"
          hiddenLabel
          size="small"
          className="abc"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

SelectWithSearch.propTypes = {
  heading: PropTypes.any,
  handleChange: PropTypes.any,
  optionData: PropTypes.any,
};

export default SelectWithSearch;
