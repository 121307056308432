import { deleteRequest, get, patch, post } from "../utils/api";

export const addSubscriptions = (data) => {
  return post(`/subscriptions/`, data);
};

export const listSubscriptions = () => {
  return get(`/subscriptions/`);
};

export const singleSubscriptions = (id) => {
  return get(`/subscriptions/${id}`);
};

export const updateSubscriptions = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/subscriptions/${id}`, data);
};

export const deleteSubscriptions = (id) => {
  return deleteRequest(`/subscriptions/${id}`);
};

export const subscriptionsApi = {
  addSubscriptions,
  listSubscriptions,
  singleSubscriptions,
  updateSubscriptions,
  deleteSubscriptions,
};
