import { useCallback, useState } from "react";
import "./profileForm.css";

import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import CropUploadFile from "../../../@extended/uploadfile/CropUploadFile";
import ButtonComponent from "../../../@extended/button/ButtonComponent";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  // PROFILE_DETAILS,
  UPDATE_DETAILS,
} from "../../../../actions/authActions";
// import { useEffect } from "react";
// import { cookie } from "../../../../utils/cookies/cookies";

// =====Todo :- for more add form input === //
// import dayjs from "dayjs";
// import CommonTable from "../../@extended/table/CommonTable";
// import { addDays } from "date-fns";
// import BasicDatePicker from "../../@extended/datepicker/DatePicker";
// import DateRangeNew from "../../@extended/daterangepicker/DateRangeNew";
// import CommonTable from "../../@extended/table/CommonTable";
// import { addDays } from "date-fns";
// import BasicDatePicker from "./../../@extended/";
// import DateRangeNew from "../../@extended/daterangepicker/DateRangeNew";

const ProfileForm = () => {
  const dispatch = useDispatch();
  const details = useSelector((data) => data?.auth?.profileDetails?.user);

  // const profileDetails = useCallback((values) => {
  //   dispatch({ type: PROFILE_DETAILS, payload: values });
  // }, []);
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );

  const updateDetails = useCallback((values) => {
    dispatch({ type: UPDATE_DETAILS, payload: values });
  }, []);

  // =====Todo :- for more add form input === //
  // const columns = [
  //   {
  //     field: "id",
  //     headerName: "ID",
  //     flex: 1,
  //     headerClassName: "header",
  //   },
  //   {
  //     field: "firstName",
  //     headerName: "First name",
  //     flex: 1,
  //     headerClassName: "header",
  //   },
  //   {
  //     field: "lastName",
  //     headerName: "Last name",
  //     flex: 1,
  //     headerClassName: "header",
  //   },
  //   {
  //     field: "age",
  //     headerName: "Age",
  //     // type: "number",
  //     flex: 1,
  //     headerClassName: "header",
  //   },
  //   {
  //     field: "fullName",
  //     headerName: "Full name",
  //     description: "This column has a value getter and is not sortable.",
  //     sortable: false,
  //     flex: 1,
  //     headerClassName: "header",
  //     valueGetter: (params) =>
  //       `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  //   },
  // ];

  // const rows = [
  //   { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  //   { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  //   { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  //   { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  //   { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: 16 },
  //   { id: 6, lastName: "Melisandre", firstName: "Melisandre", age: 150 },
  //   { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  //   { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  //   { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  // ];

  // date picker
  // const [value, setValue] = useState(dayjs());
  // const handleDateChange = (date) => {
  //   setValue(date);
  // };
  // // date range picker new
  // const [range, setRange] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 7),
  //     key: "selection",
  //   },
  // ]);

  // const token = cookie.get("token");
  // useEffect(() => {
  //   profileDetails(token);
  // }, [token]);

  const [show, setShow] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Box className={isMobile ? "main-div" : "mobile-main-div"}>
      <Formik
        initialValues={{
          firstName: details ? details?.firstName : " ",
          lastName: details ? details?.lastName : "",
          email: details?.email || "",
          phone: details?.mobile || "",
          image: "",
          address: details?.address || "",
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().max(255),
          lastName: Yup.string().max(255),
          email: Yup.string().email("Must be a valid email").max(255),
          phone: Yup.number(),
          image: Yup.string(),
          address: Yup.string(),
        })}
        onSubmit={(values) => {
          updateDetails({
            firstName: values?.firstName
              ? values?.firstName
              : details?.firstName,
            lastName: values?.lastName ? values?.lastName : details?.lastName,
            email: values?.email ? values?.email : details?.email,
            mobile:
              values?.phone || values?.phone === ""
                ? values?.phone
                : details?.phone,
            address:
              values?.address || values?.address === ""
                ? values?.address
                : details?.address,
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          // isSubmitting,
          touched,
          values,
        }) => (
          <div className="profile-form-div">
            <Form noValidate onSubmit={handleSubmit}>
              <Grid container>
                <Grid
                  className={
                    isMobile
                      ? "profile-form-main-grid"
                      : "mobile-form-main-grid"
                  }
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="profile-form-main-input"
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="firstName">First Name*</InputLabel>
                      <OutlinedInput
                        id="firstName"
                        value={values.firstName}
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Please Enter First Name"
                        fullWidth
                        error={Boolean(touched.firstName && errors.firstName)}
                      />
                      {touched.firstName && errors.firstName && (
                        <FormHelperText error id="helper-text-name">
                          {errors.firstName}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="profile-form-main-input"
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="lastName">Last Name*</InputLabel>
                      <OutlinedInput
                        id="lastName"
                        type="lastName"
                        value={values.lastName}
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Please Enter Last Name"
                        fullWidth
                        error={Boolean(touched.lastName && errors.lastName)}
                      />
                      {touched.lastName && errors.lastName && (
                        <FormHelperText error id="helper-text-name">
                          {errors.lastName}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  className={
                    isMobile
                      ? "profile-form-main-grid"
                      : "mobile-form-main-grid"
                  }
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="profile-form-main-input"
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email">Email*</InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        id="email"
                        value={values.email}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="demo@company.com"
                        inputProps={{}}
                      />
                      {touched.email && errors.email && (
                        <FormHelperText error id="helper-text-email">
                          {errors.email}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} className="profile-form-main-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="phone">Phone Number*</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.phone && errors.phone)}
                      id="phone"
                      type="number"
                      value={values.phone}
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Phone Number"
                      inputProps={{}}
                    />
                    {touched.phone && errors.phone && (
                      <FormHelperText error id="helper-text-phone">
                        {errors.phone}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="profile-form-main-input">
                  <CropUploadFile
                    name="image"
                    value={values?.image}
                    className="custom-file-upload"
                    label="Profile Image"
                    inputClassName="input-file-hide"
                    show={show}
                  />
                  {touched.image && errors.image && (
                    <FormHelperText error id="helper-text-image">
                      {errors.image}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} className="profile-form-main-input">
                  <Stack spacing={1}>
                    <Typography
                      variant="body2"
                      className="profile-form-address"
                    >
                      Address
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      name="address"
                      id="address"
                      value={values.address}
                      onBlur={handleBlur}
                      error={errors.address}
                      onChange={handleChange}
                      rows={4}
                      placeholder="Please Enter Address"
                    />
                    {touched.address && errors.address && (
                      <FormHelperText error id="helper-text-address">
                        {errors.address}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={isMobile ? "button-main" : "mobile-button"}
                >
                  <ButtonComponent
                    className="btn-update"
                    name="Update"
                    type="submit"
                    isName={true}
                    Icon={EditOutlinedIcon}
                    onClick={() => setShow(true)}
                    disabledbtn={userRoleAccess?.userRoleType == "admin"}
                  />
                </Grid>
              </Grid>
            </Form>
          </div>
        )}
      </Formik>
      {/* 
      // =====Todo :- for more add form input === //
      <CommonTable columns={columns} rows={rows} /> */}
      {/* <Box className="date-picker">
        <BasicDatePicker
          value={value}
          setValue={setValue}
          handleDateChange={handleDateChange}
        />
        <DateRangeNew range={range} setRange={setRange} />
      </Box> */}
    </Box>
  );
};

export default ProfileForm;
