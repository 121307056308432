/* eslint-disable no-unused-vars */
import {
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import React from "react";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import BasicDatePicker from "../../@extended/datepicker/DatePicker";
import dayjs from "dayjs";
import { useState } from "react";
import "./ViewPlan.css";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import {
  ADD_LIST_PLAN,
  LIST_PLAN,
  PAYMENT_MODE_LIST,
  SUBSCRIPTION_LIST_PLAN,
} from "../../../actions/companyActions";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import CustomizedModal from "../../@extended/customizedmodal/CustomizedModal";
import PaymentModesForm from "../settings/paymentmodes/PaymentModesForm";
import CommonFormDrawer from "../../@extended/drawer/CommonFormDrawer";
import AddNewSubscriptionPlans from "../ subscription/plans/subscriptionplan/AddNewSubscriptionPlan";

const ViewPlan = ({ onClick, id }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = useState(false);
  const [state, setState] = React.useState({
    right: false,
  });

  // const [Id, setId] = useState("");

  const dispatch = useDispatch();

  const plan = useCallback(() => {
    dispatch({ type: LIST_PLAN, payload: id });
  }, []);

  const addPlan = useCallback((data) => {
    dispatch({ type: ADD_LIST_PLAN, payload: data });
  }, []);

  const subscriptionList = useCallback(() => {
    dispatch({ type: SUBSCRIPTION_LIST_PLAN, payload: id });
  }, []);

  const paymentList = useCallback(() => {
    dispatch({ type: PAYMENT_MODE_LIST, payload: id });
  }, []);

  const [value, setValue] = useState(dayjs());
  const handleDateChange = (date) => {
    setValue(date);
  };

  const toggleDrawer = (right, open, params) => (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [right]: open });
    // setId(params?.row?.rowId);
  };

  const planList = useSelector((data) => data?.company?.listPlan);

  const subscriptionPlan = useSelector(
    (data) => data?.company?.subscriptionPlanList,
  );

  const paymentModeList = useSelector((data) => data?.company?.paymentModeList);
  const isAddPaymentSuccess = useSelector(
    (data) => data?.setting?.isAddPaymentSuccess,
  );

  let lastPlan = {};

  const isSuccess = useSelector(
    (data) => data?.subscriptions?.isAddSubscriptionsSuccess,
  );
  function dateObjectFromUTC(s) {
    let date = new Date(s);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return year + "-" + month + "-" + dt;
  }

  useEffect(() => {
    plan();
    subscriptionList();
    paymentList();
  }, [id, isAddPaymentSuccess, isSuccess]);
  return (
    <>
      <Box className={isMobile ? "language-main-div" : "language-form-box"}>
        <Grid
          container
          spacing={1}
          borderBottom={"1px solid var(--white-shade-color)"}
          // padding="10px 5px 40px 50px"
          sx={{ padding: "", p: 2 }}
        >
          {planList?.map((data) => {
            if (!data.isRenewal) lastPlan = data;
            return (
              <>
                <Grid item lg={6}>
                  <Box sx={{ pb: 2 }}>
                    Subscription Plan : {data.subscriptionPlan.name}
                  </Box>
                  <Box>
                    Last Paid On :{" "}
                    {data.paymentDate
                      ? dateObjectFromUTC(data.paymentDate)
                      : "-----"}
                  </Box>
                </Grid>
                <Grid item lg={6}>
                  <Box sx={{ pb: 2 }}>Plan Type: {data.planType}</Box>
                  <Box>
                    Next Payment Date :
                    {data.licenseWillExpiresOn
                      ? dateObjectFromUTC(data.licenseWillExpiresOn)
                      : "-----"}
                  </Box>
                </Grid>
              </>
            );
          })}
        </Grid>
        <Formik
          enableReinitialize={true}
          initialValues={{
            companyId: id,
            subscriptionPlan: lastPlan?.subscriptionPlan?.id
              ? lastPlan?.subscriptionPlan?.id
              : "",
            user: "651e93817e47083a4c6ce14a",
            planType: lastPlan?.planType ? lastPlan?.planType : "",
            paymentMode: "",
            amount: 0,
            paymentDate: "",
            licenseWillExpiresOn: lastPlan.licenseWillExpiresOn
              ? lastPlan.licenseWillExpiresOn
              : "2023-10-28T05:27:56.876Z",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required(" Unit Name is required"),
            key: Yup.string().max(255).required(" Short Name is required"),
            flag: Yup.string(),
          })}
          onSubmit={(values) => {
            console.log("values==>", values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Box padding="10px 5px 40px 5px">
                <Grid container className="language-form-container">
                  <Grid item xs={12} md={6} xl={6} className="taxes-form-input">
                    {/* {" "} */}
                    <Stack>
                      <InputLabel htmlFor="type">Subscription Plan*</InputLabel>
                      <Box display={"flex"} gap={"5px"}>
                        <Select
                          labelId="demo-select-small-label"
                          id="type"
                          name="subscriptionPlan"
                          size="small"
                          fullWidth
                          value={values?.subscriptionPlan}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.type && errors.type}
                          style={{ borderBlockColor: "gray" }}
                        >
                          {subscriptionPlan?.results?.map((data, ind) => {
                            return (
                              <MenuItem key={ind} value={data?.id}>
                                {data?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <Box className="add-user-icon">
                          <AddIcon
                            name="Add New Subscription Plan"
                            isName={true}
                            Icon={AddIcon}
                            onClick={toggleDrawer("right", true)}
                          />
                          {toggleDrawer && (
                            <CommonFormDrawer
                              isAddSubscription={true}
                              state={state}
                              toggleDrawer={toggleDrawer}
                              heading={"Add New Subscription Plan"}
                              width={
                                isTablet
                                  ? "1000px"
                                  : isMobile
                                    ? "670px"
                                    : "400px"
                              }
                              childrenComponent={
                                <AddNewSubscriptionPlans
                                  toggleDrawer={toggleDrawer}
                                />
                              }
                            />
                          )}
                        </Box>
                      </Box>
                      {touched.type && errors.type && (
                        <FormHelperText error id="helper-text-type">
                          {errors.type}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6} xl={6} className="taxes-form-input">
                    <Stack>
                      <InputLabel htmlFor="type">Plan Type*</InputLabel>
                      <Box display={"flex"} gap={"5px"}>
                        <Select
                          labelId="demo-select-small-label"
                          id="planType"
                          name="planType"
                          size="small"
                          fullWidth
                          value={values?.planType}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.type && errors.type}
                          style={{ borderBlockColor: "gray" }}
                        >
                          <MenuItem value={"monthly"}>monthly</MenuItem>
                          <MenuItem value={"annual"}>annual</MenuItem>
                        </Select>
                      </Box>
                      {touched.type && errors.type && (
                        <FormHelperText error id="helper-text-type">
                          {errors.type}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6} xl={6} className="taxes-form-input">
                    <Stack>
                      <InputLabel htmlFor="type">Payment Mode*</InputLabel>
                      <Box display={"flex"} gap={"5px"}>
                        <Select
                          labelId="demo-select-small-label"
                          id="type"
                          name="paymentMode"
                          size="small"
                          fullWidth
                          value={values?.paymentMode}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.type && errors.type}
                          style={{ borderBlockColor: "gray" }}
                        >
                          {paymentModeList?.results?.map((data, ind) => {
                            return (
                              <MenuItem key={ind} value={data?.id}>
                                {data?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <Box
                          className="add-user-icon"
                          // onClick={toggleDrawer("right", true)}
                        >
                          <AddIcon onClick={() => setOpen(true)} />
                          {open && (
                            <CustomizedModal
                              isHeadingName={true}
                              heading={
                                id
                                  ? "Edit Payment Mode"
                                  : "Add New Payment Mode"
                              }
                              open={open}
                              onClick={() => {
                                setOpen(false);
                              }}
                            >
                              <PaymentModesForm
                                onClick={() => {
                                  setOpen(false);
                                }}
                              />
                            </CustomizedModal>
                          )}
                        </Box>
                      </Box>
                      {touched.type && errors.type && (
                        <FormHelperText error id="helper-text-type">
                          {errors.type}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6} xl={6} className="taxes-form-input">
                    <Box className="select-wrapper">
                      <label htmlFor="discount" className="label-div">
                        Amount*
                      </label>
                      <TextField
                        id="outlined-adornment-amount"
                        name="amount"
                        type="number"
                        size="small"
                        onChange={handleChange}
                        value={values?.amount}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start">
                                <AttachMoneyIcon fontSize="20px" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item className="taxes-form-input">
                    <InputLabel htmlFor="type">Payment Date*</InputLabel>
                    <BasicDatePicker
                      value={dayjs(values?.paymentDate)}
                      name="paymentDate"
                      setValue={setValue}
                      handleDateChange={handleDateChange}
                    />
                  </Grid>
                  <Grid item className="taxes-form-input">
                    <InputLabel htmlFor="type">
                      License Will Expires On
                    </InputLabel>
                    <BasicDatePicker
                      value={dayjs(values?.licenseWillExpiresOn)}
                      disabled={true}
                      name="licenseWillExpiresOn"
                      setValue={setValue}
                      handleDateChange={handleDateChange}
                    />
                  </Grid>
                  {/* <Grid item xs={12} className="language-form-input">
                    <UploadFile
                      name="flag"
                      value={values?.flag}
                      className="custom-file-upload"
                      label="Flag"
                      inputClassName="input-file-hide"
                      show={show}
                    />
                    {touched.flag && errors.flag && (
                      <FormHelperText error id="helper-text-image">
                        {errors.flag}
                      </FormHelperText>
                    )}
                  </Grid> */}
                </Grid>
              </Box>
              <Box className="language-form-drawer-btn-wrapper-create">
                <ButtonComponent
                  name="Create"
                  isName={true}
                  Icon={SaveAsIcon}
                  type="submit"
                  onClick={(e) => {
                    onClick();
                    addPlan({
                      ...values,
                      paymentDate: new Date(value)?.toISOString(),
                    });
                  }}
                />
                <ButtonComponent
                  name="Cancel"
                  isName={true}
                  className="background-white"
                  onClick={onClick}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};
ViewPlan.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
};

export default ViewPlan;
