import {
  DELETE_EXPENSE_CATEGORY,
  DELETE_EXPENSE_CATEGORY_FAIL,
  DELETE_EXPENSE_CATEGORY_SUCCESS,
  EXPENSE_CATEGORY,
  EXPENSE_CATEGORY_FAIL,
  EXPENSE_CATEGORY_SUCCESS,
  LIST_EXPENSE_CATEGORY,
  LIST_EXPENSE_CATEGORY_FAIL,
  LIST_EXPENSE_CATEGORY_SUCCESS,
  LIST_SINGLE_EXPENSE_CATEGORY,
  LIST_SINGLE_EXPENSE_CATEGORY_FAIL,
  LIST_SINGLE_EXPENSE_CATEGORY_SUCCESS,
  UPDATE_EXPENSE_CATEGORY,
  UPDATE_EXPENSE_CATEGORY_FAIL,
  UPDATE_EXPENSE_CATEGORY_SUCCESS,
} from "../actions/expenseCategoryActions";
import { API } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";

//expense saga
function* addExpenseCategory(action) {
  try {
    const response = yield call(API.addExpenseCategory, action.payload);
    yield put({ type: EXPENSE_CATEGORY_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: EXPENSE_CATEGORY_FAIL, payload: { error: error } });
  }
}
function* listExpenseCategory(action) {
  try {
    const response = yield call(API.listExpenseCategory, action.payload);
    yield put({ type: LIST_EXPENSE_CATEGORY_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_EXPENSE_CATEGORY_FAIL, payload: { error: error } });
  }
}
function* singleExpenseCategory(action) {
  try {
    const response = yield call(API.singleExpenseCategory, action.payload);
    yield put({
      type: LIST_SINGLE_EXPENSE_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LIST_SINGLE_EXPENSE_CATEGORY_FAIL,
      payload: { error: error },
    });
  }
}
function* updateExpenseCategory(action) {
  try {
    const response = yield call(API.updateExpenseCategory, action.payload);
    yield put({
      type: UPDATE_EXPENSE_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: UPDATE_EXPENSE_CATEGORY_FAIL,
      payload: { error: error },
    });
  }
}
function* deleteExpenseCategory(action) {
  try {
    const response = yield call(API.deleteExpenseCategory, action.payload);
    yield put({
      type: DELETE_EXPENSE_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DELETE_EXPENSE_CATEGORY_FAIL,
      payload: { error: error },
    });
  }
}
function* expenseCategory() {
  yield takeLatest(EXPENSE_CATEGORY, addExpenseCategory);
  yield takeLatest(LIST_EXPENSE_CATEGORY, listExpenseCategory);
  yield takeLatest(LIST_SINGLE_EXPENSE_CATEGORY, singleExpenseCategory);
  yield takeLatest(UPDATE_EXPENSE_CATEGORY, updateExpenseCategory);
  yield takeLatest(DELETE_EXPENSE_CATEGORY, deleteExpenseCategory);
}
export default expenseCategory;
