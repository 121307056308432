import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import CommonTable from "../../@extended/table/CommonTable";

const TablePage = (props) => {
  const { columns, rows } = props;
  return (
    <Box className="email-query-box">
      <Box className="email-query-common-table-box-main">
        <Box className="email-query-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
          />
        </Box>
      </Box>
    </Box>
  );
};
TablePage.propTypes = {
  columns: PropTypes.any,
  rows: PropTypes.any,
};
export default TablePage;
