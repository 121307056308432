/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Drawer, useMediaQuery, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import BreadCrumb from "../../@extended/breadcrumbs/Breadcrumbs";
import Tablayout from "../settings/profile/TabLayout";
import Plans from "./plans/Plans";
import OfflinePaymentModes from "./offlinepaymentmodes/OfflinePaymentModes";

const Subscription = () => {
  const { id } = useParams();
  const [state, setState] = React.useState({
    bottom: false,
  });

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  const data = [
    {
      iconPosition: "start",
      label: "Subscription Plans",
      ...a11yProps(0),
      link: "/subscriptions/plans",
    },
    {
      iconPosition: "start",
      label: "Offline Payment Modes",
      ...a11yProps(1),
      link: "/subscriptions/Offline-payment-modes",
    },

    {
      iconPosition: "start",
      label: "Stripe",
      ...a11yProps(2),
      link: "/subscriptions/stripe",
    },

    {
      iconPosition: "start",
      label: "Paypal",
      ...a11yProps(3),
      link: "/subscriptions/paypal",
    },
  ];

  const toggleDrawer = (bottom, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [bottom]: open });
  };
  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.up("lg"));

  const breadCrumbLabel = {
    plans: "Subscription Plans",
    "Offline-payment-modes": "Offline Payment Modes",
    stripe: "Stripe",
    paypal: "Paypal",
  };

  let BreadCrumbId;
  function GetComponent() {
    switch (id) {
      case "plans":
        return <Plans />;
      case "Offline-payment-modes":
        return <OfflinePaymentModes />;
      case "stripe":
        return "pending";
      case "paypal":
        return "pending";
      default:
        return <></>;
    }
  }

  return (
    <>
      <Box>
        <BreadCrumb
          title={breadCrumbLabel?.[id]}
          perentTabName="Subscriptions"
          childTabName={breadCrumbLabel?.[id]}
          openMenuClick={toggleDrawer("bottom", true)}
        />
        <Box
          style={{
            display: isLaptop ? "flex" : "block",
            width: isLaptop ? "" : "100%",
          }}
        >
          {isLaptop ? (
            <Tablayout
              data={data}
              active={data.findIndex(
                (data) => data.label == breadCrumbLabel?.[id],
              )}
            />
          ) : (
            <React.Fragment key={"bottom"}>
              <Drawer
                anchor={"bottom"}
                open={state["bottom"]}
                onClose={toggleDrawer("bottom", false)}
              >
                <Tablayout
                  data={data}
                  active={data.findIndex(
                    (data) => data.label == breadCrumbLabel?.[id],
                  )}
                  onClose={toggleDrawer("bottom", false)}
                />
              </Drawer>
            </React.Fragment>
          )}
          <GetComponent />
        </Box>
      </Box>
    </>
  );
};

export default Subscription;
