import { deleteRequest, get, patch, post } from "../utils/api";

//ExpenseCategory Api

export const addExpenseCategory = (data) => {
  return post(`/expense-category/`, data);
};

export const listExpenseCategory = (data) => {
  return get(`/expense-category/`, data);
};

export const singleExpenseCategory = (id) => {
  return get(`/expense-category/${id}`);
};

export const updateExpenseCategory = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/expense-category/${id}`, data);
};

export const deleteExpenseCategory = (id) => {
  return deleteRequest(`/expense-category/${id}`);
};

export const expenseCategoryApi = {
  addExpenseCategory,
  listExpenseCategory,
  singleExpenseCategory,
  updateExpenseCategory,
  deleteExpenseCategory,
};
