import React from "react";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";

const OrderCountTable = ({ rows, orderItems, setOrderItems }) => {
  return (
    <table
      width={"100%"}
      border="1"
      cellPadding="0"
      cellSpacing="0"
      style={{ borderCollapse: "collapse", borderColor: "#F0F0F0" }}
    >
      <thead>
        <tr>
          <th>No</th>
          <th>Product Name</th>
          <th>Qty</th>
          <th>Price</th>
          <th>Transport Charge</th>
          <th>Seller</th>
          <th>Warehouse</th>
          <th>Subtotal</th>
          <th>Tax</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {rows?.length > 0
          ? rows.map((row, index) => {
              const currProduct = orderItems?.find(
                (data) => data?.sellingProduct === row?.id,
              );
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row.sellingProduct}</td>
                  <td>
                    <TextField
                      type="number"
                      size="small"
                      value={currProduct?.qty}
                      onChange={(e) => {
                        setOrderItems((prev) => {
                          return prev?.map((data) => {
                            if (
                              data?.sellingProduct === row?.sellingProductId
                            ) {
                              return {
                                ...data,
                                qty: parseFloat(e.target.value),
                              };
                            } else {
                              return data;
                            }
                          });
                        });
                      }}
                    />
                  </td>
                  <td>
                    <TextField
                      type="number"
                      size="small"
                      value={currProduct?.price}
                      onChange={(e) => {
                        setOrderItems((prev) => {
                          return prev?.map((data) => {
                            if (
                              data?.sellingProduct === row?.sellingProductId
                            ) {
                              return {
                                ...data,
                                price: parseFloat(e.target.value),
                              };
                            } else {
                              return data;
                            }
                          });
                        });
                      }}
                    />
                  </td>
                  <td>
                    <TextField
                      type="number"
                      size="small"
                      value={currProduct?.transportCharge}
                      onChange={(e) => {
                        setOrderItems((prev) => {
                          return prev?.map((data) => {
                            if (
                              data?.sellingProduct === row?.sellingProductId
                            ) {
                              return {
                                ...data,
                                transportCharge: parseFloat(e.target.value),
                              };
                            } else {
                              return data;
                            }
                          });
                        });
                      }}
                    />
                  </td>
                  <td>{row.seller}</td>
                  <td>{row.warehouse}</td>
                  {/* //! subtotal Data */}
                  <td>
                    {(currProduct?.qty || 1) * (currProduct?.price || 0) * 1000}
                  </td>
                  {/* //! tax count here */}
                  <td>
                    {(currProduct?.qty || 1) *
                      (currProduct?.price || 0) *
                      1000 *
                      0.18}
                  </td>
                  {/* //! total here */}
                  <td>
                    {(currProduct?.qty || 1) *
                      (currProduct?.price || 0) *
                      1000 +
                      (currProduct?.qty || 1) *
                        (currProduct?.price || 0) *
                        1000 *
                        0.18 +
                      (currProduct?.transportCharge || 0)}
                  </td>
                </tr>
              );
            })
          : []}
      </tbody>
    </table>
  );
};
OrderCountTable.propTypes = {
  rows: PropTypes.any,
  orderItems: PropTypes.any,
  setOrderItems: PropTypes.any,
  id: PropTypes.any,
};

export default OrderCountTable;
