import {
  DELETE_EXPENSE,
  DELETE_EXPENSE_FAIL,
  DELETE_EXPENSE_SUCCESS,
  EXPENSE,
  EXPENSE_FAIL,
  EXPENSE_SUCCESS,
  LIST_EXPENSE,
  LIST_EXPENSE_FAIL,
  LIST_EXPENSE_SUCCESS,
  LIST_SINGLE_EXPENSE,
  LIST_SINGLE_EXPENSE_FAIL,
  LIST_SINGLE_EXPENSE_SUCCESS,
  UPDATE_EXPENSE,
  UPDATE_EXPENSE_FAIL,
  UPDATE_EXPENSE_SUCCESS,
} from "../actions/expenseActions";
import { API } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";

//expense saga
function* addExpense(action) {
  try {
    const response = yield call(API.addExpense, action.payload);
    yield put({ type: EXPENSE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: EXPENSE_FAIL, payload: { error: error } });
  }
}
function* listExpense(action) {
  try {
    const response = yield call(API.listExpense, action.payload);
    yield put({ type: LIST_EXPENSE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_EXPENSE_FAIL, payload: { error: error } });
  }
}
function* singleExpense(action) {
  try {
    const response = yield call(API.singleExpense, action.payload);
    yield put({
      type: LIST_SINGLE_EXPENSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LIST_SINGLE_EXPENSE_FAIL,
      payload: { error: error },
    });
  }
}
function* updateExpense(action) {
  try {
    const response = yield call(API.updateExpense, action.payload);
    yield put({ type: UPDATE_EXPENSE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_EXPENSE_FAIL, payload: { error: error } });
  }
}
function* deleteExpense(action) {
  try {
    const response = yield call(API.deleteExpense, action.payload);
    yield put({ type: DELETE_EXPENSE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DELETE_EXPENSE_FAIL, payload: { error: error } });
  }
}
function* expense() {
  yield takeLatest(EXPENSE, addExpense);
  yield takeLatest(LIST_EXPENSE, listExpense);
  yield takeLatest(LIST_SINGLE_EXPENSE, singleExpense);
  yield takeLatest(UPDATE_EXPENSE, updateExpense);
  yield takeLatest(DELETE_EXPENSE, deleteExpense);
}
export default expense;
