import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import CommonTable from "../../@extended/table/CommonTable";
import PropTypes from "prop-types";
import { LIST_LOCATION } from "../../../actions/locationActions";
import { useDispatch, useSelector } from "react-redux";

const LocationTable = (props) => {
  const { columns, search } = props;
  const dispatch = useDispatch();

  const datalist = useSelector((data) => data?.location?.locationList);

  const [limitState, setLimit] = useState(10);
  const [pageState, setPage] = useState(1);
  const { totalResults = 0, limit, page } = datalist;

  const { isAddLocationSuccess, isUpdateLocation, isDeleteLocation } =
    useSelector((data) => data?.location);

  const locationList = useCallback((values) => {
    dispatch({ type: LIST_LOCATION, payload: values });
  }, []);

  useEffect(() => {
    locationList({ limitState, pageState, search });
  }, [
    isAddLocationSuccess,
    isUpdateLocation,
    isDeleteLocation,
    limitState,
    pageState,
    search,
  ]);

  const rows = datalist?.results?.map((item, ind) => {
    return {
      id: ind + 1,
      no: ind + 1,
      name: item?.name,
      actions: "",
      rowId: item?.id,
    };
  });

  return (
    <Box className="email-query-box">
      <Box className="email-query-common-table-box-main">
        <Box className="email-query-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
            pageData={{ limit, page, totalResults, setLimit, setPage }}
          />
        </Box>
      </Box>
    </Box>
  );
};
LocationTable.propTypes = {
  columns: PropTypes.any,
  type: PropTypes.any,
  search: PropTypes.any,
  setDeleteModal: PropTypes.any,
};
export default LocationTable;
