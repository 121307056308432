import { combineReducers } from "redux";
import authReducer from "./authReducer";
import sidebarReducer from "./sidebarReducer";
import settingReducer from "./settingReducer";
import companyReducer from "./companyReducer";
import expenseReducer from "./expenseReducer";
import expenseCategoryReducer from "./expenseCategoryReducer";
import subscriptionsReducer from "./subscriptionsReducer";
import emailQueryReducer from "./emailQueryReducer";
import sellerReducer from "./sellerReducer";
import buyerReducer from "./buyerReducer";
import productReducer from "./product";
import roleReducer from "./roleReducer";
import roleUserReducer from "./roleUserReducer";
import orderReducer from "./orderReducer";
import locationReducer from "./locationReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
  setting: settingReducer,
  company: companyReducer,
  expense: expenseReducer,
  expenseCategory: expenseCategoryReducer,
  subscriptions: subscriptionsReducer,
  emailQuery: emailQueryReducer,
  seller: sellerReducer,
  buyer: buyerReducer,
  product: productReducer,
  role: roleReducer,
  roleUser: roleUserReducer,
  order: orderReducer,
  location: locationReducer,
});

export default rootReducer;
