import React, { useCallback, useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import "../seller/Seller.css";
import BreadCrumb from "../../@extended/breadcrumbs/Breadcrumbs";
import BuyerEnquiryTable from "./BuyerEnquiryTable";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CommonTab from "../../@extended/tabs/CommonTab";
import { useDispatch } from "react-redux";
import { BUYER_ENQUIRY_STATUS_CHANGE } from "../../../actions/productActions";

const BuyerEnquiryModule = () => {
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  const enquieryStatusUpdate = useCallback((data) => {
    dispatch({ type: BUYER_ENQUIRY_STATUS_CHANGE, payload: data });
  }, []);

  const columns = [
    {
      field: "no",
      headerName: "No",
      headerClassName: "email-query-header",
    },
    {
      field: "materialName",
      headerName: "Material",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "qty",
      headerName: "Quntity",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "firstName",
      headerName: "First name",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "lastName",
      headerName: "Last name",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "workEmail",
      headerName: "email",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "phoneNumber",
      headerName: "Mobile",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "isRead",
      headerName: "Status",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "email-query-header",
      flex: 1,
      renderCell: (params) => {
        // const userRoleData = userRoleAccess?.userPermissions?.find(
        //   (data) => data?.userModule == "order" && data?.edit,
        // );
        // const isAdmin = userRoleAccess?.userPermissions?.find(
        //   (data) => data?.userModule == "all",
        // );
        return (
          <Box sx={{ display: "flex", gap: "5px" }}>
            {params?.row?.isRead ? (
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  enquieryStatusUpdate({
                    id: params?.row?.rowId,
                    isRead: false,
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }}
              >
                Mark Unread
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  enquieryStatusUpdate({
                    id: params?.row?.rowId,
                    isRead: true,
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }}
              >
                Mark Read
              </Button>
            )}
          </Box>
        );
      },
    },
  ];

  const commonTabData = [
    {
      label: "Pending",
      value: "1",
      icon: <ScheduleIcon fontSize="small" />,
      component: (
        <BuyerEnquiryTable columns={columns} type="pending" search={search} />
      ),
    },
    // ---------todo:- pending ---------- //
    {
      label: "Completed",
      value: "2",
      icon: <CheckCircleIcon fontSize="small" />,
      component: (
        <BuyerEnquiryTable columns={columns} type="completed" search={search} />
      ),
    },
  ];

  return (
    <Box className="email-query-box">
      <BreadCrumb title="Buyer Enquiry" perentTabName="Buyer Enquiry" />
      <Box className="email-query-box-main">
        <Box>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={(e) => setSearch(e.target.value)}
            sx={{ width: "400px" }}
            inputProps={{ placeholder: "Search here...." }}
          />
        </Box>
      </Box>
      <CommonTab data={commonTabData} />
    </Box>
  );
};

export default BuyerEnquiryModule;
