//Expense_Category Action

export const EXPENSE_CATEGORY = "EXPENSE_CATEGORY";
export const EXPENSE_CATEGORY_SUCCESS = "EXPENSE_CATEGORY_SUCCESS";
export const EXPENSE_CATEGORY_FAIL = "EXPENSE_CATEGORY_FAIL";

export const LIST_EXPENSE_CATEGORY = "LIST_EXPENSE_CATEGORY";
export const LIST_EXPENSE_CATEGORY_SUCCESS = "LIST_EXPENSE_CATEGORY_SUCCESS";
export const LIST_EXPENSE_CATEGORY_FAIL = "LIST_EXPENSE_CATEGORY_FAIL";

export const LIST_SINGLE_EXPENSE_CATEGORY = "LIST_SINGLE_EXPENSE_CATEGORY";
export const LIST_SINGLE_EXPENSE_CATEGORY_SUCCESS =
  "LIST_SINGLE_EXPENSE_CATEGORY_SUCCESS";
export const LIST_SINGLE_EXPENSE_CATEGORY_FAIL =
  "LIST_SINGLE_EXPENSE_CATEGORY_FAIL";

export const UPDATE_EXPENSE_CATEGORY = "UPDATE_EXPENSE_CATEGORY";
export const UPDATE_EXPENSE_CATEGORY_SUCCESS =
  "UPDATE_EXPENSE_CATEGORY_SUCCESS";
export const UPDATE_EXPENSE_CATEGORY_FAIL = "UPDATE_EXPENSE_CATEGORY_FAIL";

export const DELETE_EXPENSE_CATEGORY = "DELETE_EXPENSE_CATEGORY";
export const DELETE_EXPENSE_CATEGORY_SUCCESS =
  "DELETE_EXPENSE_CATEGORY_SUCCESS";
export const DELETE_EXPENSE_CATEGORY_FAIL = "DELETE_EXPENSE_CATEGORY_FAIL";
