// import toast from "react-hot-toast";
import { toast } from "react-hot-toast";
import {
  SELLER_ADD,
  SELLER_ADD_FAIL,
  SELLER_ADD_SUCCESS,
  SELLER_DELETE,
  SELLER_DELETE_FAIL,
  SELLER_DELETE_SUCCESS,
  SELLER_LIST,
  SELLER_LIST_FAIL,
  SELLER_LIST_SUCCESS,
  SELLER_SINGLE,
  SELLER_SINGLE_FAIL,
  SELLER_SINGLE_SUCCESS,
  SELLER_SINGLE_UPDATE,
  SELLER_SINGLE_UPDATE_FAIL,
  SELLER_SINGLE_UPDATE_SUCCESS,
  SELLER_UPDATE,
  SELLER_UPDATE_FAIL,
  SELLER_UPDATE_SUCCESS,
  WAREHOUSE_ADD,
  WAREHOUSE_ADD_FAIL,
  WAREHOUSE_ADD_SUCCESS,
  WAREHOUSE_DELETE,
  WAREHOUSE_DELETE_FAIL,
  WAREHOUSE_DELETE_SUCCESS,
  WAREHOUSE_LIST,
  WAREHOUSE_LIST_FAIL,
  WAREHOUSE_LIST_SUCCESS,
  WAREHOUSE_SINGLE,
  WAREHOUSE_SINGLE_FAIL,
  WAREHOUSE_SINGLE_SUCCESS,
  WAREHOUSE_STATUS_UPDATE,
  WAREHOUSE_STATUS_UPDATE_FAIL,
  WAREHOUSE_STATUS_UPDATE_SUCCESS,
  WAREHOUSE_UPDATE,
  WAREHOUSE_UPDATE_FAIL,
  WAREHOUSE_UPDATE_SUCCESS,
} from "../actions/sellerActions";

const initialState = {
  sellerList: {},
  sellerAdd: {},
  wareHouseList: {},
  sellerWareHouseAdd: {},
  sellerSingle: {},
  isLodding: false,
  error: "",
  isSuccess: false,
  isADDWarHouseSuccess: false,
  isWarHouseListSuccess: false,
  wareHouseSingle: {},
  isWareHouseUpdate: false,
  isWareHouseDelete: false,
  isWareHouseStatusUpdate: false,
};
const sellerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SELLER_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SELLER_LIST_SUCCESS: {
      return {
        ...state,
        sellerList: payload.data,
        isLoading: false,
      };
    }

    case SELLER_LIST_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SELLER_ADD: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case SELLER_ADD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        sellerAdd: payload.data,
        isLoading: false,
        isSuccess: true,
      };
    }

    case SELLER_ADD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }
    case SELLER_DELETE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case SELLER_DELETE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    }

    case SELLER_DELETE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }
    case SELLER_SINGLE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SELLER_SINGLE_SUCCESS: {
      return {
        ...state,
        sellerSingle: payload.data,
        isLoading: false,
      };
    }

    case SELLER_SINGLE_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SELLER_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case SELLER_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        // sellerUpdate: payload.data,
        isLoading: false,
        isSuccess: true,
      };
    }

    case SELLER_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }
    case SELLER_SINGLE_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case SELLER_SINGLE_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    }

    case SELLER_SINGLE_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    // ! Seller Warehouse reducer

    case WAREHOUSE_ADD: {
      return {
        ...state,
        isLoading: true,
        isADDWarHouseSuccess: false,
      };
    }

    case WAREHOUSE_ADD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        sellerWareHouseAdd: payload.data,
        isLoading: false,
        isADDWarHouseSuccess: true,
      };
    }

    case WAREHOUSE_ADD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isADDWarHouseSuccess: false,
      };
    }

    case WAREHOUSE_LIST: {
      return {
        ...state,
        isLoading: true,
        isWarHouseListSuccess: false,
      };
    }

    case WAREHOUSE_LIST_SUCCESS: {
      return {
        ...state,
        wareHouseList: payload.data,
        isLoading: false,
        isWarHouseListSuccess: true,
      };
    }

    case WAREHOUSE_LIST_FAIL: {
      return {
        ...state,
        isLoading: true,
        isWarHouseListSuccess: false,
      };
    }

    case WAREHOUSE_SINGLE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case WAREHOUSE_SINGLE_SUCCESS: {
      return {
        ...state,
        wareHouseSingle: payload.data,
        isLoading: false,
      };
    }

    case WAREHOUSE_SINGLE_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case WAREHOUSE_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isWareHouseUpdate: false,
      };
    }

    case WAREHOUSE_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isWareHouseUpdate: true,
      };
    }

    case WAREHOUSE_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isWareHouseUpdate: false,
      };
    }

    case WAREHOUSE_DELETE: {
      return {
        ...state,
        isLoading: true,
        isWareHouseDelete: false,
      };
    }

    case WAREHOUSE_DELETE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isWareHouseDelete: true,
      };
    }

    case WAREHOUSE_DELETE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isWareHouseDelete: false,
      };
    }

    case WAREHOUSE_STATUS_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isWareHouseStatusUpdate: false,
      };
    }

    case WAREHOUSE_STATUS_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isWareHouseStatusUpdate: true,
      };
    }

    case WAREHOUSE_STATUS_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isWareHouseStatusUpdate: false,
      };
    }
    default: {
      return { ...state, isSuccess: false, isWareHouseUpdate: false };
    }
  }
};
export default sellerReducer;
