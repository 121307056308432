import { authApi } from "./apis";
import { settingApi } from "./settingApis";
import { companyApi } from "./companyApis";
import { expenseApi } from "./expenseApis";
import { expenseCategoryApi } from "./expenseCategoryApis";
import { subscriptionsApi } from "./subscriptionsApis";
import { emailQueryApi } from "./emailQueryApis";
import { sellerApi } from "./sellerApis";
import { buyerApi } from "./buyerApis";
import { productApi } from "./productApis";
import { roleApi } from "./roleApis";
import { roleUserApi } from "./roleUserApis";
import { orderApi } from "./orderApis";
import { locationApi } from "./locationApis";

export const API = {
  ...authApi,
  ...settingApi,
  ...companyApi,
  ...expenseApi,
  ...expenseCategoryApi,
  ...subscriptionsApi,
  ...emailQueryApi,
  ...sellerApi,
  ...buyerApi,
  ...productApi,
  ...roleApi,
  ...roleUserApi,
  ...orderApi,
  ...locationApi,
};
