import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./../paymentDrawer/paymentDrawer.css";
import ButtonComponent from "./../../../@extended/button/ButtonComponent";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CommonTable from "../../../@extended/table/CommonTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CheckIcon from "@mui/icons-material/Check";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

const PaymentDrawer = (props) => {
  const [formData, setFormData] = useState([]);
  const { onClick, rowsData } = props;
  const columns = [
    {
      field: "mode",
      headerName: "Payment Mode",
      flex: 1,
      headerClassName: "paymentDrawer-header",
      id: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      headerClassName: "paymentDrawer-header",
      id: 2,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "paymentDrawer-header",
      id: 3,
      renderCell: () => {
        return (
          <Box className="table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
            />
          </Box>
        );
      },
    },
  ];
  // const rows = [
  //   {
  //     id: 1,
  //     mode: "cash",
  //     amount: "$41.00",
  //     action: "",
  //   },
  //   {
  //     id: 2,
  //     paymentMode: "cash",
  //     amount: "$41.00",
  //     action: "",
  //   },
  //   {
  //     id: 3,
  //     paymentMode: "cash",
  //     amount: "$41.00",
  //     action: "",
  //   },
  // ];
  const rows = formData?.map((data, ind) => {
    return {
      id: ind + 1,
      mode: data?.mode,
      amount: data?.amount,
      action: "",
    };
  });
  const [isAddPaymentMode, setIsAddPaymentMode] = useState(false);
  const totalSubtotal = rowsData.reduce((total, item) => {
    // Assuming "subtotal" is a string in the format "$XX.XX", remove the "$" and convert to a float
    const subtotalValue = parseFloat(item.subtotal.slice(1));
    return total + subtotalValue;
  }, 0);

  return (
    <>
      <Grid container padding={"24px"}>
        <Grid item xs={4}>
          <Box className="payment-detail-box">
            <Typography className="payment-title">Total Items</Typography>
            <Box className="payment-money">{rowsData.length}</Box>
          </Box>
          <Box className="payment-detail-box">
            <Typography className="payment-title">Paying Amount</Typography>
            <Box className="payment-money"> $0.00</Box>
          </Box>
          <Box className="payment-detail-box">
            <Typography className="payment-title">Payable Amount</Typography>
            <Box className="payment-money">${totalSubtotal.toFixed(2)}</Box>
          </Box>
          <Box className="payment-detail-box">
            <Typography className="payment-title">Due Amount</Typography>
            <Box className="payment-money">${totalSubtotal.toFixed(2)}</Box>
          </Box>
        </Grid>
        <Grid item xs={8}>
          {!isAddPaymentMode && (
            <>
              <Box className="payment-btns-main">
                <ButtonComponent
                  isName={true}
                  name="Add New Payment"
                  Icon={AddIcon}
                  className="new-payment-button"
                  onClick={() => setIsAddPaymentMode(true)}
                />
                <ButtonComponent
                  isName={true}
                  name="Complete Order"
                  EndIcon={ArrowForwardIosIcon}
                  className="complete-order-button background-white"
                  onClick={onClick}
                />
              </Box>
              <CommonTable columns={columns} rows={rows} />
            </>
          )}
          {isAddPaymentMode && (
            // <div onClick={() => setIsAddPaymentMode(false)}>hii</div>
            <>
              <ButtonComponent
                isName={true}
                name="back"
                Icon={ArrowBackIosNewIcon}
                className="new-payment-button"
                onClick={() => setIsAddPaymentMode(false)}
              />
              <Formik
                initialValues={{
                  mode: "",
                  amount: "",
                  note: "",
                }}
                onSubmit={(values) => {
                  setFormData((prev) => [...prev, values]);
                  setIsAddPaymentMode(false);
                }}
              >
                {({
                  handleChange,
                  handleSubmit,
                  // isSubmitting,
                  values,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Box className="payment-mode-wrapper">
                      <Box width={"100%"}>
                        <InputLabel
                          htmlFor="mode"
                          className="payment-label-div"
                        >
                          Payment Mode
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          name="mode"
                          value={values?.mode}
                          onChange={handleChange}
                          style={{ borderBlockColor: "gray" }}
                        >
                          <MenuItem value={"cash"}>Cash</MenuItem>
                          <MenuItem value={"stripe"}>Stripe</MenuItem>
                          <MenuItem value={"paypal"}>Paypal</MenuItem>
                        </Select>
                      </Box>
                      <Box width={"100%"}>
                        <InputLabel
                          htmlFor="amount"
                          className="payment-label-div"
                        >
                          Paying Amount
                        </InputLabel>
                        <TextField
                          fullWidth
                          name="amount"
                          id="amount"
                          value={values?.amount}
                          onChange={handleChange}
                          placeholder="Please Enter Payment Amount"
                        />
                        <Typography className="payment-text">
                          Payable Amount $26.00
                        </Typography>
                      </Box>
                    </Box>
                    <Box width={"100%"}>
                      <InputLabel htmlFor="note" className="payment-label-div">
                        Note
                      </InputLabel>
                      <TextField
                        fullWidth
                        multiline
                        name="note"
                        id="note"
                        value={values?.note}
                        onChange={handleChange}
                        rows={4}
                      />
                    </Box>
                    <ButtonComponent
                      isName={true}
                      name="Add"
                      Icon={CheckIcon}
                      className="payment-add-btn"
                      type="submit"
                    />
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

PaymentDrawer.propTypes = {
  onClick: PropTypes.any,
  rowsData: PropTypes.any,
};

export default PaymentDrawer;
