import { deleteRequest, get, patch, post } from "../utils/api";

export const addLocation = (data) => {
  return post(`/admin/location/`, data);
};
export const listLocation = (data) => {
  const { pageState = 1, limitState = 10, search = "" } = data;
  return get(
    `/admin/location/?page=${pageState}&limit=${limitState}&search=${search}`,
  );
};
export const deleteLocation = (id) => {
  return deleteRequest(`/admin/location/${id}`);
};
export const updateLocation = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/location/${id}`, data);
};

export const singleLocation = (id) => {
  return get(`/admin/location/${id}`);
};

export const fullListLocation = (data) => {
  return get(`/admin/location/full-list`, data);
};

export const locationApi = {
  addLocation,
  listLocation,
  deleteLocation,
  updateLocation,
  singleLocation,
  fullListLocation,
};
