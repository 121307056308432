export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REGESTER = "REGESTER";
export const REGESTER_SUCCESS = "REGESTER_SUCCESS";
export const REGESTER_FAIL = "REGESTER_FAIL";

export const PROFILE_DETAILS = "PROFILE_DETAILS";
export const PROFILE_DETAILS_SUCCESS = "PROFILE_DETAILS_SUCCESS";
export const PROFILE_DETAILS_FAIL = "PROFILE_DETAILS_FAIL";

export const UPDATE_DETAILS = "UPDATE_DETAILS";
export const UPDATE_DETAILS_SUCCESS = "UPDATE_DETAILS_SUCCESS";
export const UPDATE_DETAILS_FAIL = "UPDATE_DETAILS_FAIL";

export const COMPANY_COUNT = "COMPANY_COUNT";
export const COMPANY_COUNT_SUCCESS = "COMPANY_COUNT_SUCCESS";
export const COMPANY_COUNT_FAIL = "COMPANY_COUNT_FAIL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
