import {
  ADD_LOCATION,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAIL,
  LIST_LOCATION,
  LIST_LOCATION_SUCCESS,
  LIST_LOCATION_FAIL,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAIL,
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAIL,
  SINGLE_LOCATION,
  SINGLE_LOCATION_SUCCESS,
  SINGLE_LOCATION_FAIL,
  FULL_LIST_LOCATION,
  FULL_LIST_LOCATION_SUCCESS,
  FULL_LIST_LOCATION_FAIL,
} from "../actions/locationActions";
import { API } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";

function* addLocation(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.addLocation, otherPayload);
    yield put({ type: ADD_LOCATION_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: ADD_LOCATION_FAIL, payload: { error: error } });
  }
}

function* listLocation(action) {
  try {
    const response = yield call(API.listLocation, action.payload);
    yield put({ type: LIST_LOCATION_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_LOCATION_FAIL, payload: { error: error } });
  }
}

function* deleteLocation(action) {
  try {
    const response = yield call(API.deleteLocation, action.payload);
    yield put({ type: DELETE_LOCATION_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DELETE_LOCATION_FAIL, payload: { error: error } });
  }
}

function* updateLocation(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.updateLocation, otherPayload);
    yield put({ type: UPDATE_LOCATION_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: UPDATE_LOCATION_FAIL, payload: { error: error } });
  }
}

function* singleLocation(action) {
  try {
    const response = yield call(API.singleLocation, action.payload);
    yield put({ type: SINGLE_LOCATION_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: SINGLE_LOCATION_FAIL, payload: { error: error } });
  }
}

function* fullListLocation(action) {
  try {
    const response = yield call(API.fullListLocation, action.payload);
    yield put({ type: FULL_LIST_LOCATION_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: FULL_LIST_LOCATION_FAIL, payload: { error: error } });
  }
}

function* locationSaga() {
  yield takeLatest(ADD_LOCATION, addLocation);
  yield takeLatest(LIST_LOCATION, listLocation);
  yield takeLatest(DELETE_LOCATION, deleteLocation);
  yield takeLatest(UPDATE_LOCATION, updateLocation);
  yield takeLatest(SINGLE_LOCATION, singleLocation);
  yield takeLatest(FULL_LIST_LOCATION, fullListLocation);
}

export default locationSaga;
