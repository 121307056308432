import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";

import format from "date-fns/format";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Box, InputAdornment, OutlinedInput } from "@mui/material";
import PropTypes from "prop-types";
import EventIcon from "@mui/icons-material/Event";
import "./../../@extended/daterangepicker/dateRangeNew.css";

const DateRangeNew = (props) => {
  const { range, setRange } = props;

  const [open, setOpen] = useState(false);

  const refOne = useRef(null);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  return (
    <Box className="calendarWrap">
      <OutlinedInput
        value={`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(
          range[0].endDate,
          "MM/dd/yyyy",
        )}`}
        endAdornment={
          <InputAdornment
            position="end"
            onClick={() => setOpen((open) => !open)}
          >
            <EventIcon />
          </InputAdornment>
        }
      />

      <Box ref={refOne}>
        {open && (
          <DateRangePicker
            onChange={(item) => setRange([item.selection])}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            showSelectionPreview={true}
            ranges={range}
            months={1}
            direction="horizontal"
            className="calendarElement"
          />
        )}
      </Box>
    </Box>
  );
};
DateRangeNew.propTypes = {
  range: PropTypes.any,
  setRange: PropTypes.any,
};

export default DateRangeNew;
