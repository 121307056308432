import { Box } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { PRODUCT_SETTING_LIST } from "../../../actions/productActions";
import CommonTable from "../../@extended/table/CommonTable";

const ProductSettingTable = (props) => {
  const { columns } = props;
  const dispatch = useDispatch();
  const productSettingListData = useSelector(
    (data) => data?.product?.productSettingList,
  );

  const productSettingList = useCallback((values) => {
    dispatch({ type: PRODUCT_SETTING_LIST, payload: values });
  }, []);

  useEffect(() => {
    productSettingList();
  }, [window.location.pathname === "/admin/setting/product-setting"]);
  const rows = productSettingListData?.results?.map((item, ind) => {
    return {
      id: ind + 1,
      no: ind + 1,
      filedName: item?.filedName,
      value: item?.value,
      actions: "",
      rowId: item?.id,
    };
  });

  return (
    <Box className="email-query-box">
      <Box className="email-query-common-table-box-main">
        <Box className="email-query-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
          />
        </Box>
      </Box>
    </Box>
  );
};
ProductSettingTable.propTypes = {
  columns: PropTypes.any,
  type: PropTypes.any,
  search: PropTypes.any,
  setDeleteModal: PropTypes.any,
};
export default ProductSettingTable;
