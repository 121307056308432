import {
  Box,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { useDispatch, useSelector } from "react-redux";
// import { CONFIRM_ORDER } from "../../../actions/orderActions";
import dayjs from "dayjs";
import BasicDatePicker from "../../@extended/datepicker/DatePicker";
import { ORDER_PAYMENT } from "../../../actions/orderActions";
// import dayjs from "dayjs";

const PaymentStatus = ({ onClick, itemId, setId, setFetchData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );
  // date picker
  const [value, setValue] = useState(dayjs());
  const handleDateChange = (date) => {
    setValue(date);
  };
  return (
    <React.Fragment>
      <Box
        className={
          isMobile ? "expense-category-main-div" : "expense-category-form-box"
        }
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            paymentDate: "",
            paymentReferenceNumber: "",
          }}
          onSubmit={(values) => {
            dispatch({
              type: ORDER_PAYMENT,
              payload: {
                ...values,
                paymentStatus: "paid",
                id: itemId,
                paymentDate: value,
              },
            });
            setId(itemId);
            onClick();
            setFetchData(true);
          }}
        >
          {({ handleBlur, handleChange, handleSubmit, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Box
                maxHeight={"calc(100vh - 300px)"}
                overflow={"auto"}
                padding="10px 5px 40px 5px"
              >
                <Grid container>
                  <Grid item xs={12} className="expense-category-form-input">
                    <Stack spacing={1}>
                      <InputLabel htmlFor="name">Select Date</InputLabel>
                      <BasicDatePicker
                        value={values?.paymentDate}
                        name="paymentDate"
                        setValue={setValue}
                        handleDateChange={handleDateChange}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">
                      Payment Reference Number*
                    </InputLabel>
                    <OutlinedInput
                      id="paymentReferenceNumber"
                      size="large"
                      fullWidth
                      value={values?.paymentReferenceNumber}
                      name="paymentReferenceNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter reference number"
                    />
                  </Stack>
                </Grid>
              </Box>
              <Box className="expense-category-form-drawer-btn-wrapper-create">
                <ButtonComponent
                  name={itemId ? "Update" : "Create"}
                  isName={true}
                  Icon={SaveAsIcon}
                  type="submit"
                  disabledbtn="none"
                />
                <ButtonComponent
                  name="Cancel"
                  isName={true}
                  className="background-white"
                  onClick={onClick}
                  disabledbtn={userRoleAccess?.userPermissions?.find(
                    (data) =>
                      data?.userModule == "order" ||
                      userRoleAccess?.userRoleType == "admin",
                  )}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </React.Fragment>
  );
};
PaymentStatus.propTypes = {
  onClick: PropTypes.any,
  itemId: PropTypes.any,
  orderPayment: PropTypes.any,
  setId: PropTypes.any,
  setFetchData: PropTypes.any,
};
export default PaymentStatus;
