import { toast } from "react-hot-toast";
import {
  ADD_LOCATION,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAIL,
  LIST_LOCATION,
  LIST_LOCATION_SUCCESS,
  LIST_LOCATION_FAIL,
  DELETE_LOCATION,
  DELETE_LOCATION_FAIL,
  DELETE_LOCATION_SUCCESS,
  UPDATE_LOCATION,
  UPDATE_LOCATION_FAIL,
  UPDATE_LOCATION_SUCCESS,
  SINGLE_LOCATION,
  SINGLE_LOCATION_SUCCESS,
  SINGLE_LOCATION_FAIL,
  FULL_LIST_LOCATION,
  FULL_LIST_LOCATION_FAIL,
  FULL_LIST_LOCATION_SUCCESS,
} from "../actions/locationActions";

const initialState = {
  locationAdd: {},
  locationList: {},
  locationFullList: {},
  locationSingle: {},
  locationUpdate: {},

  isLoading: false,
  isAddLocationSuccess: false,
  isDeleteLocation: false,
  isUpdateLocation: false,
  error: "",
};
const locationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_LOCATION: {
      return {
        ...state,
        isLoading: true,
        isAddLocationSuccess: false,
      };
    }
    case ADD_LOCATION_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        locationAdd: payload.data,
        isLoading: false,
        isAddLocationSuccess: true,
      };
    }
    case ADD_LOCATION_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isAddLocationSuccess: false,
      };
    }
    case LIST_LOCATION: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LIST_LOCATION_SUCCESS: {
      return {
        ...state,
        locationList: payload?.data,
        isLoading: false,
      };
    }
    case LIST_LOCATION_FAIL: {
      return {
        ...state,
      };
    }
    case DELETE_LOCATION: {
      return {
        ...state,
        isLoading: true,
        isDeleteLocation: false,
      };
    }
    case DELETE_LOCATION_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteLocation: true,
      };
    }
    case DELETE_LOCATION_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteLocation: false,
      };
    }
    case UPDATE_LOCATION: {
      return {
        ...state,
        isLoading: true,
        isUpdateLocation: false,
      };
    }
    case UPDATE_LOCATION_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        locationUpdate: payload,
        isUpdateLocation: true,
      };
    }
    case UPDATE_LOCATION_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isUpdateLocation: false,
      };
    }
    case SINGLE_LOCATION: {
      return {
        ...state,
        isLoading: true,
        isUpdateLocation: false,
      };
    }
    case SINGLE_LOCATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        locationSingle: payload.data,
        isUpdateLocation: true,
      };
    }
    case SINGLE_LOCATION_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isUpdateLocation: false,
      };
    }
    case FULL_LIST_LOCATION: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FULL_LIST_LOCATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        locationFullList: payload.data,
      };
    }
    case FULL_LIST_LOCATION_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return { ...state };
    }
  }
};
export default locationReducer;
