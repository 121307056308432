//Expense Action

export const EXPENSE = "EXPENSE";
export const EXPENSE_SUCCESS = "EXPENSE_SUCCESS";
export const EXPENSE_FAIL = "EXPENSE_FAIL";

export const LIST_EXPENSE = "LIST_EXPENSE";
export const LIST_EXPENSE_SUCCESS = "LIST_EXPENSE_SUCCESS";
export const LIST_EXPENSE_FAIL = "LIST_EXPENSE_FAIL";

export const LIST_SINGLE_EXPENSE = "LIST_SINGLE_EXPENSE";
export const LIST_SINGLE_EXPENSE_SUCCESS = "LIST_SINGLE_EXPENSE_SUCCESS";
export const LIST_SINGLE_EXPENSE_FAIL = "LIST_SINGLE_EXPENSE_FAIL";

export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const UPDATE_EXPENSE_SUCCESS = "UPDATE_EXPENSE_SUCCESS";
export const UPDATE_EXPENSE_FAIL = "UPDATE_EXPENSE_FAIL";

export const DELETE_EXPENSE = "DELETE_EXPENSE";
export const DELETE_EXPENSE_SUCCESS = "DELETE_EXPENSE_SUCCESS";
export const DELETE_EXPENSE_FAIL = "DELETE_EXPENSE_FAIL";
