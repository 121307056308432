import toast from "react-hot-toast";
import {
  DELETE_EXPENSE,
  DELETE_EXPENSE_FAIL,
  DELETE_EXPENSE_SUCCESS,
  EXPENSE,
  EXPENSE_FAIL,
  EXPENSE_SUCCESS,
  LIST_EXPENSE,
  LIST_EXPENSE_FAIL,
  LIST_EXPENSE_SUCCESS,
  LIST_SINGLE_EXPENSE,
  LIST_SINGLE_EXPENSE_FAIL,
  LIST_SINGLE_EXPENSE_SUCCESS,
  UPDATE_EXPENSE,
  UPDATE_EXPENSE_FAIL,
  UPDATE_EXPENSE_SUCCESS,
} from "../actions/expenseActions";

const initialState = {
  addExpense: {},
  listExpense: {},
  singleExpense: {},
  updateExpense: {},
  deleteExpense: {},
  isAddExpenseSuccess: false,
  isUpdateExpenseSuccess: false,
  isDeleteExpenseSuccess: false,
  error: "",
};
const expenseReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    //EXPENSE reducer
    case EXPENSE: {
      return {
        ...state,
        isLoading: true,
        isAddExpenseSuccess: false,
      };
    }
    case EXPENSE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        addExpense: payload.user,
        isLoading: false,
        isAddExpenseSuccess: true,
      };
    }

    case EXPENSE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isAddExpenseSuccess: false,
      };
    }
    case LIST_EXPENSE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_EXPENSE_SUCCESS: {
      return {
        ...state,
        listExpense: payload.data,
        isLoading: false,
      };
    }

    case LIST_EXPENSE_FAIL: {
      return {
        ...state,
      };
    }

    case LIST_SINGLE_EXPENSE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_SINGLE_EXPENSE_SUCCESS: {
      return {
        ...state,
        singleExpense: payload.data,
        isLoading: false,
      };
    }

    case LIST_SINGLE_EXPENSE_FAIL: {
      return {
        ...state,
      };
    }
    case UPDATE_EXPENSE: {
      return {
        ...state,
        isLoading: true,
        isUpdateExpenseSuccess: false,
      };
    }

    case UPDATE_EXPENSE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        updateExpense: payload,
        isLoading: false,
        isUpdateExpenseSuccess: true,
      };
    }

    case UPDATE_EXPENSE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isUpdateExpenseSuccess: false,
      };
    }
    case DELETE_EXPENSE: {
      return {
        ...state,
        isLoading: true,
        isDeleteExpenseSuccess: false,
      };
    }

    case DELETE_EXPENSE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteExpenseSuccess: true,
      };
    }

    case DELETE_EXPENSE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteExpenseSuccess: false,
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default expenseReducer;
