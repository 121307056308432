import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import CommonTable from "../../../components/@extended/table/CommonTable";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ButtonComponent from "../../../components/@extended/button/ButtonComponent";
import "./expenseCategories.css";
import SelectWithSearch from "../../../components/@extended/selectwithsearch/SelectWithSearch";
import AddIcon from "@mui/icons-material/Add";
import CustomizedModal from "../../../components/@extended/customizedmodal/CustomizedModal";
import ExpenseCategoryForm from "./ExpenseCategoriesForm";
import {
  DELETE_EXPENSE_CATEGORY,
  LIST_EXPENSE_CATEGORY,
  LIST_SINGLE_EXPENSE_CATEGORY,
} from "../../../actions/expenseCategoryActions";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../components/@extended/deletemodal/DeleteModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const ExpenseCategories = () => {
  const [open, setOpen] = useState("");
  const [id, setId] = useState("");
  const [deleteModal, setDeleteModal] = useState("");
  const dispatch = useDispatch();
  const listDataExpenseCategory = useSelector(
    (state) => state?.expenseCategory?.listExpenseCategory?.results,
  );
  const isDeleteSuccess = useSelector(
    (data) => data?.expenseCategory?.isDeleteExpenseCategorySuccess,
  );
  const isAddSuccess = useSelector(
    (data) => data?.expenseCategory?.isAddExpenseCategorySuccess,
  );
  const isUpdateSuccess = useSelector(
    (data) => data?.expenseCategory?.isUpdateExpenseCategorySuccess,
  );
  const singleDataListExpenseCategory = useSelector(
    (data) => data?.expenseCategory?.singleExpenseCategory,
  );

  const listExpenseCategory = useCallback((data) => {
    dispatch({ type: LIST_EXPENSE_CATEGORY, payload: data });
  }, []);

  const singleListExpenseCategory = useCallback((data) => {
    dispatch({ type: LIST_SINGLE_EXPENSE_CATEGORY, payload: data });
  }, []);
  const deleteExpenseCategory = useCallback((data) => {
    dispatch({ type: DELETE_EXPENSE_CATEGORY, payload: data });
  }, []);

  const rows = listDataExpenseCategory?.map((item, ind) => {
    return {
      id: ind + 1,
      name: item?.name,
      description: item?.description,
      actions: "",
      rowId: item?.id,
    };
  });
  const columns = [
    {
      field: "name",
      headerName: "	Expense Category Name",
      flex: 1,
      headerClassName: "expense-category-header",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      headerClassName: "expense-category-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "expense-category-header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="expense-category-table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              onClick={() => {
                setOpen(true);
                setId(params?.row?.rowId);
              }}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => {
                setDeleteModal(true);
                setId(params?.row?.rowId);
              }}
            />
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    listExpenseCategory();
    if (id) {
      singleListExpenseCategory(id);
    }
    if (isAddSuccess) {
      listExpenseCategory();
    }
    if (isDeleteSuccess) {
      listExpenseCategory();
    }
    if (isUpdateSuccess) {
      listExpenseCategory();
    }
  }, [isAddSuccess, isDeleteSuccess, isUpdateSuccess, id]);
  const [heading, setHeading] = React.useState("en");
  const handleChange = (event) => {
    setHeading(event.target.value);
  };
  return (
    <Box className="expense-category-box">
      <Box className="expense-category-box-main">
        <ButtonComponent
          name="Add New Expense Category"
          isName={true}
          Icon={AddIcon}
          onClick={() => setOpen(true)}
        />
        <SelectWithSearch
          handleChange={handleChange}
          heading={heading}
          optionData={columns}
        />
      </Box>
      <Box className="expense-category-common-table-box-main">
        <Box className="expense-category-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
          />
        </Box>
      </Box>
      {open && (
        <CustomizedModal
          isHeadingName={true}
          heading={id ? "Edit Expense Category" : "Add New Expense Category"}
          open={open}
          onClick={() => {
            setOpen(false);
            setId("");
          }}
        >
          <ExpenseCategoryForm
            onClick={() => {
              setOpen(false);
              setId("");
            }}
            singleData={singleDataListExpenseCategory}
            id={id}
          />
        </CustomizedModal>
      )}
      {deleteModal && (
        <DeleteModal
          isIcon={true}
          Icon={ErrorOutlineOutlinedIcon}
          open={deleteModal}
          onClick={() => {
            setDeleteModal(false);
            setId("");
          }}
          id={id}
          deleteData={deleteExpenseCategory}
        />
      )}
    </Box>
  );
};

export default ExpenseCategories;
