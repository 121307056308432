import { all, fork } from "redux-saga/effects";
import auth from "./authSaga";
import setting from "./settingSaga";
import company from "./companySaga";
import expense from "./expenseSaga";
import expenseCategory from "./expenseCategorySaga";
import subscriptions from "./subscriptionsSaga";
import emailQuery from "./emailQuerySaga";
import seller from "./sellerSaga";
import buyer from "./buyerSaga";
import product from "./product";
import role from "./roleSaga";
import roleUser from "./roleUserSaga";
import order from "./orderSaga";
import location from "./locationSaga";

export default function* rootSaga() {
  yield all([
    fork(auth),
    fork(setting),
    fork(expense),
    fork(expenseCategory),
    fork(company),
    fork(subscriptions),
    fork(emailQuery),
    fork(seller),
    fork(buyer),
    fork(product),
    fork(role),
    fork(roleUser),
    fork(order),
    fork(location),
  ]);
}
