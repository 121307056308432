import { deleteRequest, get, patch, post } from "../utils/api";

export const listCompany = (data) => {
  return get(
    `/company/list/?page=${data.pageState ? data.pageState : 1}&limit=${
      data.limitState ? data.limitState : 10
    }&status=${data.statusStatus ? data.statusStatus : ""}`,
  );
};

export const addCompany = (data) => {
  return post(`/company/`, data);
};

export const updateCompany = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/company/${id}`, data);
};

export const singleCompanyList = (id) => {
  return get(`/company/${id}`);
};

export const deleteCompanyList = (id) => {
  return deleteRequest(`/company/${id}`);
};

export const planList = (id) => {
  return get(`/subscribe-package/${id}`);
};

export const subscriptionPlanList = () => {
  return get(`/subscriptions/`);
};

export const paymentModeList = () => {
  return get(`/payment-mode/`);
};

export const addPlanList = (values) => {
  return post(`/subscribe-package/`, values);
};

export const companyApi = {
  listCompany,
  addCompany,
  singleCompanyList,
  updateCompany,
  deleteCompanyList,
  planList,
  subscriptionPlanList,
  paymentModeList,
  addPlanList,
};
