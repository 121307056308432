/* eslint-disable no-unused-vars */

import "./../subscriptionplan/addNewSubscriptionPlan.css";
import PropTypes from "prop-types";

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { FieldArray, Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../../../@extended/button/ButtonComponent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddIcon from "@mui/icons-material/Add";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTIONS,
} from "../../../../../actions/subscriptionsActions";
import CloseIcon from "@mui/icons-material/Close";

const AddNewSubscriptionPlans = (props) => {
  const { toggleDrawer, id, singleData } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));

  const dispatch = useDispatch();
  //add Subscription
  const subscriptionAdd = useCallback((values) => {
    dispatch({ type: SUBSCRIPTIONS, payload: values });
  });

  // update Subscription
  const updateSubscription = useCallback((data) => {
    dispatch({ type: UPDATE_SUBSCRIPTIONS, payload: data });
  }, []);
  const modules = [
    { label: "POS", value: "pos" },
    { label: "Stock Transfer", value: "stockTransfer" },
    { label: "Stock Adjustment", value: "stockAdjustment" },
    { label: "Online Store", value: "onlineStore" },
    { label: "Expense", value: "expense" },
    { label: "Quotation/Estimate", value: "quotation" },
    { label: "Purchase Return", value: "purchaseReturn" },
    { label: "Sales Return", value: "salesReturn" },
    { label: "Reports", value: "reports" },
    { label: "Reports Download", value: "reportsDownload" },
  ];

  return (
    <Box
      className={
        isMobile
          ? "subscription-plans-main-div"
          : "subscription-plans-mobile-main-div"
      }
    >
      <Formik
        initialValues={{
          name: id ? singleData?.name : "",
          maxProducts: id ? singleData?.maxProducts : "",
          description: id ? singleData?.description : "",
          isPopular: id ? singleData?.isPopular : false,
          monthlyPrice: id ? singleData?.monthlyPrice : "",
          annualPrice: id ? singleData?.annualPrice : "",
          monthlyPlanId: id ? singleData?.monthlyPlanId : "",
          annualPlanId: id ? singleData?.annualPlanId : "",
          modules: id
            ? singleData?.modules?.map((data) => data?.moduleName)
            : [],
          features: id ? singleData?.features : [],
        }}
        validationSchema={Yup.object({
          name: Yup.string().max(255).required("Name is required"),
          maxProducts: Yup.string()
            .max(255)
            .required("Max Products is required"),
          description: Yup.string().required("Description is required"),
          monthlyPrice: Yup.string().required("Monthly Price is required"),
          annualPrice: Yup.string().required("Annual Price is required"),
          // isPopular: Yup.string(),
          // modules: Yup.string(),
          monthlyPlanId: Yup.string().required(
            "Stripe Monthly Plan ID is required",
          ),

          annualPlanId: Yup.string().required(
            "Stripe Annual Plan ID is required",
          ),
          features: Yup.array().of(Yup.string()),
        })}
        onSubmit={(values) => {
          console.log("values==>", values);
        }}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
        }) => (
          <div className="subscription-plans-div">
            <Form noValidate onSubmit={handleSubmit}>
              <Grid container className="subscription-plans-form-container">
                <Grid
                  className={
                    isMobile
                      ? "subscription-plans-main-grid"
                      : "subscription-plans-mobile-form-main-grid"
                  }
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="subscription-plans-main-input"
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="name">Name*</InputLabel>
                      <OutlinedInput
                        id="name"
                        value={values?.name}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Please Enter Name"
                        fullWidth
                        error={Boolean(touched?.name && errors?.name)}
                      />
                      {touched?.name && errors?.name && (
                        <FormHelperText error id="helper-text-name">
                          {errors?.name}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="subscription-plans-main-input"
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="maxProducts">
                        Max Products*
                      </InputLabel>
                      <OutlinedInput
                        id="maxProducts"
                        value={values?.maxProducts}
                        name="maxProducts"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Please Enter Max Products"
                        fullWidth
                        error={Boolean(
                          touched?.maxProducts && errors?.maxProducts,
                        )}
                      />
                      {touched?.maxProducts && errors?.maxProducts && (
                        <FormHelperText error id="helper-text-maxProducts">
                          {errors?.maxProducts}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className="subscription-plans-main-input"
                >
                  <Stack spacing={1}>
                    <Typography
                      variant="body2"
                      className="subscription-plans-address"
                    >
                      Description
                    </Typography>
                    <TextField
                      multiline
                      name="description"
                      id="description"
                      value={values?.description}
                      onBlur={handleBlur}
                      error={errors?.description}
                      onChange={handleChange}
                      rows={2}
                      placeholder="Please Enter Description"
                    />
                    {touched?.description && errors?.description && (
                      <FormHelperText error id="helper-text-description">
                        {errors?.description}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>

                <Grid xs={12} className="subscription-plans-main-invoice">
                  <InputLabel
                    htmlFor="isPopular"
                    className="subscription-plans-form-invoice"
                  >
                    {" "}
                    Is Popular
                  </InputLabel>
                  <FormGroup>
                    <FormControlLabel
                      name="isPopular"
                      control={
                        <Switch
                          defaultChecked
                          checked={values?.isPopular}
                          onChange={handleChange}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid xs={12} className="subscription-plans-heading">
                  Pricing Details{" "}
                </Grid>

                <Grid
                  className={
                    isMobile
                      ? "subscription-plans-main-grid"
                      : "subscription-plans-mobile-form-main-grid"
                  }
                >
                  <Grid
                    xs={12}
                    md={6}
                    xl={6}
                    className="subscription-plans-main-input"
                  >
                    <Box className="select-wrapper">
                      <label htmlFor="monthlyPrice" className="label-div">
                        Monthly Price
                      </label>
                      <TextField
                        id="outlined-adornment-amount"
                        type="number"
                        name="monthlyPrice"
                        value={values?.monthlyPrice}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start">
                                <AttachMoneyIcon fontSize="20px" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {touched?.monthlyPrice && errors?.monthlyPrice && (
                        <FormHelperText error id="helper-text-monthlyPrice">
                          {errors?.monthlyPrice}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    xs={12}
                    md={6}
                    xl={6}
                    className="subscription-plans-main-input"
                  >
                    <Box className="select-wrapper">
                      <label htmlFor="annualPrice" className="label-div">
                        Annual Price
                      </label>
                      <TextField
                        id="outlined-adornment-amount"
                        type="number"
                        name="annualPrice"
                        value={values?.annualPrice}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start">
                                <AttachMoneyIcon fontSize="20px" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {touched?.annualPrice && errors?.annualPrice && (
                        <FormHelperText error id="helper-text-annualPrice">
                          {errors?.annualPrice}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid xs={12} className="subscription-plans-heading">
                  Payment Gateway{" "}
                </Grid>
                <Grid
                  className={
                    isMobile
                      ? "subscription-plans-main-grid"
                      : "subscription-plans-mobile-form-main-grid"
                  }
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="subscription-plans-main-input"
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="monthlyPlanId">
                        Stripe Monthly Plan ID*
                      </InputLabel>
                      <OutlinedInput
                        id="monthlyPlanId"
                        value={values?.monthlyPlanId}
                        name="monthlyPlanId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Please Enter Stripe Monthly Plan ID"
                        fullWidth
                        error={Boolean(
                          touched?.monthlyPlanId && errors?.monthlyPlanId,
                        )}
                      />
                      {touched?.monthlyPlanId && errors?.monthlyPlanId && (
                        <FormHelperText error id="helper-text-monthlyPlanId">
                          {errors?.monthlyPlanId}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="subscription-plans-main-input"
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="annualPlanId">
                        Stripe Annual Plan ID*
                      </InputLabel>
                      <OutlinedInput
                        id="annualPlanId"
                        value={values?.annualPlanId}
                        name="annualPlanId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Please Enter Stripe Annual Plan ID"
                        fullWidth
                        error={Boolean(
                          touched?.annualPlanId && errors?.annualPlanId,
                        )}
                      />
                      {touched?.annualPlanId && errors?.annualPlanId && (
                        <FormHelperText error id="helper-text-annualPlanId">
                          {errors?.annualPlanId}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid xs={12} className="subscription-plans-heading">
                  Enabled Modules{" "}
                </Grid>

                {modules?.map((role, ind) => {
                  return (
                    <Grid item xs={4} key={ind} container>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values?.modules?.includes(role?.value)}
                            onChange={() => {
                              if (values?.modules?.includes(role?.value)) {
                                const updatedModules = values?.modules?.filter(
                                  (value) => value !== role?.value,
                                );
                                setFieldValue("modules", updatedModules);
                              } else {
                                const updatedModules = [
                                  ...values.modules,
                                  role?.value,
                                ];
                                setFieldValue("modules", updatedModules);
                              }
                            }}
                          />
                        }
                        label={role?.label}
                        labelPlacement="end"
                      />
                    </Grid>
                  );
                })}
                <Grid xs={12} className="subscription-plans-heading">
                  Features{" "}
                </Grid>
                <FieldArray name="features">
                  {({ insert, remove, push }) => (
                    <>
                      {values?.features?.length > 0 &&
                        values?.features?.map((friend, index) => (
                          <Box key={index}>
                            <Grid
                              item
                              className="subscription-plans-main-input-features"
                            >
                              <OutlinedInput
                                id="features"
                                value={values?.features?.[index]}
                                name={`features.${index}`}
                                onChange={handleChange}
                                fullWidth
                                size="small"
                                error={Boolean(
                                  touched?.features?.[index] &&
                                    errors?.features?.[index],
                                )}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => remove(index)}
                                      edge="end"
                                      size="large"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              {touched?.features?.[index] &&
                                errors?.features?.[index] && (
                                  <FormHelperText
                                    error
                                    id="helper-text-features"
                                  >
                                    {errors?.features?.[index]}
                                  </FormHelperText>
                                )}
                            </Grid>
                          </Box>
                        ))}
                      <ButtonComponent
                        name="Add Features"
                        isName={true}
                        Icon={AddIcon}
                        className="background-white-border"
                        onClick={() => push("")}
                      />
                    </>
                  )}
                </FieldArray>
              </Grid>
              <Box className="common-form-drawer-btn-wrapper">
                <ButtonComponent
                  name={id ? "Update" : "Create"}
                  isName={true}
                  Icon={SaveAsIcon}
                  type="submit"
                  onClick={(e) => {
                    if (id) {
                      updateSubscription({ ...values, id: id });
                    } else {
                      subscriptionAdd({ ...values });
                    }
                    toggleDrawer("right", false)(e);
                  }}
                />
                <Box className="common-form-drawer-btn-2">
                  <ButtonComponent
                    name="Cancel"
                    isName={true}
                    className="background-white"
                    onClick={toggleDrawer("right", false)}
                  />
                </Box>
              </Box>
            </Form>
          </div>
        )}
      </Formik>
    </Box>
  );
};
AddNewSubscriptionPlans.propTypes = {
  toggleDrawer: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
};

export default AddNewSubscriptionPlans;
