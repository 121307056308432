export const ADD_LOCATION = "ADD_LOCATION";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_FAIL = "ADD_LOCATION_FAIL";

export const LIST_LOCATION = "LIST_LOCATION";
export const LIST_LOCATION_SUCCESS = "LIST_LOCATION_SUCCESS";
export const LIST_LOCATION_FAIL = "LIST_LOCATION_FAIL";

export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAIL = "DELETE_LOCATION_FAIL";

export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_FAIL = "UPDATE_LOCATION_FAIL";

export const SINGLE_LOCATION = "SINGLE_LOCATION";
export const SINGLE_LOCATION_SUCCESS = "SINGLE_LOCATION_SUCCESS";
export const SINGLE_LOCATION_FAIL = "SINGLE_LOCATION_FAIL";

export const FULL_LIST_LOCATION = "FULL_LIST_LOCATION";
export const FULL_LIST_LOCATION_SUCCESS = "FULL_LIST_LOCATION_SUCCESS";
export const FULL_LIST_LOCATION_FAIL = "FULL_LIST_LOCATION_FAIL";
