import { toast } from "react-hot-toast";
import {
  DELETE_ROLE,
  DELETE_ROLE_FAIL,
  DELETE_ROLE_SUCCESS,
  LIST_ROLE,
  LIST_ROLE_FAIL,
  LIST_ROLE_SUCCESS,
  LIST_SINGLE_ROLE,
  LIST_SINGLE_ROLE_FAIL,
  LIST_SINGLE_ROLE_SUCCESS,
  ROLE_ADD,
  ROLE_ADD_FAIL,
  ROLE_ADD_SUCCESS,
  UPDATE_ROLE,
  UPDATE_ROLE_FAIL,
  UPDATE_ROLE_SUCCESS,
} from "../actions/roleActions";

const initialState = {
  roleAdd: {},
  listRole: {},
  listSingleRole: {},
  updateRole: {},
  isLodding: false,
  error: "",
  isSuccess: false,
  isUpdataRoleSuccess: false,
  isDeleteRoleSuccess: false,
};
const roleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ROLE_ADD: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case ROLE_ADD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        roleAdd: payload.data,
        isLoading: false,
        isSuccess: true,
      };
    }

    case ROLE_ADD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case LIST_ROLE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_ROLE_SUCCESS: {
      return {
        ...state,
        listRole: payload?.data,
        isLoading: false,
      };
    }

    case LIST_ROLE_FAIL: {
      return {
        ...state,
      };
    }

    case LIST_SINGLE_ROLE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_SINGLE_ROLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        listSingleRole: payload,
      };
    }

    case LIST_SINGLE_ROLE_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case UPDATE_ROLE: {
      return {
        ...state,
        isLoading: true,
        isUpdataRoleSuccess: false,
      };
    }

    case UPDATE_ROLE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        updateRole: payload,
        isLoading: false,
        isUpdataRoleSuccess: true,
      };
    }

    case UPDATE_ROLE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isUpdataRoleSuccess: false,
      };
    }
    case DELETE_ROLE: {
      return {
        ...state,
        isLoading: true,
        isDeleteRoleSuccess: false,
      };
    }

    case DELETE_ROLE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteRoleSuccess: true,
      };
    }

    case DELETE_ROLE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteRoleSuccess: false,
      };
    }

    default: {
      return { ...state };
    }
  }
};
export default roleReducer;
