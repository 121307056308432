import {
  BRANCH,
  BRANCH_FAIL,
  BRANCH_SUCCESS,
  CURRENCIES,
  CURRENCIES_FAIL,
  CURRENCIES_SUCCESS,
  CUSTOM_FIELD,
  CUSTOM_FIELD_FAIL,
  CUSTOM_FIELD_SUCCESS,
  DELETE_BRANCH,
  DELETE_BRANCH_FAIL,
  DELETE_BRANCH_SUCCESS,
  DELETE_CURRENCIES,
  DELETE_CURRENCIES_FAIL,
  DELETE_CURRENCIES_SUCCESS,
  DELETE_CUSTOM_FIELD,
  DELETE_CUSTOM_FIELD_FAIL,
  DELETE_CUSTOM_FIELD_SUCCESS,
  DELETE_PAYMENT_MODE,
  DELETE_PAYMENT_MODE_FAIL,
  DELETE_PAYMENT_MODE_SUCCESS,
  DELETE_TAX,
  DELETE_TAX_FAIL,
  DELETE_TAX_SUCCESS,
  DELETE_UNIT,
  DELETE_UNIT_FAIL,
  DELETE_UNIT_SUCCESS,
  LIST_BRANCH,
  LIST_BRANCH_FAIL,
  LIST_BRANCH_SUCCESS,
  LIST_CURRENCIES,
  LIST_CURRENCIES_FAIL,
  LIST_CURRENCIES_SUCCESS,
  LIST_CUSTOM_FIELD,
  LIST_CUSTOM_FIELD_FAIL,
  LIST_CUSTOM_FIELD_SUCCESS,
  LIST_PAYMENT_MODE,
  LIST_PAYMENT_MODE_FAIL,
  LIST_PAYMENT_MODE_SUCCESS,
  LIST_SINGLE_CURRENCIES,
  LIST_SINGLE_CURRENCIES_FAIL,
  LIST_SINGLE_CURRENCIES_SUCCESS,
  LIST_SINGLE_CUSTOM_FIELD,
  LIST_SINGLE_CUSTOM_FIELD_FAIL,
  LIST_SINGLE_CUSTOM_FIELD_SUCCESS,
  LIST_SINGLE_PAYMENT_MODE,
  LIST_SINGLE_PAYMENT_MODE_FAIL,
  LIST_SINGLE_PAYMENT_MODE_SUCCESS,
  LIST_SINGLE_TAX,
  LIST_SINGLE_TAX_FAIL,
  LIST_SINGLE_TAX_SUCCESS,
  LIST_SINGLE_UNIT,
  LIST_SINGLE_UNIT_FAIL,
  LIST_SINGLE_UNIT_SUCCESS,
  LIST_TAX,
  LIST_TAX_FAIL,
  LIST_TAX_SUCCESS,
  LIST_UNIT,
  LIST_UNIT_FAIL,
  LIST_UNIT_SUCCESS,
  PAYMENT_MODE,
  PAYMENT_MODE_FAIL,
  PAYMENT_MODE_SUCCESS,
  TAX,
  TAX_FAIL,
  TAX_SUCCESS,
  UNIT,
  UNIT_FAIL,
  UNIT_SUCCESS,
  UPDATE_BRANCH,
  UPDATE_BRANCH_FAIL,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_CURRENCIES,
  UPDATE_CURRENCIES_FAIL,
  UPDATE_CURRENCIES_SUCCESS,
  UPDATE_CUSTOM_FIELD,
  UPDATE_CUSTOM_FIELD_FAIL,
  UPDATE_CUSTOM_FIELD_SUCCESS,
  UPDATE_PAYMENT_MODE,
  UPDATE_PAYMENT_MODE_FAIL,
  UPDATE_PAYMENT_MODE_SUCCESS,
  UPDATE_TAX,
  UPDATE_TAX_FAIL,
  UPDATE_TAX_SUCCESS,
  UPDATE_UNIT,
  UPDATE_UNIT_SUCCESS,
} from "../actions/settingActions";
import { API } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";

//Branch Sagas
function* branch(action) {
  try {
    const response = yield call(API.addBranch, action.payload);
    yield put({ type: BRANCH_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BRANCH_FAIL, payload: { error: error } });
  }
}
function* branchList(action) {
  try {
    const response = yield call(API.listBranch, action.payload);
    yield put({ type: LIST_BRANCH_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_BRANCH_FAIL, payload: { error: error } });
  }
}

function* updateBranch(action) {
  try {
    const response = yield call(API.updateBranch, action.payload);
    yield put({ type: UPDATE_BRANCH_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_BRANCH_FAIL, payload: { error: error } });
  }
}
function* deleteBranch(action) {
  try {
    const response = yield call(API.deleteBranch, action.payload);
    yield put({ type: DELETE_BRANCH_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DELETE_BRANCH_FAIL, payload: { error: error } });
  }
}

//unit sagas
function* addUnit(action) {
  try {
    const response = yield call(API.addUnit, action.payload);
    yield put({ type: UNIT_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UNIT_FAIL, payload: { error: error } });
  }
}
function* listUnit(action) {
  try {
    const response = yield call(API.listUnit, action.payload);
    yield put({ type: LIST_UNIT_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_UNIT_FAIL, payload: { error: error } });
  }
}
function* singleUnit(action) {
  try {
    const response = yield call(API.singleUnit, action.payload);
    yield put({ type: LIST_SINGLE_UNIT_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_SINGLE_UNIT_FAIL, payload: { error: error } });
  }
}
function* updateUnit(action) {
  try {
    const response = yield call(API.updateUnit, action.payload);
    yield put({ type: UPDATE_UNIT_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_UNIT_FAIL, payload: { error: error } });
  }
}
function* deleteUnit(action) {
  try {
    const response = yield call(API.deleteUnit, action.payload);
    yield put({ type: DELETE_UNIT_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DELETE_UNIT_FAIL, payload: { error: error } });
  }
}

//tax sagas
function* addTax(action) {
  try {
    const response = yield call(API.addTax, action.payload);
    yield put({ type: TAX_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: TAX_FAIL, payload: { error: error } });
  }
}
function* listTax(action) {
  try {
    const response = yield call(API.listTax, action.payload);
    yield put({ type: LIST_TAX_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_TAX_FAIL, payload: { error: error } });
  }
}
function* singleTax(action) {
  try {
    const response = yield call(API.singleTax, action.payload);
    yield put({ type: LIST_SINGLE_TAX_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_SINGLE_TAX_FAIL, payload: { error: error } });
  }
}
function* updateTax(action) {
  try {
    const response = yield call(API.updateTax, action.payload);
    yield put({ type: UPDATE_TAX_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_TAX_FAIL, payload: { error: error } });
  }
}
function* deleteTax(action) {
  try {
    const response = yield call(API.deleteTax, action.payload);
    yield put({ type: DELETE_TAX_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DELETE_TAX_FAIL, payload: { error: error } });
  }
}
//payment sagas
function* addPaymentMode(action) {
  try {
    const response = yield call(API.addPaymentMode, action.payload);
    yield put({ type: PAYMENT_MODE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: PAYMENT_MODE_FAIL, payload: { error: error } });
  }
}
function* listPaymentMode(action) {
  try {
    const response = yield call(API.listPaymentMode, action.payload);
    yield put({ type: LIST_PAYMENT_MODE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_PAYMENT_MODE_FAIL, payload: { error: error } });
  }
}
function* singlePaymentMode(action) {
  try {
    const response = yield call(API.singlePaymentMode, action.payload);
    yield put({
      type: LIST_SINGLE_PAYMENT_MODE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LIST_SINGLE_PAYMENT_MODE_FAIL,
      payload: { error: error },
    });
  }
}
function* updatePaymentMode(action) {
  try {
    const response = yield call(API.updatePaymentMode, action.payload);
    yield put({ type: UPDATE_PAYMENT_MODE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_PAYMENT_MODE_FAIL, payload: { error: error } });
  }
}
function* deletePaymentMode(action) {
  try {
    const response = yield call(API.deletePaymentMode, action.payload);
    yield put({ type: DELETE_PAYMENT_MODE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DELETE_PAYMENT_MODE_FAIL, payload: { error: error } });
  }
}
// Currency saga

function* addCurrency(action) {
  try {
    const response = yield call(API.addCurrencies, action.payload);
    yield put({ type: CURRENCIES_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CURRENCIES_FAIL, payload: { error: error } });
  }
}
function* listCurrency(action) {
  try {
    const response = yield call(API.listCurrencies, action.payload);
    yield put({ type: LIST_CURRENCIES_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_CURRENCIES_FAIL, payload: { error: error } });
  }
}
function* singleCurrency(action) {
  try {
    const response = yield call(API.singleCurrencies, action.payload);
    yield put({ type: LIST_SINGLE_CURRENCIES_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_SINGLE_CURRENCIES_FAIL, payload: { error: error } });
  }
}
function* updateCurrency(action) {
  try {
    const response = yield call(API.updateCurrencies, action.payload);
    yield put({ type: UPDATE_CURRENCIES_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_CURRENCIES_FAIL, payload: { error: error } });
  }
}
function* deleteCurrency(action) {
  try {
    const response = yield call(API.deleteCurrencies, action.payload);
    yield put({ type: DELETE_CURRENCIES_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DELETE_CURRENCIES_FAIL, payload: { error: error } });
  }
}
//custom field saga
function* addCustomField(action) {
  try {
    const response = yield call(API.addCustomField, action.payload);
    yield put({ type: CUSTOM_FIELD_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CUSTOM_FIELD_FAIL, payload: { error: error } });
  }
}
function* listCustomField(action) {
  try {
    const response = yield call(API.listCustomField, action.payload);
    yield put({ type: LIST_CUSTOM_FIELD_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_CUSTOM_FIELD_FAIL, payload: { error: error } });
  }
}
function* singleCustomField(action) {
  try {
    const response = yield call(API.singleCustomField, action.payload);
    yield put({
      type: LIST_SINGLE_CUSTOM_FIELD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LIST_SINGLE_CUSTOM_FIELD_FAIL,
      payload: { error: error },
    });
  }
}
function* updateCustomField(action) {
  try {
    const response = yield call(API.updateCustomField, action.payload);
    yield put({ type: UPDATE_CUSTOM_FIELD_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_CUSTOM_FIELD_FAIL, payload: { error: error } });
  }
}
function* deleteCustomField(action) {
  try {
    const response = yield call(API.deleteCustomField, action.payload);
    yield put({ type: DELETE_CUSTOM_FIELD_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DELETE_CUSTOM_FIELD_FAIL, payload: { error: error } });
  }
}
function* setting() {
  yield takeLatest(BRANCH, branch);
  yield takeLatest(LIST_BRANCH, branchList);
  yield takeLatest(UPDATE_BRANCH, updateBranch);
  yield takeLatest(DELETE_BRANCH, deleteBranch);
  yield takeLatest(UNIT, addUnit);
  yield takeLatest(LIST_UNIT, listUnit);
  yield takeLatest(LIST_SINGLE_UNIT, singleUnit);
  yield takeLatest(UPDATE_UNIT, updateUnit);
  yield takeLatest(DELETE_UNIT, deleteUnit);
  yield takeLatest(TAX, addTax);
  yield takeLatest(LIST_TAX, listTax);
  yield takeLatest(LIST_SINGLE_TAX, singleTax);
  yield takeLatest(UPDATE_TAX, updateTax);
  yield takeLatest(DELETE_TAX, deleteTax);
  yield takeLatest(PAYMENT_MODE, addPaymentMode);
  yield takeLatest(LIST_PAYMENT_MODE, listPaymentMode);
  yield takeLatest(LIST_SINGLE_PAYMENT_MODE, singlePaymentMode);
  yield takeLatest(UPDATE_PAYMENT_MODE, updatePaymentMode);
  yield takeLatest(DELETE_PAYMENT_MODE, deletePaymentMode);
  yield takeLatest(CURRENCIES, addCurrency);
  yield takeLatest(LIST_CURRENCIES, listCurrency);
  yield takeLatest(LIST_SINGLE_CURRENCIES, singleCurrency);
  yield takeLatest(UPDATE_CURRENCIES, updateCurrency);
  yield takeLatest(DELETE_CURRENCIES, deleteCurrency);
  yield takeLatest(CUSTOM_FIELD, addCustomField);
  yield takeLatest(LIST_CUSTOM_FIELD, listCustomField);
  yield takeLatest(LIST_SINGLE_CUSTOM_FIELD, singleCustomField);
  yield takeLatest(UPDATE_CUSTOM_FIELD, updateCustomField);
  yield takeLatest(DELETE_CUSTOM_FIELD, deleteCustomField);
}
export default setting;
