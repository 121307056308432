export const BUYER_LIST = "BUYER_LIST";
export const BUYER_LIST_SUCCESS = "BUYER_LIST_SUCCESS";
export const BUYER_LIST_FAIL = "BUYER_LIST_FAIL";

export const BUYER_ADD = "BUYER_ADD";
export const BUYER_ADD_SUCCESS = "BUYER_ADD_SUCCESS";
export const BUYER_ADD_FAIL = "BUYER_ADD_FAIL";

export const BUYER_DELETE = "BUYER_DELETE";
export const BUYER_DELETE_SUCCESS = "BUYER_DELETE_SUCCESS";
export const BUYER_DELETE_FAIL = "BUYER_DELETE_FAIL";

export const BUYER_SINGLE = "BUYER_SINGLE";
export const BUYER_SINGLE_SUCCESS = "BUYER_SINGLE_SUCCESS";
export const BUYER_SINGLE_FAIL = "BUYER_SINGLE_FAIL";

export const BUYER_UPDATE = "BUYER_UPDATE";
export const BUYER_UPDATE_SUCCESS = "BUYER_UPDATE_SUCCESS";
export const BUYER_UPDATE_FAIL = "BUYER_UPDATE_FAIL";

export const BUYER_SINGLE_UPDATE = "BUYER_SINGLE_UPDATE";
export const BUYER_SINGLE_UPDATE_SUCCESS = "BUYER_SINGLE_UPDATE_SUCCESS";
export const BUYER_SINGLE_UPDATE_FAIL = "BUYER_SINGLE_UPDATE_FAIL";

// ! wareHouse all actions here

export const BUYER_WITH_WAREHOUSE = "BUYER_WITH_WAREHOUSE";
export const BUYER_WITH_WAREHOUSE_SUCCESS = "BUYER_WITH_WAREHOUSE_SUCCESS";
export const BUYER_WITH_WAREHOUSE_FAIL = "BUYER_WITH_WAREHOUSE_FAIL";
