import { deleteRequest, get, patch, post } from "../utils/api";

//Expense Api

export const addExpense = (data) => {
  return post(`/expense/`, data);
};

export const listExpense = (data) => {
  return get(`/expense/`, data);
};

export const singleExpense = (id) => {
  return get(`/expense/${id}`);
};

export const updateExpense = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/expense/${id}`, data);
};

export const deleteExpense = (id) => {
  return deleteRequest(`/expense/${id}`);
};

export const expenseApi = {
  addExpense,
  listExpense,
  singleExpense,
  updateExpense,
  deleteExpense,
};
