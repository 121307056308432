/* eslint-disable no-unused-vars */
import * as React from "react";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./commonFormDrawer.css";

const CommonFormDrawer = (props) => {
  const {
    state,
    toggleDrawer,
    heading,
    childrenComponent,
    width,
    isAddSubscription,
    setId,
  } = props;

  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={(e) => {
            props?.setId && setId("");
            props?.toggleDrawer && toggleDrawer("right", false)(e);
          }}
          sx={{ zIndex: isAddSubscription && 9999 }}
        >
          <Box sx={{ width: { width } }}>
            <Box className="common-form-drawer-box">
              <Box className="common-form-drawer-padd">
                <CloseIcon
                  onClick={(e) => {
                    props?.setId && setId("");
                    toggleDrawer("right", false)(e);
                  }}
                  className="common-form-drawer-close"
                />
              </Box>
              <Box className="common-form-drawer-marginLeft">{heading}</Box>
            </Box>
            {childrenComponent}
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
};
CommonFormDrawer.propTypes = {
  state: PropTypes.any,
  toggleDrawer: PropTypes.any,
  childrenComponent: PropTypes.any,
  heading: PropTypes.string,
  width: PropTypes.string,
  isAddSubscription: PropTypes.bool,
  setId: PropTypes.any,
};
export default CommonFormDrawer;
