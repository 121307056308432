// import { toast } from "react-hot-toast";
import toast from "react-hot-toast";
import {
  CONFIRM_ORDER,
  CONFIRM_ORDER_FAIL,
  CONFIRM_ORDER_SUCCESS,
  ORDER_ADD,
  ORDER_ADD_FAIL,
  ORDER_ADD_SUCCESS,
  ORDER_LIST,
  ORDER_LIST_FAIL,
  ORDER_LIST_SUCCESS,
  ORDER_PAYMENT,
  ORDER_PAYMENT_FAIL,
  ORDER_PAYMENT_SUCCESS,
  ORDER_SINGLE,
  ORDER_SINGLE_FAIL,
  ORDER_SINGLE_SUCCESS,
  ORDER_SINGLE_UPDATE,
  ORDER_SINGLE_UPDATE_FAIL,
  ORDER_SINGLE_UPDATE_SUCCESS,
  ORDER_STATUS,
  ORDER_STATUS_FAIL,
  ORDER_STATUS_SUCCESS,
  ORDER_UPDATE,
  ORDER_UPDATE_FAIL,
  ORDER_UPDATE_SUCCESS,
} from "../actions/orderActions";

const initialState = {
  orderList: {},
  singleOrder: {},
  isLodding: false,
  error: "",
  isSuccess: false,
  orderSingleData: {},
  updatedData: {},
  confirmOrderData: {},
  orderPayment: {},
  orderStatus: {},
};
const orderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ORDER_ADD: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case ORDER_ADD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        roleAdd: payload.data,
        isLoading: false,
        isSuccess: true,
      };
    }

    case ORDER_ADD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case ORDER_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ORDER_LIST_SUCCESS: {
      return {
        ...state,
        orderList: payload?.data,
        isLoading: false,
      };
    }

    case ORDER_LIST_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ORDER_SINGLE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case ORDER_SINGLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        singleOrder: payload,
        isSuccess: true,
      };
    }

    case ORDER_SINGLE_FAIL: {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
      };
    }
    case ORDER_SINGLE_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case ORDER_SINGLE_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        orderSingleData: payload,
        isLoading: false,
        isSuccess: true,
      };
    }

    case ORDER_SINGLE_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
      };
    }

    case ORDER_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case ORDER_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        updatedData: payload,
        isLoading: false,
        isSuccess: true,
      };
    }

    case ORDER_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
      };
    }
    case CONFIRM_ORDER: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case CONFIRM_ORDER_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        confirmOrderData: payload,
        isLoading: false,
        isSuccess: true,
      };
    }

    case CONFIRM_ORDER_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
      };
    }

    case ORDER_PAYMENT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ORDER_PAYMENT_SUCCESS: {
      return {
        ...state,
        orderPayment: payload?.data,
        isLoading: false,
      };
    }

    case ORDER_PAYMENT_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ORDER_STATUS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ORDER_STATUS_SUCCESS: {
      return {
        ...state,
        orderStatus: payload?.data,
        isLoading: false,
      };
    }

    case ORDER_STATUS_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return { ...state };
    }
  }
};
export default orderReducer;
