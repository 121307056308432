import {
  DELETE_ROLE,
  DELETE_ROLE_FAIL,
  DELETE_ROLE_SUCCESS,
  LIST_ROLE,
  LIST_ROLE_FAIL,
  LIST_ROLE_SUCCESS,
  LIST_SINGLE_ROLE,
  LIST_SINGLE_ROLE_FAIL,
  LIST_SINGLE_ROLE_SUCCESS,
  ROLE_ADD,
  ROLE_ADD_FAIL,
  ROLE_ADD_SUCCESS,
  UPDATE_ROLE,
  UPDATE_ROLE_FAIL,
  UPDATE_ROLE_SUCCESS,
} from "../actions/roleActions";
import { API } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";

function* addRole(action) {
  try {
    const response = yield call(API.addUserRole, action.payload);
    yield put({ type: ROLE_ADD_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ROLE_ADD_FAIL, payload: { error: error } });
  }
}

function* listRole(action) {
  try {
    const response = yield call(API.listRole, action.payload);
    yield put({ type: LIST_ROLE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_ROLE_FAIL, payload: { error: error } });
  }
}
function* singleRole(action) {
  try {
    const response = yield call(API.singleRole, action.payload);
    yield put({ type: LIST_SINGLE_ROLE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_SINGLE_ROLE_FAIL, payload: { error: error } });
  }
}
function* updateRole(action) {
  try {
    const response = yield call(API.updateRole, action.payload);
    yield put({ type: UPDATE_ROLE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_ROLE_FAIL, payload: { error: error } });
  }
}
function* deleteRole(action) {
  try {
    const response = yield call(API.deleteRole, action.payload);
    yield put({ type: DELETE_ROLE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DELETE_ROLE_FAIL, payload: { error: error } });
  }
}

function* role() {
  yield takeLatest(ROLE_ADD, addRole);
  yield takeLatest(LIST_ROLE, listRole);
  yield takeLatest(LIST_SINGLE_ROLE, singleRole);
  yield takeLatest(UPDATE_ROLE, updateRole);
  yield takeLatest(DELETE_ROLE, deleteRole);
}
export default role;
