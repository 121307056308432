import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Breadcrumbs, useMediaQuery, useTheme } from "@mui/material";
import "./../../@extended/breadcrumbs/breadcrumbs.css";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";

const BreadCrumb = (props) => {
  const {
    title,
    perentTabName,
    childTabName,
    openMenuClick,
    isBackIcon,
    onBackClick,
  } = props;
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const isLaptop = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Box className="breadcrumb-box">
      <AppBar position="static" className="perent-navbar-wrapper">
        <Box className="main">
          <Box>
            <Toolbar className="main_toolbar">
              <Typography
                className={
                  isMobile
                    ? isBackIcon
                      ? "main-profile-with-back"
                      : "main-profile"
                    : "mobile-main-profile"
                }
                component="div"
              >
                {isBackIcon && (
                  <KeyboardBackspaceOutlinedIcon onClick={onBackClick} />
                )}
                {title}
              </Typography>
            </Toolbar>
            <Breadcrumbs
              separator="-"
              aria-label="breadcrumb"
              className={
                isMobile ? "profile-breadcrumbs" : "mobile-profile-breadcrumbs"
              }
            >
              <Typography
                variant="h6"
                component="div"
                className={
                  isMobile ? "main-typography1" : "mobile-main-typography1"
                }
                onClick={() => navigate("/admin/dashboard")}
              >
                Dashboard
              </Typography>
              <Typography
                variant="h6"
                component="div"
                className={
                  isMobile ? "main-typography2" : "mobile-main-typography2"
                }
              >
                {perentTabName}
              </Typography>
              {childTabName && (
                <Typography
                  variant="h6"
                  component="div"
                  className={
                    isMobile ? "main-typography" : "mobile-main-typography"
                  }
                >
                  {childTabName}
                </Typography>
              )}
            </Breadcrumbs>
          </Box>
          {!isLaptop && (
            <Box
              style={{
                display: "flex",
                alignItem: "center",
                justifyContent: "center",
              }}
            >
              <MenuIcon color="primary" onClick={openMenuClick} />
            </Box>
          )}
          {/* <Box
            style={{
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <MenuIcon color="primary" />
          </Box> */}
        </Box>
      </AppBar>
    </Box>
  );
};
export default BreadCrumb;

BreadCrumb.propTypes = {
  title: PropTypes.string,
  perentTabName: PropTypes.string,
  childTabName: PropTypes.string,
  openMenuClick: PropTypes.any,
  isBackIcon: PropTypes.bool,
  onBackClick: PropTypes.any,
};
