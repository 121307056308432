import React, { useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import "./emailQuery.css";
import DescriptionIcon from "@mui/icons-material/Description";
import TaskIcon from "@mui/icons-material/Task";

import { useDispatch, useSelector } from "react-redux";
import CommonTab from "../../@extended/tabs/CommonTab";
import TablePage from "./Table";
import { LIST_EMAIL_QUERY } from "../../../actions/emailQueryActions";

const EmailQuery = () => {
  const dispatch = useDispatch();
  const listDataEmailQuery = useSelector(
    (state) => state?.emailQuery?.listEmailQuery?.results,
  );
  const listEmailQuery = useCallback((data) => {
    dispatch({ type: LIST_EMAIL_QUERY, payload: data });
  }, []);

  function formatDateTimeToDDMMYYYY(isoDate) {
    const date = new Date(isoDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = "am";

    // Convert to 12-hour format
    if (hours >= 12) {
      ampm = "pm";
      if (hours > 12) {
        hours -= 12;
      }
    }

    const time = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;

    return `${day}-${month}-${year} ${time}`;
  }

  const rows = listDataEmailQuery?.map((item, ind) => {
    return {
      id: ind + 1,
      dateTime: item?.createdAt
        ? formatDateTimeToDDMMYYYY(item?.createdAt)
        : "-",
      name: item?.firstName,
      email: item?.workEmail,
      message: item?.companyName,
      replied: item?.isAgree ? "Not Replied" : "replied",
      actions: "",
      rowId: item?.id,
    };
  });

  const columns = [
    {
      field: "dateTime",
      headerName: "	Date Time",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "replied",
      headerName: "Replied",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "email-query-header",
      flex: 1,
      renderCell: () => {
        return (
          <Box className="email-query-table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              onClick={() => {
                // setOpen(true);
                // setId(params?.row?.rowId);
              }}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => {
                // setDeleteModal(true);
                // setId(params?.row?.rowId);
              }}
            />
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    listEmailQuery();
  }, []);

  const commonTabData = [
    {
      label: "Not Replied",
      value: "1",
      icon: <DescriptionIcon />,
      component: (
        <TablePage
          // handleChange={handleChange}
          columns={columns}
          rows={rows?.length > 0 ? rows : []}
          ischeck={true}
          isPagination={true}
        />
      ),
    },
    {
      label: " Replied",
      value: "2",
      icon: <TaskIcon />,
      component: (
        <TablePage
          // handleChange={handleChange}
          columns={columns}
          rows={rows?.length > 0 ? rows : []}
          ischeck={true}
          isPagination={true}
        />
      ),
    },
  ];
  return (
    <Box className="email-query-box">
      <CommonTab data={commonTabData} />
      {/* <Box className="email-query-box-main">
        <SelectWithSearch
          handleChange={handleChange}
          heading={heading}
          optionData={columns}
        />
      </Box> */}
      {/* <Box className="email-query-common-table-box-main">
        <Box className="email-query-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={true}
            isPagination={true}
          />
        </Box>
      </Box> */}
      {/* {open && (
        <CustomizedModal
          isHeadingName={true}
          heading="Send Mail"
          open={open}
          onClick={() => {
            setOpen(false);
            setId("");
          }}
        >
          <EmailQueryForm
            onClick={() => {
              setOpen(false);
              setId("");
            }}
            singleData={singleDataListEmailQuery}
            id={id}
          />
        </CustomizedModal>
      )} */}
      {/* {deleteModal && (
        <DeleteModal
          isIcon={true}
          Icon={ErrorOutlineOutlinedIcon}
          open={deleteModal}
          onClick={() => {
            setDeleteModal(false);
            setId("");
          }}
          id={id}
          deleteData={deleteExpenseCategory}
        />
      )} */}
    </Box>
  );
};

export default EmailQuery;
