import React, { useCallback, useState } from "react";
import { Box, TextField } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import AddIcon from "@mui/icons-material/Add";
import BreadCrumb from "../../@extended/breadcrumbs/Breadcrumbs";
import LocationTable from "./LocationTable";
import LocationForm from "./LocationForm";
import CustomizedModal from "../../@extended/customizedmodal/CustomizedModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_LOCATION } from "../../../actions/locationActions";
import DeleteModal from "../../@extended/deletemodal/DeleteModal";

const Location = () => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );

  const locationDelete = useCallback((data) => {
    dispatch({ type: DELETE_LOCATION, payload: data });
  }, []);

  const columns = [
    {
      field: "no",
      headerName: "No",
      headerClassName: "email-query-header",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "email-query-header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="email-query-table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              onClick={() => {
                setOpen(true);
                setId(params?.row?.rowId);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "location" && data?.edit) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => {
                setDeleteModal(true);
                setId(params?.row?.rowId);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "location" && data?.delete) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
          </Box>
        );
      },
    },
  ];
  return (
    <Box className="email-query-box">
      <BreadCrumb title="Location" perentTabName="Location" />
      <Box className="email-query-box-main">
        <Box>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={(e) => setSearch(e.target.value)}
            sx={{ width: "400px" }}
            inputProps={{ placeholder: "Search here...." }}
          />
        </Box>
        <ButtonComponent
          name="Add Location"
          isName={true}
          Icon={AddIcon}
          onClick={() => {
            setOpen(true);
          }}
          disabledbtn={userRoleAccess?.userPermissions?.find(
            (data) =>
              (data?.userModule == "location" && data?.add) ||
              userRoleAccess?.userRoleType == "admin",
          )}
        />
      </Box>
      <LocationTable columns={columns} search={search} />
      {open && (
        <CustomizedModal
          isHeadingName={true}
          heading="Location Form"
          open={open}
          onClick={() => {
            setOpen(false);
            setId("");
          }}
        >
          <LocationForm
            onClick={() => {
              setOpen(false);
              setId("");
            }}
            id={id}
          />
        </CustomizedModal>
      )}
      {deleteModal && (
        <DeleteModal
          isIcon={true}
          Icon={ErrorOutlineOutlinedIcon}
          open={deleteModal}
          onClick={() => {
            setDeleteModal(false);
            setId("");
          }}
          id={id}
          deleteData={locationDelete}
          userModule="location"
        />
      )}
    </Box>
  );
};
export default Location;
