import { API } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_COMPANY,
  ADD_COMPANY_FAIL,
  ADD_COMPANY_SUCCESS,
  LIST_COMPANY,
  LIST_COMPANY_FAIL,
  LIST_COMPANY_SUCCESS,
  SINGLE_COMPANY_LIST_FAIL,
  SINGLE_COMPANY_LIST,
  SINGLE_COMPANY_LIST_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  DELETE_COMPANY,
  LIST_PLAN_SUCCESS,
  LIST_PLAN_FAIL,
  LIST_PLAN,
  SUBSCRIPTION_LIST_PLAN,
  SUBSCRIPTION_LIST_PLAN_SUCCESS,
  SUBSCRIPTION_LIST_PLAN_FAIL,
  PAYMENT_MODE_LIST,
  PAYMENT_MODE_LIST_SUCCESS,
  PAYMENT_MODE_LIST_FAIL,
  ADD_LIST_PLAN,
  ADD_LIST_PLAN_SUCCESS,
  ADD_LIST_PLAN_FAIL,
} from "../actions/companyActions";

function* companyList(action) {
  try {
    const response = yield call(API.listCompany, action.payload);
    yield put({ type: LIST_COMPANY_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_COMPANY_FAIL, payload: { error: error } });
  }
}

function* addCompany(action) {
  try {
    const response = yield call(API.addCompany, action.payload);
    yield put({ type: ADD_COMPANY_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ADD_COMPANY_FAIL, payload: { error: error } });
  }
}

function* singleCompanyList(action) {
  try {
    const response = yield call(API.singleCompanyList, action.payload);
    yield put({ type: SINGLE_COMPANY_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: SINGLE_COMPANY_LIST_FAIL, payload: { error: error } });
  }
}

function* updateCompany(action) {
  try {
    const response = yield call(API.updateCompany, action.payload);
    yield put({ type: UPDATE_COMPANY_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_COMPANY_FAIL, payload: { error: error } });
  }
}

function* deleteCompanyList(action) {
  try {
    const response = yield call(API.deleteCompanyList, action.payload);
    yield put({ type: DELETE_COMPANY_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DELETE_COMPANY_FAIL, payload: { error: error } });
  }
}

function* planList(action) {
  try {
    const response = yield call(API.planList, action.payload);
    yield put({ type: LIST_PLAN_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_PLAN_FAIL, payload: { error: error } });
  }
}

function* subscriptionPlanList(action) {
  try {
    const response = yield call(API.subscriptionPlanList, action.payload);
    yield put({ type: SUBSCRIPTION_LIST_PLAN_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: SUBSCRIPTION_LIST_PLAN_FAIL, payload: { error: error } });
  }
}

function* paymentModeList(action) {
  try {
    const response = yield call(API.paymentModeList, action.payload);
    yield put({ type: PAYMENT_MODE_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: PAYMENT_MODE_LIST_FAIL, payload: { error: error } });
  }
}

function* addPlanList(action) {
  try {
    const response = yield call(API.addPlanList, action.payload);
    yield put({ type: ADD_LIST_PLAN_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ADD_LIST_PLAN_FAIL, payload: { error: error } });
  }
}

function* company() {
  yield takeLatest(LIST_COMPANY, companyList);
  yield takeLatest(ADD_COMPANY, addCompany);
  yield takeLatest(SINGLE_COMPANY_LIST, singleCompanyList);
  yield takeLatest(UPDATE_COMPANY, updateCompany);
  yield takeLatest(DELETE_COMPANY, deleteCompanyList);
  yield takeLatest(LIST_PLAN, planList);
  yield takeLatest(SUBSCRIPTION_LIST_PLAN, subscriptionPlanList);
  yield takeLatest(PAYMENT_MODE_LIST, paymentModeList);
  yield takeLatest(ADD_LIST_PLAN, addPlanList);
}
export default company;
