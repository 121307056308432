import PropTypes from "prop-types";
import "../seller/SellerForm.css";
import {
  Autocomplete,
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  TRANSPORT_CHARGE_ADD,
  TRANSPORT_CHARGE_LIST,
  TRANSPORT_CHARGE_SINGLE,
  TRANSPORT_CHARGE_UPDATE,
} from "../../../actions/productActions";
import { FULL_LIST_LOCATION } from "../../../actions/locationActions";

const TransportChargeForm = (props) => {
  const { onClick, id } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const [recentValues, setRecentValues] = useState("");
  console.log(recentValues);
  const locationFullList = useCallback((values) => {
    dispatch({ type: FULL_LIST_LOCATION, payload: values });
  }, []);

  const fullListLocationData = useSelector(
    (data) => data?.location?.locationFullList,
  );

  useEffect(() => {
    locationFullList();
  }, []);
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );
  const TransportChargeList = useCallback((values) => {
    dispatch({ type: TRANSPORT_CHARGE_LIST, payload: values });
  }, []);

  const singleListTransportCharge = useCallback((data) => {
    dispatch({ type: TRANSPORT_CHARGE_SINGLE, payload: data });
  }, []);
  const singleData = useSelector(
    (data) => data?.product?.transportChargeSingle,
  );

  const TransportChargeUpdate = useCallback((values) => {
    dispatch({ type: TRANSPORT_CHARGE_UPDATE, payload: values });
  }, []);
  useEffect(() => {
    if (id) {
      singleListTransportCharge(id);
    }
  }, [id]);
  useEffect(() => {
    TransportChargeList({ type: "active", search: "" });
  }, []);

  return (
    <Box
      className={
        isMobile ? "expense-category-main-div" : "expense-category-form-box"
      }
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          fromLocation: id ? singleData?.fromLocation : "",
          toLocation: id ? singleData?.toLocation : "",
          rate: id ? `${singleData?.rate}` : "",
        }}
        validationSchema={Yup.object().shape({
          fromLocation: Yup.string().required("From location is required"),
          toLocation: Yup.string().required("To location is required"),
          rate: Yup.number().required("Rate is required"),
        })}
        onSubmit={(values) => {
          if (id) {
            TransportChargeUpdate({ ...values, id: id, callback: onClick });
          } else {
            dispatch({
              type: TRANSPORT_CHARGE_ADD,
              payload: { ...values, callback: onClick },
            });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="fromLocation">
                      From Location*
                    </InputLabel>
                    <Autocomplete
                      defaultValue={
                        id
                          ? singleData?.fromLocation && singleData?.fromLocation
                          : ""
                      }
                      disablePortal
                      size="small"
                      id="location"
                      options={
                        fullListLocationData?.length > 0
                          ? fullListLocationData?.map((item) => ({
                              label: item?.name,
                              value: item?.id,
                            }))
                          : []
                      }
                      name="fromLocation"
                      onChange={(event, value) => {
                        setFieldValue("fromLocation", value?.label);
                        setRecentValues(value?.label);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option?.label || ""}
                      fullWidth
                      error={Boolean(
                        touched.fromLocation && errors.fromLocation,
                      )}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Please Enter location"
                        />
                      )}
                    />
                    {touched.fromLocation && errors.fromLocation && (
                      <FormHelperText error id="helper-text-name">
                        {errors.fromLocation}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="toLocation">To Location*</InputLabel>
                    <Autocomplete
                      defaultValue={
                        id
                          ? singleData?.toLocation && singleData?.toLocation
                          : ""
                      }
                      disablePortal
                      size="small"
                      id="location"
                      options={
                        fullListLocationData?.length > 0
                          ? fullListLocationData?.map((item) => ({
                              label: item?.name,
                              value: item?.id,
                            }))
                          : []
                      }
                      name="toLocation"
                      onChange={(event, value) => {
                        setFieldValue("toLocation", value?.label);
                        setRecentValues(value?.label);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option?.label || ""}
                      fullWidth
                      error={Boolean(touched.toLocation && errors.toLocation)}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Please Enter location"
                        />
                      )}
                    />
                    {touched.toLocation && errors.toLocation && (
                      <FormHelperText error id="helper-text-name">
                        {errors.toLocation}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="rate">Rate*</InputLabel>
                    <OutlinedInput
                      size="large"
                      fullWidth
                      value={values?.rate}
                      name="rate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Name"
                      error={Boolean(touched.rate && errors.rate)}
                    />
                    {touched.rate && errors.rate && (
                      <FormHelperText error id="helper-text-name">
                        {errors.rate}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box className="expense-category-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={id ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "transport-charge" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                onClick={onClick}
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "transport-charge" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

TransportChargeForm.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
};

export default TransportChargeForm;
