import PropTypes from "prop-types";
import "../seller/SellerForm.css";
import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import {
  PRODUCT_SETTING_LIST,
  PRODUCT_SETTING_UPDATE,
} from "../../../actions/productActions";

const ProductSettingForm = (props) => {
  const { onClick, rowData } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );

  const productSettingUpdate = useCallback((values) => {
    dispatch({ type: PRODUCT_SETTING_UPDATE, payload: values });
  }, []);

  const productSettingList = useCallback((values) => {
    dispatch({ type: PRODUCT_SETTING_LIST, payload: values });
  }, []);

  return (
    <Box
      className={
        isMobile ? "expense-category-main-div" : "expense-category-form-box"
      }
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          filedName: rowData ? rowData?.filedName : "",
          value: rowData ? rowData?.value : "",
        }}
        validationSchema={Yup.object().shape({
          filedName: Yup.string().required("Name is required"),
          value: Yup.string().required("value is required"),
        })}
        onSubmit={(values) => {
          if (rowData) {
            productSettingUpdate({
              value: values.value,
              id: rowData.rowId,
            });
          }
          onClick();
          setTimeout(() => {
            productSettingList();
          }, 500);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">Product Name*</InputLabel>
                    <OutlinedInput
                      id="categoryName"
                      size="large"
                      fullWidth
                      value={values?.filedName}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Name"
                      readOnly
                    />
                    {touched.filedName && errors.filedName && (
                      <FormHelperText error id="helper-text-name">
                        {errors.filedName}
                      </FormHelperText>
                    )}
                  </Stack>
                  <Stack spacing={2}>
                    <InputLabel htmlFor="name">Value</InputLabel>
                    <OutlinedInput
                      id="categoryName"
                      size="large"
                      fullWidth
                      value={values?.value}
                      name="value"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Name"
                    />
                    {touched.value && errors.value && (
                      <FormHelperText error id="helper-text-name">
                        {errors.value}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box className="expense-category-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={rowData ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "product" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                onClick={onClick}
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "product" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

ProductSettingForm.propTypes = {
  onClick: PropTypes.any,
  rowData: PropTypes.any,
  singleData: PropTypes.any,
};

export default ProductSettingForm;
