import PropTypes from "prop-types";
import "./../../settings/company/addLanguageForm.css";

import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../../@extended/button/ButtonComponent";
import UploadFile from "../../../@extended/uploadfile/UploadFile";
import { useState } from "react";

const AddLanguageForm = ({ onClick }) => {
  const [show, setShow] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box className={isMobile ? "language-main-div" : "language-form-box"}>
      <Formik
        initialValues={{
          name: "",
          key: "",
          flag: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required(" Unit Name is required"),
          key: Yup.string().max(255).required(" Short Name is required"),
          flag: Yup.string(),
        })}
        onSubmit={(values) => {
          console.log("values==>", values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container className="language-form-container">
                <Grid item xs={12} className="language-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">Name*</InputLabel>
                    <OutlinedInput
                      id="name"
                      fullWidth
                      value={values.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Unit Name"
                      error={Boolean(touched.name && errors.name)}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error id="helper-text-name">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="language-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="key">Key *</InputLabel>
                    <OutlinedInput
                      id="key"
                      fullWidth
                      value={values.key}
                      name="key"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Short Name"
                      error={Boolean(touched.key && errors.key)}
                    />
                    {touched.key && errors.key && (
                      <FormHelperText error id="helper-text-key">
                        {errors.key}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="language-form-input">
                  <UploadFile
                    name="flag"
                    value={values?.flag}
                    className="custom-file-upload"
                    label="Flag"
                    inputClassName="input-file-hide"
                    show={show}
                  />
                  {touched.flag && errors.flag && (
                    <FormHelperText error id="helper-text-image">
                      {errors.flag}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box className="language-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name="Create"
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
                onClick={() => setShow(true)}
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                onClick={onClick}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
AddLanguageForm.propTypes = {
  onClick: PropTypes.any,
};

export default AddLanguageForm;
