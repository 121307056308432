import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../../@extended/breadcrumbs/Breadcrumbs";
import "./../../dashboard/pos/pos.css";
import MainCard from "../commoncard/MainCard";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CommonTable from "../../@extended/table/CommonTable";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import category1 from "./../../../assets/images/category_1.png";
import category2 from "./../../../assets/images/category_2.png";
import category3 from "./../../../assets/images/category_18.png";
import CommonFormDrawer from "../../@extended/drawer/CommonFormDrawer";
import AddNewCustomerForm from "./addcustomer/AddNewCustomerForm";
import PaymentDrawer from "./paymentDrawer/PaymentDrawer";
import { useNavigate } from "react-router-dom";
import PrintInvoiceForm from "./printInvoice/PrintInvoiceForm";
import CustomizedModal from "../../../../src/components/@extended/customizedmodal/CustomizedModal";

const Pos = () => {
  const [optionOpen, setOptionOpen] = useState(false);
  const [user, setUser] = useState([]);
  const [mainUser, setMainUser] = useState([]);

  useEffect(() => {
    const data = [
      {
        user: "keyur 1",
        number: "+91 9879879877",
      },
      {
        user: "keioiooioi 2",
        number: "+91 9879879877",
      },
      {
        user: "neel 3",
        number: "+91 9879879877",
      },
      {
        user: "nirali 4",
        number: "+91 9879879877",
      },
      {
        user: "user 5",
        number: "+91 9879879877",
      },
      {
        user: "user 6",
        number: "+91 9879879877",
      },
      {
        user: "user 7",
        number: "+91 9879879877",
      },
      {
        user: "user 8",
        number: "+91 9879879877",
      },
      {
        user: "user 9",
        number: "+91 9879879877",
      },
      {
        user: "user 10",
        number: "+91 9879879877",
      },
      {
        user: "user 11",
        number: "+91 9879879877",
      },
      {
        user: "user 12",
        number: "+91 9879879877",
      },
    ];
    setUser(data);
    setMainUser(data);
  }, []);

  const handleChange = (e) => {
    const event = e.target.value;

    const filterData = user?.filter((data) =>
      data?.user?.toLowerCase()?.startsWith(event?.toLowerCase()),
    );
    setMainUser(filterData);
  };

  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 30,
      // flex: 1,
      headerClassName: "pos-header",
      id: 1,
    },
    {
      field: "name",
      headerName: "Name",
      // width: 300,
      flex: 1,
      headerClassName: "pos-header",
      id: 2,
      renderCell: (params) => {
        return (
          <Box>
            <Typography fontSize={"13px"}>{params?.row?.name}</Typography>
            <Box className="row-name-field">
              Avl. qty: {params?.row?.stock}pc
            </Box>
          </Box>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      // width: 120,
      flex: 1,
      headerClassName: "pos-header",
      id: 3,
      renderCell: (params) => {
        return (
          <Box className="table-number-main">
            <TextField
              type="number"
              size="small"
              value={params.value}
              onChange={(e) =>
                handleQuantityChange(params.row.id, e.target.value)
              }
            />
          </Box>
        );
      },
    },

    {
      field: "subtotal",
      headerName: "SubTotal",
      // width: 100,
      flex: 1,
      headerClassName: "pos-header",
      id: 4,
      renderCell: (params) => {
        return <Typography fontSize={"15px"}>{params.value}</Typography>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      // width: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerClassName: "pos-header",
      id: 5,
      renderCell: (params) => {
        return (
          <Box className="table-btn-main">
            <ButtonComponent
              className="btn-update-2"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
            />
            <ButtonComponent
              className="btn-update-2"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => handleDeleteRow(params.row.id)}
            />
          </Box>
        );
      },
    },
  ];

  const [option, setOption] = React.useState("en");
  const [discountOption, setDiscountOption] = useState("");
  const [openInvoice, setOpenInvoice] = React.useState(false);

  const handleSelectChange = (event) => {
    setOption(event.target.value);
  };
  const handleDiscountChange = (event) => {
    setDiscountOption(event.target.value);
  };
  const productFilterData = [
    {
      src: category1,
      name: "Product 1",
    },
    {
      src: category2,
      name: "Product 2",
    },
    {
      src: category3,
      name: "Product 3321354654",
    },
    {
      src: category1,
      name: "Product 1",
    },
    {
      src: category2,
      name: "Product 2",
    },
    {
      src: category3,
      name: "Product 3",
    },
    {
      src: category1,
      name: "Product 1",
    },
    {
      src: category2,
      name: "Product 2",
    },
    {
      src: category3,
      name: "Product 3",
    },
    {
      src: category1,
      name: "Product 1",
    },
    {
      src: category2,
      name: "Product 2",
    },
    {
      src: category3,
      name: "Product 3",
    },
    {
      src: category1,
      name: "Product 1",
    },
    {
      src: category2,
      name: "Product 2",
    },
    {
      src: category3,
      name: "Product 3",
    },
    {
      src: category1,
      name: "Product 1",
    },
    {
      src: category2,
      name: "Product 2",
    },
    {
      src: category3,
      name: "Product 3",
    },
  ];
  const productCardData = [
    {
      src: category1,
      name: "Product 1",
      price: "$ 41.99",
      stock: "5",
      id: 1,
      qtn: 1,
    },
    {
      src: category2,
      name: "Product 2",
      price: "$ 50.99",
      stock: "81",
      id: 2,
      qtn: 1,
    },
    {
      src: category3,
      name: "Product 3321354654",
      price: "$ 81.99",
      stock: "3",
      id: 3,
      qtn: 1,
    },
    {
      src: category1,
      name: "Product 1",
      price: "$ 41.99",
      stock: "5",
      id: 4,
      qtn: 1,
    },
    {
      src: category2,
      name: "Product 2",
      price: "$ 50.99",
      stock: "81",
      id: 5,
      qtn: 1,
    },
    {
      src: category3,
      name: "Product 3321354654",
      price: "$ 81.99",
      stock: "3",
      id: 6,
      qtn: 1,
    },
    {
      src: category1,
      name: "Product 1",
      price: "$ 41.99",
      stock: "5",
      id: 7,
      qtn: 1,
    },
    {
      src: category2,
      name: "Product 2",
      price: "$ 50.99",
      stock: "81",
      id: 8,
      qtn: 1,
    },
    {
      src: category3,
      name: "Product 3321354654",
      price: "$ 81.99",
      stock: "3",
      id: 9,
      qtn: 1,
    },
    {
      src: category1,
      name: "Product 1",
      price: "$ 41.99",
      stock: "5",
      id: 10,
      qtn: 1,
    },
    {
      src: category2,
      name: "Product 2",
      price: "$ 50.99",
      stock: "81",
      id: 11,
      qtn: 1,
    },
    {
      src: category3,
      name: "Product 3321354654",
      price: "$ 81.99",
      stock: "3",
      id: 12,
      qtn: 1,
    },
    {
      src: category1,
      name: "Product 1",
      price: "$ 41.99",
      stock: "5",
      id: 13,
      qtn: 1,
    },
    {
      src: category2,
      name: "Product 2",
      price: "$ 50.99",
      stock: "81",
      id: 14,
      qtn: 1,
    },
    {
      src: category3,
      name: "Product 3321354654",
      price: "$ 81.99",
      stock: "3",
      id: 15,
      qtn: 1,
    },
    {
      src: category1,
      name: "Product 1",
      price: "$ 41.99",
      stock: "5",
      id: 16,
      qtn: 1,
    },
    {
      src: category2,
      name: "Product 2",
      price: "$ 50.99",
      stock: "81",
      id: 17,
      qtn: 1,
    },
    {
      src: category3,
      name: "Product 3321354654",
      price: "$ 81.99",
      stock: "3",
      id: 18,
      qtn: 1,
    },
  ];
  const [tableData, setTableData] = useState([]); // State to store table data
  const [selectedProduct, setSelectedProduct] = useState(null); // State to store selected product

  // Function to handle when a product card is clicked
  const handleProductCardClick = (product) => {
    const existingProduct = tableData.find((item) => item.id === product.id);
    if (existingProduct) {
      // If the product already exists in the table, increase its quantity
      existingProduct.quantity += 1;
      existingProduct.subtotal = `$${(
        existingProduct.quantity * parseFloat(product.price.replace("$", ""))
      ).toFixed(2)}`;
      setTableData([...tableData]);
    } else {
      // If the product is not in the table, add it as a new row
      const newRow = {
        id: product.id,
        name: product.name,
        stock: product.stock,
        quantity: 1,
        subtotal:
          "$" +
          (product.qtn * parseFloat(product.price.replace("$", ""))).toFixed(2),
        action: "",
      };
      setTableData([...tableData, newRow]);
    }
    setSelectedProduct(product); // Store the selected product
  };
  const handleQuantityChange = (rowId, newQuantity) => {
    const updatedTableData = tableData?.map((row) => {
      if (row.id === rowId) {
        // Update the quantity for the specific row
        row.quantity = parseInt(newQuantity, 10) || 0; // Convert input to an integer or default to 0 if NaN

        // Calculate the subtotal based on the updated quantity
        if (selectedProduct) {
          row.subtotal =
            "$" +
            (
              row.quantity * parseFloat(selectedProduct.price.replace("$", ""))
            ).toFixed(2);
        }
      }
      return row;
    });

    setTableData(updatedTableData);
  };

  // Function to delete a row
  const handleDeleteRow = (rowId) => {
    const updatedTableData = tableData?.filter((row) => row.id !== rowId);
    setTableData(updatedTableData);
  };
  const calculateGrandTotal = () => {
    let grandTotal = 0;
    for (const row of tableData) {
      grandTotal += parseFloat(row.subtotal.replace("$", ""));
    }
    return grandTotal.toFixed(2); // Return the grand total with 2 decimal places
  };
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (right, open) => (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [right]: open });
  };

  const [payNow, setPayNow] = React.useState({
    right: false,
  });

  const payToggleDrawer = (right, open) => (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }

    setPayNow({ ...payNow, [right]: open });
  };
  const navigate = useNavigate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      <BreadCrumb
        isBackIcon={true}
        title="POS"
        perentTabName="Stock Manager"
        childTabName="POS"
        onBackClick={() => navigate(-1)}
      />
      <Box>
        <Grid container spacing={2} className="main-pos-wrapper">
          <Grid item lg={5} xs={12} className="child-pos-wrapper-1">
            <MainCard>
              <Box className="user-search-main">
                <Box display={"flex"} gap={"5px"}>
                  <Box width={"100%"}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      hiddenLabel
                      size="small"
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              edge="end"
                              onClick={() => setOptionOpen(!optionOpen)}
                            >
                              {optionOpen ? (
                                <SearchIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {optionOpen && (
                      <Box className="dropdown-wrapper">
                        {mainUser?.map((data1, ind) => {
                          return (
                            <Box key={ind} className="user-search-item">
                              <Box className="user-search-item-text">
                                {data1?.user}
                              </Box>
                              <Box className="user-search-item-number">
                                {data1?.number}
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                    {/* )} */}
                  </Box>
                  <Box
                    className="add-user-icon"
                    onClick={toggleDrawer("right", true)}
                  >
                    <AddIcon />
                  </Box>
                </Box>
                <Box marginTop={"2%"}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    hiddenLabel
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="end">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </MainCard>
            <MainCard sx={{ mt: 1.5 }} contentSX={{ height: "493px" }}>
              <CommonTable columns={columns} rows={tableData} ischeck={false} />
            </MainCard>
            <MainCard sx={{ mt: 1.5 }}>
              <Box display={"flex"} width={"100%"} gap={"10px"}>
                <Box className="select-wrapper">
                  <label htmlFor="tax" className="label-div">
                    Order Tax
                  </label>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    name="tax"
                    value={option}
                    onChange={handleSelectChange}
                    style={{ borderBlockColor: "gray" }}
                  >
                    <MenuItem value={"en"}>English</MenuItem>
                  </Select>
                </Box>
                <Box className="select-wrapper">
                  <label htmlFor="discount" className="label-div">
                    Discount
                  </label>
                  <Box display={"flex"}>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="discount"
                      value={discountOption}
                      sx={{ borderRadius: "4px 0px 0px 4px" }}
                      onChange={handleDiscountChange}
                      style={{ borderBlockColor: "gray" }}
                    >
                      <MenuItem value={"%"}>%</MenuItem>
                      <MenuItem value={"$"}>$</MenuItem>
                    </Select>
                    <TextField
                      fullWidth
                      type="number"
                      hiddenLabel
                      sx={{ borderRadius: "0px 4px 4px 0px" }}
                    />
                  </Box>
                </Box>
                <Box className="select-wrapper">
                  <label htmlFor="discount" className="label-div">
                    Shipping
                  </label>
                  <TextField
                    id="outlined-adornment-amount"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="start">
                            <AttachMoneyIcon fontSize="20px" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </MainCard>
            <MainCard sx={{ mt: 1.5 }}>
              <Box className="total-container">
                <Box className="total-wrapper">
                  <Box>Grand Total :</Box>
                  <Box>${calculateGrandTotal()}</Box>
                </Box>
                <Typography>Tax : $0.00 | Discount : $0.00</Typography>
                <Box display={"flex"} gap={"10px"}>
                  <ButtonComponent
                    isName="true"
                    name="Pay Now"
                    className="pay-now-btn"
                    disabledbtn={tableData?.length === 0}
                    onClick={payToggleDrawer("right", true)}
                  />
                  <ButtonComponent
                    isName="true"
                    name={"Reset"}
                    variant={"outlined"}
                  />
                </Box>
              </Box>
            </MainCard>
          </Grid>
          <Grid item lg={7} xs={12} className="child-pos-wrapper-2">
            <MainCard>
              <Box className="filter-card">
                {/* <Box className="filter-card-child">
                  <ListAltIcon className="list-icon-div" />
                  <Typography>All</Typography>
                </Box> */}
                {productFilterData?.map((data, ind) => {
                  return (
                    <Box key={ind} className="filter-card-child">
                      <img
                        alt={data?.name}
                        src={data?.src}
                        style={{ width: 38, height: 38 }}
                      />
                      <Tooltip title={data?.name}>
                        <Typography className="product-name">
                          {data?.name}
                        </Typography>
                      </Tooltip>
                    </Box>
                  );
                })}
              </Box>
            </MainCard>
            <MainCard sx={{ mt: 1.5 }}>
              {/* <Box className="product-card-list"> */}
              <Grid container spacing={3} className="product-card-list">
                {productCardData?.map((data, index) => {
                  return (
                    <Grid item xl={3} lg={4} key={index}>
                      <Box
                        className={`product-card-list-child ${
                          selectedProduct && selectedProduct?.id === data?.id
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => handleProductCardClick(data)}
                      >
                        <Typography className="available-product">
                          {data?.stock} pc
                        </Typography>
                        <Box className="product-img-wrapper">
                          <img
                            src={data?.src}
                            alt={data?.name}
                            style={{ width: "auto", height: "auto" }}
                          />
                        </Box>
                        <Box className="card-footer">
                          <Tooltip title={data?.name}>
                            <Typography className="card-product-name">
                              {data?.name}
                            </Typography>
                          </Tooltip>
                          <Typography className="card-product-price">
                            {data?.price}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </MainCard>
          </Grid>
        </Grid>
        {openInvoice && (
          <CustomizedModal
            isHeadingName={true}
            heading="Print Invoice"
            open={openInvoice}
            onClick={() => setOpenInvoice(false)}
          >
            <PrintInvoiceForm
              onClick={() => {
                setOpenInvoice(false);
                // setId("");
              }}
              tableData={tableData}
              // id={id}
            />
          </CustomizedModal>
        )}
      </Box>

      {toggleDrawer && (
        <CommonFormDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          heading="Add New Customer"
          // width="1200px"
          width={isTablet ? "1200px" : isMobile ? "670px" : "400px"}
          childrenComponent={<AddNewCustomerForm toggleDrawer={toggleDrawer} />}
        />
      )}
      {payToggleDrawer && (
        <CommonFormDrawer
          state={payNow}
          toggleDrawer={payToggleDrawer}
          heading="Order Payment"
          // width="1000px"
          childrenComponent={
            <PaymentDrawer
              rowsData={tableData}
              onClick={() => {
                setOpenInvoice(true);
                setPayNow({ right: false });
              }}
            />
          }
          // width="1000px"
          width={isTablet ? "1000px" : isMobile ? "670px" : "400px"}
          // childrenComponent={<PaymentDrawer />}
        />
      )}
    </>
  );
};

export default Pos;
