import PropTypes from "prop-types";
import "../seller/SellerForm.css";

import {
  Autocomplete,
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import allStateList from "../../../state.json";
import AWS from "aws-sdk";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BUYER_ADD,
  BUYER_SINGLE,
  BUYER_UPDATE,
} from "../../../actions/buyerActions";
import { FULL_LIST_LOCATION } from "../../../actions/locationActions";

const BuyerForm = (props) => {
  const { onClick, id } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints.up("sm"));
  const [recentValues, setRecentValues] = useState("");
  const dispatch = useDispatch();

  const updateBuyer = useCallback((values) => {
    dispatch({ type: BUYER_UPDATE, payload: values });
  }, []);

  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );
  const singleData = useSelector((data) => data?.buyer?.buyerSingle);
  const [gstCertificate, setGSTCertificate] = useState(
    id ? singleData?.buyer?.gstCertificate : "",
  );

  const singleListBuyer = useCallback((data) => {
    dispatch({ type: BUYER_SINGLE, payload: data });
  }, []);

  useEffect(() => {
    if (id) {
      singleListBuyer(id);
    }
  }, [id]);

  const handleUpload = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    try {
      // Initialize S3 client
      const s3 = new AWS.S3({
        accessKeyId: "AKIA47CRXW4MNFSCJ3VM",
        secretAccessKey: "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",
        region: "ap-south-1",
      });

      const params = {
        Bucket: "crystal-polymer-dev",
        Key: `gstCertificate/${selectedFile.name}`,
        Body: selectedFile,
      };

      // Upload file to S3 bucket
      const response = await s3.upload(params).promise();
      setGSTCertificate(response.Location);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const locationFullList = useCallback((values) => {
    dispatch({ type: FULL_LIST_LOCATION, payload: values });
  }, []);

  const fullListLocationData = useSelector(
    (data) => data?.location?.locationFullList,
  );

  useEffect(() => {
    locationFullList();
  }, []);

  return (
    <Box
      className={
        isMobile ? "expense-category-main-div" : "expense-category-form-box"
      }
    >
      <Formik
        initialValues={{
          companyName: id ? singleData?.companyDetails?.companyName : "",
          firstName: id ? singleData?.buyer?.firstName : "",
          lastName: id ? singleData?.buyer?.lastName : "",
          email: id ? singleData?.buyer?.email : "",
          mobile: id ? singleData?.buyer?.mobile : "",
          state: id ? singleData?.buyer?.state : "",
          address: {
            addressLineOne: id
              ? singleData?.companyDetails?.address?.addressLineOne
              : "",
            addressLineTwo: id
              ? singleData?.companyDetails?.address?.addressLineTwo
              : "",
            city: id ? singleData?.companyDetails?.address?.city : "",
            pincode: id ? singleData?.companyDetails?.address?.pincode : "",
          },
          gstCertificate: id ? "" : "",
          gstNumber: id ? singleData?.companyDetails?.gstNumber : "",
        }}
        validationSchema={Yup.object().shape({
          companyName: Yup.string().required("Company Name is required"),
          firstName: Yup.string().max(255).required("FirstName is required"),
          lastName: Yup.string().required("LastName is required"),
          state: Yup.string().required("State is required"),
          email: Yup.string()
            .email("Invalid email")
            .required("Email is required"),
          mobile: Yup.string().required("phone number is required"),
          address: Yup.object().shape({
            addressLineOne: Yup.string().required("Addres line 1 is required"),
            city: Yup.string().required("City is required"),
            pincode: Yup.string().required("Pincode is required"),
          }),
          gstNumber: Yup.string().required("Gst Number is required"),
        })}
        onSubmit={(values) => {
          if (id) {
            updateBuyer({
              ...values,
              gstCertificate,
              id: id,
              callback: onClick,
            });
          } else {
            dispatch({
              type: BUYER_ADD,
              payload: {
                ...values,
                gstCertificate,
                isVerified: false,
                callback: onClick,
              },
            });
          }
        }}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          setFieldValue,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="companyName">Company Name*</InputLabel>
                    <OutlinedInput
                      autoFocus
                      id="companyName"
                      size="small"
                      fullWidth
                      value={values?.companyName}
                      name="companyName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Company Name Name"
                      error={Boolean(touched.companyName && errors.companyName)}
                    />
                    {touched.companyName && errors.companyName && (
                      <FormHelperText error id="helper-text-name">
                        {errors.firstName}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="firstName">First Name*</InputLabel>
                    <OutlinedInput
                      id="firstName"
                      size="small"
                      fullWidth
                      value={values?.firstName}
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Expense Category Name"
                      error={Boolean(touched.firstName && errors.firstName)}
                    />
                    {touched.firstName && errors.firstName && (
                      <FormHelperText error id="helper-text-name">
                        {errors.firstName}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="lastName">Last Name*</InputLabel>
                    <TextField
                      // multiline
                      // rows={8}
                      size="small"
                      id="lastName"
                      fullWidth
                      value={values?.lastName}
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Description"
                      error={Boolean(touched.lastName && errors.lastName)}
                    />
                    {touched.lastName && errors.lastName && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors.lastName}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="description"> Email*</InputLabel>
                    <TextField
                      // multiline
                      // rows={8}
                      size="small"
                      id="email"
                      fullWidth
                      value={values?.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter email"
                      error={Boolean(touched.email && errors.email)}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors.email}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="mobile"> Phone Number*</InputLabel>
                    <TextField
                      // multiline
                      // rows={8}
                      size="small"
                      id="mobile"
                      fullWidth
                      value={values?.mobile}
                      name="mobile"
                      type="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Phone Number"
                      error={Boolean(touched.mobile && errors.mobile)}
                    />
                    {touched.mobile && errors.mobile && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors.mobile}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="lastName">Location*</InputLabel>
                    <Autocomplete
                      defaultValue={
                        id ? singleData?.location && singleData?.location : ""
                      }
                      disablePortal
                      size="small"
                      id="location"
                      options={
                        fullListLocationData?.length > 0
                          ? fullListLocationData?.map((item) => ({
                              label: item?.name,
                              value: item?.id,
                            }))
                          : []
                      }
                      name="location"
                      onChange={(event, value) => {
                        setFieldValue("location", value?.label);
                        setRecentValues(value?.label);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option?.label || ""}
                      fullWidth
                      error={Boolean(touched.location && errors.location)}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Please Enter location"
                        />
                      )}
                    />
                    {touched.location && errors.location && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors.location}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="lastName">Address line1*</InputLabel>
                    <TextField
                      size="small"
                      id="address.addressLineOne"
                      fullWidth
                      value={values?.address?.addressLineOne}
                      name="address.addressLineOne"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter address line 1"
                      error={Boolean(
                        touched?.address?.addressLineOne &&
                          errors?.address?.addressLineOne,
                      )}
                    />
                    {touched?.address?.addressLineOne &&
                      errors?.address?.addressLineOne && (
                        <FormHelperText error id="helper-text-shortname">
                          {errors?.address?.addressLineOne}
                        </FormHelperText>
                      )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="addreshLine2">
                      Address line 2*
                    </InputLabel>
                    <TextField
                      size="small"
                      id="address.addressLineTwo"
                      fullWidth
                      value={values?.address?.addressLineTwo}
                      name="address.addressLineTwo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter address line 2"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="city"> City*</InputLabel>
                    <TextField
                      size="small"
                      id="address.city"
                      fullWidth
                      value={values?.address?.city}
                      name="address.city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter City"
                      error={Boolean(
                        touched?.address?.city && errors?.address?.city,
                      )}
                    />
                    {touched?.address?.city && errors?.address?.city && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors?.address?.city}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className="expense-category-form-input"
                >
                  <InputLabel htmlFor="currency" sx={{ marginBottom: "8px" }}>
                    State*
                  </InputLabel>
                  <Autocomplete
                    defaultValue={
                      id ? singleData?.state && singleData?.state : ""
                    }
                    disablePortal
                    size="small"
                    options={
                      allStateList?.stateList?.length > 0
                        ? allStateList?.stateList?.map((recent) => ({
                            label: recent?.state,
                            value: recent?.id,
                          }))
                        : []
                    }
                    // value={recentLabels}
                    name="state"
                    onChange={(event, value) => {
                      setRecentValues(value?.value);
                      setFieldValue("state", value?.label);
                    }}
                    error={touched.state && errors.state}
                    renderInput={(params) => {
                      const findVal = allStateList?.stateList?.find(
                        (data) => data?.id === recentValues,
                      );
                      return (
                        <TextField
                          {...params}
                          value={findVal?.state}
                          placeholder="State"
                        />
                      );
                    }}
                  />
                  {touched.state && errors.state && (
                    <FormHelperText error id="helper-text-name">
                      {errors.state}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="pincode"> Pincode* </InputLabel>
                    <TextField
                      size="small"
                      id="address.pincode"
                      value={values?.address?.pincode}
                      name="address.pincode"
                      onBlur={handleBlur}
                      type="number"
                      onChange={handleChange}
                      placeholder="Please Enter pincode"
                      fullWidth
                      error={Boolean(
                        touched?.address?.pincode && errors?.address?.pincode,
                      )}
                    />
                    {touched?.address?.pincode && errors?.address?.pincode && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors?.address?.pincode}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="gstCertificate">
                      Gst Certificate*
                    </InputLabel>
                    {gstCertificate && (
                      <a href={gstCertificate} target="_blank" rel="noreferrer">
                        Download GST Certificate
                      </a>
                    )}
                    <TextField
                      id="gstCertificate"
                      fullWidth
                      type="file"
                      accept="application/pdf"
                      name="gstCertificate"
                      onBlur={handleBlur}
                      onChange={(e) => handleUpload(e)}
                      error={Boolean(
                        touched.gstCertificate && errors.gstCertificate,
                      )}
                    />
                    {touched.gstCertificate && errors.gstCertificate && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors.gstCertificate}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="address">Gst Number*</InputLabel>
                    <TextField
                      multiline
                      size="small"
                      id="gstNumber"
                      fullWidth
                      value={values?.gstNumber}
                      name="gstNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Gst Number"
                      error={Boolean(touched.gstNumber && errors.gstNumber)}
                    />
                    {touched.gstNumber && errors.gstNumber && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors.gstNumber}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box className="expense-category-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={id ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "buyer" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                onClick={onClick}
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "buyer" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
BuyerForm.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
};

export default BuyerForm;
