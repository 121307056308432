import PropTypes from "prop-types";
import "./../../settings/currencies/currenciesForm.css";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../../@extended/button/ButtonComponent";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import {
  CURRENCIES,
  UPDATE_CURRENCIES,
} from "../../../../actions/settingActions";

const CurrenciesForm = (props) => {
  const { onClick, id, singleData } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const addCurrency = useCallback((data) => {
    dispatch({ type: CURRENCIES, payload: data });
  }, []);
  const updateCurrency = useCallback((data) => {
    dispatch({ type: UPDATE_CURRENCIES, payload: data });
  }, []);

  return (
    <Box className={isMobile ? "currencies-main-div" : "currencies-form-box"}>
      <Formik
        initialValues={{
          name: id ? singleData?.name : "",
          symbol: id ? singleData?.symbol : "",
          code: id ? singleData?.code : "",
          position: id ? singleData?.position : "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required(" Currency Name is required"),
          symbol: Yup.string().max(255).required("Symbol is required"),
          code: Yup.string().max(255).required("Code is required"),
          position: Yup.string(),
        })}
        onSubmit={(values) => {
          if (id) {
            updateCurrency({ ...values, id: id });
          } else {
            addCurrency({ ...values });
          }

          onClick();
        }}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container>
                <Grid item xs={12} className="currencies-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">Currency Name *</InputLabel>
                    <OutlinedInput
                      id="name"
                      fullWidth
                      value={values?.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Currency Name"
                      error={Boolean(touched.name && errors.name)}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error id="helper-text-name">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="currencies-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="symbol">Currency Symbol *</InputLabel>
                    <OutlinedInput
                      id="symbol"
                      fullWidth
                      value={values?.symbol}
                      name="symbol"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Currency Symbol"
                      error={Boolean(touched.symbol && errors.symbol)}
                    />
                    {touched.symbol && errors.symbol && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors.symbol}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="currencies-form-input">
                  <Stack spacing={1}>
                    <FormControl>
                      <FormLabel htmlFor="position">
                        Currency Position
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="position"
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="Front"
                          checked={values?.position === "Front"}
                          control={<Radio />}
                          label="Front (Example : $100)"
                        />
                        <FormControlLabel
                          value="Behind"
                          checked={values?.position === "Behind"}
                          control={<Radio />}
                          label="Behind (Example : 100$)"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} className="currencies-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="symbol">Currency Code *</InputLabel>
                    <OutlinedInput
                      id="code"
                      fullWidth
                      value={values?.code}
                      name="code"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Currency Code"
                      error={Boolean(touched.code && errors.code)}
                    />
                    {touched.code && errors.code && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors.code}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box className="currencies-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={id ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                onClick={onClick}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
CurrenciesForm.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
};

export default CurrenciesForm;
