import { Box } from "@mui/material";
import React from "react";
import Pos from "../../components/dashboard/pos/Pos";

const PosPage = () => {
  return (
    <Box>
      <Pos />
    </Box>
  );
};
export default PosPage;
