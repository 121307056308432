import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CommonTable from "../../@extended/table/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import { WAREHOUSE_LIST } from "../../../actions/sellerActions";

const BuyerWareHouseTable = (props) => {
  const { columns, search } = props;
  const dispatch = useDispatch();

  const wareHouseData = useSelector((data) => data?.seller?.wareHouseList);
  const { totalResults = 0, limit, page } = wareHouseData;
  const [limitState, setLimit] = useState(10);
  const [pageState, setPage] = useState(1);

  const buyerWareHouseList = useCallback((values) => {
    dispatch({ type: WAREHOUSE_LIST, payload: values });
  }, []);

  const rows = wareHouseData?.results?.map((item, ind) => {
    return {
      id: ind + 1,
      no: ind + 1,
      warehouseName: item?.name,
      city: item?.address?.city,
      buyerName: `${item?.buyer?.firstName || ""} ${
        item?.buyer?.lastName || ""
      }`,
      mobile: item?.buyer?.mobile,
      location: item?.location,
      isVerified: item?.isVerified,
      actions: "",
      rowId: item?.id,
    };
  });

  useEffect(() => {
    if (wareHouseData?.limit) {
      setLimit(wareHouseData?.limit);
    }
    if (wareHouseData?.page) {
      setPage(wareHouseData?.page);
    }
  }, [wareHouseData?.limit, wareHouseData?.page]);

  useEffect(() => {
    buyerWareHouseList({ limitState, pageState, search, userType: "buyer" });
  }, [limitState, pageState, search]);

  return (
    <Box className="email-query-box">
      <Box className="email-query-common-table-box-main">
        <Box className="email-query-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
            pageData={{ limit, page, totalResults, setLimit, setPage }}
          />
        </Box>
      </Box>
    </Box>
  );
};
BuyerWareHouseTable.propTypes = {
  columns: PropTypes.any,
  type: PropTypes.any,
  search: PropTypes.any,
  setDeleteModal: PropTypes.any,
};
export default BuyerWareHouseTable;
