import {
  DELETE_ROLE_USER,
  DELETE_ROLE_USER_FAIL,
  DELETE_ROLE_USER_SUCCESS,
  LIST_ROLE_USER,
  LIST_ROLE_USER_FAIL,
  LIST_ROLE_USER_SUCCESS,
  LIST_SINGLE_ROLE_USER,
  LIST_SINGLE_ROLE_USER_FAIL,
  LIST_SINGLE_ROLE_USER_SUCCESS,
  ROLE_USER_ADD,
  ROLE_USER_ADD_FAIL,
  ROLE_USER_ADD_SUCCESS,
  UPDATE_ROLE_USER,
  UPDATE_ROLE_USER_FAIL,
  UPDATE_ROLE_USER_SUCCESS,
} from "../actions/roleUserActions";
import { API } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";

function* addRoleUser(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.addRoleUser, otherPayload);
    yield put({ type: ROLE_USER_ADD_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: ROLE_USER_ADD_FAIL, payload: { error: error } });
  }
}

function* listRoleUser(action) {
  try {
    const response = yield call(API.listRoleUser, action.payload);
    yield put({ type: LIST_ROLE_USER_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_ROLE_USER_FAIL, payload: { error: error } });
  }
}
function* singleRoleUser(action) {
  try {
    const response = yield call(API.singleRoleUser, action.payload);
    yield put({ type: LIST_SINGLE_ROLE_USER_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_SINGLE_ROLE_USER_FAIL, payload: { error: error } });
  }
}
function* updateRoleUser(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.updateRoleUser, otherPayload);
    yield put({ type: UPDATE_ROLE_USER_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: UPDATE_ROLE_USER_FAIL, payload: { error: error } });
  }
}
function* deleteRoleUser(action) {
  try {
    const response = yield call(API.deleteRoleUser, action.payload);
    yield put({ type: DELETE_ROLE_USER_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DELETE_ROLE_USER_FAIL, payload: { error: error } });
  }
}

function* roleUser() {
  yield takeLatest(ROLE_USER_ADD, addRoleUser);
  yield takeLatest(LIST_ROLE_USER, listRoleUser);
  yield takeLatest(LIST_SINGLE_ROLE_USER, singleRoleUser);
  yield takeLatest(UPDATE_ROLE_USER, updateRoleUser);
  yield takeLatest(DELETE_ROLE_USER, deleteRoleUser);
}
export default roleUser;
