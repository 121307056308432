import { deleteRequest, get, patch, post } from "../utils/api";

export const buyerList = (data) => {
  const { pageState = 1, limitState = 100, type, search = "" } = data;
  return get(
    `/admin/buyer?type=${type}&page=${pageState}&limit=${limitState}&text=${search}`,
  );
};
export const addBuyer = (data) => {
  return post(`/admin/buyer/`, data);
};
export const deleteBuyer = (id) => {
  return deleteRequest(`/admin/buyer/${id}`);
};
export const singleBuyer = (id) => {
  return get(`/admin/buyer/${id}`);
};
export const updateBuyer = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/buyer/${id}`, data);
};
export const buyerSingleUpdate = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/buyer/update/${id}`, data);
};

export const buyerWithWareHouse = () => {
  return get(`/admin/buyer/with-warehouse`);
};

export const buyerApi = {
  buyerList,
  addBuyer,
  deleteBuyer,
  singleBuyer,
  updateBuyer,
  buyerSingleUpdate,
  // addBuyerWareHouse,
  // listBuyerWareHouse,
  // singleBuyerWareHouse,
  // updateBuyerWareHouse,
  // deleteBuyerWareHouse,
  buyerWithWareHouse,
};
