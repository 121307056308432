import { toast } from "react-hot-toast";
import {
  DELETE_ROLE_USER,
  DELETE_ROLE_USER_FAIL,
  DELETE_ROLE_USER_SUCCESS,
  LIST_ROLE_USER,
  LIST_ROLE_USER_FAIL,
  LIST_ROLE_USER_SUCCESS,
  LIST_SINGLE_ROLE_USER,
  LIST_SINGLE_ROLE_USER_FAIL,
  LIST_SINGLE_ROLE_USER_SUCCESS,
  ROLE_USER_ADD,
  ROLE_USER_ADD_FAIL,
  ROLE_USER_ADD_SUCCESS,
  UPDATE_ROLE_USER,
  UPDATE_ROLE_USER_FAIL,
  UPDATE_ROLE_USER_SUCCESS,
} from "../actions/roleUserActions";

const initialState = {
  roleUserAdd: {},
  listRoleUser: {},
  listSingleRoleUser: {},
  updateRoleUser: {},
  isLodding: false,
  error: "",
  isSuccess: false,
  isUpdataRoleUserSuccess: false,
  isDeleteRoleUserSuccess: false,
};
const roleUserReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ROLE_USER_ADD: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case ROLE_USER_ADD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        roleUserAdd: payload.data,
        isLoading: false,
        isSuccess: true,
      };
    }

    case ROLE_USER_ADD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case LIST_ROLE_USER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_ROLE_USER_SUCCESS: {
      return {
        ...state,
        listRoleUser: payload?.data,
        isLoading: false,
      };
    }

    case LIST_ROLE_USER_FAIL: {
      return {
        ...state,
      };
    }

    case LIST_SINGLE_ROLE_USER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_SINGLE_ROLE_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        listSingleRoleUser: payload,
      };
    }

    case LIST_SINGLE_ROLE_USER_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case UPDATE_ROLE_USER: {
      return {
        ...state,
        isLoading: true,
        isUpdataRoleUserSuccess: false,
      };
    }

    case UPDATE_ROLE_USER_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        updateRoleUser: payload,
        isLoading: false,
        isUpdataRoleUserSuccess: true,
      };
    }

    case UPDATE_ROLE_USER_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isUpdataRoleUserSuccess: false,
      };
    }
    case DELETE_ROLE_USER: {
      return {
        ...state,
        isLoading: true,
        isDeleteRoleUserSuccess: false,
      };
    }

    case DELETE_ROLE_USER_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteRoleUserSuccess: true,
      };
    }

    case DELETE_ROLE_USER_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteRoleUserSuccess: false,
      };
    }

    default: {
      return { ...state };
    }
  }
};
export default roleUserReducer;
