export const SELLER_LIST = "SELLER_LIST";
export const SELLER_LIST_SUCCESS = "SELLER_LIST_SUCCESS";
export const SELLER_LIST_FAIL = "SELLER_LIST_FAIL";

export const SELLER_ADD = "SELLER_ADD";
export const SELLER_ADD_SUCCESS = "SELLER_ADD_SUCCESS";
export const SELLER_ADD_FAIL = "SELLER_ADD_FAIL";

export const SELLER_DELETE = "SELLER_DELETE";
export const SELLER_DELETE_SUCCESS = "SELLER_DELETE_SUCCESS";
export const SELLER_DELETE_FAIL = "SELLER_DELETE_FAIL";

export const SELLER_SINGLE = "SELLER_SINGLE";
export const SELLER_SINGLE_SUCCESS = "SELLER_SINGLE_SUCCESS";
export const SELLER_SINGLE_FAIL = "SELLER_SINGLE_FAIL";

export const SELLER_UPDATE = "SELLER_UPDATE";
export const SELLER_UPDATE_SUCCESS = "SELLER_UPDATE_SUCCESS";
export const SELLER_UPDATE_FAIL = "SELLER_UPDATE_FAIL";

export const SELLER_SINGLE_UPDATE = "SELLER_SINGLE_UPDATE";
export const SELLER_SINGLE_UPDATE_SUCCESS = "SELLER_SINGLE_UPDATE_SUCCESS";
export const SELLER_SINGLE_UPDATE_FAIL = "SELLER_SINGLE_UPDATE_FAIL";

// ! wareHouse all actions here

export const WAREHOUSE_ADD = "WAREHOUSE_ADD";
export const WAREHOUSE_ADD_SUCCESS = "WAREHOUSE_ADD_SUCCESS";
export const WAREHOUSE_ADD_FAIL = "WAREHOUSE_ADD_FAIL";

export const WAREHOUSE_LIST = "WAREHOUSE_LIST";
export const WAREHOUSE_LIST_SUCCESS = "WAREHOUSE_LIST_SUCCESS";
export const WAREHOUSE_LIST_FAIL = "WAREHOUSE_LIST_FAIL";

export const WAREHOUSE_SINGLE = "WAREHOUSE_SINGLE";
export const WAREHOUSE_SINGLE_SUCCESS = "WAREHOUSE_SINGLE_SUCCESS";
export const WAREHOUSE_SINGLE_FAIL = "WAREHOUSE_SINGLE_FAIL";

export const WAREHOUSE_UPDATE = "WAREHOUSE_UPDATE";
export const WAREHOUSE_UPDATE_SUCCESS = "WAREHOUSE_UPDATE_SUCCESS";
export const WAREHOUSE_UPDATE_FAIL = "WAREHOUSE_UPDATE_FAIL";

export const WAREHOUSE_DELETE = "WAREHOUSE_DELETE";
export const WAREHOUSE_DELETE_SUCCESS = "WAREHOUSE_DELETE_SUCCESS";
export const WAREHOUSE_DELETE_FAIL = "WAREHOUSE_DELETE_FAIL";

export const WAREHOUSE_STATUS_UPDATE = "WAREHOUSE_STATUS_UPDATE";
export const WAREHOUSE_STATUS_UPDATE_SUCCESS =
  "WAREHOUSE_STATUS_UPDATE_SUCCESS";
export const WAREHOUSE_STATUS_UPDATE_FAIL = "WAREHOUSE_STATUS_UPDATE_FAIL";
