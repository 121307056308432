import { get, patch, post } from "../utils/api";

export const orderList = (data) => {
  const {
    pageState = 1,
    limitState = 10,
    status = "pending",
    search = "",
  } = data;
  return get(
    `/admin/order?status=${status}&page=${pageState}&limit=${limitState}&search=${search}`,
  );
};
export const addOrder = (data) => {
  return post(`/admin/order/`, data);
};
export const singleOrder = (id) => {
  return get(`/admin/order/${id}`);
};
export const orderSingleUpdate = (data) => {
  const { id } = data;
  delete data["id"];
  return patch(`/admin/order/${id}`, data);
};
export const orderUpdate = (data) => {
  const { id } = data;
  delete data["id"];
  return patch(`/admin/order/${id}`, data);
};
export const confirmOrder = (data) => {
  const { id } = data;
  delete data["id"];
  return patch(`/admin/order/confirm/${id}`, data);
};

export const orderPayment = (data) => {
  const { id } = data;
  delete data["id"];
  return patch(`/admin/order/paymentstatus/${id}`, data);
};
export const orderStatus = (data) => {
  const { id } = data;
  delete data["id"];
  return patch(`/admin/order/orderstatus/${id}`, data);
};

export const orderApi = {
  orderList,
  addOrder,
  singleOrder,
  orderSingleUpdate,
  orderUpdate,
  confirmOrder,
  orderPayment,
  orderStatus,
};
