import PropTypes from "prop-types";
import "../seller/SellerForm.css";

import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import {
  CATEGORY_ADD,
  CATEGORY_LIST,
  CATEGORY_SINGLE,
  CATEGORY_UPDATE,
} from "../../../actions/productActions";

const CategoryForm = (props) => {
  const { onClick, id } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );

  const categoryList = useCallback((values) => {
    dispatch({ type: CATEGORY_LIST, payload: values });
  }, []);

  const categoryUpdate = useCallback((values) => {
    dispatch({ type: CATEGORY_UPDATE, payload: values });
  }, []);
  const singleListCategory = useCallback((data) => {
    dispatch({ type: CATEGORY_SINGLE, payload: data });
  }, []);

  const singleData = useSelector((data) => data?.product?.categorySingle);
  useEffect(() => {
    if (id) {
      singleListCategory(id);
    }
  }, [id]);
  useEffect(() => {
    categoryList({ type: "active", search: "" });
  }, []);
  return (
    <Box
      className={
        isMobile ? "expense-category-main-div" : "expense-category-form-box"
      }
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          categoryName: id ? singleData?.categoryName : "",
        }}
        validationSchema={Yup.object().shape({
          categoryName: Yup.string().required("CategoryName is required"),
        })}
        onSubmit={(values) => {
          if (id) {
            categoryUpdate({ ...values, id: id, callback: onClick });
          } else {
            dispatch({
              type: CATEGORY_ADD,
              payload: { ...values, callback: onClick },
            });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">Material Name*</InputLabel>
                    <OutlinedInput
                      autoFocus
                      id="categoryName"
                      size="large"
                      fullWidth
                      value={values?.categoryName}
                      name="categoryName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Name"
                      error={Boolean(
                        touched.categoryName && errors.categoryName,
                      )}
                    />
                    {touched.categoryName && errors.categoryName && (
                      <FormHelperText error id="helper-text-name">
                        {errors.categoryName}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box className="expense-category-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={id ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "category" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                onClick={onClick}
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "category" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

CategoryForm.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
};

export default CategoryForm;
