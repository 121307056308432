import {
  LIST_EMAIL_QUERY,
  LIST_EMAIL_QUERY_FAIL,
  LIST_EMAIL_QUERY_SUCCESS,
} from "../actions/emailQueryActions";
import { API } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";

//expense saga

function* listEmailQuery(action) {
  try {
    const response = yield call(API.listEmailQuery, action.payload);
    yield put({ type: LIST_EMAIL_QUERY_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_EMAIL_QUERY_FAIL, payload: { error: error } });
  }
}
function* emailQuery() {
  yield takeLatest(LIST_EMAIL_QUERY, listEmailQuery);
}
export default emailQuery;
