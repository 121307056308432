import PropTypes from "prop-types";
import "./../../settings/paymentmodes/paymentModesForm.css";

import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../../@extended/button/ButtonComponent";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import {
  PAYMENT_MODE,
  UPDATE_PAYMENT_MODE,
} from "../../../../actions/settingActions";

const PaymentModesForm = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const { onClick, id, singleData } = props;
  const dispatch = useDispatch();
  // add payment mode
  const addPaymentData = useCallback((data) => {
    dispatch({ type: PAYMENT_MODE, payload: data });
  });
  // update payment mode
  const updatePayment = useCallback((data) => {
    dispatch({ type: UPDATE_PAYMENT_MODE, payload: data });
  }, []);
  return (
    <Box
      className={isMobile ? "payment-mode-main-div" : "payment-mode-form-box"}
    >
      <Formik
        initialValues={{
          name: id ? singleData?.name : "",
          type: id ? singleData?.type : "",
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255)
            .required(" Payment Mode Name is required"),
          type: Yup.string(),
        })}
        onSubmit={(values) => {
          if (id) {
            updatePayment({ ...values, id: id });
          } else {
            addPaymentData({ ...values });
          }
          onClick();
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container className="payment-mode-form-container">
                <Grid item xs={12} className="payment-mode-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">Payment Mode Name*</InputLabel>
                    <OutlinedInput
                      id="name"
                      fullWidth
                      value={values.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Payment Mode Name"
                      error={Boolean(touched.name && errors.name)}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error id="helper-text-name">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="payment-mode-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">Mode Type *</InputLabel>
                    <ToggleButtonGroup
                      color="primary"
                      value={values?.type}
                      exclusive
                      onChange={(e, val) => setFieldValue("type", val)}
                      aria-label="Platform1"
                      name="type"
                    >
                      <ToggleButton value="cash">Cash</ToggleButton>
                      <ToggleButton value="bank" sx={{ padding: "5px 15px" }}>
                        Bank
                      </ToggleButton>
                    </ToggleButtonGroup>
                    {touched.type && errors.type && (
                      <FormHelperText error id="helper-text-name">
                        {errors.type}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box className="payment-mode-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={id ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                onClick={onClick}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
PaymentModesForm.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
};

export default PaymentModesForm;
