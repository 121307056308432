import { deleteRequest, get, patch, post } from "../utils/api";

//emailQuery Api

export const addEmailQuery = (data) => {
  return post(`/enquiry/`, data);
};

export const listEmailQuery = (data) => {
  return get(`/enquiry/`, data);
};

export const singleEmailQuery = (id) => {
  return get(`/enquiry/${id}`);
};

export const updateEmailQuery = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/enquiry/${id}`, data);
};

export const deleteEmailQuery = (id) => {
  return deleteRequest(`/enquiry/${id}`);
};

export const emailQueryApi = {
  addEmailQuery,
  listEmailQuery,
  singleEmailQuery,
  updateEmailQuery,
  deleteEmailQuery,
};
