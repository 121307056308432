import PropTypes from "prop-types";
import "./../addUser/addUserForm.css";

import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  useMediaQuery,
  useTheme,
  TextField,
  Autocomplete,
} from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../../@extended/button/ButtonComponent";
import { useCallback, useEffect, useState } from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import {
  LIST_SINGLE_ROLE_USER,
  ROLE_USER_ADD,
  UPDATE_ROLE_USER,
} from "../../../../actions/roleUserActions";
import { useDispatch, useSelector } from "react-redux";
import { LIST_ROLE } from "../../../../actions/roleActions";
// import { useDispatch } from "react-redux";
// import { useCallback } from "react";
// import { user, UPDATE_user } from "../../../../actions/settingActions";

const AddUserForm = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const [recentValues, setRecentValues] = useState("");
  const { onClick, id } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const singleListuserData = useCallback((data) => {
    dispatch({ type: LIST_SINGLE_ROLE_USER, payload: data });
  });
  const singleData = useSelector(
    (data) => data?.roleUser?.listSingleRoleUser?.data,
  );
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();
  const detailsRoleData = useSelector((data) => data?.role?.listRole?.results);

  //   // add user
  const adduserData = useCallback((data) => {
    dispatch({ type: ROLE_USER_ADD, payload: data });
  }, []);
  const listRoleData = useCallback(() => {
    dispatch({ type: LIST_ROLE });
  }, []);
  useEffect(() => {
    if (id) {
      singleListuserData(id);
    }
    listRoleData();
  }, [id]);
  //   // update user

  const updateuser = useCallback((data) => {
    dispatch({ type: UPDATE_ROLE_USER, payload: data });
  }, []);
  return (
    <Box className={isMobile ? "users-main-div" : "users-form-box"}>
      <Formik
        initialValues={{
          firstName: id ? singleData?.firstName : "",
          lastName: id ? singleData?.lastName : "",
          mobile: id ? singleData?.mobile : "",
          userRole: id ? singleData?.userRole?.id || "" : "",
          email: id ? singleData?.email : "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().max(255).required("First Name is required"),
          lastName: Yup.string().max(255).required("Last Name is required"),
          mobile: Yup.string().required("* Please Enter phone number"),
          userRole: Yup.string().max(255).required("Role Type is required"),
          email: Yup.string()
            .email("* Please enter valid email")
            .required("* Please Enter Your email "),
          password: Yup.string().required("* Please Enter Your password "),
          confirmPassword: Yup.string()
            .required("* Please enter your password again")
            .oneOf([Yup.ref("password")], "Passwords not matched"),
        })}
        enableReinitialize={true}
        onSubmit={(values) => {
          if (id) {
            updateuser({ ...values, id: id, callback: onClick });
          } else {
            adduserData({ ...values, callback: onClick });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container className="users-form-container">
                <Grid item xs={12} className="users-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="firstName">First Name*</InputLabel>
                    <OutlinedInput
                      id="firstName"
                      size="small"
                      fullWidth
                      value={values?.firstName}
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter First Name"
                      error={Boolean(touched?.firstName && errors?.firstName)}
                    />
                    {touched?.firstName && errors?.firstName && (
                      <FormHelperText error id="helper-text-name">
                        {errors?.firstName}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="users-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="firstName">Last Name*</InputLabel>
                    <OutlinedInput
                      id="lastName"
                      fullWidth
                      size="small"
                      value={values?.lastName}
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Last Name"
                      error={Boolean(touched?.lastName && errors?.lastName)}
                    />
                    {touched?.lastName && errors?.lastName && (
                      <FormHelperText error id="helper-text-name">
                        {errors?.lastName}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="users-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="mobile">Mobile Number*</InputLabel>
                    <OutlinedInput
                      type="number"
                      id="mobile"
                      fullWidth
                      size="small"
                      value={values?.mobile}
                      name="mobile"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter mobile Number"
                      error={Boolean(touched?.mobile && errors?.mobile)}
                    />
                    {touched?.mobile && errors?.mobile && (
                      <FormHelperText error id="helper-text-name">
                        {errors?.mobile}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                {/* <Grid item xs={12} className="users-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="userRole" sx={{ marginBottom: "8px" }}>
                      Role*
                    </InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      labelId="userRole"
                      id="userRole"
                      name="userRole"
                      value={values?.userRole}
                      onChange={handleChange}
                      style={{ borderBlockColor: "gray" }}
                      error={Boolean(touched?.userRole && errors?.userRole)}
                    >
                      {detailsRoleData?.map((data, ind) => {
                        return (
                          <MenuItem key={ind} value={data?.id}>
                            {data?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {touched?.userRole && errors?.userRole && (
                      <FormHelperText error id="helper-text-name">
                        {errors?.userRole}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  md={12}
                  className="expense-category-form-input"
                >
                  <InputLabel htmlFor="currency" sx={{ marginBottom: "8px" }}>
                    Role*
                  </InputLabel>
                  <Autocomplete
                    defaultValue={
                      id
                        ? singleData?.userRole?.name &&
                          singleData?.userRole?.name
                        : ""
                    }
                    disablePortal
                    size="small"
                    options={
                      detailsRoleData?.length > 0
                        ? detailsRoleData?.map((recent) => ({
                            label: `${recent?.name}`,
                            value: recent?.id,
                          }))
                        : []
                    }
                    // value={recentLabels}
                    name="userRole"
                    onChange={(event, value) => {
                      setRecentValues(value?.value);
                      setFieldValue("userRole", value?.value);
                    }}
                    error={touched.userRole && errors.userRole}
                    renderInput={(params) => {
                      const findVal = detailsRoleData?.find(
                        (data) => data?.id === recentValues,
                      );
                      return (
                        <TextField
                          {...params}
                          value={findVal?.name}
                          placeholder="Role"
                        />
                      );
                    }}
                  />
                  {touched.userRole && errors.userRole && (
                    <FormHelperText error id="helper-text-name">
                      {errors.userRole}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} className="users-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email">Email*</InputLabel>
                    <OutlinedInput
                      fullWidth
                      size="small"
                      error={Boolean(touched?.email && errors?.email)}
                      id="email"
                      value={values?.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="demo@company.com"
                      inputProps={{}}
                    />
                    {touched?.email && errors?.email && (
                      <FormHelperText error id="helper-text-email">
                        {errors?.email}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="users-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="password">Password*</InputLabel>
                    <OutlinedInput
                      size="small"
                      fullWidth
                      error={Boolean(touched.password && errors.password)}
                      id="password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      name="password"
                      onBlur={handleBlur}
                      placeholder="Please Enter Password"
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{}}
                    />
                    {touched.password && errors.password && (
                      <FormHelperText error id="helper-text-password">
                        {errors.password}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="users-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="confirmPassword">
                      confirm Password*
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      fullWidth
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword,
                      )}
                      id="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      value={values?.confirmPassword}
                      name="confirmPassword"
                      onBlur={handleBlur}
                      placeholder="Please Enter Confirm Password"
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                            edge="end"
                            size="large"
                          >
                            {showConfirmPassword ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {touched.confirmPassword && errors.confirmPassword && (
                      <FormHelperText error id="helper-text-password">
                        {errors.confirmPassword}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box className="users-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={id ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "user" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                onClick={onClick}
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "user" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
AddUserForm.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
};

export default AddUserForm;
