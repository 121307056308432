import toast from "react-hot-toast";
import {
  DELETE_EXPENSE_CATEGORY,
  DELETE_EXPENSE_CATEGORY_FAIL,
  DELETE_EXPENSE_CATEGORY_SUCCESS,
  EXPENSE_CATEGORY,
  EXPENSE_CATEGORY_FAIL,
  EXPENSE_CATEGORY_SUCCESS,
  LIST_EXPENSE_CATEGORY,
  LIST_EXPENSE_CATEGORY_FAIL,
  LIST_EXPENSE_CATEGORY_SUCCESS,
  LIST_SINGLE_EXPENSE_CATEGORY,
  LIST_SINGLE_EXPENSE_CATEGORY_FAIL,
  LIST_SINGLE_EXPENSE_CATEGORY_SUCCESS,
  UPDATE_EXPENSE_CATEGORY,
  UPDATE_EXPENSE_CATEGORY_FAIL,
  UPDATE_EXPENSE_CATEGORY_SUCCESS,
} from "../actions/expenseCategoryActions";

const initialState = {
  addExpenseCategory: {},
  listExpenseCategory: {},
  singleExpenseCategory: {},
  updateExpenseCategory: {},
  deleteExpenseCategory: {},
  isAddExpenseCategorySuccess: false,
  isUpdateExpenseCategorySuccess: false,
  isDeleteExpenseCategorySuccess: false,
  error: "",
};
const expenseCategoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    //ExpenseCategory reducer
    case EXPENSE_CATEGORY: {
      return {
        ...state,
        isLoading: true,
        isAddExpenseCategorySuccess: false,
      };
    }
    case EXPENSE_CATEGORY_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        addExpenseCategory: payload.user,
        isLoading: false,
        isAddExpenseCategorySuccess: true,
      };
    }

    case EXPENSE_CATEGORY_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isAddExpenseCategorySuccess: false,
      };
    }
    case LIST_EXPENSE_CATEGORY: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_EXPENSE_CATEGORY_SUCCESS: {
      return {
        ...state,
        listExpenseCategory: payload.data,
        isLoading: false,
      };
    }

    case LIST_EXPENSE_CATEGORY_FAIL: {
      return {
        ...state,
      };
    }

    case LIST_SINGLE_EXPENSE_CATEGORY: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_SINGLE_EXPENSE_CATEGORY_SUCCESS: {
      return {
        ...state,
        singleExpenseCategory: payload.data,
        isLoading: false,
      };
    }

    case LIST_SINGLE_EXPENSE_CATEGORY_FAIL: {
      return {
        ...state,
      };
    }
    case UPDATE_EXPENSE_CATEGORY: {
      return {
        ...state,
        isLoading: true,
        isUpdateExpenseCategorySuccess: false,
      };
    }

    case UPDATE_EXPENSE_CATEGORY_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        updateExpenseCategory: payload,
        isLoading: false,
        isUpdateExpenseCategorySuccess: true,
      };
    }

    case UPDATE_EXPENSE_CATEGORY_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isUpdateExpenseCategorySuccess: false,
      };
    }
    case DELETE_EXPENSE_CATEGORY: {
      return {
        ...state,
        isLoading: true,
        isDeleteExpenseCategorySuccess: false,
      };
    }

    case DELETE_EXPENSE_CATEGORY_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteExpenseCategorySuccess: true,
      };
    }

    case DELETE_EXPENSE_CATEGORY_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteExpenseCategorySuccess: false,
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default expenseCategoryReducer;
