import { API } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGESTER_SUCCESS,
  REGESTER_FAIL,
  REGESTER,
  PROFILE_DETAILS,
  PROFILE_DETAILS_SUCCESS,
  PROFILE_DETAILS_FAIL,
  UPDATE_DETAILS,
  UPDATE_DETAILS_SUCCESS,
  UPDATE_DETAILS_FAIL,
  COMPANY_COUNT,
  COMPANY_COUNT_SUCCESS,
  COMPANY_COUNT_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from "../actions/authActions";

function* login(action) {
  try {
    const { isKeep, ...otherPayload } = action.payload;
    const response = yield call(API.login, otherPayload);
    yield put({ type: LOGIN_SUCCESS, payload: { isKeep, ...response.data } });
  } catch (error) {
    yield put({ type: LOGIN_FAIL, payload: { error: error } });
  }
}

function* register(action) {
  try {
    const response = yield call(API.register, action.payload);
    yield put({ type: REGESTER_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: REGESTER_FAIL, payload: { error: error } });
  }
}
function* changePassword(action) {
  try {
    const response = yield call(API.changePassword, action.payload);
    yield put({ type: CHANGE_PASSWORD_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CHANGE_PASSWORD_FAIL, payload: { error: error } });
  }
}

function* profileDetails(action) {
  try {
    const response = yield call(API.profileDetails, action.payload);
    yield put({ type: PROFILE_DETAILS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: PROFILE_DETAILS_FAIL, payload: { error: error } });
  }
}

function* updateDetails(action) {
  try {
    const response = yield call(API.updateDetails, action.payload);
    yield put({ type: UPDATE_DETAILS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_DETAILS_FAIL, payload: { error: error } });
  }
}

function* companyCount(action) {
  try {
    const response = yield call(API.companyCount, action.payload);
    yield put({ type: COMPANY_COUNT_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: COMPANY_COUNT_FAIL, payload: { error: error } });
  }
}

function* auth() {
  yield takeLatest(LOGIN, login);
  yield takeLatest(REGESTER, register);
  yield takeLatest(PROFILE_DETAILS, profileDetails);
  yield takeLatest(UPDATE_DETAILS, updateDetails);
  yield takeLatest(COMPANY_COUNT, companyCount);
  yield takeLatest(CHANGE_PASSWORD, changePassword);
}
export default auth;
