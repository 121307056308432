/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import "./printInvoiceForm.css";

import {
  Box,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
// import SaveAsIcon from "@mui/icons-material/SaveAs";
import PrintIcon from "@mui/icons-material/Print";
import ButtonComponent from "../../../@extended/button/ButtonComponent";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  CUSTOM_FIELD,
  UPDATE_CUSTOM_FIELD,
} from "../../../../actions/settingActions";
import CommonTable from "../../../@extended/table/CommonTable";

const PrintInvoiceForm = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));

  const { onClick, id, tableData } = props;
  const dispatch = useDispatch();
  // add tax
  const addTaxData = useCallback((data) => {
    dispatch({ type: CUSTOM_FIELD, payload: data });
  });

  const updateCustomField = useCallback((data) => {
    dispatch({ type: UPDATE_CUSTOM_FIELD, payload: data });
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "#",
      flex: 1,
      headerClassName: "paymentDrawer-header",
      id: 1,
    },
    {
      field: "item",
      headerName: "Item",
      flex: 1,
      headerClassName: "paymentDrawer-header",
      id: 2,
    },
    {
      field: "qty",
      headerName: "Qty",
      flex: 1,
      headerClassName: "paymentDrawer-header",
      id: 3,
    },
    {
      field: "mrp",
      headerName: "MRP",
      flex: 1,
      headerClassName: "paymentDrawer-header",
      id: 4,
    },
    {
      field: "rate",
      headerName: "Rate",
      flex: 1,
      headerClassName: "paymentDrawer-header",
      id: 5,
    },
    {
      field: "total",
      headerName: "Total",
      flex: 1,
      headerClassName: "paymentDrawer-header",
      id: 6,
    },
  ];
  const fetchedRows = tableData?.map((item, ind) => {
    return {
      id: ind + 1,
      item: item.name,
      qty: item.quantity,
      mrp: "",
      rate: "",
      total: item.subtotal,
      rowId: item?.id,
    };
  });
  const [rows, setRows] = useState([]);

  useEffect(() => {
    // TODO:
    // Calculate the sum of each column
    // const sumQty = fetchedRows.reduce((sum, row) => sum + row.qty, 0);
    // const sumMRP = fetchedRows.reduce((sum, row) => sum + row.mrp, 0);
    // const sumRate = fetchedRows.reduce((sum, row) => sum + row.rate, 0);
    // const sumTotal = fetchedRows.reduce((sum, row) => sum + row.total, 0);

    setRows(fetchedRows);
    // You can remove this useEffect dependency array if it's not needed
  }, []);

  const totalSubtotal = tableData?.reduce((total, item) => {
    // Assuming "subtotal" is a string in the format "$XX.XX", remove the "$" and convert to a float
    const subtotalValue = parseFloat(item?.subtotal?.slice(1));
    return total + subtotalValue;
  }, 0);

  return (
    <Box
      className={
        isMobile
          ? "print-Invoice-field-name-main-div"
          : "print-Invoice-field-name-form-box"
      }
    >
      <Box
        maxHeight={"calc(100vh - 300px)"}
        overflow={"auto"}
        padding="10px 5px 40px 5px"
      >
        <Grid container className="field-name-form-container">
          <Grid item xs={12} className="print-Invoice-field-name-form-input">
            <Stack spacing={1}></Stack>
            <Grid item xs={12}>
              <Box className="print-Invoice-field-src">
                <img
                  src="https://saas.stockifly.in/uploads/warehouses/electronifly.png"
                  alt=""
                />
              </Box>
              <Box className="print-Invoice-field-demo">Electronifly</Box>
              <Box className="print-Invoice-field-demo-child">
                <Box className="print-Invoice-field-demo-child1">
                  Phone: +16305617543
                </Box>
                <Box className="print-Invoice-field-demo-child1">
                  Email: electronifly@example.com
                </Box>
              </Box>
              <Box className="print-Invoice-field-demo-tax">TAX INVOICE</Box>
              <Box className="print-Invoice-field-demo-sale">
                <Box>
                  <Box>Invoice : SALE-89</Box>
                  <Box>Customer : Walk In Customer</Box>
                </Box>
                <Box>
                  <Box> Date : 17-10-2023</Box>
                  <Box> Sold By : Admin</Box>
                </Box>
              </Box>

              <Box className="print-Invoice-field-demo-table-border">
                <CommonTable columns={columns} rows={rows} />
              </Box>
              <Box className="print-Invoice-field-demo-payment-mode-Order-Tax">
                <Box>
                  <Box>Order Tax </Box>
                  <Box>Discount </Box>
                  <Box>Shipping </Box>
                </Box>
                <Box>
                  <Box> $0.00</Box>
                  <Box> $0.00</Box>
                  <Box> $0.00</Box>
                </Box>
              </Box>
              <Box className="print-Invoice-field-demo-payment-mode-child2">
                <Box>Items :{rows?.length}</Box>
                <Box>
                  Qty :
                  {fetchedRows?.reduce((sum, row) => sum + row?.qty, 0) || 0}
                </Box>
                <Box>Total :${totalSubtotal.toFixed(2)}</Box>
              </Box>
              <Box className="print-Invoice-field-demo-payment-mode-Order-Paid-Amount">
                <Box className="print-Invoice-field-demo-payment-mode-Order-Paid-Amount-child">
                  <Box>Paid Amount </Box>
                  <Box>Due Amount </Box>
                </Box>
                <Box className="print-Invoice-field-demo-payment-mode-Order-Paid-Amount-child1">
                  <Box> $0.00</Box>
                  <Box> ${totalSubtotal.toFixed(2)}</Box>
                </Box>
              </Box>
              <Box className="print-Invoice-field-demo-payment-mode">
                Payment Mode:
              </Box>
              <Box className="print-Invoice-field-demo-payment-mode-child">
                <Box>Total Discount On MRP : ₹4.00</Box>
                <Box>Total Discount : 8.89%</Box>
                <Box>Total Tax : ₹0.00</Box>
              </Box>
              <Box className="print-Invoice-field-demo-payment-mode-child1">
                <Box className="print-Invoice-field-src-barcode">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcWhVDPqb8Q3Ii5MAqAViDt1hSPxb4TMD3Hka_8Edc&s"
                    alt=""
                  />
                </Box>
                <Box className="print-Invoice-field-demo-tax">
                  Thank You For Shopping With Us. Please Come Again
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        className="print-Invoice-field-name-form-drawer-btn-wrapper-create"
        center
      >
        <ButtonComponent
          onClick={onClick}
          name="Print Invoice"
          className="print-Invoice-field-demo-print-invoice-btn"
          isName={true}
          Icon={PrintIcon}
          type="submit"
        />
      </Box>
    </Box>
  );
};
PrintInvoiceForm.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
  tableData: PropTypes.any,
  modalClosePrintInvoice: PropTypes.any,
};

export default PrintInvoiceForm;
