import { get, patch, post, put } from "../utils/api";

export const login = (data) => {
  return post(`/admin/auth/login`, data);
};

export const register = (data) => {
  return post(`/admin/auth/register`, data);
};

export const profileDetails = () => {
  return get(`/admin/profile/me`);
};

export const updateDetails = (data) => {
  return patch(`/admin/profile/update-info`, data);
};

export const companyCount = () => {
  return get(`/dashboard/company-count`);
};
export const changePassword = (data) => {
  return put(`/admin/profile/change-password`, data);
};

export const authApi = {
  login,
  register,
  profileDetails,
  updateDetails,
  companyCount,
  changePassword,
};
