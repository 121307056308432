export const ROLE_USER_ADD = "ROLE_USER_ADD";
export const ROLE_USER_ADD_SUCCESS = "ROLE_USER_ADD_SUCCESS";
export const ROLE_USER_ADD_FAIL = "ROLE_USER_ADD_FAIL";

export const LIST_ROLE_USER = "LIST_ROLE_USER";
export const LIST_ROLE_USER_SUCCESS = "LIST_ROLE_USER_SUCCESS";
export const LIST_ROLE_USER_FAIL = "LIST_ROLE_USER_FAIL";

export const LIST_SINGLE_ROLE_USER = "LIST_SINGLE_ROLE_USER";
export const LIST_SINGLE_ROLE_USER_SUCCESS = "LIST_SINGLE_ROLE_USER_SUCCESS";
export const LIST_SINGLE_ROLE_USER_FAIL = "LIST_SINGLE_ROLE_USER_FAIL";

export const UPDATE_ROLE_USER = "UPDATE_ROLE_USER";
export const UPDATE_ROLE_USER_SUCCESS = "UPDATE_ROLE_USER_SUCCESS";
export const UPDATE_ROLE_USER_FAIL = "UPDATE_ROLE_USER_FAIL";

export const DELETE_ROLE_USER = "DELETE_ROLE_USER";
export const DELETE_ROLE_USER_SUCCESS = "DELETE_ROLE_USER_SUCCESS";
export const DELETE_ROLE_USER_FAIL = "DELETE_ROLE_USER_FAIL";
