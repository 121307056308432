import PropTypes from "prop-types";
import "../seller/SellerForm.css";

import {
  // Autocomplete,
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  // TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import {
  CATEGORY_LIST,
  SUB_CATEGORY_ADD,
  SUB_CATEGORY_LIST,
  SUB_CATEGORY_SINGLE,
  SUB_CATEGORY_UPDATE,
} from "../../../actions/productActions";

const SubCategoryForm = (props) => {
  const { onClick, id } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints.up("sm"));
  // const [recentValues, setRecentValues] = useState("");
  const dispatch = useDispatch();
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );

  const subCategoryList = useCallback((values) => {
    dispatch({ type: SUB_CATEGORY_LIST, payload: values });
  }, []);

  const categoryList = useCallback((values) => {
    dispatch({ type: CATEGORY_LIST, payload: values });
  }, []);
  const singleListCategory = useCallback((data) => {
    dispatch({ type: SUB_CATEGORY_SINGLE, payload: data });
  }, []);

  const singleData = useSelector((data) => data?.product?.subCategorySingle);

  useEffect(() => {
    if (id) {
      singleListCategory(id);
    }
  }, [id]);

  const subCategoryUpdate = useCallback((values) => {
    dispatch({ type: SUB_CATEGORY_UPDATE, payload: values });
  }, []);
  // const categoryListData = useSelector((data) => data?.product?.categoryList);
  useEffect(() => {
    subCategoryList({ type: "active", search: "" });
    categoryList({ limitState: 10, pageState: 1 });
  }, []);
  return (
    <Box
      className={
        isMobile ? "expense-category-main-div" : "expense-category-form-box"
      }
    >
      <Formik
        initialValues={{
          subCategoryName: id ? singleData?.subCategoryName : "",
        }}
        validationSchema={Yup.object().shape({
          subCategoryName: Yup.string().required(
            "Material type name is required",
          ),
        })}
        onSubmit={(values) => {
          if (id) {
            subCategoryUpdate({ ...values, id: id, callback: onClick });
          } else {
            dispatch({
              type: SUB_CATEGORY_ADD,
              payload: { ...values, callback: onClick },
            });
          }
        }}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          // setFieldValue,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">Material Type Name*</InputLabel>
                    <OutlinedInput
                      autoFocus
                      id="subCategoryName"
                      size="large"
                      fullWidth
                      value={values?.subCategoryName}
                      name="subCategoryName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Sub CategoryName"
                      error={Boolean(
                        touched.subCategoryName && errors.subCategoryName,
                      )}
                    />
                    {touched.subCategoryName && errors.subCategoryName && (
                      <FormHelperText error id="helper-text-name">
                        {errors.subCategoryName}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  md={12}
                  className="expense-category-form-input"
                >
                  <InputLabel htmlFor="currency" sx={{ marginBottom: "8px" }}>
                    Material*
                  </InputLabel>
                  <Autocomplete
                    defaultValue={
                      id
                        ? singleData?.category?.categoryName &&
                          singleData?.category?.categoryName
                        : ""
                    }
                    disablePortal
                    size="small"
                    options={
                      categoryListData?.results?.length > 0
                        ? categoryListData?.results?.map((recent) => ({
                            label: `${recent?.categoryName}`,
                            value: recent?.id,
                          }))
                        : []
                    }
                    // value={recentLabels}
                    name="category"
                    onChange={(event, value) => {
                      setRecentValues(value?.value);
                      // setRecentLabels(value?.label);
                      setFieldValue("category", value?.value);
                    }}
                    error={touched.category && errors.category}
                    renderInput={(params) => {
                      const findVal = categoryListData?.results?.find(
                        (data) => data?.id === recentValues,
                      );
                      return (
                        <TextField
                          {...params}
                          value={findVal?.categoryName}
                          placeholder="Material"
                        />
                      );
                    }}
                  />
                  {touched.category && errors.category && (
                    <FormHelperText error id="helper-text-name">
                      {errors.category}
                    </FormHelperText>
                  )}
                </Grid> */}
              </Grid>
            </Box>
            <Box className="expense-category-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={id ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "subCategory" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                onClick={onClick}
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "subCategory" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

SubCategoryForm.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
};

export default SubCategoryForm;
