import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CommonTable from "../../@extended/table/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import { SELLER_LIST } from "../../../actions/sellerActions";

const SellerTablePage = (props) => {
  const { columns, type, search } = props;
  const dispatch = useDispatch();

  // const companyData = useSelector((data) => data?.company?.listCompany);
  const sellerData = useSelector((data) => data?.seller?.sellerList);
  const { totalResults = 0, limit, page } = sellerData;
  const [limitState, setLimit] = useState(10);
  const [pageState, setPage] = useState(1);

  const isSuccess = useSelector((data) => data?.seller?.isSuccess);
  const sellerList = useCallback((values) => {
    dispatch({ type: SELLER_LIST, payload: values });
  }, []);

  const rows = sellerData?.results?.map((item, ind) => {
    return {
      id: ind + 1,
      no: ind + 1,
      firstName: item?.firstName,
      lastName: item?.lastName,
      mobile: item?.mobile,
      isVerify: item?.isVerified,
      email: item?.email,
      gstCertificate: item?.gstCertificate,
      createBy: item?.createBy?.firstName,
      actions: "",
      rowId: item?.id,
    };
  });

  useEffect(() => {
    if (sellerData?.limit) {
      setLimit(sellerData?.limit);
    }
    if (sellerData?.page) {
      setPage(sellerData?.page);
    }
  }, [sellerData?.limit, sellerData?.page]);

  useEffect(() => {
    sellerList({ limitState, pageState, type, search });
  }, [isSuccess, limitState, pageState, type, search]);

  return (
    <Box className="email-query-box">
      <Box className="email-query-common-table-box-main">
        <Box className="email-query-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
            pageData={{ limit, page, totalResults, setLimit, setPage }}
          />
        </Box>
      </Box>
    </Box>
  );
};
SellerTablePage.propTypes = {
  columns: PropTypes.any,
  type: PropTypes.any,
  search: PropTypes.text,
  setDeleteModal: PropTypes.any,
};
export default SellerTablePage;
