export const LIST_COMPANY = "LIST_COMPANY";
export const LIST_COMPANY_SUCCESS = "LIST_COMPANY_SUCCESS";
export const LIST_COMPANY_FAIL = "LIST_COMPANY_FAIL";

export const ADD_COMPANY = "ADD_COMPANY";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL";

export const SINGLE_COMPANY_LIST = "SINGLE_COMPANY_LIST";
export const SINGLE_COMPANY_LIST_SUCCESS = "SINGLE_COMPANY_LIST_SUCCESS";
export const SINGLE_COMPANY_LIST_FAIL = "SINGLE_COMPANY_LIST_FAIL";

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL";

export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL";

//Plan
export const LIST_PLAN = "LIST_PLAN";
export const LIST_PLAN_SUCCESS = "LIST_PLAN_SUCCESS";
export const LIST_PLAN_FAIL = "LIST_PLAN_FAIL";

export const SUBSCRIPTION_LIST_PLAN = "SUBSCRIPTION_LIST_PLAN";
export const SUBSCRIPTION_LIST_PLAN_SUCCESS = "SUBSCRIPTION_LIST_PLAN_SUCCESS";
export const SUBSCRIPTION_LIST_PLAN_FAIL = "SUBSCRIPTION_LIST_PLAN_FAIL";

export const PAYMENT_MODE_LIST = "PAYMENT_MODE_LIST";
export const PAYMENT_MODE_LIST_SUCCESS = "PAYMENT_MODE_LIST_SUCCESS";
export const PAYMENT_MODE_LIST_FAIL = "PAYMENT_MODE_LIST_FAIL";

export const ADD_LIST_PLAN = "ADD_LIST_PLAN";
export const ADD_LIST_PLAN_SUCCESS = "ADD_LIST_PLAN_SUCCESS";
export const ADD_LIST_PLAN_FAIL = "ADD_LIST_PLAN_FAIL";
