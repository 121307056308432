import React, { useCallback } from "react";
import CommonTable from "../../components/@extended/table/CommonTable";
import { useEffect } from "react";
import ButtonComponent from "../../components/@extended/button/ButtonComponent";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Box, Button } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CommonFormDrawer from "../@extended/drawer/CommonFormDrawer";
import CommonTab from "../@extended/tabs/CommonTab";
import DeleteModal from "../@extended/deletemodal/DeleteModal";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  DELETE_COMPANY,
  SINGLE_COMPANY_LIST,
} from "../../actions/companyActions";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CompaniesForm from "./companies/CompaniesForm";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CustomizedModal from "../@extended/customizedmodal/CustomizedModal";
import ViewPlan from "./companies/ViewPlan";

function DashboardTable({ companyCount }) {
  const [state, setState] = useState({
    right: false,
  });
  const [id, setId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();

  // ! for future use
  // const listCompany = useCallback((values) => {
  //   dispatch({ type: LIST_COMPANY, payload: values });
  // }, []);

  const singleListCompany = useCallback((values) => {
    dispatch({ type: SINGLE_COMPANY_LIST, payload: values });
  }, []);

  const deleteCompany = useCallback((data) => {
    dispatch({ type: DELETE_COMPANY, payload: data });
  }, []);

  const companyData = useSelector((data) => data?.company?.listCompany);

  const singleData = useSelector((data) => data?.company?.singleCompany);

  const [limitState, setLimit] = useState(0);
  const [pageState, setPage] = useState(0);
  const [open, setOpen] = useState(false);

  const { totalResults = 0, limit, page } = companyData;

  const toggleDrawer = (right, open, params) => (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [right]: open });
    setId(params?.row?.rowId);
  };

  const companyRows = companyData?.results?.map((data, ind) => {
    return {
      id: ind + 1,
      logo: data?.logos?.darkLogo,
      name: data?.name,
      email: data?.email,
      details: {
        varified: data?.isVerified,
        registerDate: data?.createdAt,
      },
      status: data?.status,
      action: "",
      rowId: data?.id,
    };
  });

  const InactiveButton = () => {
    return (
      <Button type="button" color="error">
        Inactive
      </Button>
    );
  };
  const ActiveButton = () => {
    return (
      <Button type="button" color="success">
        Active
      </Button>
    );
  };
  const PendingButton = () => {
    return (
      <Button type="button" color="secondary">
        Pending
      </Button>
    );
  };
  const PlanButton = (params) => {
    return (
      <Button
        type="button"
        color="primary"
        variant="outlined"
        onClick={() => {
          setOpen(true);
          setId(params?.row?.rowId);
        }}
      >
        View Plan
      </Button>
    );
  };

  const columns = [
    {
      field: "logo",
      headerName: "Company Logo",
      flex: 1,
      headerClassName: "company-header",
      renderCell: (params) => {
        return (
          <Box
            width={"100%"}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              style={{
                width: "100px",
              }}
            >
              <img
                src={`${params?.row?.logo}`}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                alt="img"
              />
            </Box>
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Company Name",
      flex: 1,
      headerClassName: "company-header",
    },
    {
      field: "email",
      headerName: "Company Email",
      flex: 1,
      headerClassName: "company-header",
    },
    {
      field: "details",
      headerName: "Details",
      flex: 1,
      headerClassName: "company-header",
      renderCell: (params) => {
        return (
          <Box
            width={"100%"}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <div>Varified: {`${params?.row?.details?.varified}`}</div>
              <div>
                Register Date:{" "}
                {new Date(params?.row?.details?.registerDate)
                  .toISOString()
                  .slice(0, 10)}
              </div>
            </div>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: "ONLINE",
      flex: 1,
      headerClassName: "company-header",
      renderCell: (params) => {
        return (
          <Box
            width={"100%"}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {params?.row?.status === "Pending" && PendingButton()}
            {params?.row?.status === "Active" && ActiveButton()}
            {params?.row?.status === "Inactive" && InactiveButton()}
          </Box>
        );
      },
    },
    {
      field: "subscription",
      headerName: "Subscription Plan",
      sortable: "ONLINE",
      flex: 1,
      headerClassName: "company-header",
      renderCell: (params) => {
        return (
          <Box
            width={"100%"}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {PlanButton(params)}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "company-header",
      renderCell: (params) => {
        return (
          <Box className="table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              onClick={toggleDrawer("right", true, params)}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => {
                setDeleteModal(true);
                setId(params?.row?.rowId);
              }}
            />
          </Box>
        );
      },
    },
  ];

  const commonTabData = [
    {
      label: " Basic Details",
      value: "1",
      icon: <InsertDriveFileIcon />,
      component: (
        <CompaniesForm
          onClick={(e) => {
            toggleDrawer("right", false)(e);
            setId("");
          }}
          id={id}
          singleData={singleData}
        />
      ),
    },
  ];

  useEffect(() => {
    // ! for future use
    // listCompany({ limitState, pageState });
    if (id) {
      singleListCompany(id);
      companyCount();
    }
  }, [limitState, pageState, id]);
  return (
    <>
      <Box className="company-common-table-box-main">
        <Box className="common-table-box">
          <CommonTable
            columns={columns}
            rows={companyRows?.length > 0 ? companyRows : []}
            ischeck={false}
            isPagination={true}
            pageData={{ limit, page, totalResults, setLimit, setPage }}
          />
        </Box>
      </Box>
      {toggleDrawer && (
        <CommonFormDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          setId={setId}
          heading={id ? "Edit Company" : "Add New Company"}
          width="1000px"
          childrenComponent={<CommonTab data={commonTabData} />}
        />
      )}
      {deleteModal && (
        <DeleteModal
          isIcon={true}
          Icon={ErrorOutlineOutlinedIcon}
          open={deleteModal}
          onClick={() => {
            setDeleteModal(false);
          }}
          id={id}
          deleteData={deleteCompany}
        />
      )}
      {open && (
        <CustomizedModal
          isHeadingName={true}
          heading="View Plan"
          open={open}
          onClick={() => setOpen(false)}
        >
          <ViewPlan onClick={() => setOpen(false)} id={id} />
        </CustomizedModal>
      )}
    </>
  );
}

DashboardTable.propTypes = {
  companyCount: PropTypes.any,
};

export default DashboardTable;
