import PropTypes from "prop-types";
import "../seller/SellerForm.css";
import {
  // Autocomplete,
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  // TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import {
  BRAND_LIST,
  GRADE_ADD,
  GRADE_LIST,
  GRADE_SINGLE,
  GRADE_UPDATE,
} from "../../../actions/productActions";

const GradeForm = (props) => {
  const { onClick, id } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints.up("sm"));
  const dispatch = useDispatch();
  // const brandListData = useSelector((state) => state?.product?.brandList);

  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );
  // const [recentValues, setRecentValues] = useState("");
  const GradeList = useCallback((values) => {
    dispatch({ type: GRADE_LIST, payload: values });
  }, []);

  const singleListGrade = useCallback((data) => {
    dispatch({ type: GRADE_SINGLE, payload: data });
  }, []);
  const singleData = useSelector((data) => data?.product?.gradeSingle);
  const BrandList = useCallback((values) => {
    dispatch({ type: BRAND_LIST, payload: values });
  }, []);

  const GradeUpdate = useCallback((values) => {
    dispatch({ type: GRADE_UPDATE, payload: values });
  }, []);
  useEffect(() => {
    if (id) {
      singleListGrade(id);
    }
  }, [id]);
  useEffect(() => {
    GradeList({ type: "active", search: "" });
    BrandList({ limitState: 10, pageState: 1 });
  }, []);

  return (
    <Box
      className={
        isMobile ? "expense-category-main-div" : "expense-category-form-box"
      }
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: id ? singleData?.name : "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name is required"),
        })}
        onSubmit={(values) => {
          if (id) {
            GradeUpdate({ ...values, id: id, callback: onClick });
          } else {
            dispatch({
              type: GRADE_ADD,
              payload: { ...values, callback: onClick },
            });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          // setFieldValue,
          handleSubmit,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">Grade Name*</InputLabel>
                    <OutlinedInput
                      autoFocus
                      id="categoryName"
                      size="large"
                      fullWidth
                      value={values?.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Name"
                      error={Boolean(touched.name && errors.name)}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error id="helper-text-name">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  md={12}
                  className="expense-category-form-input"
                >
                  <InputLabel htmlFor="currency" sx={{ marginBottom: "8px" }}>
                    Brand*
                  </InputLabel>
                  <Autocomplete
                    defaultValue={
                      id
                        ? singleData?.brand?.name && singleData?.barnd?.name
                        : ""
                    }
                    disablePortal
                    size="small"
                    options={
                      brandListData?.results?.length > 0
                        ? brandListData?.results?.map((recent) => ({
                            label: `${recent?.name}`,
                            value: recent?.id,
                          }))
                        : []
                    }
                    name="brand"
                    onChange={(event, value) => {
                      setRecentValues(value?.value);
                      setFieldValue("brand", value?.value);
                    }}
                    error={touched.brand && errors.brand}
                    renderInput={(params) => {
                      const findVal = brandListData?.results?.find(
                        (data) => data?.id === recentValues,
                      );
                      return (
                        <TextField
                          {...params}
                          value={findVal?.name}
                          placeholder="Brand"
                        />
                      );
                    }}
                  />
                  {touched.brand && errors.brand && (
                    <FormHelperText error id="helper-text-name">
                      {errors.brand}
                    </FormHelperText>
                  )}
                </Grid> */}
              </Grid>
            </Box>
            <Box className="expense-category-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={id ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "grade" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                onClick={onClick}
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "grade" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

GradeForm.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
};

export default GradeForm;
