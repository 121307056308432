import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  Avatar,
  ButtonBase,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import avatar1 from "./../../../assets/images/avatar-1.png";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import "./mainLayout.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { cookie } from "../../../utils/cookies/cookies";
// ! for future use
// import Notification from "../../@extended/notification/NotificationComponent";
import { useDispatch, useSelector } from "react-redux";
import { SET_IS_SIDEBAR_OPEN } from "../../../actions/sidebarActions";
import { PROFILE_DETAILS } from "../../../actions/authActions";
import { useEffect } from "react";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
// ! for sels icon section/*
// import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { useCallback } from "react";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  zIndex: 1,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  // const { id } = useParams();
  const location = useLocation();
  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = React.useState(isLaptop ? true : false);
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch({ type: SET_IS_SIDEBAR_OPEN, payload: true });
  };

  const handleDrawerClose = () => {
    setOpen(false);
    dispatch({ type: SET_IS_SIDEBAR_OPEN, payload: false });
  };
  const token = cookie.get("token");
  const isProfileUpdate = useSelector((state) => state?.auth?.isSuccess);
  const profileDetails = useCallback((values) => {
    dispatch({ type: PROFILE_DETAILS, payload: values });
  }, []);

  useEffect(() => {
    if (token) {
      profileDetails();
    }
  }, [token, isProfileUpdate]);
  const userDetails = useSelector((state) => state?.auth?.profileDetails?.user);
  const pendingOrderCount = useSelector(
    (state) => state?.auth?.profileDetails?.pendingOrderCount,
  );
  const unreadEnquiry = useSelector(
    (state) => state?.auth?.profileDetails?.unreadEnquiry,
  );
  // const userRoleType = userDetails?.userRoleType;
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user?.userPermissions,
  );
  const user = useSelector((state) => state?.auth?.profileDetails);
  const adminUser = userDetails?.userPermissions?.find(
    (data) => data?.userModule === "all",
  );
  // ------------------- for admin users ------------------------ //
  const adminData = [
    {
      path: location?.pathname === "/admin/dashboard",
      icon: (
        <HomeOutlinedIcon
          color={
            location?.pathname === "/admin/dashboard" ? "primary" : "inherit"
          }
        />
      ),
      isTab: true,
      title: "Dashboard",
      href: "/admin/dashboard",
    },
    {
      path:
        location?.pathname === "/admin/seller" ||
        location?.pathname === "/admin/seller/warehouse",
      icon: (
        <StoreOutlinedIcon
          color={location?.pathname === "/admin/seller" ? "primary" : "inherit"}
        />
      ),
      title: "Seller",
      id: 1,
      secondary: "Seller, Seller Warehouse",
      isOption: true,
      isTab:
        (token &&
          userRoleAccess?.find(
            (data) => data?.userModule === "seller" && data?.view === true,
          )) ||
        adminUser,
      options: [
        {
          path: location?.pathname === "/admin/seller",
          name: "Seller",
          href: "/admin/seller",
        },
        {
          path: location?.pathname === "/admin/seller/warehouse",
          name: "Seller Warehouse",
          href: "/admin/seller/warehouse",
        },
      ],
    },
    {
      path:
        location?.pathname === "/admin/buyer" ||
        location?.pathname === "/admin/buyer/warehouse",
      icon: (
        <PersonOutlineOutlinedIcon
          color={location?.pathname === "/admin/buyer" ? "primary" : "inherit"}
        />
      ),
      title: "Buyer",
      id: 2,
      secondary: "Buyer, Buyer Warehouse",
      isOption: true,
      isTab:
        (token &&
          userRoleAccess?.find(
            (data) =>
              data?.userModule === "buyer" ||
              (data?.userModule === "warehouse" && data?.view === true),
          )) ||
        adminUser,
      options: [
        {
          path: location?.pathname === "/admin/buyer",
          name: "Buyer",
          href: "/admin/buyer",
        },
        {
          path: location?.pathname === "/admin/buyer/warehouse",
          name: "Buyer Warehouse",
          href: "/admin/buyer/warehouse",
        },
      ],
    },

    {
      path: location?.pathname === "/admin/order",
      icon: (
        <AddShoppingCartOutlinedIcon
          color={location?.pathname === "/admin/order" ? "primary" : "inherit"}
        />
      ),
      isTab:
        (token &&
          userRoleAccess?.find(
            (data) => data?.userModule === "order" && data?.view === true,
          )) ||
        adminUser,
      title: `Order`,
      href: "/admin/order",
      count: pendingOrderCount,
    },
    {
      path:
        location?.pathname === "/admin/category" ||
        location?.pathname === "/admin/subCategory" ||
        location?.pathname === "/admin/brand" ||
        location?.pathname === "/admin/mfi" ||
        location?.pathname === "/admin/grade",
      icon: (
        <CategoryOutlinedIcon
          color={
            location?.pathname === "/admin/category" ? "primary" : "inherit"
          }
        />
      ),
      title: "Material",
      id: 3,
      secondary: "Material, Material Type, Brands, MFI, Grade",
      isOption: true,
      isTab:
        (token &&
          userRoleAccess?.find(
            (data) =>
              data?.userModule === "category" ||
              data?.userModule === "subCategory" ||
              data?.userModule === "mfi" ||
              data?.userModule === "grade" ||
              (data?.userModule === "brand" && data?.view === true),
          )) ||
        adminUser,
      options: [
        {
          path: location?.pathname === "/admin/category",
          name: "Material",
          href: "/admin/category",
        },
        {
          path: location?.pathname === "/admin/subCategory",
          name: "Material Type",
          href: "/admin/subCategory",
        },
        {
          path: location?.pathname === "/admin/brand",
          name: "Brand",
          href: "/admin/brand",
        },
        {
          path: location?.pathname === "/admin/mfi",
          name: "MFI",
          href: "/admin/mfi",
        },
        {
          path: location?.pathname === "/admin/grade",
          name: "Grade",
          href: "/admin/grade",
        },
      ],
    },

    {
      path:
        location?.pathname === "/admin/product" ||
        location?.pathname === "/admin/selling-product",
      icon: (
        <Inventory2OutlinedIcon
          color={
            location?.pathname === "/admin/product" ? "primary" : "inherit"
          }
        />
      ),
      title: "Product",
      id: 4,
      secondary: "Product, Selling Product",
      isOption: true,
      isTab:
        (token &&
          userRoleAccess?.find(
            (data) =>
              data?.userModule === "product" ||
              (data?.userModule === "seller" && data?.view === true),
          )) ||
        adminUser,
      options: [
        {
          path: location?.pathname === "/admin/product",
          name: "Product",
          href: "/admin/product",
        },
        {
          path: location?.pathname === "/admin/selling-product",
          name: "Selling Product",
          href: "/admin/selling-product",
        },
      ],
    },
    {
      path: location?.pathname === "/admin/buyer-enquiry",
      icon: (
        <StoreOutlinedIcon
          color={
            location?.pathname === "/admin/buyer-enquiry"
              ? "primary"
              : "inherit"
          }
        />
      ),
      isTab:
        (token &&
          userRoleAccess?.find(
            (data) => data?.userModule === "seller" && data?.view === true,
          )) ||
        adminUser,
      title: `Buyer Enquiry`,
      href: "/admin/buyer-enquiry",
      count: unreadEnquiry,
    },
    {
      path: location?.pathname === "/admin/transport-charge",
      icon: (
        <GradeOutlinedIcon
          color={
            location?.pathname === "/admin/transport-charge"
              ? "primary"
              : "inherit"
          }
        />
      ),
      isTab:
        (token &&
          userRoleAccess?.find(
            (data) =>
              data?.userModule === "transport-charge" && data?.view === true,
          )) ||
        adminUser,
      title: "Transport Charge",
      href: "/admin/transport-charge",
    },
    {
      path: location?.pathname === "/admin/user-management",
      icon: (
        <PersonAddAltOutlinedIcon
          color={
            location?.pathname === "/admin/user-management"
              ? "primary"
              : "inherit"
          }
        />
      ),
      isTab: adminUser,
      title: "User Management",
      href: "/admin/user-management",
    },
    {
      path: location?.pathname === "/admin/location",
      icon: (
        <LocationOnIcon
          color={
            location?.pathname === "admin/location" ? "primary" : "inherit"
          }
        />
      ),
      isTab:
        (token &&
          userRoleAccess?.find(
            (data) => data?.userModule === "location" && data?.view === true,
          )) ||
        adminUser,
      title: "Location",
      href: "/admin/location",
    },
    {
      path: location?.pathname === "/login",
      icon: (
        <LogoutOutlinedIcon
          color={location?.pathname === "/login" ? "primary" : "inherit"}
        />
      ),
      isTab: true,
      title: "Logout",
      href: "/login",
    },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);

  // IGWDZH4AZEADFHNO35WDR7PUTQRTIHOE
  {
    /* //! notification and language for future use */
  }
  // const [language, setLanguage] = React.useState("en");
  // const handleChange = (event) => {
  //   setLanguage(event.target.value);
  // };
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const [openOptions, setOpenOptions] = React.useState(0);
  return (
    <Box className="main-layout-wrapper">
      <CssBaseline />
      <AppBar position="fixed" open={open} className="perent-navbar-wrapper">
        <Toolbar className="toolbar-wrapper">
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            className="icon-button"
          >
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <Box className="navbar-wrapper">
            {/* //! notification and language for future use */}
            {/* <Typography>
              <Notification />
            </Typography>
            <Typography>
              <FormControl variant="standard" className="form-wrapper">
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={language}
                  onChange={handleChange}
                  label="language"
                  style={{ borderBlockColor: "gray" }}
                >
                  <MenuItem value={"en"}>English</MenuItem>
                </Select>
              </FormControl>
            </Typography> */}
            <Typography variant="h6" noWrap component="div">
              <ButtonBase
                sx={{
                  p: 0.25,
                  bgcolor: "transparent",
                  borderRadius: 1,
                  "&:hover": { bgcolor: "secondary.lighter" },
                }}
                aria-label="open profile"
                aria-controls={open ? "profile-grow" : undefined}
                aria-haspopup="true"
              >
                <Box
                  onClick={() => navigate("/admin/setting/profile")}
                  color="inherit"
                  sx={{
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{ p: 0.5, textDecoration: "none" }}
                  >
                    <Avatar
                      alt="profile user"
                      src={avatar1}
                      sx={{ width: 32, height: 32 }}
                    />
                    {isMobile && (
                      <Typography
                        variant="subtitle1"
                        sx={{ textDecoration: "none" }}
                      >
                        {user?.user?.firstName}
                      </Typography>
                    )}
                  </Stack>
                </Box>
              </ButtonBase>
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Box className="drawer-main">
          <DrawerHeader className="drawer-header">Crystal</DrawerHeader>
          <Divider />
          <List>
            {adminData?.map((item) => (
              <ListItem
                key={item?.title}
                disablePadding
                sx={{ display: "block" }}
              >
                {item?.isTab && (
                  <>
                    <ListItemButton
                      href={item?.title === "Logout" && item?.href}
                      onClick={() => {
                        if (item?.title === "Logout") {
                          cookie.remove("token");
                        } else {
                          navigate(item?.href);
                        }
                        if (item?.isOption) {
                          setOpenOptions(
                            openOptions === item?.id ? 0 : item?.id,
                          );
                        }
                      }}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        borderRight:
                          item?.path && "2px solid var(--primary-color)",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item?.title}
                        // primary="Parties"
                        secondary={item?.secondary}
                        secondaryTypographyProps={{
                          noWrap: true,
                          width: "90%",
                          // textOverflow: "ellipsis",
                          fontSize: 12,
                          lineHeight: "16px",
                          // color: open ? "rgba(0,0,0,0)" : "rgba(255,255,255,0.5)",
                          display:
                            openOptions === item?.id ? "none" : "inline-block",
                        }}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: item?.path
                            ? "var(--primary-color)"
                            : "var(--gray-color)",
                        }}
                      />
                      {item?.count && item?.count != 0 ? ( // ! for future use
                        <Typography
                          variant="caption"
                          sx={{
                            color: "white",
                            backgroundColor: "var(--primary-color)",
                            fontWeight: "medium",
                            padding: "8px",
                            borderRadius: "20%",
                          }}
                        >
                          {item?.count}
                        </Typography>
                      ) : null}
                      {item?.isOption && (
                        <KeyboardArrowDown
                          sx={{
                            mr: -1,
                            transform:
                              openOptions === item?.id
                                ? "rotate(-180deg)"
                                : "rotate(0)",
                            transition: "0.2s",
                            color: "var(--gray-color)",
                          }}
                        />
                      )}
                    </ListItemButton>
                    {openOptions === item?.id &&
                      item?.options?.map((data) => {
                        return (
                          <ListItemButton
                            onClick={() => navigate(data?.href)}
                            // href={item?.href}
                            key={data?.name}
                            sx={{
                              py: 1,
                              px: 5,
                              minHeight: 32,
                              borderRight:
                                data?.path && "1px solid var(--primary-color)",
                              backgroundColor:
                                data?.path && "var(--light-gray-color)",
                              color: data?.path
                                ? "var(--primary-color)"
                                : "var(--gray-color)",
                            }}
                          >
                            <ListItemText
                              primary={data?.name}
                              primaryTypographyProps={{
                                fontSize: 14,
                                fontWeight: "medium",
                              }}
                            />{" "}
                          </ListItemButton>
                        );
                      })}
                  </>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        className={isMobile ? "children-box" : "mobile-children-box"}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
