import PropTypes from "prop-types";
import "./expenseForm.css";

import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
  IconButton,
  InputAdornment,
} from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../../components/@extended/button/ButtonComponent";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useRef } from "react";
import { useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import BasicDatePicker from "../../../components/@extended/datepicker/DatePicker";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { EXPENSE, UPDATE_EXPENSE } from "../../../actions/expenseActions";
import { LIST_EXPENSE_CATEGORY } from "../../../actions/expenseCategoryActions";

const ExpenseForm = (props) => {
  const { id, singleData, toggleDrawer } = props;
  // const [show, setShow] = useState(false);
  const [optionOpen, setOptionOpen] = useState(false);
  const [Expense, setExpense] = useState();
  const [userText, setUserText] = useState();
  const [optionOpen2, setOptionOpen2] = useState(false);
  const [user, setUser] = useState([]);
  const [mainUser, setMainUser] = useState([]);
  const handleChange2 = (e) => {
    const event = e.target.value;

    const filterData = user?.filter((data) =>
      data?.name?.toLowerCase()?.startsWith(event?.toLowerCase()),
    );
    setMainUser(filterData);
  };
  const listDataExpenseCategory = useSelector(
    (state) => state?.expenseCategory?.listExpenseCategory?.results,
  );

  // list expense categories
  const listExpenseCategory = useCallback((data) => {
    dispatch({ type: LIST_EXPENSE_CATEGORY, payload: data });
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();

  //add Expense
  const addExpense = useCallback((data) => {
    dispatch({ type: EXPENSE, payload: data });
  }, []);

  //update Expense
  const updateExpense = useCallback((data) => {
    dispatch({ type: UPDATE_EXPENSE, payload: data });
  }, []);

  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // date picker
  const [value, setValue] = useState(dayjs());
  const handleDateChange = (date) => {
    setValue(date);
  };

  useEffect(() => {
    listExpenseCategory();
  }, []);

  useEffect(() => {
    setUser(listDataExpenseCategory);
    setMainUser(listDataExpenseCategory);
  }, [listDataExpenseCategory]);

  return (
    <Box className={isMobile ? "expense-main-div" : "expense-form-box"}>
      <Formik
        initialValues={{
          expenseCategory: id ? singleData?.expenseCategory : "",
          amount: id ? singleData?.amount : "",
          date: id ? singleData?.date : "",
          user: id ? singleData?.user : "",
          expenseBill: id ? singleData?.expenseBill : "",
        }}
        validationSchema={Yup.object().shape({
          expenseCategory: Yup.string()
            .max(255)
            .required(" expense category Name is required"),
          amount: Yup.string().max(255).required("amount is required"),
          date: Yup.string().max(255).required("Date is required"),
          user: Yup.string(),
          expenseBill: Yup.string(),
        })}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container>
                <Grid item xs={12} md={6} className="expense-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">
                      Expense Category Name*
                    </InputLabel>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      gap={"5px"}
                    >
                      <Box>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          hiddenLabel
                          size="small"
                          value={Expense?.name}
                          name="expenseCategory"
                          className="expense-category-name"
                          onChange={handleChange2}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  edge="end"
                                  onClick={() => setOptionOpen(!optionOpen)}
                                >
                                  {optionOpen ? (
                                    <SearchIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {console.log("Loading", mainUser)}
                        {optionOpen && (
                          <Box className="expense-dropdown-wrapper">
                            {mainUser?.map((data1, ind) => {
                              return (
                                <Box
                                  key={ind}
                                  className="expense-user-search-item"
                                >
                                  <Box
                                    className="expense-user-search-item-text"
                                    onClick={() => {
                                      setExpense(data1);
                                      setOptionOpen(false);
                                    }}
                                  >
                                    {data1?.name}
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        )}
                        {/* )} */}
                      </Box>
                      <Box
                        className="add-user-icon"
                        onClick={toggleDrawer("right", true)}
                      >
                        <AddIcon />
                      </Box>
                    </Box>
                    {touched?.name && errors?.name && (
                      <FormHelperText error id="helper-text-name">
                        {errors?.name}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} className="expense-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="user">User</InputLabel>
                    <Box display={"flex"} gap={"5px"}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        hiddenLabel
                        size="small"
                        value={userText?.name}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                edge="end"
                                onClick={() => setOptionOpen2(!optionOpen2)}
                              >
                                {optionOpen2 ? (
                                  <SearchIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {optionOpen2 && (
                        <Box className="expense-dropdown-wrapper">
                          {mainUser?.map((data2, ind) => {
                            return (
                              <Box
                                key={ind}
                                className="expense-user-search-item"
                              >
                                <Box
                                  className="expense-user-search-item-text"
                                  onClick={() => {
                                    setUserText(data2);
                                    setOptionOpen2(false);
                                  }}
                                >
                                  {data2?.name}
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                      <Box
                        className="add-user-icon"
                        onClick={toggleDrawer("right", true)}
                      >
                        <AddIcon />
                      </Box>
                    </Box>
                    {touched.name && errors.name && (
                      <FormHelperText error id="helper-text-name">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} className="expense-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="date">Date</InputLabel>
                    <Box>
                      <BasicDatePicker
                        value={values?.date}
                        name="date"
                        setValue={setValue}
                        handleDateChange={handleDateChange}
                      />
                    </Box>
                    {/* {touched.date && errors.date && (
                      <FormHelperText error id="helper-text-name">
                        {errors.date}
                      </FormHelperText>
                    )} */}
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6} className="expense-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="amount">Amount</InputLabel>
                    <OutlinedInput
                      id="amount"
                      fullWidth
                      value={values?.amount}
                      name="amount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter amount"
                      error={Boolean(touched.amount && errors.amount)}
                    />
                    {touched.amount && errors.amount && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors.amount}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} className="expense-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="amount">Expense Bill</InputLabel>
                    <Box>
                      <ButtonComponent
                        name="Upload"
                        isName={true}
                        Icon={FileUploadOutlinedIcon}
                        className="background-white"
                        onClick={handleButtonClick}
                      />
                    </Box>
                    <Box className="image-child">
                      <label>
                        <input
                          type="file"
                          name={values?.expenseBill}
                          id={values?.expenseBill}
                          value={values?.expenseBill}
                          className="expense-input-file-hide"
                          accept="image/*"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                        />
                      </label>
                    </Box>
                    {touched.logo && errors.logo && (
                      <FormHelperText error id="helper-text-logo">
                        {errors.logo}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="Note">Notes</InputLabel>
                    <TextField
                      multiline
                      rows={4}
                      id="note"
                      fullWidth
                      value={values?.note}
                      name="note"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter notes"
                      error={Boolean(touched.note && errors.note)}
                    />
                    {touched.note && errors.note && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors.note}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box className="expense-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={id ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
                onClick={(e) => {
                  if (id) {
                    updateExpense({ ...values, id: id });
                  } else {
                    addExpense({
                      ...values,
                      expenseCategory: Expense?.id,
                      user: userText?.id,
                      branch: userText?.id,
                      date: new Date(value)?.toISOString(),
                    });
                  }
                  toggleDrawer("right", false)(e);
                }}
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                // onClick={onClick}
                onClick={toggleDrawer("right", false)}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
ExpenseForm.propTypes = {
  // onClick: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
  toggleDrawer: PropTypes.any,
};

export default ExpenseForm;
