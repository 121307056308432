import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ButtonComponent from "../../../components/@extended/button/ButtonComponent";
import "./expense.css";
import SelectWithSearch from "../../../components/@extended/selectwithsearch/SelectWithSearch";
import AddIcon from "@mui/icons-material/Add";
import CommonFormDrawer from "../../../components/@extended/drawer/CommonFormDrawer";
import CommonTab from "./../../../components/@extended/tabs/CommonTab";
// import SettingsIcon from "@mui/icons-material/Settings";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ExpenseForm from "./ExpenseForm";
import DeleteModal from "../../../components/@extended/deletemodal/DeleteModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../../components/@extended/table/CommonTable";
import {
  DELETE_EXPENSE,
  LIST_EXPENSE,
  LIST_SINGLE_EXPENSE,
} from "../../../actions/expenseActions";

const Expense = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const detailsList = useSelector(
    (data) => data?.expense?.listExpense?.results,
  );
  // listExpense
  const listExpense = useCallback((values) => {
    dispatch({ type: LIST_EXPENSE, payload: values });
  }, []);

  // single expense api call
  const singleListExpense = useCallback((data) => {
    dispatch({ type: LIST_SINGLE_EXPENSE, payload: data });
  }, []);

  //deleteExpense
  const deleteExpense = useCallback((id) => {
    dispatch({ type: DELETE_EXPENSE, payload: id });
  }, []);

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (right, open, params) => (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [right]: open });
    setId(params?.row?.rowId);
  };
  const isDeleteSuccess = useSelector(
    (data) => data?.expense?.isDeleteExpenseSuccess,
  );
  const isAddSuccess = useSelector(
    (data) => data?.expense?.isAddExpenseSuccess,
  );
  const isUpdateSuccess = useSelector(
    (data) => data?.expense?.isUpdateExpenseSuccess,
  );

  useEffect(() => {
    listExpense();
    if (id) {
      singleListExpense(id);
    }
    if (isDeleteSuccess) {
      listExpense();
    }
    if (isAddSuccess) {
      listExpense();
    }
    if (isUpdateSuccess) {
      listExpense();
    }
  }, [isAddSuccess, isDeleteSuccess, isUpdateSuccess, id]);
  const [deleteModal, setDeleteModal] = useState(false);
  function formatDateToDDMMYYYY(isoDate) {
    const date = new Date(isoDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  const rows = detailsList?.map((item, ind) => {
    return {
      id: ind + 1,
      name: item?.expenseCategory?.name,
      amount: item?.amount,
      date: item?.date ? formatDateToDDMMYYYY(item?.date) : "-",
      actions: "",
      rowId: item?.id,
    };
  });

  const columns = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   flex: 1,
    //   headerClassName: "expense-header",
    // },
    {
      field: "name",
      headerName: "Expense Category",
      flex: 1,
      headerClassName: "expense-header",
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      headerClassName: "expense-header",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerClassName: "expense-header",
    },
    // --------Todo:- extra -------\\
    // {
    //   field: "online",
    //   headerName: "Online Store",
    //   sortable: false,
    //   flex: 1,
    //   headerClassName: "expense-header",
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Box className="expense-space">
    //           <Box>
    //             <Switch
    //               checked={params.row.checked}
    //               onChange={() => {
    //                 const updatedRows = currentRows?.map((row) => {
    //                   if (row?.id === params?.row?.id) {
    //                     return {
    //                       ...row,
    //                       checked: !row?.checked,
    //                     };
    //                   }
    //                   return row;
    //                 });
    //                 setCurrentRows(updatedRows);
    //               }}
    //             />
    //             {params?.row.online && (
    //               <Typography>View Online Store</Typography>
    //             )}
    //           </Box>
    //         </Box>
    //       </>
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "expense-header",
      flex: 1,
      // id: 6,
      renderCell: (params) => {
        return (
          <Box className="table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              onClick={toggleDrawer("right", true, params)}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => {
                setDeleteModal(true);
                setId(params?.row?.rowId);
              }}
            />
          </Box>
        );
      },
    },
  ];
  const [heading, setHeading] = React.useState("en");
  const handleChange = (event) => {
    setHeading(event.target.value);
  };

  const commonTabData = [
    {
      label: " Basic Details",
      value: "1",
      icon: <InsertDriveFileIcon />,
      component: (
        <ExpenseForm
          toggleDrawer={toggleDrawer}
          id={id}
          // listData={!id ? [] : detailsExpense}
        />
      ),
    },
    // ---------todo:- pending ---------- //
    // {
    //   label: "Visibility",
    //   value: "2",
    //   icon: <VisibilityIcon />,
    //   component: <Visibility toggleDrawer={toggleDrawer} />,
    // },
    // {
    //   label: "POS Settingss",
    //   value: "2",
    //   icon: <SettingsIcon />,
    //   component: <WareHousePosSetting toggleDrawer={toggleDrawer} />,
    // },
  ];

  return (
    <Box className="expense-box">
      <Box className="expense-box-main">
        <ButtonComponent
          name="Add New Expense"
          isName={true}
          Icon={AddIcon}
          onClick={toggleDrawer("right", true)}
        />
        <SelectWithSearch
          handleChange={handleChange}
          heading={heading}
          optionData={columns}
        />
      </Box>
      <Box className="expense-common-table-box-main">
        <Box className="expense-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
          />
        </Box>
      </Box>

      {toggleDrawer && (
        <CommonFormDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          heading={id ? "Edit Expense" : "Add New Expense"}
          width="1000px"
          childrenComponent={<CommonTab data={commonTabData} />}
        />
      )}
      {deleteModal && (
        <DeleteModal
          id={id}
          isIcon={true}
          Icon={ErrorOutlineOutlinedIcon}
          open={deleteModal}
          onClick={() => setDeleteModal(false)}
          deleteData={deleteExpense}
        />
      )}
    </Box>
  );
};

export default Expense;
