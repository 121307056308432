import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CommonTable from "../../@extended/table/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import { TRANSPORT_CHARGE_LIST } from "../../../actions/productActions";

const TransportChargeTable = (props) => {
  const { columns, search } = props;
  const dispatch = useDispatch();

  const transportChargeListData = useSelector(
    (data) => data?.product?.transportChargeList,
  );
  const isTransportChargeSuccess = useSelector(
    (data) => data?.product?.isTransportChargeSuccess,
  );

  const { totalResults = 0, limit, page } = transportChargeListData;
  const [limitState, setLimit] = useState(10);
  const [pageState, setPage] = useState(1);

  const transportChargeList = useCallback((values) => {
    dispatch({ type: TRANSPORT_CHARGE_LIST, payload: values });
  }, []);

  const rows = transportChargeListData?.results?.map((item, ind) => {
    return {
      id: ind + 1,
      no: ind + 1,
      fromLocation: item?.fromLocation,
      toLocation: item?.toLocation,
      rate: item?.rate,
      actions: "",
      rowId: item?.id,
    };
  });

  useEffect(() => {
    if (transportChargeListData?.limit) {
      setLimit(transportChargeListData?.limit);
    }
    if (transportChargeListData?.page) {
      setPage(transportChargeListData?.page);
    }
  }, [transportChargeListData?.limit, transportChargeListData?.page]);

  useEffect(() => {
    transportChargeList({ limitState, pageState, search });
  }, [limitState, pageState, isTransportChargeSuccess, search]);

  return (
    <Box className="email-query-box">
      <Box className="email-query-common-table-box-main">
        <Box className="email-query-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
            pageData={{ limit, page, totalResults, setLimit, setPage }}
          />
        </Box>
      </Box>
    </Box>
  );
};
TransportChargeTable.propTypes = {
  columns: PropTypes.any,
  type: PropTypes.any,
  search: PropTypes.any,
  setDeleteModal: PropTypes.any,
};
export default TransportChargeTable;
