export const ORDER_LIST = "ORDER_LIST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const ORDER_ADD = "ORDER_ADD";
export const ORDER_ADD_SUCCESS = "ORDER_ADD_SUCCESS";
export const ORDER_ADD_FAIL = "ORDER_ADD_FAIL";

export const ORDER_SINGLE = "ORDER_SINGLE";
export const ORDER_SINGLE_SUCCESS = "ORDER_SINGLE_SUCCESS";
export const ORDER_SINGLE_FAIL = "ORDER_SINGLE_SUCCESS";

export const ORDER_SINGLE_UPDATE = "ORDER_SINGLE_UPDATE";
export const ORDER_SINGLE_UPDATE_SUCCESS = "ORDER_SINGLE_UPDATE_SUCCESS";
export const ORDER_SINGLE_UPDATE_FAIL = "ORDER_SINGLE_UPDATE_FAIL";

export const ORDER_UPDATE = "ORDER_UPDATE";
export const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS";
export const ORDER_UPDATE_FAIL = "ORDER_UPDATE_FAIL";

export const CONFIRM_ORDER = "CONFIRM_ORDER";
export const CONFIRM_ORDER_SUCCESS = "CONFIRM_ORDER_SUCCESS";
export const CONFIRM_ORDER_FAIL = "CONFIRM_ORDER_FAIL";

export const ORDER_PAYMENT = "ORDER_PAYMENT";
export const ORDER_PAYMENT_SUCCESS = "ORDER_PAYMENT_SUCCESS";
export const ORDER_PAYMENT_FAIL = "ORDER_PAYMENT_FAIL";

export const ORDER_STATUS = "ORDER_STATUS";
export const ORDER_STATUS_SUCCESS = "ORDER_STATUS_SUCCESS";
export const ORDER_STATUS_FAIL = "ORDER_STATUS_FAIL";
