import { Box } from "@mui/material";
import React, { useState } from "react";
import BreadCrumb from "../../@extended/breadcrumbs/Breadcrumbs";
import SelectWithSearch from "../../@extended/selectwithsearch/SelectWithSearch";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CommonTable from "../../@extended/table/CommonTable";
import DeleteModal from "../../@extended/deletemodal/DeleteModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import "./../transactions/transactions.css";

const Transactions = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [heading, setHeading] = React.useState("");
  const handleChange = (event) => {
    setHeading(event.target.value);
  };

  // const detailsList = useSelector(
  //   (data) => data?.expense?.listExpense?.results
  // );

  // list
  // const listExpense = useCallback((values) => {
  //   dispatch({ type: LIST_EXPENSE, payload: values });
  // }, []);

  const rows = [
    // {
    //   id: 1,
    //   date: "",
    //   company: "",
    //   transacationId: "",
    //   nextPaymentDate: "",
    //   paymentMethod: "",
    //   actions: "",
    // },
  ];

  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerClassName: "transactions-header",
    },
    {
      field: "company",
      headerName: "Company",
      flex: 1,
      headerClassName: "transactions-header",
    },
    {
      field: "transacationId",
      headerName: "Transacation ID",
      flex: 1,
      headerClassName: "transactions-header",
    },
    {
      field: "nextPaymentDate",
      headerName: "Next Payment Date",
      flex: 1,
      headerClassName: "transactions-header",
    },
    {
      field: "paymentMethod",
      headerName: "Payment Method",
      flex: 1,
      headerClassName: "transactions-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "transactions-header",
      flex: 1,
      id: 6,
      renderCell: () => {
        return (
          <Box className="transactions-table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => {
                setDeleteModal(true);
              }}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      {/* <NotifyMessage
        heading="Trial Plan"
        description="You are on a trial version! Your trial ends on 24-02-2024"
        backgroundColor="var(--notify-bg-color)"
        borderColor="var(--notify-border-color)"
        className="btn-change-plan"
      /> */}
      <BreadCrumb
        title="Transactions"
        perentTabName="Transactions"
        // childTabName="Expense"
        // openMenuClick={toggleDrawer("bottom", true)}
      />
      <Box className="transactions-box">
        <Box className="transactions-box-main">
          <SelectWithSearch
            handleChange={handleChange}
            heading={heading}
            optionData={columns}
          />
        </Box>
        <Box className="transactions-common-table-box-main">
          <Box className="transactions-common-table-box">
            <CommonTable
              columns={columns}
              rows={rows?.length > 0 ? rows : []}
              ischeck={false}
              isPagination={true}
            />
          </Box>
        </Box>
        {deleteModal && (
          <DeleteModal
            isIcon={true}
            Icon={ErrorOutlineOutlinedIcon}
            open={deleteModal}
            onClick={() => {
              setDeleteModal(false);
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Transactions;
