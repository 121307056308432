import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import CommonTable from "../../../@extended/table/CommonTable";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ButtonComponent from "../../../@extended/button/ButtonComponent";
import "./addUser.css";
import AddIcon from "@mui/icons-material/Add";
import CustomizedModal from "../../../@extended/customizedmodal/CustomizedModal";
import DeleteModal from "../../../@extended/deletemodal/DeleteModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import AddUserForm from "./AddUserForm";
import { useDispatch, useSelector } from "react-redux";
import {
  DELETE_ROLE_USER,
  LIST_ROLE_USER,
} from "../../../../actions/roleUserActions";

const AddUser = () => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();

  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );

  const isDeleteSuccess = useSelector(
    (data) => data?.roleUser?.isDeleteRoleUserSuccess,
  );
  const isAddSuccess = useSelector((data) => data?.roleUser?.isSuccess);
  const isUpdateSuccess = useSelector(
    (data) => data?.roleUser?.isUpdataRoleUserSuccess,
  );
  const detailsRoleuser = useSelector(
    (data) => data?.roleUser?.listRoleUser?.results,
  );
  //   // list user
  const listRoleUser = useCallback((values) => {
    dispatch({ type: LIST_ROLE_USER, payload: values });
  }, []);

  //   // delete user
  const deleteRoleUser = useCallback((id) => {
    dispatch({ type: DELETE_ROLE_USER, payload: id });
  }, []);
  const rows = detailsRoleuser?.map((item, ind) => {
    return {
      id: ind + 1,
      firstName: item?.firstName,
      lastName: item?.lastName,
      userType: item?.userType,
      actions: "",
      rowId: item?.id,
    };
  });

  const columns = [
    {
      field: "id",
      headerName: "Sr.No",
      flex: 1,
      headerClassName: "rolepermissions-header",
      // width: 870,
    },
    {
      field: "firstName",
      headerName: "	First Name",
      flex: 1,
      headerClassName: "users-header",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      headerClassName: "users-header",
    },
    {
      field: "userType",
      headerName: "User Type",
      flex: 1,
      headerClassName: "users-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "users-header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="users-table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              onClick={() => {
                setOpen(true);
                setId(params?.row?.rowId);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "user" && data?.edit) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => {
                setDeleteModal(true);
                setId(params?.row?.rowId);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "user" && data?.delete) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    listRoleUser();
    if (isAddSuccess) {
      listRoleUser();
    }
    if (isUpdateSuccess) {
      listRoleUser();
    }
    if (isDeleteSuccess) {
      listRoleUser();
    }
  }, [isAddSuccess, isUpdateSuccess, isDeleteSuccess]);
  return (
    <Box className="users-box">
      <Box className="users-box-main">
        <ButtonComponent
          name="Add New user"
          isName={true}
          Icon={AddIcon}
          onClick={() => setOpen(true)}
          disabledbtn={userRoleAccess?.userPermissions?.find(
            (data) =>
              (data?.userModule == "user" && data?.add) ||
              userRoleAccess?.userRoleType == "admin",
          )}
        />
      </Box>
      <Box className="users-common-table-box-main">
        <Box className="users-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={false}
          />
        </Box>
      </Box>
      {open && (
        <CustomizedModal
          isHeadingName={true}
          heading={id ? "Edit user" : "Add New user"}
          open={open}
          onClick={() => {
            setOpen(false);
            setId("");
          }}
        >
          <AddUserForm
            onClick={() => {
              setOpen(false);
              setId("");
            }}
            id={id}
          />
        </CustomizedModal>
      )}
      {deleteModal && (
        <DeleteModal
          id={id}
          deleteData={deleteRoleUser}
          isIcon={true}
          Icon={ErrorOutlineOutlinedIcon}
          open={deleteModal}
          onClick={() => setDeleteModal(false)}
          userModule="user"
        />
      )}
    </Box>
  );
};

export default AddUser;
