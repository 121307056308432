import { Box } from "@mui/material";
import React from "react";
import ExpenseCategories from "../../components/dashboard/expensecategory/ExpenseCategories";
import NotifyMessage from "../../components/@extended/notify/NotifyMessage";
import BreadCrumb from "../../components/@extended/breadcrumbs/Breadcrumbs";

const ExpenseCategoriesPage = () => {
  return (
    <Box>
      <NotifyMessage
        heading="Trial Plan"
        description="You are on a trial version! Your trial ends on 24-02-2024"
        backgroundColor="var(--notify-bg-color)"
        borderColor="var(--notify-border-color)"
        className="btn-change-plan"
      />
      <BreadCrumb
        title="Expense Categories"
        perentTabName="Expenses"
        childTabName="Expense Categories"
        // openMenuClick={toggleDrawer("bottom", true)}
      />
      <ExpenseCategories />
    </Box>
  );
};
export default ExpenseCategoriesPage;
