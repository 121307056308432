import PropTypes from "prop-types";
import "./rolePermissionsForm.css";

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../../@extended/button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  LIST_SINGLE_ROLE,
  ROLE_ADD,
  UPDATE_ROLE,
} from "../../../../actions/roleActions";
import { useCallback, useEffect } from "react";

const RolePermissionsForm = (props) => {
  const { toggleDrawer, id } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));

  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );
  const singleData = useSelector((data) => data?.role?.listSingleRole?.data);
  const singleListRoleData = useCallback((data) => {
    dispatch({ type: LIST_SINGLE_ROLE, payload: data });
  }, []);

  useEffect(() => {
    if (id) {
      singleListRoleData(id);
    }
  }, [id]);

  const roleName = [
    {
      userModule: "seller",
      module: "Seller",
      add: false,
      view: false,
      edit: false,
      delete: false,
    },
    {
      userModule: "warehouse",
      module: "Warehouse",
      add: false,
      view: false,
      edit: false,
      delete: false,
    },
    {
      userModule: "buyer",
      module: "Buyer",
      add: false,
      view: false,
      edit: false,
      delete: false,
    },
    {
      userModule: "order",
      module: "Order",
      add: false,
      view: false,
      edit: false,
      delete: false,
    },
    {
      userModule: "category",
      module: "Category",
      add: false,
      view: false,
      edit: false,
      delete: false,
    },
    {
      userModule: "subCategory",
      module: "Sub Category",
      add: false,
      view: false,
      edit: false,
      delete: false,
    },
    {
      userModule: "brand",
      module: "Brand",
      add: false,
      view: false,
      edit: false,
      delete: false,
    },
    {
      userModule: "grade",
      module: "Grade",
      add: false,
      view: false,
      edit: false,
      delete: false,
    },
    // {
    //   userModule: "sales",
    //   module: "Sales",
    //   add: false,
    //   view: false,
    //   edit: false,
    //   delete: false,
    // },
    {
      userModule: "product",
      module: "Product",
      add: false,
      view: false,
      edit: false,
      delete: false,
    },
    {
      userModule: "location",
      module: "Location",
      add: false,
      view: false,
      edit: false,
      delete: false,
    },
  ];
  const dispatch = useDispatch();

  const addRoleData = useCallback((data) => {
    dispatch({ type: ROLE_ADD, payload: data });
  }, []);

  // edit role

  const editRole = useCallback((data) => {
    dispatch({ type: UPDATE_ROLE, payload: data });
  }, []);

  const RolePermissionsFormSchema = Yup.object().shape({
    // displayName: Yup.string().max(255).required("Display Name is required"),
    name: Yup.string().max(255).required("Role Name is required"),
    rights: Yup.array().of(
      Yup.object().shape({
        userModule: Yup.string().required("User Module is required"),
        add: Yup.boolean(),
        view: Yup.boolean(),
        edit: Yup.boolean(),
        delete: Yup.boolean(),
      }),
    ),
  });
  // const isAddSuccess = useSelector((data) => data?.role?.isSuccess);
  // const isUpdateSuccess = useSelector(
  //   (data) => data?.role?.isUpdateRoleSuccess,
  // );
  return (
    <Box className={isMobile ? "role-main-div" : "role-permissions-form-box"}>
      <Formik
        initialValues={{
          name: id ? singleData?.userRole?.name : "",
          rights: id ? singleData?.userPermission : roleName,
        }}
        validationSchema={RolePermissionsFormSchema}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          // isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Grid container className="role-form-container">
              <Grid
                className={
                  isMobile
                    ? "role-permissions-form-main-grid"
                    : "role-form-main-grid"
                }
              >
                <Grid
                  item
                  xs={12}
                  // md={6}
                  // xl={6}
                  className="role-permissions-form-input"
                >
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">Role Name*</InputLabel>
                    <OutlinedInput
                      id="name"
                      value={values?.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Role Name"
                      fullWidth
                      error={Boolean(touched.name && errors.name)}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error id="helper-text-role">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                className="role-permissions-form-input"
              >
                <Stack spacing={1}>
                  <Typography
                    variant="body2"
                    className="role-permissions-form-description"
                  >
                    Permissions
                  </Typography>
                  {values?.rights?.map((role, ind) => {
                    return (
                      <Grid
                        key={ind}
                        container
                        className="role-permissions-grid-checkbox"
                      >
                        <Grid item xs={4}>
                          <FormLabel
                            component="legend"
                            onChange={handleChange}
                            name="rights"
                          >
                            {id ? role?.userModule : role.module}
                          </FormLabel>
                        </Grid>
                        {Object.prototype.hasOwnProperty.call(role, "add") && (
                          <Grid item xs={2}>
                            <FormControlLabel
                              value="add"
                              control={<Checkbox />}
                              label="Create"
                              checked={role?.add}
                              labelPlacement="end"
                              onChange={(e, val) => {
                                if (val && !role.view) {
                                  setFieldValue(`rights[${ind}].view`, true);
                                }
                                setFieldValue(`rights[${ind}].add`, val);
                              }}
                            />
                          </Grid>
                        )}
                        {Object.prototype.hasOwnProperty.call(role, "view") && (
                          <Grid item xs={2}>
                            <FormControlLabel
                              value="view"
                              control={<Checkbox />}
                              label="Read"
                              checked={role?.view}
                              labelPlacement="end"
                              onChange={(e, val) => {
                                if (!val) {
                                  setFieldValue(`rights[${ind}].add`, false);
                                  setFieldValue(`rights[${ind}].edit`, false);
                                  setFieldValue(`rights[${ind}].delete`, false);
                                }
                                setFieldValue(`rights[${ind}].view`, val);
                              }}
                            />
                          </Grid>
                        )}
                        {Object.prototype.hasOwnProperty.call(role, "edit") && (
                          <Grid item xs={2}>
                            <FormControlLabel
                              value="edit"
                              control={<Checkbox />}
                              label="Update"
                              checked={role?.edit}
                              labelPlacement="end"
                              onChange={(e, val) => {
                                if (val && !role.view) {
                                  setFieldValue(`rights[${ind}].view`, true);
                                }
                                setFieldValue(`rights[${ind}].edit`, val);
                              }}
                            />
                          </Grid>
                        )}
                        {Object.prototype.hasOwnProperty.call(
                          role,
                          "delete",
                        ) && (
                          <Grid item xs={2}>
                            <FormControlLabel
                              value="delete"
                              control={<Checkbox />}
                              label="Delete"
                              checked={role?.delete}
                              labelPlacement="end"
                              onChange={(e, val) => {
                                if (val && !role.view) {
                                  setFieldValue(`rights[${ind}].view`, true);
                                }
                                setFieldValue(`rights[${ind}].delete`, val);
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}
                </Stack>
              </Grid>
            </Grid>
            <Box className="common-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={id ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
                onClick={(e) => {
                  const rights = values?.rights?.map((data) => {
                    delete data?.module;
                    return data;
                  });
                  if (id) {
                    editRole({ ...values, rights, id: id });
                  } else {
                    addRoleData({ ...values, rights });
                  }
                  // if (isUpdateSuccess || isAddSuccess) {
                  toggleDrawer("right", false)(e);
                  // }
                }}
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "role" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
              <Box className="common-form-drawer-btn-cancel">
                <ButtonComponent
                  name="Cancel"
                  isName={true}
                  className="background-white"
                  onClick={toggleDrawer("right", false)}
                  disabledbtn={userRoleAccess?.userPermissions?.find(
                    (data) =>
                      data?.userModule == "role" ||
                      userRoleAccess?.userRoleType == "admin",
                  )}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
RolePermissionsForm.propTypes = {
  toggleDrawer: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
  // isAddSuccess: PropTypes.any,
  // isUpdateSuccess: PropTypes.any,
};

export default RolePermissionsForm;
