import { useState } from "react";
import "./../../pos/addcustomer/addNewCustomerForm.css";

import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import UploadFile from "./../../../@extended/uploadfile/UploadFile";
import ButtonComponent from "./../../../@extended/button/ButtonComponent";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import AddIcon from "@mui/icons-material/Add";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CommonFormDrawer from "../../../@extended/drawer/CommonFormDrawer";
import CommonTab from "../../../@extended/tabs/CommonTab";
import WareHouseForm from "./../../settings/warehouse/warehouseform/WareHouseForm";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PropTypes from "prop-types";

const AddNewCustomerForm = ({ toggleDrawer }) => {
  const [show, setShow] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showDrawer, setShowDrawer] = useState({
    right: false,
  });

  const toggleDrawer1 = (right, open) => (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }

    setShowDrawer({ ...showDrawer, [right]: open });
  };
  const commonTabData = [
    {
      label: " Basic Details",
      value: "1",
      icon: <InsertDriveFileIcon />,
      component: <WareHouseForm toggleDrawer={toggleDrawer1} />,
    },
    {
      label: "Visibility",
      value: "2",
      icon: <VisibilityIcon />,
      component: "Pending",
    },
    {
      label: "POS Settingss",
      value: "3",
      icon: <SettingsIcon />,
      component: "Pending",
    },
  ];

  return (
    <Box
      className={
        isMobile ? "main-div-add-customer" : "mobile-main-div-add-customer"
      }
    >
      <Formik
        initialValues={{
          logo: "",
          warehouse: "",
          name: "",
          email: "",
          phone: "",
          status: "",
          password: "",
          taxNumber: "",
          openBalance: "",
          creditPeriod: "",
          creditLimit: "",
          address: "",
          shippingAddress: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("First Name is required"),
          warehouse: Yup.string().required("required"),
          phone: Yup.number().required("Phone Number is required"),
          status: Yup.string(),
        })}
        onSubmit={(values) => {
          console.log("valuesAdd==>", values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          // isSubmitting,
          touched,
          values,
        }) => (
          <div className="add-customer-houseform-div">
            <Form noValidate onSubmit={handleSubmit}>
              <Grid container className="add-customer-form-container">
                <Grid
                  item
                  xs={12}
                  className="add-customer-houseform-main-input"
                >
                  <Stack spacing={1}>
                    <UploadFile
                      name="logo"
                      value={values?.logo}
                      className="custom-file-upload"
                      label="Profile Image"
                      inputClassName="input-file-hide"
                      show={show}
                    />
                    {touched.logo && errors.logo && (
                      <FormHelperText error id="helper-text-logo">
                        {errors.logo}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="add-customer-houseform-main-input"
                >
                  <InputLabel htmlFor="name" sx={{ paddingBottom: "8px" }}>
                    Warehouse*
                  </InputLabel>
                  <Box display={"flex"} gap={"5px"}>
                    <Box width={"100%"}>
                      <Select
                        fullWidth
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name="warehouse"
                        value={values?.warehouse}
                        onChange={handleChange}
                        style={{ borderBlockColor: "gray" }}
                      >
                        <MenuItem value={"electronifly"}>Electronifly</MenuItem>
                      </Select>
                    </Box>
                    <Box
                      className="add-customer-icon"
                      onClick={toggleDrawer1("right", true)}
                    >
                      <AddIcon />
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  className={
                    isMobile
                      ? "add-customer-houseform-main-grid"
                      : "add-customer-mobile-form-main-grid"
                  }
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="add-customer-houseform-main-input"
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="name">Name*</InputLabel>
                      <OutlinedInput
                        id="name"
                        type="name"
                        value={values.name}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Please Enter Name"
                        fullWidth
                        error={Boolean(touched.name && errors.name)}
                      />
                      {touched.name && errors.name && (
                        <FormHelperText error id="helper-text-name">
                          {errors.name}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="add-customer-houseform-main-input"
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="slug">Phone Number*</InputLabel>
                      <OutlinedInput
                        id="phone"
                        value={values.phone}
                        name="phone"
                        onChange={handleChange}
                        placeholder="Please Enter phone number"
                        fullWidth
                        error={Boolean(touched.phone && errors.phone)}
                      />
                      {touched.phone && errors.phone && (
                        <FormHelperText error id="helper-text-phone">
                          {errors.phone}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  className={
                    isMobile
                      ? "add-customer-houseform-main-grid"
                      : "add-customer-mobile-form-main-grid"
                  }
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="add-customer-houseform-main-input"
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        id="email"
                        type="email"
                        value={values.email}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="demo@company.com"
                        inputProps={{}}
                      />
                      {touched.email && errors.email && (
                        <FormHelperText error id="helper-text-email">
                          {errors.email}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="add-customer-houseform-main-input"
                  >
                    <Box className="add-customer-select-wrapper">
                      <InputLabel htmlFor="email" className="add-label-div">
                        Status*
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name="status"
                        value={values?.status}
                        onChange={handleChange}
                        style={{ borderBlockColor: "gray" }}
                      >
                        <MenuItem value={"enable"}>Enable</MenuItem>
                        <MenuItem value={"disable"}>Disable</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="add-customer-houseform-main-input"
                >
                  <Stack spacing={1}>
                    <InputLabel htmlFor="password-signup">Password*</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.password && errors.password)}
                      id="password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      name="password"
                      onBlur={handleBlur}
                      placeholder="Please Enter Password"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{}}
                    />
                    {touched.password && errors.password && (
                      <FormHelperText error id="helper-text-password">
                        {errors.password}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="add-customer-houseform-main-input"
                >
                  <Stack spacing={1}>
                    <InputLabel htmlFor="taxNumber">Tax Number</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.taxNumber && errors.taxNumber)}
                      id="taxNumber"
                      value={values.taxNumber}
                      name="taxNumber"
                      onChange={handleChange}
                    />
                    {touched.taxNumber && errors.taxNumber && (
                      <FormHelperText error id="helper-text-taxNumber">
                        {errors.taxNumber}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="add-customer-houseform-main-input"
                >
                  <Box className="add-customer-select-wrapper">
                    <InputLabel htmlFor="email" className="add-label-div">
                      Opening Balance
                    </InputLabel>

                    <Box display={"flex"} width={"100%"}>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name="openBalance"
                        value={values?.openBalance}
                        sx={{ borderRadius: "4px 0px 0px 4px" }}
                        onChange={handleChange}
                        style={{ borderBlockColor: "gray", width: "15%" }}
                      >
                        <MenuItem value={"receive"}>Receive</MenuItem>
                        <MenuItem value={"pay"}>Pay</MenuItem>
                      </Select>
                      <TextField
                        name="balance"
                        value={values?.balance}
                        onChange={handleChange}
                        fullWidth
                        id="outlined-adornment-amount"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start">
                                <AttachMoneyIcon fontSize="20px" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  className={
                    isMobile
                      ? "add-customer-houseform-main-grid"
                      : "add-customer-mobile-form-main-grid"
                  }
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="add-customer-houseform-main-input"
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="creditPeriod">
                        Credit Period
                      </InputLabel>
                      <TextField
                        name="creditPeriod"
                        value={values?.creditPeriod}
                        onChange={handleChange}
                        id="outlined-adornment-amount"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {`Day(s)`}
                            </InputAdornment>
                          ),
                        }}
                      />
                      {touched.creditPeriod && errors.creditPeriod && (
                        <FormHelperText error id="helper-text-name">
                          {errors.creditPeriod}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className="add-customer-houseform-main-input"
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="creditLimit">
                        Credit Limit
                      </InputLabel>
                      <TextField
                        id="outlined-adornment-amount"
                        name="creditLimit"
                        value={values?.creditLimit}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start">
                                <AttachMoneyIcon fontSize="20px" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {touched.creditLimit && errors.creditLimit && (
                        <FormHelperText error id="helper-text-phone">
                          {errors.creditLimit}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="add-customer-houseform-main-input"
                >
                  <Stack spacing={1}>
                    <Typography
                      variant="body2"
                      className="add-customer-houseform-address"
                    >
                      Billing Address
                    </Typography>
                    <TextField
                      //   fullWidth
                      multiline
                      name="address"
                      id="address"
                      value={values.address}
                      onChange={handleChange}
                      rows={2}
                      placeholder="Please Enter Billing Address"
                    />
                    {touched.address && errors.address && (
                      <FormHelperText error id="helper-text-address">
                        {errors.address}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="add-customer-houseform-main-input"
                >
                  <Stack spacing={1}>
                    <Typography
                      variant="body2"
                      className="add-customer-houseform-address"
                    >
                      Shipping Address
                    </Typography>
                    <TextField
                      //   fullWidth
                      multiline
                      name="shippingAddress"
                      id="shippingAddress"
                      value={values.shippingAddress}
                      onChange={handleChange}
                      rows={2}
                      placeholder="Please Enter Shipping Address"
                    />
                    {touched.shippingAddress && errors.shippingAddress && (
                      <FormHelperText error id="helper-text-address">
                        {errors.shippingAddress}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Box className="add-customer-form-drawer-btn-wrapper">
                <ButtonComponent
                  name="Create"
                  isName={true}
                  Icon={SaveAsIcon}
                  onClick={() => setShow(true)}
                  type="submit"
                />
                <Box className="add-form-drawer-btn-2">
                  <ButtonComponent
                    name="Cancel"
                    isName={true}
                    className="background-white"
                    onClick={toggleDrawer("right", false)}
                  />
                </Box>
              </Box>
            </Form>
          </div>
        )}
      </Formik>
      {toggleDrawer1 && (
        <CommonFormDrawer
          state={showDrawer}
          toggleDrawer={toggleDrawer1}
          heading="Add New Warehouse"
          // width="1000px"
          width={isTablet ? "1000px" : isMobile ? "670px" : "400px"}
          childrenComponent={<CommonTab data={commonTabData} />}
        />
      )}
    </Box>
  );
};
AddNewCustomerForm.propTypes = {
  toggleDrawer: PropTypes.any,
};

export default AddNewCustomerForm;
