import {
  SELLER_ADD,
  SELLER_ADD_FAIL,
  SELLER_ADD_SUCCESS,
  SELLER_DELETE,
  SELLER_DELETE_FAIL,
  SELLER_DELETE_SUCCESS,
  SELLER_LIST,
  SELLER_LIST_FAIL,
  SELLER_LIST_SUCCESS,
  SELLER_SINGLE,
  SELLER_SINGLE_FAIL,
  SELLER_SINGLE_SUCCESS,
  SELLER_SINGLE_UPDATE,
  SELLER_SINGLE_UPDATE_FAIL,
  SELLER_SINGLE_UPDATE_SUCCESS,
  SELLER_UPDATE,
  SELLER_UPDATE_FAIL,
  SELLER_UPDATE_SUCCESS,
  WAREHOUSE_ADD,
  WAREHOUSE_ADD_FAIL,
  WAREHOUSE_ADD_SUCCESS,
  WAREHOUSE_DELETE,
  WAREHOUSE_DELETE_FAIL,
  WAREHOUSE_DELETE_SUCCESS,
  WAREHOUSE_LIST,
  WAREHOUSE_LIST_FAIL,
  WAREHOUSE_LIST_SUCCESS,
  WAREHOUSE_SINGLE,
  WAREHOUSE_SINGLE_FAIL,
  WAREHOUSE_SINGLE_SUCCESS,
  WAREHOUSE_STATUS_UPDATE,
  WAREHOUSE_STATUS_UPDATE_FAIL,
  WAREHOUSE_STATUS_UPDATE_SUCCESS,
  WAREHOUSE_UPDATE,
  WAREHOUSE_UPDATE_FAIL,
  WAREHOUSE_UPDATE_SUCCESS,
} from "../actions/sellerActions";
import { API } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";

//expense saga

function* sellerList(action) {
  try {
    const response = yield call(API.sellerList, action.payload);
    yield put({ type: SELLER_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: SELLER_LIST_FAIL, payload: { error: error } });
  }
}
function* addSeller(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.addSeller, otherPayload);
    yield put({ type: SELLER_ADD_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: SELLER_ADD_FAIL, payload: { error: error } });
  }
}
function* deleteSeller(action) {
  try {
    const response = yield call(API.deleteSeller, action.payload);
    yield put({ type: SELLER_DELETE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: SELLER_DELETE_FAIL, payload: { error: error } });
  }
}
function* singleSeller(action) {
  try {
    const response = yield call(API.singleSeller, action.payload);
    yield put({ type: SELLER_SINGLE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: SELLER_SINGLE_FAIL, payload: { error: error } });
  }
}
function* updateSeller(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.updateSeller, otherPayload);
    yield put({ type: SELLER_UPDATE_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: SELLER_UPDATE_FAIL, payload: { error: error } });
  }
}
function* sellerSingleUpdate(action) {
  try {
    const response = yield call(API.sellerSingleUpdate, action.payload);
    yield put({ type: SELLER_SINGLE_UPDATE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: SELLER_SINGLE_UPDATE_FAIL, payload: { error: error } });
  }
}

// ! all WareHouse saga :

function* wareHouseAdd(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.addWareHouse, otherPayload);
    yield put({ type: WAREHOUSE_ADD_SUCCESS, payload: response.data });
    if (typeof callback === "function") {
      callback();
    }
  } catch (error) {
    yield put({ type: WAREHOUSE_ADD_FAIL, payload: { error: error } });
  }
}
function* wareHouseList(action) {
  try {
    const response = yield call(API.listWareHouse, action.payload);
    yield put({ type: WAREHOUSE_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: WAREHOUSE_LIST_FAIL, payload: { error: error } });
  }
}
function* wareHouseSingle(action) {
  try {
    const response = yield call(API.singleWareHouse, action.payload);
    yield put({
      type: WAREHOUSE_SINGLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WAREHOUSE_SINGLE_FAIL,
      payload: { error: error },
    });
  }
}
function* wareHouseUpdate(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.updateWareHouse, otherPayload);
    yield put({
      type: WAREHOUSE_UPDATE_SUCCESS,
      payload: response.data,
    });
    if (typeof callback === "function") {
      callback();
    }
  } catch (error) {
    yield put({
      type: WAREHOUSE_UPDATE_FAIL,
      payload: { error: error },
    });
  }
}
function* wareHouseDelete(action) {
  try {
    const response = yield call(API.deleteWareHouse, action.payload);
    yield put({
      type: WAREHOUSE_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WAREHOUSE_DELETE_FAIL,
      payload: { error: error },
    });
  }
}

function* wareHouseStatusUpdate(action) {
  try {
    const response = yield call(API.updateWareHouseStatus, action.payload);
    yield put({
      type: WAREHOUSE_STATUS_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WAREHOUSE_STATUS_UPDATE_FAIL,
      payload: { error: error },
    });
  }
}

function* seller() {
  yield takeLatest(SELLER_LIST, sellerList);
  yield takeLatest(SELLER_ADD, addSeller);
  yield takeLatest(SELLER_DELETE, deleteSeller);
  yield takeLatest(SELLER_SINGLE, singleSeller);
  yield takeLatest(SELLER_UPDATE, updateSeller);
  yield takeLatest(SELLER_SINGLE_UPDATE, sellerSingleUpdate);
  yield takeLatest(WAREHOUSE_ADD, wareHouseAdd);
  yield takeLatest(WAREHOUSE_LIST, wareHouseList);
  yield takeLatest(WAREHOUSE_SINGLE, wareHouseSingle);
  yield takeLatest(WAREHOUSE_UPDATE, wareHouseUpdate);
  yield takeLatest(WAREHOUSE_DELETE, wareHouseDelete);
  yield takeLatest(WAREHOUSE_STATUS_UPDATE, wareHouseStatusUpdate);
}
export default seller;
