/* eslint-disable no-unused-vars */
import { useState } from "react";
import "./companiesForm.css";

import {
  Box,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import AddIcon from "@mui/icons-material/Add";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import CustomizedModal from "../../@extended/customizedmodal/CustomizedModal";
import CurrenciesForm from "../settings/currencies/CurrenciesForm";
import AddLanguageForm from "../settings/company/AddLanguageForm";
import PropTypes from "prop-types";
import UploadFile from "../../@extended/uploadfile/UploadFile";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { ADD_COMPANY, UPDATE_COMPANY } from "../../../actions/companyActions";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const CompaniesForm = (props) => {
  const { onClick, id, singleData } = props;

  const [show, setShow] = useState(false);
  const [isLanModal, setIsLanModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const [open, setOpen] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const dispatch = useDispatch();
  const addCompany = useCallback((data) => {
    dispatch({ type: ADD_COMPANY, payload: data });
  }, []);

  const updateCompany = useCallback((id) => {
    dispatch({ type: UPDATE_COMPANY, payload: id });
  }, []);
  return (
    <>
      <Box
        className={
          isMobile ? "companies-main-div" : "companies-mobile-main-div"
        }
      >
        <Formik
          initialValues={{
            name: id ? singleData?.companyId?.name : "",
            shortName: id ? singleData?.companyId?.shortName : "",
            email: id ? singleData?.companyId?.email : "",
            mobile: id ? singleData?.companyId?.mobile : "",
            currency: id ? singleData?.companyId?.currency || "" : "dollar",
            layout: id ? singleData?.companyId?.layout || "" : "ltr",
            language: id ? singleData?.companyId?.language || "" : "en",
            dateFormat: id ? singleData?.companyId?.dateFormat || "" : "en",
            timeFormat: id ? singleData?.companyId?.timeFormat || "" : "en",
            timezone: id ? singleData?.companyId?.timezone || "" : "as/kol",
            logos: {
              darkLogo: id ? singleData?.companyId?.logos?.darkLogo : "",
              lightLogo: id ? singleData?.companyId?.logos?.lightLogo : "",
              smallDarkLogo: id
                ? singleData?.companyId?.logos?.smallDarkLogo
                : "",
              smallLightLogo: id
                ? singleData?.companyId?.logos?.smallLightLogo
                : "",
            },
            address: id ? singleData?.companyId?.address : "",
            status: id ? singleData?.companyId?.status || "" : "Inactive",
            isAutoDetectTimezone: id
              ? singleData?.companyId?.isAutoDetectTimezone
              : false,
            adminEmail: id ? singleData?.email : "",
            password: "",
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255),
            shortName: Yup.string().max(255),
            mobile: Yup.string().max(255),
            email: Yup.string().email("Must be a valid email").max(255),
            adminEmail: Yup.string(),
            password: Yup.string(),
            currency: Yup.string(),
            dateFormat: Yup.string(),
            timeFormat: Yup.string(),
            timezone: Yup.string(),
            logos: Yup.object().shape({
              darkLogo: Yup.string(),
              lightLogo: Yup.string(),
              smallDarkLogo: Yup.string(),
              smallLightLogo: Yup.string(),
            }),
            address: Yup.string(),
            isAutoDetectTimezone: Yup.boolean(),
          })}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <>
              <Form noValidate onSubmit={handleSubmit}>
                <Grid className="companies-form-div" container>
                  <Grid
                    className={
                      isMobile
                        ? "companies-form-main-grid"
                        : "companies-mobile-form-main-grid"
                    }
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      xl={6}
                      className="companies-form-main-input"
                    >
                      <Stack spacing={1}>
                        <InputLabel htmlFor="name">Company Name*</InputLabel>
                        <OutlinedInput
                          id="name"
                          value={values?.name}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Please Enter First Name"
                          fullWidth
                          size="small"
                          error={Boolean(touched.name && errors.name)}
                        />
                        {touched.name && errors.name && (
                          <FormHelperText error id="helper-text-name">
                            {errors.name}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      xl={6}
                      className="companies-form-main-input"
                    >
                      <Stack spacing={1}>
                        <InputLabel htmlFor="shortName">
                          Company Short Name *
                        </InputLabel>
                        <OutlinedInput
                          id="shortName"
                          value={values.shortName}
                          name="shortName"
                          onBlur={handleBlur}
                          size="small"
                          onChange={handleChange}
                          placeholder="Please Enter Last Name"
                          fullWidth
                          error={Boolean(touched.shortName && errors.shortName)}
                        />
                        {touched.shortName && errors.shortName && (
                          <FormHelperText error id="helper-text-name">
                            {errors.shortName}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid
                    className={
                      isMobile
                        ? "companies-form-main-grid"
                        : "companies-mobile-form-main-grid"
                    }
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      xl={6}
                      className="companies-form-main-input"
                    >
                      <Stack spacing={1}>
                        <InputLabel htmlFor="email">Company Email*</InputLabel>
                        <OutlinedInput
                          fullWidth
                          size="small"
                          error={Boolean(touched?.email && errors?.email)}
                          id="email"
                          value={values?.email}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="demo@company.com"
                          inputProps={{}}
                        />
                        {touched?.email && errors?.email && (
                          <FormHelperText error id="helper-text-email">
                            {errors?.email}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      xl={6}
                      className="companies-form-main-input"
                    >
                      <Stack spacing={1}>
                        <InputLabel htmlFor="mobile">Company Phone*</InputLabel>
                        <OutlinedInput
                          fullWidth
                          size="small"
                          error={Boolean(touched.mobile && errors.mobile)}
                          id="mobile"
                          value={values?.mobile}
                          name="mobile"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="demo@company.com"
                          inputProps={{}}
                        />
                        {touched.mobile && errors.mobile && (
                          <FormHelperText error id="helper-text-mobile">
                            {errors.mobile}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="companies-form-main-input">
                    <Stack spacing={1}>
                      <InputLabel htmlFor="address">
                        Company Address*
                      </InputLabel>
                      <TextField
                        fullWidth
                        multiline
                        name="address"
                        id="address"
                        value={values?.address}
                        onBlur={handleBlur}
                        error={errors.address}
                        onChange={handleChange}
                        rows={4}
                        placeholder="Please Enter address"
                      />
                      {touched.address && errors.address && (
                        <FormHelperText error id="helper-text-address">
                          {errors.address}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid
                    className={
                      isMobile
                        ? "companies-form-main-grid"
                        : "companies-mobile-form-main-grid"
                    }
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      xl={6}
                      className="companies-form-main-input"
                    >
                      <Stack spacing={1}>
                        <InputLabel htmlFor="adminEmail">
                          Admin Email*
                        </InputLabel>
                        <OutlinedInput
                          fullWidth
                          size="small"
                          error={Boolean(
                            touched?.adminEmail && errors?.adminEmail,
                          )}
                          id="adminEmail"
                          value={values?.adminEmail}
                          name="adminEmail"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="demo@company.com"
                          inputProps={{}}
                        />
                        {touched?.adminEmail && errors?.adminEmail && (
                          <FormHelperText error id="helper-text-email">
                            {errors?.adminEmail}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      xl={6}
                      className="companies-form-main-input"
                    >
                      <Stack spacing={1}>
                        <InputLabel htmlFor="password-signup">
                          Admin Password*
                        </InputLabel>
                        <OutlinedInput
                          size="small"
                          fullWidth
                          error={Boolean(touched.password && errors.password)}
                          id="password"
                          type={showPassword ? "text" : "password"}
                          value={values.password}
                          name="password"
                          onBlur={handleBlur}
                          placeholder="Please Enter Password"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="large"
                              >
                                {showPassword ? (
                                  <EyeOutlined />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          inputProps={{}}
                        />
                        {touched.password && errors.password && (
                          <FormHelperText error id="helper-text-password">
                            {errors.password}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    className="companies-form-main-input"
                  >
                    <UploadFile
                      name="darkLogo"
                      value={values?.darkLogo}
                      className="custom-file-upload"
                      label="Dark Logo"
                      inputClassName="input-file-hide"
                      show={show}
                    />
                    {touched.darkLogo && errors.darkLogo && (
                      <FormHelperText error id="helper-text-image">
                        {errors.darkLogo}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    className="companies-form-main-input"
                  >
                    <UploadFile
                      name="lightLogo"
                      value={values?.lightLogo}
                      className="custom-file-upload"
                      label="Light Logo"
                      inputClassName="input-file-hide"
                      show={show}
                    />
                    {touched.lightLogo && errors.lightLogo && (
                      <FormHelperText error id="helper-text-image">
                        {errors.lightLogo}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    className="companies-form-main-input"
                  >
                    <UploadFile
                      name="smallDarkLogo"
                      value={values?.smallDarkLogo}
                      className="custom-file-upload"
                      label="Small Dark Logo"
                      inputClassName="input-file-hide"
                      show={show}
                    />
                    {touched.smallDarkLogo && errors.smallDarkLogo && (
                      <FormHelperText error id="helper-text-image">
                        {errors.smallDarkLogo}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    className="companies-form-main-input"
                  >
                    <UploadFile
                      name="smallLightLogo"
                      value={values?.smallLightLogo}
                      className="custom-file-upload"
                      label="Small Light Logo"
                      inputClassName="input-file-hide"
                      show={show}
                    />
                    {touched.smallLightLogo && errors.smallLightLogo && (
                      <FormHelperText error id="helper-text-image">
                        {errors.smallLightLogo}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className="companies-form-main-input"
                  >
                    <InputLabel htmlFor="currency" sx={{ marginBottom: "8px" }}>
                      Currency*
                    </InputLabel>
                    <Box display={"flex"} gap={"5px"} mr={"10px"}>
                      <Select
                        fullWidth
                        size="small"
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name="currency"
                        value={values?.currency}
                        onChange={handleChange}
                        style={{ borderBlockColor: "gray" }}
                      >
                        <MenuItem value={"dollar"}>Dollar ($)</MenuItem>
                        <MenuItem value={"rupee"}>Rupee (₹)</MenuItem>
                      </Select>
                      <Box
                        className="company-add-user-icon"
                        onClick={() => setOpen(true)}
                      >
                        <AddIcon />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    display={"flex"}
                    gap={"1%"}
                    className="companies-form-main-input"
                  >
                    <Box width={"50%"}>
                      <InputLabel htmlFor="layout" sx={{ marginBottom: "8px" }}>
                        Layout*
                      </InputLabel>
                      <Select
                        fullWidth
                        size="small"
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name="layout"
                        value={values?.layout}
                        onChange={handleChange}
                        style={{ borderBlockColor: "gray" }}
                      >
                        <MenuItem value={"ltr"}>LTR</MenuItem>
                        <MenuItem value={"rtl"}>RTL</MenuItem>
                      </Select>
                    </Box>
                    <Box width={"50%"}>
                      <InputLabel
                        htmlFor="language"
                        sx={{ marginBottom: "8px" }}
                      >
                        Language*
                      </InputLabel>
                      <Box display={"flex"} gap={"5px"} width={"100%"}>
                        <Select
                          fullWidth
                          size="small"
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          name="language"
                          value={values?.language}
                          onChange={handleChange}
                          style={{ borderBlockColor: "gray" }}
                        >
                          <MenuItem value={"en"}>English</MenuItem>
                        </Select>
                        <Box
                          className="company-add-user-icon"
                          onClick={() => setIsLanModal(true)}
                        >
                          <AddIcon />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item md={2}>
                      <InputLabel htmlFor="isAutoDetectTimezone">
                        Auto Detect Timezone
                      </InputLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              name="isAutoDetectTimezone"
                              value={values?.isAutoDetectTimezone}
                              checked={values?.isAutoDetectTimezone}
                              onChange={handleChange}
                            />
                          }
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item md={4}>
                      <Box width={"100%"}>
                        <InputLabel
                          htmlFor="status"
                          sx={{ marginBottom: "8px" }}
                        >
                          Status*
                        </InputLabel>
                        <Select
                          fullWidth
                          size="small"
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          name="status"
                          value={values?.status}
                          onChange={handleChange}
                          style={{ borderBlockColor: "gray" }}
                        >
                          <MenuItem value={"Pending"}>Pending</MenuItem>
                          <MenuItem value={"Active"}>Active</MenuItem>
                          <MenuItem value={"Inactive"}>Inactive</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <InputLabel
                        htmlFor="timezone"
                        sx={{ marginBottom: "8px" }}
                      >
                        Default Timezone
                      </InputLabel>
                      <Select
                        fullWidth
                        size="small"
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name="timezone"
                        value={values?.timezone}
                        onChange={handleChange}
                        style={{
                          borderBlockColor: "gray",
                          marginRight: "10px",
                        }}
                      >
                        <MenuItem value={"as/kol"}>Asia/Kolkata</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid
                    className={
                      isMobile
                        ? "companies-form-main-grid"
                        : "companies-mobile-form-main-grid"
                    }
                    marginTop={"10px"}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      xl={6}
                      className="companies-form-main-input"
                    >
                      <Stack spacing={1}>
                        <InputLabel htmlFor="dateFormat">
                          Date Format
                        </InputLabel>
                        <Select
                          fullWidth
                          size="small"
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          name="dateFormat"
                          value={values?.dateFormat}
                          onChange={handleChange}
                          style={{ borderBlockColor: "gray" }}
                        >
                          <MenuItem value={"en"}>English</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      xl={6}
                      className="companies-form-main-input"
                    >
                      <Stack spacing={1}>
                        <InputLabel htmlFor="timeFormat">
                          Time Format *
                        </InputLabel>
                        <Select
                          fullWidth
                          size="small"
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          name="timeFormat"
                          value={values?.timeFormat}
                          onChange={handleChange}
                          style={{ borderBlockColor: "gray" }}
                        >
                          <MenuItem value={"en"}>English</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>

                <Box className="companies-form-drawer-btn-wrapper-create">
                  <ButtonComponent
                    name={id ? "Update" : "Create"}
                    isName={true}
                    Icon={SaveAsIcon}
                    type="submit"
                    onClick={() => {
                      if (id) {
                        if (!values?.password) {
                          delete values?.password;
                        }
                        updateCompany({ ...values, id: id });
                      } else {
                        addCompany({ ...values });
                      }
                      onClick();
                    }}
                  />
                  <ButtonComponent
                    name="Cancel"
                    isName={true}
                    className="background-white"
                    onClick={onClick}
                  />
                </Box>
              </Form>
            </>
          )}
        </Formik>
        {open && (
          <CustomizedModal
            isHeadingName={true}
            heading="Add New Currency"
            open={open}
            onClick={() => setOpen(false)}
          >
            <CurrenciesForm onClick={() => setOpen(false)} />
          </CustomizedModal>
        )}
        {isLanModal && (
          <CustomizedModal
            isHeadingName={true}
            heading="Add New Language"
            open={isLanModal}
            onClick={() => setIsLanModal(false)}
          >
            <AddLanguageForm onClick={() => setIsLanModal(false)} />
          </CustomizedModal>
        )}
      </Box>
    </>
  );
};
CompaniesForm.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
};
export default CompaniesForm;
