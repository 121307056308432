import toast from "react-hot-toast";
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGESTER,
  REGESTER_FAIL,
  REGESTER_SUCCESS,
  PROFILE_DETAILS,
  PROFILE_DETAILS_SUCCESS,
  PROFILE_DETAILS_FAIL,
  UPDATE_DETAILS,
  UPDATE_DETAILS_SUCCESS,
  UPDATE_DETAILS_FAIL,
  COMPANY_COUNT,
  COMPANY_COUNT_SUCCESS,
  COMPANY_COUNT_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from "../actions/authActions";
import { cookie } from "../utils/cookies/cookies";

const initialState = {
  loginData: {},
  registerData: {},
  isLoading: false,
  isCompanyCountLoading: false,
  isSuccess: false,
  isProfileSuccess: false,
  error: "",
  profileDetails: {},
  companyCount: {},
  changePasswordData: {},
};
const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }
    case LOGIN_SUCCESS: {
      toast.success(payload?.message);
      if (payload?.isKeep) {
        cookie.set(
          "token",
          payload?.data?.tokens?.access?.token,
          3600 * 1000 * 24 * 15,
        );
      } else {
        cookie.set("token", payload?.data?.tokens?.access?.token);
      }
      return {
        ...state,
        loginData: payload?.data,
        isLoading: false,
        isSuccess: true,
      };
    }

    case LOGIN_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
      };
    }

    case REGESTER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case REGESTER_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        registerData: payload.user,
        isLoading: false,
      };
    }

    case REGESTER_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    }
    case CHANGE_PASSWORD: {
      return {
        ...state,
        isLodding: true,
      };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        changePasswordData: payload,
        isLodding: false,
      };
    }

    case CHANGE_PASSWORD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLodding: false,
      };
    }

    case PROFILE_DETAILS: {
      return {
        ...state,
        isLoading: true,
        isProfileSuccess: false,
      };
    }

    case PROFILE_DETAILS_SUCCESS: {
      return {
        ...state,
        profileDetails: payload.data,
        isLoading: false,
        isProfileSuccess: true,
      };
    }

    case PROFILE_DETAILS_FAIL: {
      return {
        ...state,
        isProfileSuccess: false,
      };
    }

    case UPDATE_DETAILS: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }

    case UPDATE_DETAILS_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    }

    case UPDATE_DETAILS_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
      };
    }

    case COMPANY_COUNT: {
      return {
        ...state,
        isCompanyCountLoading: true,
      };
    }

    case COMPANY_COUNT_SUCCESS: {
      return {
        ...state,
        companyCount: payload.data,
        isCompanyCountLoading: false,
      };
    }

    case COMPANY_COUNT_FAIL: {
      return {
        ...state,
        isCompanyCountLoading: false,
      };
    }

    default: {
      return { ...state };
    }
  }
};
export default authReducer;
