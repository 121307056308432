import React, { useState } from "react";
import { Box } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "./offlinePaymentModes.css";
import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ButtonComponent from "../../../@extended/button/ButtonComponent";
import SelectWithSearch from "../../../@extended/selectwithsearch/SelectWithSearch";
import CommonTable from "../../../@extended/table/CommonTable";
import CustomizedModal from "../../../@extended/customizedmodal/CustomizedModal";
import DeleteModal from "../../../@extended/deletemodal/DeleteModal";
import AddNewOfflinePayment from "./addnewpayment/AddNewOfflinePayment";

const OfflinePaymentModes = () => {
  const [open, setOpen] = useState("");
  const [id, setId] = useState("");
  const [deleteModal, setDeleteModal] = useState("");

  const rows = [
    // {
    //   id: 1,
    //   name: "Sales",
    //   description: "Jon",
    //   maxProducts: 35,
    //   actions: "",
    // },
  ];
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "offline-payment-header",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      headerClassName: "offline-payment-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "offline-payment-header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="offline-payment-table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              onClick={() => {
                setOpen(true);
                setId(params?.row?.rowId);
              }}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => {
                setDeleteModal(true);
                setId(params?.row?.rowId);
              }}
            />
          </Box>
        );
      },
    },
  ];

  const [heading, setHeading] = React.useState("en");
  const handleChange = (event) => {
    setHeading(event.target.value);
  };
  return (
    <Box className="offline-payment-box">
      <Box className="offline-payment-box-main">
        <ButtonComponent
          name="Add New Offline Payment Mode"
          isName={true}
          Icon={AddIcon}
          onClick={() => setOpen(true)}
        />
        <SelectWithSearch
          handleChange={handleChange}
          heading={heading}
          optionData={columns}
        />
      </Box>
      <Box className="offline-payment-common-table-box-main">
        <Box className="offline-payment-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
          />
        </Box>
      </Box>
      {open && (
        <CustomizedModal
          isHeadingName={true}
          heading={
            id ? "Edit Offline Payment Mode" : "Add New Offline Payment Mode"
          }
          open={open}
          onClick={() => {
            setOpen(false);
            setId("");
          }}
        >
          <AddNewOfflinePayment
            onClick={() => {
              setOpen(false);
              setId("");
            }}
          />
        </CustomizedModal>
      )}
      {deleteModal && (
        <DeleteModal
          isIcon={true}
          Icon={ErrorOutlineOutlinedIcon}
          open={deleteModal}
          onClick={() => {
            setDeleteModal(false);
            setId("");
          }}
        />
      )}
    </Box>
  );
};

export default OfflinePaymentModes;
