import React, { useCallback, useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ButtonComponent from "../../../@extended/button/ButtonComponent";
import SelectWithSearch from "../../../@extended/selectwithsearch/SelectWithSearch";
import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CommonTable from "../../../@extended/table/CommonTable";
import "./../plans/plans.css";
import DeleteModal from "../../../@extended/deletemodal/DeleteModal";
import CommonFormDrawer from "../../../@extended/drawer/CommonFormDrawer";
import AddNewSubscriptionPlans from "./subscriptionplan/AddNewSubscriptionPlan";
import {
  DELETE_SUBSCRIPTIONS,
  LIST_SINGLE_SUBSCRIPTIONS,
  LIST_SUBSCRIPTIONS,
} from "../../../../actions/subscriptionsActions";
import { useDispatch, useSelector } from "react-redux";

const Plans = () => {
  const [state, setState] = React.useState({
    right: false,
  });

  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const detailsSubscription = useSelector(
    (data) => data?.subscriptions?.listSubscriptions?.results,
  );
  const isDeleteSuccess = useSelector(
    (data) => data?.subscriptions?.isDeleteSubscriptionsSuccess,
  );
  const isUpdateSuccess = useSelector(
    (data) => data?.subscriptions?.isUpdataSubscriptionsSuccess,
  );
  const isSuccess = useSelector(
    (data) => data?.subscriptions?.isAddSubscriptionsSuccess,
  );

  const singleSubscription = useSelector(
    (data) => data?.subscriptions?.listSingleSubscriptions,
  );

  // listSubscription
  const listSubscription = useCallback((values) => {
    dispatch({ type: LIST_SUBSCRIPTIONS, payload: values });
  }, []);
  // deleteSubscription
  const deleteSubscription = useCallback((id) => {
    dispatch({ type: DELETE_SUBSCRIPTIONS, payload: id });
  }, []);

  //single Subscription
  const listSingleSubscription = useCallback((id) => {
    dispatch({ type: LIST_SINGLE_SUBSCRIPTIONS, payload: id });
  });
  const toggleDrawer = (right, open, params) => (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [right]: open });
    setId(params?.row?.rowId);
  };
  useEffect(() => {
    listSubscription();
    if (id) {
      listSingleSubscription(id);
    }
    if (isSuccess) {
      listSubscription();
    }
    if (isDeleteSuccess) {
      listSubscription();
    }
    if (isUpdateSuccess) {
      listSubscription();
    }
  }, [id, isSuccess, isDeleteSuccess, isUpdateSuccess]);
  const [deleteModal, setDeleteModal] = useState(false);

  const rows = detailsSubscription?.map((item, ind) => {
    return {
      id: ind + 1,
      name: item?.name,
      pricingDetails: {
        monthly: item?.monthlyPrice,
        annual: item?.annualPrice,
      },
      maxProducts: item?.maxProducts,
      enabledModules: item?.modules,
      actions: "",
      rowId: item?.id,
    };
  });
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "plans-header",
    },
    {
      field: "pricingDetails",
      headerName: "Pricing Details",
      flex: 1,
      headerClassName: "plans-header",
      renderCell: (params) => {
        return (
          <Box className="">
            <Box padding={"5px"}>
              Monthly Price:
              {params?.row?.pricingDetails?.monthly}
            </Box>
            <Box padding={"5px"}>
              Annual Price:{params?.row?.pricingDetails?.annual}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "maxProducts",
      headerName: "Max Products",
      flex: 1,
      headerClassName: "plans-header",
    },
    {
      field: "enabledModules",
      headerName: "Enabled Modules",
      flex: 1,
      headerClassName: "plans-header",
      renderCell: (params) => {
        return (
          <>
            <ul>
              {params?.row?.enabledModules?.map((data, index) => {
                return <li key={index}>{data?.moduleName}</li>;
              })}
            </ul>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "plans-header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              onClick={toggleDrawer("right", true, params)}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => {
                setDeleteModal(true);
                setId(params?.row?.rowId);
              }}
            />
          </Box>
        );
      },
    },
  ];
  const [heading, setHeading] = React.useState("en");
  const handleChange = (event) => {
    setHeading(event.target.value);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box className="plans-box">
      <Box className="plans-box-main">
        <ButtonComponent
          name="Add New Subscription Plan"
          isName={true}
          Icon={AddIcon}
          onClick={toggleDrawer("right", true)}
        />
        {/* <ButtonComponent
          name="Free Trail Settings"
          isName={true}
          Icon={AddIcon}
          onClick={toggleDrawer("right", true)}
        /> */}
        <SelectWithSearch
          handleChange={handleChange}
          heading={heading}
          optionData={columns}
        />
      </Box>
      <Box className="plans-common-table-box-main">
        <Box className="common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
          />
        </Box>
      </Box>

      {toggleDrawer && (
        <CommonFormDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          heading={id ? "Edit Subscription Plan" : "Add New Subscription Plan"}
          width={isTablet ? "1000px" : isMobile ? "670px" : "400px"}
          childrenComponent={
            <AddNewSubscriptionPlans
              toggleDrawer={toggleDrawer}
              singleData={singleSubscription}
              id={id}
            />
          }
        />
      )}
      {deleteModal && (
        <DeleteModal
          id={id}
          deleteData={deleteSubscription}
          isIcon={true}
          Icon={ErrorOutlineOutlinedIcon}
          open={deleteModal}
          onClick={() => setDeleteModal(false)}
        />
      )}
    </Box>
  );
};
export default Plans;
