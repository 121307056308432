import React, { useCallback, useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import "../seller/Seller.css";

import AddIcon from "@mui/icons-material/Add";
import CustomizedModal from "../../@extended/customizedmodal/CustomizedModal";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../@extended/deletemodal/DeleteModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import SellerWareHouseForm from "./SellerWareHouseForm";
import BreadCrumb from "../../@extended/breadcrumbs/Breadcrumbs";
import {
  WAREHOUSE_DELETE,
  WAREHOUSE_LIST,
  // WAREHOUSE_STATUS_UPDATE,
} from "../../../actions/sellerActions";
import SellerWareHouseTable from "./SellerWareHouseTable";

const SellerWareHouseModule = () => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  // const [switchValue, setSwitchValue] = useState(false);

  const dispatch = useDispatch();
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );
  const deleteWareHouse = useCallback((data) => {
    dispatch({ type: WAREHOUSE_DELETE, payload: data });
  }, []);

  const wareHouseList = useCallback((values) => {
    dispatch({ type: WAREHOUSE_LIST, payload: values });
  }, []);

  const isADDWarHouseSuccess = useSelector(
    (data) => data?.seller?.isADDWarHouseSuccess,
  );
  const isSellerWareHouseUpdate = useSelector(
    (data) => data?.seller?.isWareHouseUpdate,
  );
  const isWareHouseDelete = useSelector(
    (data) => data?.seller?.isWareHouseDelete,
  );
  const isWareHouseStatus = useSelector(
    (data) => data?.seller?.isWareHouseStatusUpdate,
  );
  // const WareHouseStatusUpdate = useCallback((data) => {
  //   dispatch({ type: WAREHOUSE_STATUS_UPDATE, payload: data });
  // }, []);

  const columns = [
    {
      field: "no",
      headerName: "No",
      headerClassName: "email-query-header",
    },
    {
      field: "warehouseName",
      headerName: "WarehouseName",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "sellerName",
      headerName: "SellerName",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      headerClassName: "email-query-header",
    },
    // {
    //   field: "isVerify",
    //   headerName: "Verify Status",
    //   sortable: false,
    //   disableClickEventBubbling: true,
    //   headerClassName: "email-query-header",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Box>
    //         <Switch
    //           checked={params?.row?.isVerified}
    //           onChange={() => {
    //             setSwitchValue(!switchValue);
    //             setTimeout(() => {
    //               WareHouseStatusUpdate({
    //                 id: params?.row?.rowId,
    //                 isVerified: switchValue,
    //               });
    //             }, 100);
    //           }}
    //           inputProps={{ "aria-label": "controlled" }}
    //         />
    //       </Box>
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "email-query-header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="email-query-table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              onClick={() => {
                setOpen(true);
                setId(params?.row?.rowId);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "warehouse" && data?.edit) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => {
                setDeleteModal(true);
                setId(params?.row?.rowId);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "warehouse" && data?.delete) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (
      isADDWarHouseSuccess ||
      isSellerWareHouseUpdate ||
      isWareHouseDelete ||
      isWareHouseStatus
    ) {
      wareHouseList({ userType: "seller" });
    }
  }, [
    isADDWarHouseSuccess,
    isSellerWareHouseUpdate,
    isWareHouseDelete,
    isWareHouseStatus,
  ]);

  return (
    <Box className="email-query-box">
      <BreadCrumb
        title="Seller Ware House"
        perentTabName="Ware House"
        // openMenuClick={toggleDrawer("bottom", true)}
      />
      <Box className="email-query-box-main">
        <Box>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={(e) => setSearch(e.target.value)}
            sx={{ width: "400px" }}
            inputProps={{ placeholder: "Search here...." }}
          />
        </Box>
        <ButtonComponent
          name="Add WareHouse"
          isName={true}
          Icon={AddIcon}
          onClick={() => setOpen(true)}
          disabledbtn={userRoleAccess?.userPermissions?.find(
            (data) =>
              (data?.userModule == "warehouse" && data?.add) ||
              userRoleAccess?.userRoleType == "admin",
          )}
        />
      </Box>
      <SellerWareHouseTable
        columns={columns}
        search={search}
        // switchValue={setSwitchValue}
      />
      {open && (
        <CustomizedModal
          isHeadingName={true}
          heading="Ware House Form"
          open={open}
          onClick={() => {
            setOpen(false);
            setId("");
          }}
        >
          <SellerWareHouseForm
            onClick={() => {
              setOpen(false);
              setId("");
            }}
            id={id}
          />
        </CustomizedModal>
      )}
      {deleteModal && (
        <DeleteModal
          isIcon={true}
          Icon={ErrorOutlineOutlinedIcon}
          open={deleteModal}
          onClick={() => {
            setDeleteModal(false);
            setId("");
          }}
          id={id}
          deleteData={deleteWareHouse}
          userModule="sellerWarehouse"
        />
      )}
    </Box>
  );
};

export default SellerWareHouseModule;
