import PropTypes from "prop-types";
import "../seller/SellerForm.css";

import {
  Autocomplete,
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import allStateList from "../../../state.json";

import {
  SELLER_LIST,
  WAREHOUSE_ADD,
  WAREHOUSE_SINGLE,
  WAREHOUSE_UPDATE,
} from "../../../actions/sellerActions";
import { FULL_LIST_LOCATION } from "../../../actions/locationActions";

const SellerWareHouseForm = (props) => {
  const { onClick, id } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const [recentValues, setRecentValues] = useState("");
  const sellerListResponse = useSelector((data) => data?.seller?.sellerList);

  const sellerList = useCallback((values) => {
    dispatch({ type: SELLER_LIST, payload: values });
  }, []);
  const singleListWarHouse = useCallback((data) => {
    dispatch({ type: WAREHOUSE_SINGLE, payload: data });
  }, []);

  const singleData = useSelector((data) => data?.seller?.wareHouseSingle);

  const locationFullList = useCallback((values) => {
    dispatch({ type: FULL_LIST_LOCATION, payload: values });
  }, []);

  const fullListLocationData = useSelector(
    (data) => data?.location?.locationFullList,
  );
  const updateWareHouse = useCallback((values) => {
    dispatch({ type: WAREHOUSE_UPDATE, payload: values });
  }, []);

  useEffect(() => {
    if (id) {
      singleListWarHouse(id);
    }
  }, [id]);
  useEffect(() => {
    sellerList({ type: "active", search: "" });
  }, []);
  useEffect(() => {
    locationFullList();
  }, []);

  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );
  return (
    <Box
      className={
        isMobile ? "expense-category-main-div" : "expense-category-form-box"
      }
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: id ? singleData?.name : "",
          location: id ? singleData?.location : "",
          seller: id ? singleData?.seller?.id || "" : "",
          state: id ? singleData?.state : "",
          address: {
            addressLineOne: id ? singleData?.address?.addressLineOne : "",
            addressLineTwo: id ? singleData?.address?.addressLineTwo : "",
            city: id ? singleData?.address?.city : "",
            pincode: id ? singleData?.address?.pincode : "",
          },
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("Name is required"),
          location: Yup.string().required("Location is required"),
          seller: Yup.string().required("Select Seller is required"),
          state: Yup.string().required("State is required"),
          address: Yup.object().shape({
            addressLineOne: Yup.string().required("Addres line 1 is required"),
            city: Yup.string().required("City is required"),
            pincode: Yup.string().required("Pincode is required"),
          }),
        })}
        onSubmit={(values) => {
          if (id) {
            updateWareHouse({ ...values, id: id, callback: onClick });
          } else {
            dispatch({
              type: WAREHOUSE_ADD,
              payload: { ...values, callback: onClick },
            });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">Name*</InputLabel>
                    <OutlinedInput
                      autoFocus
                      id="name"
                      size="large"
                      fullWidth
                      value={values?.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Name"
                      error={Boolean(touched.name && errors.name)}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error id="helper-text-name">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="lastName">Location*</InputLabel>
                    <Autocomplete
                      defaultValue={
                        id ? singleData?.location && singleData?.location : ""
                      }
                      disablePortal
                      size="small"
                      id="location"
                      options={
                        fullListLocationData?.length > 0
                          ? fullListLocationData?.map((item) => ({
                              label: item?.name,
                              value: item?.id,
                            }))
                          : []
                      }
                      name="location"
                      onChange={(event, value) => {
                        setFieldValue("location", value?.label);
                        setRecentValues(value?.label);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option?.label || ""}
                      fullWidth
                      error={Boolean(touched.location && errors.location)}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Please Enter location"
                        />
                      )}
                    />
                    {touched.location && errors.location && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors.location}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className="expense-category-form-input"
                >
                  <InputLabel htmlFor="currency" sx={{ marginBottom: "8px" }}>
                    Seller*
                  </InputLabel>
                  <Autocomplete
                    defaultValue={
                      id
                        ? singleData?.seller?.firstName &&
                          `${singleData?.seller?.firstName}(${
                            singleData?.seller?.mobile || " "
                          })`
                        : ""
                    }
                    disablePortal
                    size="small"
                    options={
                      sellerListResponse?.results?.length > 0
                        ? sellerListResponse?.results?.map((recent) => ({
                            label: `${recent?.firstName} ${recent?.lastName} (${recent?.mobile})`,
                            value: recent?.id,
                          }))
                        : []
                    }
                    // value={recentLabels}
                    name="seller"
                    onChange={(event, value) => {
                      setRecentValues(value?.value);
                      setFieldValue("seller", value?.value);
                    }}
                    error={touched.seller && errors.seller}
                    renderInput={(params) => {
                      const findVal = sellerListResponse?.results?.find(
                        (data) => data?.id === recentValues,
                      );
                      return (
                        <TextField
                          {...params}
                          value={findVal?.firstName}
                          placeholder="Seller"
                        />
                      );
                    }}
                  />
                  {touched.seller && errors.seller && (
                    <FormHelperText error id="helper-text-name">
                      {errors.seller}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="lastName">Address line1*</InputLabel>
                    <TextField
                      size="small"
                      id="address.addressLineOne"
                      fullWidth
                      value={values?.address?.addressLineOne}
                      name="address.addressLineOne"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Location"
                      error={Boolean(
                        touched?.address?.addressLineOne &&
                          errors?.address?.addressLineOne,
                      )}
                    />
                    {touched?.address?.addressLineOne &&
                      errors?.address?.addressLineOne && (
                        <FormHelperText error id="helper-text-shortname">
                          {errors?.address?.addressLineOne}
                        </FormHelperText>
                      )}
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="addreshLine2">
                      Address line 2*
                    </InputLabel>
                    <TextField
                      size="small"
                      id="address.addressLineTwo"
                      fullWidth
                      value={values?.address?.addressLineTwo}
                      name="address.addressLineTwo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter address line 2"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="city"> City*</InputLabel>
                    <TextField
                      size="small"
                      id="address.city"
                      fullWidth
                      value={values?.address?.city}
                      name="address.city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter City"
                      error={Boolean(
                        touched?.address?.city && errors?.address?.city,
                      )}
                    />
                    {touched?.address?.city && errors?.address?.city && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors?.address?.city}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className="expense-category-form-input"
                >
                  <InputLabel htmlFor="currency" sx={{ marginBottom: "8px" }}>
                    State*
                  </InputLabel>
                  <Autocomplete
                    defaultValue={
                      id ? singleData?.state && singleData?.state : ""
                    }
                    disablePortal
                    size="small"
                    options={
                      allStateList?.stateList?.length > 0
                        ? allStateList?.stateList?.map((recent) => ({
                            label: recent?.state,
                            value: recent?.id,
                          }))
                        : []
                    }
                    // value={recentLabels}
                    name="state"
                    onChange={(event, value) => {
                      setRecentValues(value?.value);
                      setFieldValue("state", value?.label);
                    }}
                    error={touched.state && errors.state}
                    renderInput={(params) => {
                      const findVal = allStateList?.stateList?.results?.find(
                        (data) => data?.id === recentValues,
                      );
                      return (
                        <TextField
                          {...params}
                          value={findVal?.state}
                          placeholder="State"
                        />
                      );
                    }}
                  />
                  {touched.state && errors.state && (
                    <FormHelperText error id="helper-text-name">
                      {errors.state}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="country"> Pincode* </InputLabel>
                    <TextField
                      size="small"
                      id="address.pincode"
                      value={values?.address?.pincode}
                      name="address.pincode"
                      onBlur={handleBlur}
                      type="number"
                      onChange={handleChange}
                      placeholder="Please Enter Pincode"
                      fullWidth
                      error={Boolean(
                        touched?.address?.pincode && errors?.address?.pincode,
                      )}
                    />
                    {touched?.address?.pincode && errors?.address?.pincode && (
                      <FormHelperText error id="helper-text-shortname">
                        {errors?.address?.pincode}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box className="expense-category-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={id ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "sellerWarehouse" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                onClick={onClick}
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "sellerWarehouse" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

SellerWareHouseForm.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
};

export default SellerWareHouseForm;
