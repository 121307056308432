import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_SINGLE } from "../../../actions/orderActions";
import moment from "moment";

const BuyerInvoice = ({ id }) => {
  const dispatch = useDispatch();
  const singleData = useSelector((data) => data?.order?.singleOrder.data);
  const singleListOrder = useCallback((data) => {
    dispatch({ type: ORDER_SINGLE, payload: data });
  }, []);

  useEffect(() => {
    if (id) {
      singleListOrder(id);
    }
  }, [id]);

  return (
    <div>
      <div
        style={{
          position: "relative",
          margin: "32px",
          border: "1px solid black",
        }}
      >
        <div
          style={{
            fontSize: "60px",
            fontWeight: "700",
            borderBottom: "1px solid black",
            textDecoration: "none",
            textAlign: "center",
          }}
        >
          CRYSTAL POLYMERS
        </div>
        <div
          style={{
            fontSize: "25px",
            fontWeight: "400",
            textAlign: "center",
            padding: "15px 0px",
            textDecoration: "none",
          }}
        >
          <div>
            SURVEY NO 873/B/3, NR. GETCO 66 KV SUBSTATION, G.I.D.C, POR,
            RAMANGAMDI,NEAR N.
          </div>
          <div>
            GUJARAT, INDIA, M: +91 8000906677, E-MAIL :
            crystalpoly2017@gmail.com
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
            textDecoration: "none",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "25px",
              fontWeight: "700",
              paddingLeft: "10px",
            }}
          >
            Debit Memo
          </div>
          <div
            style={{
              fontSize: "35px",
              fontWeight: "700",
            }}
          >
            TAX INVOICE
          </div>
          <div
            style={{
              fontSize: "25px",
              fontWeight: "700",
              paddingRight: "10px",
            }}
          >
            Original
          </div>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ width: "50%", borderRight: "1px solid black" }}>
            <div
              style={{
                padding: "20px 10px 20px 10px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    fontSize: "25px",
                    fontWeight: "700",
                    width: "270px",
                  }}
                >
                  Dispatch From :
                </div>
                <div>{singleData?.warehouse?.location}</div>
              </div>
              <div
                style={{
                  fontSize: "25px",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    fontSize: "25px",
                    fontWeight: "700",
                    width: "270px",
                  }}
                >
                  Vehicle No.
                </div>
                <div>KA 23 B 4581 --</div>
              </div>
              <div
                style={{
                  fontSize: "25px",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    fontSize: "25px",
                    fontWeight: "700",
                    width: "270px",
                  }}
                >
                  Transport
                </div>
                <div>{singleData?.warehouse?.name}</div>
              </div>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                padding: "10px",
              }}
            >
              <div
                style={{
                  textDecoration: "none",
                  display: "flex",
                  fontSize: "25px",
                }}
              >
                <div style={{ width: "270px" }}>Invoice No. :</div>
                <div>{singleData?.invoiceNumber}</div>
              </div>
              <div
                style={{
                  textDecoration: "none",
                  display: "flex",
                  fontSize: "25px",
                }}
              >
                <div style={{ width: "270px" }}>Date</div>
                <div>
                  {" "}
                  {moment(singleData?.invoiceDate).format("DD MMM YYYY")}
                </div>
              </div>
              <div
                style={{
                  fontSize: "25px",
                  display: "flex",
                }}
              >
                <div style={{ width: "270px" }}>Order No.</div>
                <div>{singleData?.orderNumber}</div>
              </div>
              <div
                style={{
                  fontSize: "25px",
                  display: "flex",
                }}
              >
                <div style={{ width: "270px" }}>Order Date</div>
                <div>
                  {moment(singleData?.orderHistory[0]?.createdAt).format(
                    "DD MMM YYYY",
                  )}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            border: "1px solid black",
            width: "100%",
          }}
        ></div>

        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              width: "50%",
            }}
          >
            <div
              style={{
                fontSize: "25px",
                fontWeight: "700",
                textAlign: "center",
                paddingTop: "12px",
                paddingBottom: "12px",
              }}
            >
              Bill To
            </div>
            <div
              style={{
                border: "1px solid black",
                width: "100%",
              }}
            ></div>
            <div
              style={{
                paddingLeft: "10px",
                paddingBottom: "10px",
                paddingTop: "15px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", fontSize: "25px" }}>M/s. :</div>
                <div style={{ fontSize: "25px", paddingLeft: "10px" }}>
                  <div style={{ fontWeight: "700" }}>
                    {singleData?.buyer?.firstName}
                  </div>
                  <div>
                    {" "}
                    {
                      singleData?.orderItems[0]?.sellingProduct?.warehouse
                        .address.addressLineOne
                    }
                  </div>
                  <div>
                    {
                      singleData?.orderItems[0]?.sellingProduct?.warehouse
                        .address.addressLineTwo
                    }
                  </div>
                  <div style={{ fontWeight: "700" }}>
                    {
                      singleData?.orderItems[0]?.sellingProduct?.warehouse
                        .address.city
                    }
                    -
                    {
                      singleData?.orderItems[0]?.sellingProduct?.warehouse
                        .address.pincode
                    }
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                  width: "270px",
                }}
              >
                Place of Supply
              </div>
              <div>{singleData?.buyer?.state}</div>
            </div>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                  width: "270px",
                }}
              >
                Mobile
              </div>
              <div>{singleData?.buyer?.mobile}</div>
            </div>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                  width: "270px",
                }}
              >
                GSTIN No.
              </div>
              <div> {singleData?.buyer?.gstNumber}</div>
            </div>
          </div>
          <div
            style={{
              width: "50%",
              borderLeft: "1px solid black",
              paddingBottom: "15px",
            }}
          >
            <div
              style={{
                fontSize: "25px",
                fontWeight: "700",
                textAlign: "center",
                paddingTop: "12px",
                paddingBottom: "12px",
              }}
            >
              Ship To
            </div>
            <div style={{ border: "1px solid black", width: "100%" }}></div>
            <div
              style={{
                paddingLeft: "10px",
                paddingBottom: "10px",
                paddingTop: "15px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", fontSize: "32px" }}>M/s. :</div>
                <div style={{ fontSize: "25px", paddingLeft: "10px" }}>
                  <div style={{ fontWeight: "700" }}>
                    {singleData?.seller?.firstName}
                  </div>
                  <div>{singleData?.shippingAddress?.addressLineOne} </div>
                  <div> {singleData?.shippingAddress?.addressLineTwo}</div>
                  <div style={{ fontWeight: "700" }}>
                    {singleData?.shippingAddress?.city}-
                    {singleData?.shippingAddress?.pincode}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                  width: "270px",
                }}
              >
                Place of Supply
              </div>
              <div>{singleData?.buyer?.state}</div>
            </div>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                  width: "270px",
                }}
              >
                Mobile
              </div>
              <div>{singleData?.buyer?.mobile} </div>
            </div>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                  width: "270px",
                }}
              >
                GSTIN No.
              </div>
              <div> {singleData?.buyer?.gstNumber}</div>
            </div>
          </div>
        </div>
        <div>
          <table
            style={{
              fontFamily: "arial",
              borderCollapse: "collapse",
              width: "100%",
              border: "1px solid black",
            }}
          >
            <tr
              style={{
                fontSize: "25px",
              }}
            >
              <td
                style={{
                  paddingLeft: "32px",
                  textAlign: "start",
                  width: "5%",
                  border: "1px solid black",
                }}
              >
                SrNo
              </td>
              <td
                style={{
                  paddingLeft: "32px",
                  textAlign: "start",
                  width: "20%",
                  border: "1px solid black",
                }}
              >
                Product Name
              </td>
              <td
                style={{
                  paddingLeft: "32px",
                  textAlign: "start",
                  width: "8%",
                  border: "1px solid black",
                }}
              >
                HSN/SAC
              </td>
              <td
                style={{
                  paddingLeft: "32px",
                  textAlign: "start",
                  width: "9%",
                  border: "1px solid black",
                }}
              >
                Qty
              </td>
              <td
                style={{
                  paddingLeft: "32px",
                  textAlign: "start",
                  width: "8%",
                  border: "1px solid black",
                }}
              >
                Unit
              </td>
              <td
                style={{
                  paddingLeft: "32px",
                  textAlign: "start",
                  width: "7%",
                  border: "1px solid black",
                }}
              >
                Rate/<sub>kg</sub>
              </td>
              <td
                style={{
                  paddingLeft: "32px",
                  textAlign: "start",
                  width: "12%",
                  border: "1px solid black",
                }}
              >
                SubTotal
              </td>

              <td
                style={{
                  paddingLeft: "32px",
                  textAlign: "start",
                  width: "7%",
                  border: "1px solid black",
                }}
              >
                IGST %
              </td>
              <td
                style={{
                  paddingLeft: "32px",
                  textAlign: "start",
                  width: "12%",
                  border: "1px solid black",
                }}
              >
                Tax Amount
              </td>

              <td
                style={{
                  paddingLeft: "32px",
                  textAlign: "start",
                  width: "12%",
                  border: "1px solid black",
                }}
              >
                Amount
              </td>
            </tr>
            <tbody
              style={{
                maxHeight: "500px",
                textAlign: "start",
              }}
            >
              {singleData?.orderItems.map((item, index) => (
                <>
                  <tr
                    style={{
                      fontSize: "25px",
                      height: "15px",
                      textAlign: "start",
                    }}
                  >
                    <td
                      style={{
                        border: "1px solid",
                        paddingLeft: "32px",
                        textAlign: "start",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        border: "1px solid",
                        paddingLeft: "32px",
                        textAlign: "start",
                      }}
                    >
                      {item?.sellingProduct?.product?.name}
                    </td>
                    <td
                      style={{
                        border: "1px solid",
                        paddingLeft: "32px",
                        textAlign: "start",
                      }}
                    >
                      {item?.sellingProduct?.product?.hsn
                        ? item?.sellingProduct?.product?.hsn
                        : "-----"}
                    </td>
                    <td
                      style={{
                        border: "1px solid",
                        paddingLeft: "32px",
                        textAlign: "start",
                      }}
                    >
                      {item?.qty}
                    </td>
                    <td
                      style={{
                        border: "1px solid",
                        paddingLeft: "32px",
                        textAlign: "start",
                      }}
                    >
                      MT
                    </td>
                    <td
                      style={{
                        border: "1px solid",
                        paddingLeft: "32px",
                        textAlign: "start",
                      }}
                    >
                      {item?.price}
                    </td>
                    <td
                      style={{
                        border: "1px solid",
                        paddingLeft: "32px",
                        textAlign: "start",
                      }}
                    >
                      {item?.subTotal}
                    </td>
                    <td
                      style={{
                        border: "1px solid",
                        paddingLeft: "32px",
                        textAlign: "start",
                      }}
                    >
                      18%
                    </td>
                    <td
                      style={{
                        border: "1px solid",
                        paddingLeft: "32px",
                        textAlign: "start",
                      }}
                    >
                      {item?.tax}
                    </td>
                    <td
                      style={{
                        border: "1px solid",
                        paddingLeft: "32px",
                        textAlign: "start",
                      }}
                    >
                      {item?.total}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "69%",
              borderRight: "1px solid black",
            }}
          >
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                  width: "270px",
                }}
              >
                Place of Supply
              </div>
              <div>29-Karnataka</div>
            </div>
          </div>
          <div
            style={{
              width: "31%",
            }}
          >
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                }}
              >
                Sub Total
              </div>
              <div
                style={{
                  fontSize: "25px",
                }}
              >
                {singleData?.subTotal}
              </div>
            </div>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                }}
              >
                Tax Amount
              </div>
              <div
                style={{
                  fontSize: "25px",
                }}
              >
                {singleData?.subTotal}
              </div>
            </div>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                }}
              >
                Trans port
              </div>
              <div
                style={{
                  fontSize: "25px",
                }}
              >
                {singleData?.subTotal}
              </div>
            </div>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                }}
              >
                Sub Total
              </div>
              <div
                style={{
                  fontSize: "25px",
                }}
              >
                {singleData?.subTotal}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "69%",
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "10px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                }}
              >
                Bank Name
              </div>
              <div>HDFC BANK LTD</div>
            </div>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "10px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                }}
              >
                Bank A/c. No.
              </div>
              <div>50200053288970</div>
            </div>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "10px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                }}
              >
                RTGS/IFSC Code
              </div>
              <div>HDFC0001714</div>
            </div>
          </div>
          <div
            style={{
              width: "31%",
            }}
          >
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                paddingLeft: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                }}
              >
                FREIGHT
              </div>
              <div
                style={{
                  fontSize: "25px",
                }}
              >
                {singleData?.transportCharge}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", borderBottom: "1px solid black" }}>
          <div
            style={{
              width: "69%",
              borderRight: "1px solid black",
            }}
          >
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
                paddingTop: "10px",
                paddingBottom: "40px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                  width: "270px",
                }}
              >
                Total GST
              </div>
              <div>{singleData?.tax}</div>
            </div>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
                paddingBottom: "40px",
              }}
            >
              <div
                style={{
                  fontSize: "30px",
                  fontWeight: "700",
                  width: "270px",
                }}
              >
                Bill Amount
              </div>
              <div>{singleData?.grandTotal}</div>
            </div>
          </div>
          <div
            style={{
              width: "31%",
            }}
          >
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                }}
              >
                Taxable Amount
              </div>
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                }}
              >
                {singleData?.tax}
              </div>
            </div>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                }}
              >
                Integrated Tax
              </div>
              <div
                style={{
                  fontSize: "25px",
                }}
              >
                532440.00
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid black",
            display: "flex",
            height: "150px",
          }}
        >
          <div style={{ width: "69%", borderRight: "1px solid black" }}>
            <div
              style={{
                fontSize: "25px",
                fontWeight: "700",
              }}
            >
              Ratewise Summary :
            </div>
          </div>
          <div style={{ width: "31%" }}>
            <div
              style={{
                fontSize: "25px",
                display: "flex",
                paddingLeft: "10px",
                justifyContent: "space-between",
                borderBottom: "1px solid black",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                }}
              >
                Grand Total
              </div>
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                }}
              >
                {singleData?.grandTotal}
              </div>
            </div>
            <div
              style={{
                fontSize: "25px",
                fontWeight: "700",
                width: "31%",
              }}
            >
              <div>Note :</div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "69%", paddingLeft: "10px", fontSize: "25px" }}>
            <div
              style={{
                fontSize: "25px",
                fontWeight: "700",
              }}
            >
              Terms & Condition :
            </div>
            <div>1. Goods once sold will not be taken back.</div>
            <div>
              2. Interest @18% p.a. will be charged if payment is not made
              within ____ days of Invoice Date
            </div>
            <div>
              3. Our risk and responsibility ceases as soon as the goods leave
              our premises.
            </div>
            <div>4. Subject to VADODARA Jurisdiction only. E.&.O.E</div>
          </div>
          <div
            style={{
              width: "31%",
              paddingRight: "10px",
              paddingLeft: "100px",
              fontSize: "25px",
            }}
          >
            <div>For, CRYSTAL POLYMERS</div>
            <div>Digitally signed by Saurabh Limbani</div>
            <div>Date: 2023 12 26</div>
            <div> Authorised Signatory </div>
          </div>
        </div>
      </div>
    </div>
  );
};
BuyerInvoice.propTypes = {
  id: PropTypes.any,
};
export default BuyerInvoice;
