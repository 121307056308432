import React, { useCallback, useEffect, useState } from "react";
import ButtonComponent from "../../../@extended/button/ButtonComponent";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CommonTable from "../../../@extended/table/CommonTable";
import "./rolePermissions.css";
import CommonFormDrawer from "../../../@extended/drawer/CommonFormDrawer";
import RolePermissionsForm from "./RolePermissionsForm";
import DeleteModal from "../../../@extended/deletemodal/DeleteModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_ROLE, LIST_ROLE } from "../../../../actions/roleActions";

const RolePermissions = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [state, setState] = React.useState({
    right: false,
  });
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );
  const dispatch = useDispatch();
  const isDeleteSuccess = useSelector(
    (data) => data?.role?.isDeleteRoleSuccess,
  );
  const isAddSuccess = useSelector((data) => data?.role?.isSuccess);
  const isUpdateSuccess = useSelector(
    (data) => data?.role?.isUpdateRoleSuccess,
  );
  const detailsRoleData = useSelector((data) => data?.role?.listRole?.results);
  //list role
  const listRoleData = useCallback(() => {
    dispatch({ type: LIST_ROLE });
  }, []);
  //delete role
  const deleteRole = useCallback((id) => {
    dispatch({ type: DELETE_ROLE, payload: id });
  }, []);

  useEffect(() => {
    listRoleData();
    if (isAddSuccess) {
      listRoleData();
    }
    if (isUpdateSuccess) {
      listRoleData();
    }
    if (isDeleteSuccess) {
      listRoleData();
    }
  }, [isAddSuccess, isUpdateSuccess, isDeleteSuccess]);

  const toggleDrawer = (right, open, params) => (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [right]: open });
    setId(params?.row?.rowId);
  };
  const rows = detailsRoleData?.map((item, ind) => {
    return {
      id: ind + 1,
      name: item?.name,
      actions: "",
      rowId: item?.id,
    };
  });
  const columns = [
    {
      field: "id",
      headerName: "Sr.No",
      flex: 1,
      headerClassName: "rolepermissions-header",
      // width: 870,
    },
    {
      field: "name",
      headerName: "Role Name",
      flex: 1,
      headerClassName: "rolepermissions-header",
      // width: 200,
    },
    {
      field: "action",
      headerName: "	Action",
      flex: 1,
      headerClassName: "rolepermissions-header",
      // width: 200,
      renderCell: (params) => {
        return (
          <Box className="table-btn-main-1">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              onClick={toggleDrawer("right", true, params)}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "role" && data?.edit) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => {
                setDeleteModal(true);
                setId(params?.row?.rowId);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "role" && data?.delete) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
          </Box>
        );
      },
    },
  ];
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box width={"100%"}>
      <Box className="role-btn-padding">
        <ButtonComponent
          name="Add New Role"
          isName={true}
          Icon={AddIcon}
          onClick={toggleDrawer("right", true)}
          disabledbtn={userRoleAccess?.userPermissions?.find(
            (data) =>
              (data?.userModule == "role" && data?.add) ||
              userRoleAccess?.userRoleType == "admin",
          )}
        />
      </Box>
      <Box className="role-common-table">
        <Box className="common-table-box-col">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
          />
        </Box>
      </Box>
      {toggleDrawer && (
        <CommonFormDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          heading={id ? "Edit Role" : "Add New Role"}
          // width="1000px"
          width={isTablet ? "1000px" : isMobile ? "670px" : "400px"}
          childrenComponent={
            <RolePermissionsForm
              isUpdateSuccess={isUpdateSuccess}
              isAddSuccess={isAddSuccess}
              id={id}
              toggleDrawer={toggleDrawer}
            />
          }
        />
      )}
      {deleteModal && (
        <DeleteModal
          id={id}
          deleteData={deleteRole}
          isIcon={true}
          Icon={ErrorOutlineOutlinedIcon}
          open={deleteModal}
          onClick={() => setDeleteModal(false)}
          userModule="role"
        />
      )}
    </Box>
  );
};

export default RolePermissions;
