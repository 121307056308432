import { deleteRequest, get, patch, post } from "../utils/api";

export const sellerList = (data) => {
  const { pageState = 1, limitState = 10, type, search = "" } = data;
  return get(
    `/admin/seller?type=${type}&page=${pageState}&limit=${limitState}&text=${search}`,
  );
};
export const addSeller = (data) => {
  return post(`/admin/seller/`, data);
};
export const deleteSeller = (id) => {
  return deleteRequest(`/admin/seller/${id}`);
};
export const singleSeller = (id) => {
  return get(`/admin/seller/${id}`);
};
export const updateSeller = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/seller/${id}`, data);
};

export const sellerSingleUpdate = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/seller/update/${id}`, data);
};

// ! wareHouse API

export const addWareHouse = (data) => {
  return post(`/admin/warehouse`, data);
};
export const listWareHouse = (data) => {
  const page = data?.pageState || 1;
  const limit = data?.limitState || 10;
  const search = data?.search || "";
  const userType = data?.userType || "";
  return get(
    `/admin/warehouse?page=${page}&limit=${limit}&search=${search}&userType=${userType}`,
  );
};
export const singleWareHouse = (id) => {
  return get(`/admin/warehouse/${id}`);
};

export const updateWareHouse = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/warehouse/${id}`, data);
};

export const deleteWareHouse = (id) => {
  return deleteRequest(`/admin/warehouse/${id}`);
};

export const updateWareHouseStatus = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/warehouse/update/${id}`, data);
};

export const sellerApi = {
  sellerList,
  addSeller,
  deleteSeller,
  singleSeller,
  updateSeller,
  sellerSingleUpdate,
  addWareHouse,
  listWareHouse,
  singleWareHouse,
  updateWareHouse,
  deleteWareHouse,
  updateWareHouseStatus,
};
