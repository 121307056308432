import {
  DELETE_SUBSCRIPTIONS,
  DELETE_SUBSCRIPTIONS_FAIL,
  DELETE_SUBSCRIPTIONS_SUCCESS,
  LIST_SINGLE_SUBSCRIPTIONS,
  LIST_SINGLE_SUBSCRIPTIONS_FAIL,
  LIST_SINGLE_SUBSCRIPTIONS_SUCCESS,
  LIST_SUBSCRIPTIONS,
  LIST_SUBSCRIPTIONS_FAIL,
  LIST_SUBSCRIPTIONS_SUCCESS,
  SUBSCRIPTIONS,
  SUBSCRIPTIONS_FAIL,
  SUBSCRIPTIONS_SUCCESS,
  UPDATE_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTIONS_FAIL,
  UPDATE_SUBSCRIPTIONS_SUCCESS,
} from "../actions/subscriptionsActions";
import { API } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";

function* addSubscriptions(action) {
  try {
    const response = yield call(API.addSubscriptions, action.payload);
    yield put({ type: SUBSCRIPTIONS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: SUBSCRIPTIONS_FAIL, payload: { error: error } });
  }
}
function* listSubscriptions(action) {
  try {
    const response = yield call(API.listSubscriptions, action.payload);
    yield put({ type: LIST_SUBSCRIPTIONS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_SUBSCRIPTIONS_FAIL, payload: { error: error } });
  }
}
function* singleSubscriptions(action) {
  try {
    const response = yield call(API.singleSubscriptions, action.payload);
    yield put({
      type: LIST_SINGLE_SUBSCRIPTIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LIST_SINGLE_SUBSCRIPTIONS_FAIL,
      payload: { error: error },
    });
  }
}
function* updateSubscriptions(action) {
  try {
    const response = yield call(API.updateSubscriptions, action.payload);
    yield put({ type: UPDATE_SUBSCRIPTIONS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_SUBSCRIPTIONS_FAIL, payload: { error: error } });
  }
}
function* deleteSubscriptions(action) {
  try {
    const response = yield call(API.deleteSubscriptions, action.payload);
    yield put({ type: DELETE_SUBSCRIPTIONS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DELETE_SUBSCRIPTIONS_FAIL, payload: { error: error } });
  }
}

function* subscriptions() {
  yield takeLatest(SUBSCRIPTIONS, addSubscriptions);
  yield takeLatest(LIST_SUBSCRIPTIONS, listSubscriptions);
  yield takeLatest(LIST_SINGLE_SUBSCRIPTIONS, singleSubscriptions);
  yield takeLatest(UPDATE_SUBSCRIPTIONS, updateSubscriptions);
  yield takeLatest(DELETE_SUBSCRIPTIONS, deleteSubscriptions);
}
export default subscriptions;
