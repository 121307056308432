/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as _ from "lodash";
import { SELLING_PRODUCT_LIST } from "../../../actions/productActions";
import { BUYER_WITH_WAREHOUSE } from "../../../actions/buyerActions";
import { ORDER_SINGLE } from "../../../actions/orderActions";
import PropTypes from "prop-types";

const OrderView = ({ id }) => {
  const singleData = useSelector((data) => data?.order?.singleOrder);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const singleListOrder = useCallback((data) => {
    dispatch({ type: ORDER_SINGLE, payload: data });
  }, []);

  const productSellingList = useCallback((data) => {
    dispatch({ type: SELLING_PRODUCT_LIST, payload: data });
  });
  const buyerWithWareHouse = useCallback((data) => {
    dispatch({ type: BUYER_WITH_WAREHOUSE, payload: data });
  });

  const buyerWithWareHouseData = useSelector(
    (state) => state?.buyer?.buyerWithWareHouseData,
  );
  useEffect(() => {
    if (id) {
      singleListOrder(id);
    }
  }, [id]);
  useEffect(() => {
    productSellingList();
    buyerWithWareHouse();
  }, []);

  // ! selling product data
  const sellingProductList = useSelector(
    (state) => state?.product?.sellingProductList,
  );

  const singleAddressId = sellingProductList?.results?.map((data) => data?.id);

  const findBuyerAdd = singleData?.data?.orderItems?.find((data) =>
    singleAddressId?.includes(data?.sellingProduct?.id),
  );
  // ! for product name
  const singleId = singleData?.data?.orderItems?.map(
    (data) => data?.sellingProduct?.id,
  );
  const singleProductData = sellingProductList?.results?.find((data) =>
    singleId?.includes(data?.id),
  );

  // ! all rows here
  const rows =
    singleData?.data?.orderItems?.length > 0 &&
    singleData?.data?.orderItems?.map((item, index) => {
      return {
        no: index + 1,
        sellingProduct: item?.sellingProduct?.product?.name,
        qty: item?.qty,
        price: item?.price,
        transportCharge: item?.transportCharge,
        seller: singleData?.data?.seller?.firstName,
        warehouse: item?.sellingProduct?.warehouse?.name,
        id: item?._id,
        subTotal: item?.subTotal,
        tax: item?.tax,
        total: item?.total,
      };
    });

  return (
    <>
      <Box className="order-parent">
        <Box>
          <Formik
            initialValues={{
              buyer: "",
              wareHouse: "",
              shippingAddress: {
                addressLineOne: "",
                addressLineTwo: "",
                city: "",
                country: "",
                pincode: "",
                state: "",
                landMark: "",
              },
            }}
            onSubmit={() => {}}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, values }) => (
              <div className="purchases-form-div">
                <Form noValidate onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid
                      className={
                        isMobile
                          ? "profile-form-main-grid"
                          : "mobile-form-main-grid"
                      }
                    >
                      <Grid
                        item
                        xs={12}
                        md={12}
                        xl={12}
                        className="profile-form-main-input"
                      >
                        <Grid
                          className={
                            isMobile
                              ? "profile-form-main-grid"
                              : "mobile-form-main-grid"
                          }
                        >
                          <Grid
                            item
                            xs={12}
                            md={12}
                            xl={12}
                            className="product-form-main-input"
                          >
                            <Stack spacing={1} sx={{ mb: 2 }}>
                              <InputLabel
                                htmlFor="sellingProduct"
                                className="purchases-lable"
                              >
                                Selling Product*
                              </InputLabel>
                              <Box display={"flex"} gap={"5px"}>
                                <Box width={"100%"}>
                                  <Autocomplete
                                    readOnly
                                    inputValue={
                                      singleProductData?.product?.name
                                    }
                                    disablePortal
                                    size="small"
                                    name="sellingProduct"
                                    renderInput={(params) => {
                                      return (
                                        <TextField
                                          {...params}
                                          placeholder="selling product"
                                        />
                                      );
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid
                          className={
                            isMobile
                              ? "profile-form-main-grid"
                              : "mobile-form-main-grid"
                          }
                        >
                          <Grid
                            item
                            xs={12}
                            md={12}
                            xl={12}
                            sx={{
                              marginBottom: "50px",
                            }}
                          >
                            <Stack spacing={1}>
                              <table
                                width={"100%"}
                                border="1"
                                cellPadding="0"
                                cellSpacing="0"
                                style={{
                                  borderCollapse: "collapse",
                                  borderColor: "#F0F0F0",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>Product Name</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                    <th>Transport Charge</th>
                                    <th>Seller</th>
                                    <th>Warehouse</th>
                                    <th>Subtotal</th>
                                    <th>Tax</th>
                                    <th>Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rows?.length > 0 &&
                                    rows?.map((row, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{row.sellingProduct}</td>
                                          <td>
                                            <TextField
                                              size="small"
                                              value={row.qty}
                                            />
                                          </td>
                                          <td>
                                            <TextField
                                              size="small"
                                              value={row.price}
                                            />
                                          </td>
                                          <td>
                                            <TextField
                                              size="small"
                                              value={row.transportCharge}
                                            />
                                          </td>
                                          <td>{row.seller}</td>
                                          <td>{row.warehouse}</td>
                                          <td>{row.subTotal}</td>
                                          <td>{row.tax}</td>
                                          <td>{row.total}</td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                              <Divider />
                              {/* //! sub total here */}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                className="sub-total-wrapper"
                              >
                                <Box>Grand Total: </Box>
                                <Box>
                                  <Box sx={{ paddingLeft: "50px" }}>
                                    ₹ {singleData?.data?.grandTotal}/-
                                  </Box>
                                </Box>
                              </Box>
                              <Divider />
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            xl={4}
                            className="profile-form-main-input"
                          >
                            <Stack spacing={1}>
                              <InputLabel
                                htmlFor="oderStatus"
                                className="purchases-lable"
                              >
                                Buyer*
                              </InputLabel>
                              <Select
                                labelId="demo-select-small-label"
                                id="buyer"
                                name="buyer"
                                readOnly
                                defaultValue={singleData?.data?.buyer?.id || ""}
                                value={singleData?.data?.buyer?.id || ""}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem
                                  defaultValue={
                                    singleData?.data?.buyer?.id || ""
                                  }
                                  value={singleData?.data?.buyer?.id || ""}
                                >
                                  {singleData?.data?.buyer?.firstName}
                                </MenuItem>
                              </Select>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            xl={4}
                            className="profile-form-main-input"
                          >
                            <Stack spacing={1}>
                              <InputLabel
                                htmlFor="oderStatus"
                                className="purchases-lable"
                              >
                                WareHouse*
                              </InputLabel>
                              <Select
                                labelId="demo-select-small-label"
                                id="wareHouse"
                                readOnly
                                name="wareHouse"
                                defaultValue={
                                  findBuyerAdd?.sellingProduct?.warehouse?.id ||
                                  ""
                                }
                                value={
                                  findBuyerAdd?.sellingProduct?.warehouse?.id ||
                                  ""
                                }
                                onChange={handleChange}
                                fullWidth
                              >
                                {buyerWithWareHouseData[0]?.warehouse?.length >
                                  0 &&
                                  buyerWithWareHouseData[0]?.warehouse?.map(
                                    (warehouse, index) => {
                                      return (
                                        <MenuItem
                                          defaultValue={
                                            findBuyerAdd?.sellingProduct
                                              ?.warehouse?.id || ""
                                          }
                                          value={
                                            findBuyerAdd?.sellingProduct
                                              ?.warehouse?.id || ""
                                          }
                                          key={index}
                                        >
                                          {warehouse?.name}
                                        </MenuItem>
                                      );
                                    },
                                  )}
                              </Select>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            xl={4}
                            className="profile-form-main-input"
                          >
                            <Stack spacing={1}>
                              <InputLabel
                                htmlFor="invoice"
                                className="purchases-lable"
                              >
                                Address Line 1*
                              </InputLabel>
                              <OutlinedInput
                                id="shippingAddress.addressLineOne"
                                fullWidth
                                value={
                                  findBuyerAdd?.sellingProduct?.warehouse
                                    ?.address?.addressLineOne
                                }
                                readOnly
                                name="shippingAddress.addressLineOne"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Please Enter Location"
                              />
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            xl={4}
                            className="expense-category-form-input"
                          >
                            <Stack spacing={1}>
                              <InputLabel htmlFor="addreshLine2">
                                Address line 2*
                              </InputLabel>
                              <TextField
                                size="small"
                                id="shippingAddress.addressLineTwo"
                                fullWidth
                                value={
                                  findBuyerAdd?.sellingProduct?.warehouse
                                    ?.address?.addressLineTwo
                                }
                                readOnly
                                name="shippingAddress.addressLineTwo"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Please Enter Address Line 2"
                              />
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            xl={4}
                            className="expense-category-form-input"
                          >
                            <Stack spacing={1}>
                              <InputLabel htmlFor="city"> City*</InputLabel>
                              <TextField
                                size="small"
                                id="shippingAddress.city"
                                fullWidth
                                value={
                                  findBuyerAdd?.sellingProduct?.warehouse
                                    ?.address?.city
                                }
                                readOnly
                                name="shippingAddress.city"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Please Enter City"
                              />
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            xl={4}
                            md={4}
                            className="expense-category-form-input"
                          >
                            <Stack spacing={1}>
                              <InputLabel htmlFor="country">
                                {" "}
                                pincode*{" "}
                              </InputLabel>
                              <TextField
                                size="small"
                                id="shippingAddress.pincode"
                                value={
                                  findBuyerAdd?.sellingProduct?.warehouse
                                    ?.address?.pincode
                                }
                                readOnly
                                name="shippingAddress.pincode"
                                onBlur={handleBlur}
                                type="number"
                                onChange={handleChange}
                                placeholder="Please Enter Post Code"
                                fullWidth
                              />
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            xl={4}
                            md={4}
                            className="expense-category-form-input"
                          >
                            <Stack spacing={1}>
                              <InputLabel htmlFor="city"> State*</InputLabel>
                              <TextField
                                size="small"
                                id="shippingAddress.state"
                                fullWidth
                                value={
                                  findBuyerAdd?.sellingProduct?.warehouse?.state
                                }
                                readOnly
                                name="shippingAddress.state"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Please Enter state"
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* //! save button for future use */}
                    {/* <Box className="puchases-create-button">
                      <ButtonComponent
                        className="puchases-create-button-width"
                        name={"Save"}
                        isName={true}
                        Icon={SaveAsIcon}
                        type="submit"
                        disabledbtn={userRoleAccess?.userPermissions?.find(
                          (data) =>
                            data?.userModule == "order" ||
                            userRoleAccess?.userRoleType == "admin",
                        )}
                      />
                    </Box> */}
                  </Grid>
                </Form>
              </div>
            )}
          </Formik>
        </Box>
        <Box className="history-title">Order History</Box>
        <table
          width={"100%"}
          border="1"
          cellPadding="0"
          cellSpacing="0"
          style={{
            borderCollapse: "collapse",
            borderColor: "#F0F0F0",
          }}
        >
          <thead>
            <tr>
              <th>No</th>
              <th>Create Date</th>
              <th>User</th>
              <th>Update Date</th>
              <th>Event</th>
              <th>Message</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {singleData?.data?.orderHistory?.length > 0 &&
              singleData?.data?.orderHistory?.map((history, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {new Date(history?.createdAt).toLocaleDateString(
                        "en-US",
                      ) || "-"}
                    </td>
                    <td>{history.userId?.firstName || "-"}</td>
                    <td>
                      {new Date(history?.updateAt).toLocaleDateString(
                        "en-US",
                      ) || "-"}
                    </td>
                    <td>{history.event || "-"}</td>
                    <td>{history.message || "-"}</td>
                    <td>{history.note || "-"}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Box>
    </>
  );
};

OrderView.propTypes = {
  singleData: PropTypes.any,
  id: PropTypes.any,
};

export default OrderView;
