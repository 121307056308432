import React from "react";
import Companies from "../../components/dashboard/companies/Companies";

const CompaniesPage = () => {
  return (
    <>
      <Companies />
    </>
  );
};

export default CompaniesPage;
