import toast from "react-hot-toast";
import {
  BRANCH,
  BRANCH_FAIL,
  BRANCH_SUCCESS,
  CURRENCIES,
  CURRENCIES_FAIL,
  CURRENCIES_SUCCESS,
  CUSTOM_FIELD,
  CUSTOM_FIELD_FAIL,
  CUSTOM_FIELD_SUCCESS,
  DELETE_BRANCH,
  DELETE_BRANCH_FAIL,
  DELETE_BRANCH_SUCCESS,
  DELETE_CURRENCIES,
  DELETE_CURRENCIES_FAIL,
  DELETE_CURRENCIES_SUCCESS,
  DELETE_CUSTOM_FIELD,
  DELETE_CUSTOM_FIELD_FAIL,
  DELETE_CUSTOM_FIELD_SUCCESS,
  DELETE_PAYMENT_MODE,
  DELETE_PAYMENT_MODE_FAIL,
  DELETE_PAYMENT_MODE_SUCCESS,
  DELETE_TAX,
  DELETE_TAX_FAIL,
  DELETE_TAX_SUCCESS,
  DELETE_UNIT,
  DELETE_UNIT_FAIL,
  DELETE_UNIT_SUCCESS,
  LIST_BRANCH,
  LIST_BRANCH_FAIL,
  LIST_BRANCH_SUCCESS,
  LIST_CURRENCIES,
  LIST_CURRENCIES_FAIL,
  LIST_CURRENCIES_SUCCESS,
  LIST_CUSTOM_FIELD,
  LIST_CUSTOM_FIELD_FAIL,
  LIST_CUSTOM_FIELD_SUCCESS,
  LIST_PAYMENT_MODE,
  LIST_PAYMENT_MODE_FAIL,
  LIST_PAYMENT_MODE_SUCCESS,
  LIST_SINGLE_CURRENCIES,
  LIST_SINGLE_CURRENCIES_FAIL,
  LIST_SINGLE_CURRENCIES_SUCCESS,
  LIST_SINGLE_CUSTOM_FIELD,
  LIST_SINGLE_CUSTOM_FIELD_FAIL,
  LIST_SINGLE_CUSTOM_FIELD_SUCCESS,
  LIST_SINGLE_PAYMENT_MODE,
  LIST_SINGLE_PAYMENT_MODE_FAIL,
  LIST_SINGLE_PAYMENT_MODE_SUCCESS,
  LIST_SINGLE_TAX,
  LIST_SINGLE_TAX_FAIL,
  LIST_SINGLE_TAX_SUCCESS,
  LIST_SINGLE_UNIT,
  LIST_SINGLE_UNIT_FAIL,
  LIST_SINGLE_UNIT_SUCCESS,
  LIST_TAX,
  LIST_TAX_FAIL,
  LIST_TAX_SUCCESS,
  LIST_UNIT,
  LIST_UNIT_FAIL,
  LIST_UNIT_SUCCESS,
  PAYMENT_MODE,
  PAYMENT_MODE_FAIL,
  PAYMENT_MODE_SUCCESS,
  TAX,
  TAX_FAIL,
  TAX_SUCCESS,
  UNIT,
  UNIT_FAIL,
  UNIT_SUCCESS,
  UPDATE_BRANCH,
  UPDATE_BRANCH_FAIL,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_CURRENCIES,
  UPDATE_CURRENCIES_FAIL,
  UPDATE_CURRENCIES_SUCCESS,
  UPDATE_CUSTOM_FIELD,
  UPDATE_CUSTOM_FIELD_FAIL,
  UPDATE_CUSTOM_FIELD_SUCCESS,
  UPDATE_PAYMENT_MODE,
  UPDATE_PAYMENT_MODE_FAIL,
  UPDATE_PAYMENT_MODE_SUCCESS,
  UPDATE_TAX,
  UPDATE_TAX_FAIL,
  UPDATE_TAX_SUCCESS,
  UPDATE_UNIT,
  UPDATE_UNIT_FAIL,
  UPDATE_UNIT_SUCCESS,
} from "../actions/settingActions";

const initialState = {
  branchData: {},
  listBranch: {},
  updateBranch: {},
  isLoading: false,
  isSuccess: false,
  isDeleteSuccess: false,
  userRole: {},
  listRole: {},
  listSingleRole: {},
  updateRole: {},
  isAddRoleSuccess: false,
  isUpdataRoleSuccess: false,
  isDeleteRoleSuccess: false,
  addUnit: {},
  listUnit: {},
  singleUnit: {},
  updateUnit: {},
  deleteUnit: {},
  isDeleteUnitSuccess: false,
  isAddUnitSuccess: false,
  isUpdataUnitSuccess: false,
  addTax: {},
  listTax: {},
  singleTax: {},
  updateTax: {},
  deleteTax: {},
  isAddTaxSuccess: false,
  isUpdataTaxSuccess: false,
  isDeleteTaxSuccess: false,
  addPaymentMode: {},
  listPaymentMode: {},
  singlePaymentMode: {},
  updatePaymentMode: {},
  deletePaymentMode: {},
  isAddPaymentSuccess: false,
  isUpdataPaymentSuccess: false,
  isDeletePaymentSuccess: false,
  addCurrency: {},
  listCurrency: {},
  singleCurrency: {},
  updateCurrency: {},
  deleteCurrency: {},
  isAddCurrencySuccess: false,
  isUpdataCurrencySuccess: false,
  isDeleteCurrencySuccess: false,
  addCustomField: {},
  listCustomField: {},
  singleCustomField: {},
  updateCustomField: {},
  deleteCustomField: {},
  isAddCustomFieldSuccess: false,
  isUpdateCustomFieldSuccess: false,
  isDeleteCustomFieldSuccess: false,
  error: "",
};
const settingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    //Branch Reducer
    case BRANCH: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }
    case BRANCH_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        branchData: payload.user,
        isLoading: false,
        isSuccess: true,
      };
    }

    case BRANCH_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
      };
    }

    case LIST_BRANCH: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_BRANCH_SUCCESS: {
      return {
        ...state,
        listBranch: payload.data,
        isLoading: false,
      };
    }

    case LIST_BRANCH_FAIL: {
      return {
        ...state,
      };
    }

    case UPDATE_BRANCH: {
      return {
        ...state,
        isLoading: true,
        isUpdateSuccess: false,
      };
    }

    case UPDATE_BRANCH_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        updateBranch: payload,
        isUpdateSuccess: true,
      };
    }

    case UPDATE_BRANCH_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isUpdateSuccess: true,
      };
    }
    case DELETE_BRANCH: {
      return {
        ...state,
        isLoading: true,
        isDeleteSuccess: false,
      };
    }

    case DELETE_BRANCH_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteSuccess: true,
      };
    }

    case DELETE_BRANCH_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteSuccess: false,
      };
    }

    // Unit Reducer

    case UNIT: {
      return {
        ...state,
        isLoading: true,
        isAddUnitSuccess: false,
      };
    }
    case UNIT_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        addUnit: payload.user,
        isLoading: false,
        isAddUnitSuccess: true,
      };
    }

    case UNIT_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isAddUnitSuccess: false,
      };
    }
    case LIST_UNIT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_UNIT_SUCCESS: {
      return {
        ...state,
        listUnit: payload.data,
        isLoading: false,
      };
    }

    case LIST_UNIT_FAIL: {
      return {
        ...state,
      };
    }

    case LIST_SINGLE_UNIT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_SINGLE_UNIT_SUCCESS: {
      return {
        ...state,
        singleUnit: payload.data,
        isLoading: false,
      };
    }

    case LIST_SINGLE_UNIT_FAIL: {
      return {
        ...state,
      };
    }
    case UPDATE_UNIT: {
      return {
        ...state,
        isLoading: true,
        isUpdataUnitSuccess: false,
      };
    }

    case UPDATE_UNIT_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        updateUnit: payload,
        isLoading: false,
        isUpdataUnitSuccess: true,
      };
    }

    case UPDATE_UNIT_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isUpdataUnitSuccess: false,
      };
    }
    case DELETE_UNIT: {
      return {
        ...state,
        isLoading: true,
        isDeleteUnitSuccess: false,
      };
    }

    case DELETE_UNIT_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteUnitSuccess: true,
      };
    }

    case DELETE_UNIT_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteUnitSuccess: false,
      };
    }
    // Tax Reducer

    case TAX: {
      return {
        ...state,
        isLoading: true,
        isAddTaxSuccess: false,
      };
    }
    case TAX_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        addTax: payload.user,
        isLoading: false,
        isAddTaxSuccess: true,
      };
    }

    case TAX_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isAddTaxSuccess: false,
      };
    }
    case LIST_TAX: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_TAX_SUCCESS: {
      return {
        ...state,
        listTax: payload.data,
        isLoading: false,
      };
    }

    case LIST_TAX_FAIL: {
      return {
        ...state,
      };
    }

    case LIST_SINGLE_TAX: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_SINGLE_TAX_SUCCESS: {
      return {
        ...state,
        singleTax: payload.data,
        isLoading: false,
      };
    }

    case LIST_SINGLE_TAX_FAIL: {
      return {
        ...state,
      };
    }
    case UPDATE_TAX: {
      return {
        ...state,
        isLoading: true,
        isUpdataTaxSuccess: false,
      };
    }

    case UPDATE_TAX_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        updateTax: payload,
        isLoading: false,
        isUpdataTaxSuccess: true,
      };
    }

    case UPDATE_TAX_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isUpdataTaxSuccess: false,
      };
    }
    case DELETE_TAX: {
      return {
        ...state,
        isLoading: true,
        isDeleteTaxSuccess: false,
      };
    }

    case DELETE_TAX_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteTaxSuccess: true,
      };
    }

    case DELETE_TAX_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteTaxSuccess: false,
      };
    }

    // Payment mode Reducer
    case PAYMENT_MODE: {
      return {
        ...state,
        isLoading: true,
        isAddPaymentSuccess: false,
      };
    }
    case PAYMENT_MODE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        addPaymentMode: payload.user,
        isLoading: false,
        isAddPaymentSuccess: true,
      };
    }

    case PAYMENT_MODE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isAddPaymentSuccess: false,
      };
    }
    case LIST_PAYMENT_MODE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_PAYMENT_MODE_SUCCESS: {
      return {
        ...state,
        listPaymentMode: payload.data,
        isLoading: false,
      };
    }

    case LIST_PAYMENT_MODE_FAIL: {
      return {
        ...state,
      };
    }

    case LIST_SINGLE_PAYMENT_MODE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_SINGLE_PAYMENT_MODE_SUCCESS: {
      return {
        ...state,
        singlePaymentMode: payload.data,
        isLoading: false,
      };
    }

    case LIST_SINGLE_PAYMENT_MODE_FAIL: {
      return {
        ...state,
      };
    }
    case UPDATE_PAYMENT_MODE: {
      return {
        ...state,
        isLoading: true,
        isUpdataPaymentSuccess: false,
      };
    }

    case UPDATE_PAYMENT_MODE_SUCCESS: {
      // toast.success(payload?.message);
      toast.success(payload?.message);
      return {
        ...state,
        updatePaymentMode: payload,
        isLoading: false,
        isUpdataPaymentSuccess: true,
      };
    }

    case UPDATE_PAYMENT_MODE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isUpdataPaymentSuccess: false,
      };
    }
    case DELETE_PAYMENT_MODE: {
      return {
        ...state,
        isLoading: true,
        isDeletePaymentSuccess: false,
      };
    }

    case DELETE_PAYMENT_MODE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isDeletePaymentSuccess: true,
      };
    }

    case DELETE_PAYMENT_MODE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isDeletePaymentSuccess: false,
      };
    }
    // Currency Reducer

    case CURRENCIES: {
      return {
        ...state,
        isLoading: true,
        isAddCurrencySuccess: false,
      };
    }
    case CURRENCIES_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        addCurrency: payload.user,
        isLoading: false,
        isAddCurrencySuccess: true,
      };
    }

    case CURRENCIES_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isAddCurrencySuccess: false,
      };
    }
    case LIST_CURRENCIES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_CURRENCIES_SUCCESS: {
      return {
        ...state,
        listCurrency: payload.data,
        isLoading: false,
      };
    }

    case LIST_CURRENCIES_FAIL: {
      return {
        ...state,
      };
    }

    case LIST_SINGLE_CURRENCIES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_SINGLE_CURRENCIES_SUCCESS: {
      return {
        ...state,
        singleCurrency: payload.data,
        isLoading: false,
      };
    }

    case LIST_SINGLE_CURRENCIES_FAIL: {
      return {
        ...state,
      };
    }
    case UPDATE_CURRENCIES: {
      return {
        ...state,
        isLoading: true,
        isUpdataCurrencySuccess: false,
      };
    }

    case UPDATE_CURRENCIES_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        updateCurrency: payload,
        isLoading: false,
        isUpdataCurrencySuccess: true,
      };
    }

    case UPDATE_CURRENCIES_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isUpdataCurrencySuccess: false,
      };
    }
    case DELETE_CURRENCIES: {
      return {
        ...state,
        isLoading: true,
        isDeleteCurrencySuccess: false,
      };
    }

    case DELETE_CURRENCIES_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteCurrencySuccess: true,
      };
    }

    case DELETE_CURRENCIES_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteCurrencySuccess: false,
      };
    }

    //custom reducer
    case CUSTOM_FIELD: {
      return {
        ...state,
        isLoading: true,
        isAddCustomFieldSuccess: false,
      };
    }
    case CUSTOM_FIELD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        addCustomField: payload.user,
        isLoading: false,
        isAddCustomFieldSuccess: true,
      };
    }

    case CUSTOM_FIELD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isAddCustomFieldSuccess: false,
      };
    }
    case LIST_CUSTOM_FIELD: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_CUSTOM_FIELD_SUCCESS: {
      return {
        ...state,
        listCustomField: payload.data,
        isLoading: false,
      };
    }

    case LIST_CUSTOM_FIELD_FAIL: {
      return {
        ...state,
      };
    }

    case LIST_SINGLE_CUSTOM_FIELD: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LIST_SINGLE_CUSTOM_FIELD_SUCCESS: {
      return {
        ...state,
        singleCustomField: payload.data,
        isLoading: false,
      };
    }

    case LIST_SINGLE_CUSTOM_FIELD_FAIL: {
      return {
        ...state,
      };
    }
    case UPDATE_CUSTOM_FIELD: {
      return {
        ...state,
        isLoading: true,
        isUpdateCustomFieldSuccess: false,
      };
    }

    case UPDATE_CUSTOM_FIELD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        updateCustomField: payload,
        isLoading: false,
        isUpdateCustomFieldSuccess: true,
      };
    }

    case UPDATE_CUSTOM_FIELD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isUpdateCustomFieldSuccess: false,
      };
    }
    case DELETE_CUSTOM_FIELD: {
      return {
        ...state,
        isLoading: true,
        isDeleteCustomFieldSuccess: false,
      };
    }

    case DELETE_CUSTOM_FIELD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteCustomFieldSuccess: true,
      };
    }

    case DELETE_CUSTOM_FIELD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: false,
        isDeleteCustomFieldSuccess: false,
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default settingReducer;
