import React, { useCallback, useState } from "react";

// material-ui
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  useMediaQuery,
  useTheme,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

// project import
import AnimateButton from "../@extended/AnimateButton";
import { CHANGE_PASSWORD } from "../../actions/authActions";

const AuthChangePassword = () => {
  const dispatch = useDispatch();

  const changePassword = useCallback((values) => {
    dispatch({ type: CHANGE_PASSWORD, payload: values });
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCnfNewPassword, setShowCnfNewPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Box className={isMobile ? "main-div" : "mobile-main-div"}>
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object().shape({
            currentPassword: Yup.string()
              .max(255)
              .required("Current Password is required"),
            newPassword: Yup.string()
              .max(255)
              .required("New Password is required"),
            confirmPassword: Yup.string()
              .max(255)
              .required("Confirm Password is required")
              .oneOf(
                [Yup.ref("newPassword"), null],
                "Passwords does not match",
              ),
          })}
          onSubmit={(values) => {
            delete values.confirmPassword;
            changePassword(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <div className="profile-form-div">
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email-login">
                        Current Password
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.currentPassword && errors.currentPassword,
                        )}
                        id="password"
                        type={showPassword ? "text" : "password"}
                        value={values.currentPassword}
                        name="currentPassword"
                        onBlur={handleBlur}
                        placeholder="Please Enter Password"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{}}
                      />
                      {touched.currentPassword && errors.currentPassword && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-email-login"
                        >
                          {errors.currentPassword}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email-login">
                        New Password
                      </InputLabel>

                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.newPassword && errors.newPassword,
                        )}
                        id="password"
                        type={showNewPassword ? "text" : "password"}
                        value={values.newPassword}
                        name="newPassword"
                        onBlur={handleBlur}
                        placeholder="Enter New Password"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowNewPassword(!showNewPassword)
                              }
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showNewPassword ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{}}
                      />
                      {touched.newPassword && errors.newPassword && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-email-login"
                        >
                          {errors.newPassword}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email-login">
                        Confirm Password
                      </InputLabel>

                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword,
                        )}
                        id="confirmPassword"
                        type={showCnfNewPassword ? "text" : "password"}
                        value={values.confirmPassword}
                        name="confirmPassword"
                        onBlur={handleBlur}
                        placeholder="Enter Confirm Password"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowCnfNewPassword(!showCnfNewPassword)
                              }
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showCnfNewPassword ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{}}
                      />
                      {touched.confirmPassword && errors.confirmPassword && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-email-login"
                        >
                          {errors.confirmPassword}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>

                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <AnimateButton>
                      <Button
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </AnimateButton>
                  </Grid>
                </Grid>
              </form>
            </div>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default AuthChangePassword;
