import {
  CONFIRM_ORDER,
  CONFIRM_ORDER_FAIL,
  CONFIRM_ORDER_SUCCESS,
  ORDER_ADD,
  ORDER_ADD_FAIL,
  ORDER_ADD_SUCCESS,
  ORDER_LIST,
  ORDER_LIST_FAIL,
  ORDER_LIST_SUCCESS,
  ORDER_PAYMENT,
  ORDER_PAYMENT_FAIL,
  ORDER_PAYMENT_SUCCESS,
  ORDER_SINGLE,
  ORDER_SINGLE_FAIL,
  ORDER_SINGLE_SUCCESS,
  ORDER_SINGLE_UPDATE,
  ORDER_SINGLE_UPDATE_FAIL,
  ORDER_SINGLE_UPDATE_SUCCESS,
  ORDER_STATUS,
  ORDER_STATUS_FAIL,
  ORDER_STATUS_SUCCESS,
  ORDER_UPDATE,
  ORDER_UPDATE_FAIL,
  ORDER_UPDATE_SUCCESS,
} from "../actions/orderActions";
import { API } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";

function* orderList(action) {
  try {
    const response = yield call(API.orderList, action.payload);
    yield put({ type: ORDER_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ORDER_LIST_FAIL, payload: { error: error } });
  }
}

function* addOrder(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.addOrder, otherPayload);
    yield put({ type: ORDER_ADD_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: ORDER_ADD_FAIL, payload: { error: error } });
  }
}
function* singleOrder(action) {
  try {
    const response = yield call(API.singleOrder, action.payload);
    yield put({ type: ORDER_SINGLE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ORDER_SINGLE_FAIL, payload: { error: error } });
  }
}
function* orderSingleUpdate(action) {
  try {
    const response = yield call(API.orderSingleUpdate, action.payload);
    yield put({ type: ORDER_SINGLE_UPDATE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ORDER_SINGLE_UPDATE_FAIL, payload: { error: error } });
  }
}
function* orderUpdate(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.orderUpdate, otherPayload);
    yield put({ type: ORDER_UPDATE_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: ORDER_UPDATE_FAIL, payload: { error: error } });
  }
}
function* confirmOrder(action) {
  try {
    const response = yield call(API.confirmOrder, action.payload);
    yield put({ type: CONFIRM_ORDER_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CONFIRM_ORDER_FAIL, payload: { error: error } });
  }
}
function* orderPayment(action) {
  try {
    const response = yield call(API.orderPayment, action.payload);
    yield put({ type: ORDER_PAYMENT_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ORDER_PAYMENT_FAIL, payload: { error: error } });
  }
}
function* orderStatus(action) {
  try {
    const response = yield call(API.orderStatus, action.payload);
    yield put({ type: ORDER_STATUS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ORDER_STATUS_FAIL, payload: { error: error } });
  }
}

function* order() {
  yield takeLatest(ORDER_LIST, orderList);
  yield takeLatest(ORDER_ADD, addOrder);
  yield takeLatest(ORDER_SINGLE, singleOrder);
  yield takeLatest(ORDER_SINGLE_UPDATE, orderSingleUpdate);
  yield takeLatest(ORDER_UPDATE, orderUpdate);
  yield takeLatest(CONFIRM_ORDER, confirmOrder);
  yield takeLatest(ORDER_PAYMENT, orderPayment);
  yield takeLatest(ORDER_STATUS, orderStatus);
}
export default order;
