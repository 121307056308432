import React from "react";
import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import * as Yup from "yup";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import {
  ADD_LOCATION,
  LIST_LOCATION,
  SINGLE_LOCATION,
  UPDATE_LOCATION,
} from "../../../actions/locationActions";
import PropTypes from "prop-types";

const LocationForm = (props) => {
  const { onClick, id } = props;
  const dispatch = useDispatch();

  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );

  const listLocation = useCallback((values) => {
    dispatch({ type: LIST_LOCATION, payload: values });
  });
  const updateLocation = useCallback((values) => {
    dispatch({ type: UPDATE_LOCATION, payload: values });
  });

  const singleLocation = useCallback((values) => {
    dispatch({ type: SINGLE_LOCATION, payload: values });
  });

  const singleData = useSelector((data) => data?.location?.locationSingle);
  useEffect(() => {
    listLocation();
  }, []);

  useEffect(() => {
    if (id) {
      singleLocation(id);
    }
  }, [id]);

  return (
    <Box className="expense-category-main-div">
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: id ? singleData?.name : "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Location is required"),
        })}
        onSubmit={(values) => {
          if (id) {
            updateLocation({
              ...values,
              id: id,
              callback: onClick,
            });
          } else {
            dispatch({
              type: ADD_LOCATION,
              payload: { ...values, callback: onClick },
            });
          }
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Box
              maxHeight={"calc(100vh - 300px)"}
              overflow={"auto"}
              padding="10px 5px 40px 5px"
            >
              <Grid container>
                <Grid item xs={12} className="expense-category-form-input">
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">Location Name*</InputLabel>
                    <OutlinedInput
                      autoFocus
                      id="categoryName"
                      size="large"
                      fullWidth
                      value={values?.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Please Enter Location"
                      error={Boolean(touched.name && errors.name)}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error id="helper-text-name">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box className="expense-category-form-drawer-btn-wrapper-create">
              <ButtonComponent
                name={id ? "Update" : "Create"}
                isName={true}
                Icon={SaveAsIcon}
                type="submit"
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "location" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
              <ButtonComponent
                name="Cancel"
                isName={true}
                className="background-white"
                onClick={onClick}
                disabledbtn={userRoleAccess?.userPermissions?.find(
                  (data) =>
                    data?.userModule == "location" ||
                    userRoleAccess?.userRoleType == "admin",
                )}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
LocationForm.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
  singleData: PropTypes.any,
};

export default LocationForm;
