/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import * as _ from "lodash";
import CommonTable from "../../@extended/table/CommonTable";
import { SELLING_PRODUCT_LIST } from "../../../actions/productActions";
import { BUYER_WITH_WAREHOUSE } from "../../../actions/buyerActions";
import {
  ORDER_ADD,
  ORDER_SINGLE,
  ORDER_UPDATE,
} from "../../../actions/orderActions";
import OrderCountTable from "./OrderCountTable";
import allStateList from "../../../state.json";

const OrderForm = ({ onClick, id, isEdit }) => {
  const theme = useTheme();
  const [recentValues, setRecentValues] = useState("");
  const [recentWarehouseValues, setRecentWarehouseValues] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const singleData = useSelector((data) => data?.order?.singleOrder);

  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );
  const singleListOrderData = useSelector((data) => data?.order?.isSuccess);

  // ! new ------------------------------------------------------------------------------------------------
  const singleListOrder = useCallback((data) => {
    dispatch({ type: ORDER_SINGLE, payload: data });
  }, []);

  const productSellingList = useCallback((data) => {
    dispatch({ type: SELLING_PRODUCT_LIST, payload: data });
  });
  const buyerWithWareHouse = useCallback((data) => {
    dispatch({ type: BUYER_WITH_WAREHOUSE, payload: data });
  });

  const buyerWithWareHouseData = useSelector(
    (state) => state?.buyer?.buyerWithWareHouseData,
  );
  // ! selling product data
  const sellingProductList = useSelector(
    (state) => state?.product?.sellingProductList,
  );
  useEffect(() => {
    if (singleData?.data?.orderItems?.[0]?.sellingProduct?.id && isEdit) {
      setRecentValues(singleData?.data?.orderItems?.[0]?.sellingProduct?.id);
    } else {
      setRecentValues("");
    }
  }, [singleData?.data?.orderItems?.[0]?.sellingProduct?.id, isEdit]);
  useEffect(() => {
    if (id) {
      singleListOrder(id);
    }
  }, [id]);
  useEffect(() => {
    productSellingList();
    buyerWithWareHouse();
  }, []);

  useEffect(() => {
    if (id && singleListOrderData) {
      setOrderItems(
        singleData?.data?.orderItems?.map((data) => ({
          ...data,
          sellingProduct: data?.sellingProduct?.id,
        })),
      );
    } else {
      setOrderItems([]);
    }
  }, [singleListOrderData]);

  const singleAddressId = sellingProductList?.results?.map((data) => data?.id);
  const findBuyerAdd = singleData?.data?.orderItems?.find((data) =>
    singleAddressId?.includes(data?.sellingProduct?.id),
  );
  const singleProductData = sellingProductList?.results?.filter(
    (data) => data?.id === recentValues,
  );
  // ! for product name
  const singleId = singleData?.data?.orderItems?.map(
    (data) => data?.sellingProduct?.id,
  );
  const singleProduct = sellingProductList?.results?.find((data) =>
    singleId?.includes(data?.id),
  );
  const orderUpdate = useCallback((data) => {
    dispatch({ type: ORDER_UPDATE, payload: data });
  });
  const [orderItems, setOrderItems] = useState([]);
  // ! here count the grand total
  const grandTotalPrice =
    orderItems?.length > 0 &&
    orderItems?.reduce((acc, currProduct) => {
      const total = currProduct?.price;
      return (
        currProduct?.qty * currProduct?.price * 1000 +
        currProduct?.qty * currProduct?.price * 1000 * 0.18 +
        currProduct?.transportCharge +
        acc
      );
    }, 0);

  // ! all rows here
  const rows =
    singleProductData?.length > 0
      ? singleProductData?.map((item, index) => {
          return {
            no: index + 1,
            sellingProduct: item?.product?.name,
            qty: item,
            price: item?.price,
            transportCharge: item,
            seller: item?.seller?.firstName,
            warehouse: item?.warehouse?.name,
            id: item?.id,
            sellingProductId: item?.id,
          };
        })
      : [];
  // ! for foture use data
  const editRows =
    singleData?.data?.orderItems?.length > 0 &&
    singleData?.data?.orderItems?.map((item, index) => {
      return {
        no: index + 1,
        sellingProduct: item?.sellingProduct?.product?.name,
        qty: item?.qty,
        price: item?.price,
        transportCharge: item?.transportCharge,
        seller: singleData?.data?.seller?.firstName,
        warehouse: item?.sellingProduct?.warehouse?.name,
        id: item?.sellingProduct?.id,
        subTotal: item?.subTotal,
        tax: item?.tax,
        total: item?.total,
        sellingProductId: item?.sellingProduct?.id,
      };
    });

  return (
    <>
      <Box className="order-parent">
        <Box>
          <Formik
            enableReinitialize={true}
            initialValues={{
              buyer: id ? singleData?.data?.buyer?.id || "" : "",
              warehouse: id ? singleData?.data?.warehouse?.id || "  " : "",
              shippingAddress: {
                addressLineOne: id
                  ? findBuyerAdd?.sellingProduct?.warehouse?.address
                      ?.addressLineOne
                  : "",
                addressLineTwo: id
                  ? findBuyerAdd?.sellingProduct?.warehouse?.address
                      ?.addressLineTwo
                  : "",
                city: id
                  ? findBuyerAdd?.sellingProduct?.warehouse?.address?.city
                  : "",
                //   ? findBuyerAdd?.sellingProduct?.warehouse?.address?.country
                pincode: id
                  ? findBuyerAdd?.sellingProduct?.warehouse?.address?.pincode
                  : "",
                //   ? findBuyerAdd?.sellingProduct?.warehouse?.address?.landMark
              },
            }}
            onSubmit={(values) => {
              const finalValues = {
                ...values,
                callback: onClick,
                orderItems: orderItems?.map((data) => {
                  return {
                    qty: data?.qty,
                    price: data?.price,
                    transportCharge: data?.transportCharge,
                    sellingProduct: data?.sellingProduct,
                  };
                }),
              };
              if (id) {
                orderUpdate({ ...finalValues, id: id });
              } else {
                dispatch({
                  type: ORDER_ADD,
                  payload: finalValues,
                });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              values,
            }) => (
              <div className="purchases-form-div">
                <Form noValidate onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid
                      className={
                        isMobile
                          ? "profile-form-main-grid"
                          : "mobile-form-main-grid"
                      }
                    >
                      <Grid
                        item
                        xs={12}
                        md={12}
                        xl={12}
                        className="profile-form-main-input"
                      >
                        <Grid
                          className={
                            isMobile
                              ? "profile-form-main-grid"
                              : "mobile-form-main-grid"
                          }
                        >
                          <Grid
                            item
                            xs={12}
                            md={12}
                            xl={12}
                            className="product-form-main-input"
                          >
                            <Stack spacing={1} sx={{ mb: 2 }}>
                              <InputLabel
                                htmlFor="sellingProduct"
                                className="purchases-lable"
                              >
                                Selling Product*
                              </InputLabel>
                              <Box display={"flex"} gap={"5px"}>
                                <Box width={"100%"}>
                                  <Autocomplete
                                    defaultValue={
                                      id ? singleProduct?.product?.name : ""
                                    }
                                    noOptionsText={
                                      "+ Please add Selling Product"
                                    }
                                    disablePortal
                                    size="small"
                                    options={
                                      (sellingProductList?.results?.length >
                                        0 &&
                                        sellingProductList?.results?.map(
                                          (data, index) => ({
                                            label: data?.product?.name,
                                            value: data?.id,
                                          }),
                                        )) ||
                                      []
                                    }
                                    // value={recentValues}
                                    name="sellingProduct"
                                    onChange={(event, value) => {
                                      setRecentValues(value?.value);
                                      const singleData =
                                        sellingProductList?.results?.find(
                                          (data) => data?.id === value?.value,
                                        );
                                      setOrderItems([
                                        {
                                          qty: 1,
                                          price: singleData?.price,
                                          transportCharge: 0,
                                          sellingProduct: singleData?.id,
                                        },
                                      ]);
                                      // !  in case multi product here...
                                      // if (
                                      //   orderItems
                                      //     ?.map((data) => data?.sellingProduct)
                                      //     ?.includes(singleData?.id)
                                      // ) {
                                      //   setOrderItems((prev) =>
                                      //     prev?.map((data) => {
                                      //       if (
                                      //         data?.sellingProduct ===
                                      //         singleData?.id
                                      //       ) {
                                      //         return {
                                      //           ...data,
                                      //           qty: data?.qty,
                                      //           price: data?.price,
                                      //           transportCharge: 0,
                                      //           sellingProduct: data?.id,
                                      //         };
                                      //       } else {
                                      //         return data;
                                      //       }
                                      //     }),
                                      //   );
                                      // } else {
                                      //   setOrderItems([
                                      //     {
                                      //       qty: 1,
                                      //       price: singleData?.price,
                                      //       transportCharge: 0,
                                      //       sellingProduct: singleData?.id,
                                      //     },
                                      //   ]);
                                      // }
                                    }}
                                    error={
                                      touched.sellingProduct &&
                                      errors.sellingProduct
                                    }
                                    renderInput={(params) => {
                                      const findVal =
                                        sellingProductList?.results?.find(
                                          (data) => data?.id === recentValues,
                                        );
                                      return (
                                        <TextField
                                          {...params}
                                          value={findVal?.name}
                                          placeholder="selling product"
                                        />
                                      );
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid
                          className={
                            isMobile
                              ? "profile-form-main-grid"
                              : "mobile-form-main-grid"
                          }
                        >
                          <Grid
                            item
                            xs={12}
                            md={12}
                            xl={12}
                            // contentSX={{ height: "493px" }}
                            sx={{
                              marginBottom: "50px",
                            }}
                          >
                            <Stack spacing={1}>
                              <Divider />
                              <OrderCountTable
                                rows={rows}
                                orderItems={orderItems}
                                setOrderItems={setOrderItems}
                                id={id}
                              />
                              {/* //! sub total here */}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                className="sub-total-wrapper"
                              >
                                <Box>Grand Total: </Box>
                                <Box>
                                  <Box sx={{ paddingLeft: "50px" }}>
                                    ₹ {grandTotalPrice} /-
                                  </Box>
                                </Box>
                              </Box>
                              <Divider />
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            xl={4}
                            className="expense-category-form-input"
                          >
                            <InputLabel
                              htmlFor="currency"
                              sx={{ marginBottom: "8px" }}
                            >
                              Buyer*
                            </InputLabel>
                            <Autocomplete
                              defaultValue={
                                id ? singleData?.data?.buyer?.firstName : ""
                              }
                              disablePortal
                              size="small"
                              options={
                                buyerWithWareHouseData?.length > 0
                                  ? buyerWithWareHouseData?.map((recent) => {
                                      return {
                                        label: recent?.firstName || "",
                                        value: recent?._id || "",
                                      };
                                    })
                                  : []
                              }
                              name="buyer"
                              onChange={(event, value) => {
                                setRecentWarehouseValues(value?.value);
                                setFieldValue("buyer", value?.value);
                              }}
                              error={touched.buyer && errors.buyer}
                              renderInput={(params) => {
                                const findVal =
                                  buyerWithWareHouseData?.length > 0
                                    ? buyerWithWareHouseData?.find(
                                        (data) =>
                                          data?._id === recentWarehouseValues,
                                      )
                                    : [];
                                return (
                                  <TextField
                                    {...params}
                                    value={findVal?.firstName}
                                    placeholder="Buyers"
                                  />
                                );
                              }}
                            />
                            {touched.buyer && errors.buyer && (
                              <FormHelperText error id="helper-text-name">
                                {errors.buyer}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            xl={4}
                            className="expense-category-form-input"
                          >
                            <InputLabel
                              htmlFor="currency"
                              sx={{ marginBottom: "8px" }}
                            >
                              WareHouse*
                            </InputLabel>
                            <Autocomplete
                              defaultValue={
                                id ? singleData?.data?.warehouse?.name : ""
                              }
                              disablePortal
                              size="small"
                              options={
                                buyerWithWareHouseData?.[0]?.warehouse?.length >
                                0
                                  ? buyerWithWareHouseData?.[0]?.warehouse?.map(
                                      (recent) => {
                                        return {
                                          label: recent?.name,
                                          value: recent?._id,
                                        };
                                      },
                                    )
                                  : []
                              }
                              // value={recentLabels}
                              name="warehouse"
                              onChange={(event, value) => {
                                setRecentWarehouseValues(value?.value);
                                setFieldValue("warehouse", value?.value);
                              }}
                              error={touched.warehouse && errors.warehouse}
                              renderInput={(params) => {
                                const findVal =
                                  buyerWithWareHouseData[0]?.warehouse?.find(
                                    (data) =>
                                      data?._id === recentWarehouseValues,
                                  );
                                return (
                                  <TextField
                                    {...params}
                                    value={findVal?.name}
                                    placeholder="Warehouse"
                                  />
                                );
                              }}
                            />
                            {touched.warehouse && errors.warehouse && (
                              <FormHelperText error id="helper-text-name">
                                {errors.warehouse}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            xl={4}
                            className="profile-form-main-input"
                          >
                            <Stack spacing={1}>
                              <InputLabel
                                htmlFor="invoice"
                                className="purchases-lable"
                              >
                                Address Line 1*
                              </InputLabel>
                              <OutlinedInput
                                id="shippingAddress.addressLineOne"
                                fullWidth
                                value={values?.shippingAddress?.addressLineOne}
                                name="shippingAddress.addressLineOne"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Please Enter Location"
                                error={Boolean(
                                  touched?.shippingAddress?.addressLineOne &&
                                    errors?.shippingAddress?.addressLineOne,
                                )}
                              />
                              {touched?.shippingAddress?.addressLineOne &&
                                errors?.shippingAddress?.addressLineOne && (
                                  <FormHelperText
                                    error
                                    id="helper-text-shortname"
                                  >
                                    {errors?.shippingAddress?.addressLineOne}
                                  </FormHelperText>
                                )}
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            xl={4}
                            className="expense-category-form-input"
                          >
                            <Stack spacing={1}>
                              <InputLabel htmlFor="addreshLine2">
                                Address line 2*
                              </InputLabel>
                              <TextField
                                size="small"
                                id="shippingAddress.addressLineTwo"
                                fullWidth
                                value={values?.shippingAddress?.addressLineTwo}
                                name="shippingAddress.addressLineTwo"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Please Enter Address Line 2"
                              />
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            xl={4}
                            className="expense-category-form-input"
                          >
                            <Stack spacing={1}>
                              <InputLabel htmlFor="city"> City*</InputLabel>
                              <TextField
                                size="small"
                                id="shippingAddress.city"
                                fullWidth
                                value={values?.shippingAddress?.city}
                                name="shippingAddress.city"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Please Enter City"
                                error={Boolean(
                                  touched?.shippingAddress?.city &&
                                    errors?.shippingAddress?.city,
                                )}
                              />
                              {touched?.shippingAddress?.city &&
                                errors?.shippingAddress?.city && (
                                  <FormHelperText
                                    error
                                    id="helper-text-shortname"
                                  >
                                    {errors?.shippingAddress?.city}
                                  </FormHelperText>
                                )}
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            xl={4}
                            md={4}
                            className="expense-category-form-input"
                          >
                            <Stack spacing={1}>
                              <InputLabel htmlFor="country">
                                {" "}
                                Pincode*{" "}
                              </InputLabel>
                              <TextField
                                size="small"
                                id="shippingAddress.pincode"
                                value={values?.shippingAddress?.pincode}
                                name="shippingAddress.pincode"
                                onBlur={handleBlur}
                                type="number"
                                onChange={handleChange}
                                placeholder="Please Enter Post Code"
                                fullWidth
                                error={Boolean(
                                  touched?.shippingAddress?.pincode &&
                                    errors?.shippingAddress?.pincode,
                                )}
                              />
                              {touched?.shippingAddress?.pincode &&
                                errors?.shippingAddress?.pincode && (
                                  <FormHelperText
                                    error
                                    id="helper-text-shortname"
                                  >
                                    {errors?.shippingAddress?.pincode}
                                  </FormHelperText>
                                )}
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            xl={4}
                            md={4}
                            className="expense-category-form-input"
                          >
                            <InputLabel
                              htmlFor="currency"
                              sx={{ marginBottom: "8px" }}
                            >
                              State*
                            </InputLabel>
                            <Autocomplete
                              defaultValue={
                                id
                                  ? findBuyerAdd?.sellingProduct?.warehouse
                                      ?.state
                                  : ""
                              }
                              disablePortal
                              size="small"
                              options={
                                allStateList?.stateList?.length > 0
                                  ? allStateList?.stateList?.map((recent) => ({
                                      label: recent?.state,
                                      value: recent?.id,
                                    }))
                                  : []
                              }
                              // value={recentLabels}
                              name="state"
                              onChange={(event, value) => {
                                setRecentWarehouseValues(value?.value);
                                setFieldValue("state", value?.label);
                              }}
                              error={touched.state && errors.state}
                              renderInput={(params) => {
                                const findVal = allStateList?.stateList?.find(
                                  (data) => data?.id === recentWarehouseValues,
                                );
                                return (
                                  <TextField
                                    {...params}
                                    value={findVal?.state}
                                    placeholder="State"
                                  />
                                );
                              }}
                            />
                            {touched.state && errors.state && (
                              <FormHelperText error id="helper-text-name">
                                {errors.state}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box className="puchases-create-button">
                      <ButtonComponent
                        className="puchases-create-button-width"
                        name={id ? "Update" : "Save"}
                        isName={true}
                        Icon={SaveAsIcon}
                        type="submit"
                        disabledbtn={userRoleAccess?.userPermissions?.find(
                          (data) =>
                            data?.userModule == "order" ||
                            userRoleAccess?.userRoleType == "admin",
                        )}
                      />
                    </Box>
                  </Grid>
                </Form>
              </div>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};
OrderForm.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
  isEdit: PropTypes.any,
  singleData: PropTypes.any,
};
export default OrderForm;
