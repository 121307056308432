import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CommonTable from "../../@extended/table/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import { BUYER_ENQUIRY } from "../../../actions/productActions";

const BuyerEnquiryTable = (props) => {
  const { columns, search, type } = props;
  const dispatch = useDispatch();

  const buyerEnquiryListData = useSelector(
    (data) => data?.product?.buyerEnquiryList,
  );
  const isProductSuccess = useSelector(
    (data) => data?.product?.isProductSuccess,
  );
  const { totalResults = 0, limit, page } = buyerEnquiryListData;
  const [limitState, setLimit] = useState(10);
  const [pageState, setPage] = useState(1);

  const productList = useCallback((values) => {
    dispatch({ type: BUYER_ENQUIRY, payload: values });
  }, []);
  const rows = buyerEnquiryListData?.results?.map((item, ind) => {
    return {
      id: ind + 1,
      no: ind + 1,
      materialName: item?.materialName,
      qty: item?.qty,
      firstName: item?.firstName,
      lastName: item?.lastName,
      workEmail: item?.workEmail,
      phoneNumber: item?.phoneNumber,
      isRead: item?.isRead,
      rowId: item?.id,
    };
  });

  useEffect(() => {
    if (buyerEnquiryListData?.limit) {
      setLimit(buyerEnquiryListData?.limit);
    }
    if (buyerEnquiryListData?.page) {
      setPage(buyerEnquiryListData?.page);
    }
  }, [buyerEnquiryListData?.limit, buyerEnquiryListData?.page]);

  useEffect(() => {
    productList({ limitState, pageState, search, status: type });
  }, [limitState, pageState, isProductSuccess, search]);
  return (
    <Box className="email-query-box">
      <Box className="email-query-common-table-box-main">
        <Box className="email-query-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
            pageData={{ limit, page, totalResults, setLimit, setPage }}
          />
        </Box>
      </Box>
    </Box>
  );
};
BuyerEnquiryTable.propTypes = {
  columns: PropTypes.any,
  type: PropTypes.any,
  search: PropTypes.any,
  setDeleteModal: PropTypes.any,
};
export default BuyerEnquiryTable;
