import {
  BUYER_ADD,
  BUYER_ADD_FAIL,
  BUYER_ADD_SUCCESS,
  BUYER_DELETE,
  BUYER_DELETE_FAIL,
  BUYER_DELETE_SUCCESS,
  BUYER_LIST,
  BUYER_LIST_FAIL,
  BUYER_LIST_SUCCESS,
  BUYER_SINGLE,
  BUYER_SINGLE_FAIL,
  BUYER_SINGLE_SUCCESS,
  BUYER_SINGLE_UPDATE,
  BUYER_SINGLE_UPDATE_FAIL,
  BUYER_SINGLE_UPDATE_SUCCESS,
  BUYER_UPDATE,
  BUYER_UPDATE_FAIL,
  BUYER_UPDATE_SUCCESS,
  BUYER_WITH_WAREHOUSE,
  BUYER_WITH_WAREHOUSE_FAIL,
  BUYER_WITH_WAREHOUSE_SUCCESS,
} from "../actions/buyerActions";
import { API } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";

//expense saga

function* buyerList(action) {
  try {
    const response = yield call(API.buyerList, action.payload);
    yield put({ type: BUYER_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BUYER_LIST_FAIL, payload: { error: error } });
  }
}
function* addBuyerList(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.addBuyer, otherPayload);
    yield put({ type: BUYER_ADD_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: BUYER_ADD_FAIL, payload: { error: error } });
  }
}
function* deleteBuyer(action) {
  try {
    const response = yield call(API.deleteBuyer, action.payload);
    yield put({ type: BUYER_DELETE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BUYER_DELETE_FAIL, payload: { error: error } });
  }
}
function* singleSeller(action) {
  try {
    const response = yield call(API.singleBuyer, action.payload);
    yield put({ type: BUYER_SINGLE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BUYER_SINGLE_FAIL, payload: { error: error } });
  }
}
function* updateSeller(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.updateBuyer, otherPayload);
    yield put({ type: BUYER_UPDATE_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: BUYER_UPDATE_FAIL, payload: { error: error } });
  }
}
function* sellerSingleUpdate(action) {
  try {
    const response = yield call(API.buyerSingleUpdate, action.payload);
    yield put({ type: BUYER_SINGLE_UPDATE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BUYER_SINGLE_UPDATE_FAIL, payload: { error: error } });
  }
}

function* buyerWithWareHouse(action) {
  try {
    const response = yield call(API.buyerWithWareHouse, action.payload);
    yield put({
      type: BUYER_WITH_WAREHOUSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BUYER_WITH_WAREHOUSE_FAIL,
      payload: { error: error },
    });
  }
}

function* buyer() {
  yield takeLatest(BUYER_LIST, buyerList);
  yield takeLatest(BUYER_ADD, addBuyerList);
  yield takeLatest(BUYER_DELETE, deleteBuyer);
  yield takeLatest(BUYER_SINGLE, singleSeller);
  yield takeLatest(BUYER_UPDATE, updateSeller);
  yield takeLatest(BUYER_SINGLE_UPDATE, sellerSingleUpdate);
  yield takeLatest(BUYER_WITH_WAREHOUSE, buyerWithWareHouse);
}
export default buyer;
