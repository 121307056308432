import { Box } from "@mui/material";
import React from "react";
import NotifyMessage from "../../components/@extended/notify/NotifyMessage";
import BreadCrumb from "../../components/@extended/breadcrumbs/Breadcrumbs";
import EmailQuery from "../../components/dashboard/emailquery/EmailQuery";

const EmailQueryPage = () => {
  return (
    <Box>
      <BreadCrumb title="Email Queries" perentTabName="Email Queries" />
      <Box margin={"40px"}></Box>
      <NotifyMessage
        cardIcon={"MailIcon"}
        heading="Email setting not configured"
        description="Please configure your email settings to send emails. Click Here to configure email settings."
        backgroundColor="var( --email-setting-color)"
        borderColor="var(--notify-border-color)"
        variant="outlined"
        className="outlined"
        name="Configure"
        Icon="Icon"
        EndIcon="EndIcon"
      />
      <EmailQuery />
    </Box>
  );
};
export default EmailQueryPage;
