import { SET_IS_SIDEBAR_OPEN } from "../actions/sidebarActions";

const initialState = {
  isOpen: false,
};
const sidebarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_SIDEBAR_OPEN: {
      return {
        ...state,
        isOpen: payload,
      };
    }

    default: {
      return { ...state };
    }
  }
};
export default sidebarReducer;
