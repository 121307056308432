import React, { useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import ProductSettingTable from "../dashboard/product/ProductSettingTable";
import ButtonComponent from "../@extended/button/ButtonComponent";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CustomizedModal from "../@extended/customizedmodal/CustomizedModal";
import ProductSettingForm from "../dashboard/product/ProductSettingForm";

const ProductSetting = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState("");
  const columns = [
    {
      field: "no",
      headerName: "No",
      headerClassName: "email-query-header",
    },
    {
      field: "filedName",
      headerName: "Name",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
      headerClassName: "email-query-header",
    },

    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "email-query-header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="email-query-table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              disabledbtn={true}
              onClick={() => {
                setOpen(true);
                setRowData(params?.row);
              }}
            />
          </Box>
        );
      },
    },
  ];
  return (
    <>
      <Box className={isMobile ? "main-div" : "mobile-main-div"}>
        <ProductSettingTable columns={columns} setOpen={setOpen} />
        {open && (
          <CustomizedModal
            isHeadingName={true}
            heading="Product Setting Form"
            open={open}
            onClick={() => {
              setOpen(false);
              setRowData("");
            }}
          >
            <ProductSettingForm
              onClick={() => {
                setOpen(false);
                setRowData("");
              }}
              rowData={rowData}
            />
          </CustomizedModal>
        )}
      </Box>
    </>
  );
};

export default ProductSetting;
