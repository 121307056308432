import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PropTypes from "prop-types";

const CommonTab = (props) => {
  const { data } = props;
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {data?.map((item, ind) => {
              return (
                <Tab
                  key={ind}
                  label={item?.label}
                  value={item?.value}
                  icon={item?.icon}
                  iconPosition="start"
                />
              );
            })}
          </TabList>
        </Box>
        {data?.map((comp, ind) => {
          return (
            <TabPanel key={ind} value={comp?.value}>
              {comp?.component}
            </TabPanel>
          );
        })}
      </TabContext>
    </Box>
  );
};

CommonTab.propTypes = {
  data: PropTypes.any,
};
export default CommonTab;
