export const ROLE_ADD = "ROLE_ADD";
export const ROLE_ADD_SUCCESS = "ROLE_ADD_SUCCESS";
export const ROLE_ADD_FAIL = "ROLE_ADD_FAIL";

export const LIST_ROLE = "LIST_ROLE";
export const LIST_ROLE_SUCCESS = "LIST_ROLE_SUCCESS";
export const LIST_ROLE_FAIL = "LIST_ROLE_FAIL";

export const LIST_SINGLE_ROLE = "LIST_SINGLE_ROLE";
export const LIST_SINGLE_ROLE_SUCCESS = "LIST_SINGLE_ROLE_SUCCESS";
export const LIST_SINGLE_ROLE_FAIL = "LIST_SINGLE_ROLE_FAIL";

export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";
